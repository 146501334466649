import React, {Component} from 'react';
import { ClientService } from '../../service/ClientService';
import {DataTable} from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import {Column} from 'primereact/column';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";

class PaymentHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            sortOrder: -1,
            sortField: 'id',
            paymentReference: '',
            reference: '',
            loading: false
        };

        this.clientService = new ClientService();
        this.adalipaUtil = new AdalipaUtil();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.dateTemplate = this.dateTemplate.bind(this);
        this.amountTemplate = this.amountTemplate.bind(this);
    }

    componentDidMount() {
        this.clientService.getSmsPaymentReference()
        .then(data => {
            this.setState({reference: data.paymentReference});
        });
        this.setState({loading: true});
        this.clientService.getSmsPaymentHistory(0, this.state.pageSize, 'id', 'asc')
        .then(data => {
            this.setState({loading: false});
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    onPageChange(event) {
        this.setState({loading: true});
        this.clientService.getSmsPaymentHistory(event.page, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
        .then(data => {
            this.setState({loading: false});
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
            this.setState({first: event.first, pageSize: event.rows});
        });
    }

    onSortChange(event) {
        this.setState({loading: true});
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.clientService.getSmsPaymentHistory(0, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({loading: false});
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.setState({loading: true});
            this.clientService.getSmsPaymentHistory(0, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({loading: false});
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });
        });     
    }

    dateTemplate(rowData, column) {
        return new Intl.DateTimeFormat('sw', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.parse(rowData['updatedTime']));
    }
    amountTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['amount']);
    }

    render() {

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <h3>{this.props.t('Sms Payments Guide')}</h3>
                            <div className="p-grid p-col-12 p-md-12">
                                <div className="p-col-12 p-md-6 card card-w-title div-rounded p-messages p-component p-messages-info" style={{backgroundColor: "#3B007B"}}>
                                    <div className="p-messages-wrapper" style={{textAlign: "center"}}>
                                        <h3 className="p-messages-detail">{this.props.t('Enter Company Payment Number')} - 400700</h3>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6 card card-w-title div-rounded p-messages p-component p-messages-info" style={{backgroundColor: "#3B007B"}}>
                                    <div className="p-messages-wrapper" style={{textAlign: "center"}}>
                                        <h3 className="p-messages-detail">{this.props.t('Enter Payment Reference Number')} - {this.state.reference}</h3>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-12">
                                    <div className="p-messages-wrapper" style={{textAlign: "center", fontWeight: "bold"}}>
                                        <span>{this.props.t('Payments are being done through Mobile Money using the business number 400700. Your reference number is')} {this.state.reference}.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="divider-container"><div className="divider-border" />
                        <span className="divider-content"><h3 style={{textAlign: "center"}}>{this.props.t('Sms Payments History')}</h3></span>
                        <div className="divider-border" /></div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" paginator={false} 
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="institution.instituteName" header={this.props.t("Client")} sortable={true} filter={false}/>
                            <Column field="amount" header={this.props.t("Amount")} body={this.amountTemplate} sortable={true} filter={false}/>
                            <Column field="numberOfSms" header={this.props.t("Number Of Sms")} sortable={true} filter={false}/>
                            <Column field="txnTime" header={this.props.t("Requested Time")} body={this.dateTemplate} sortable={true} filter={false}/>
                        </DataTable>
                        <Paginator currentPageReportTemplate={"Data " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(PaymentHistory)
