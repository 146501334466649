import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { LgaService } from '../../service/LgaService';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import { Dropdown } from 'primereact/dropdown';
import { format } from 'date-fns';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";

class LgaSalaries extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            employees: [],
            users: [],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            employeeId: 0,
            userId: 0,
            txnId: '',
            account: '',
            month: '',
            status: '',
            search: '',
            sortOrder: -1,
            sortField: 'id',
            loading: false
        };

        this.lgaService = new LgaService();
        this.adalipaUtil = new AdalipaUtil();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.export = this.export.bind(this);
        this.createdAtTemplate = this.createdAtTemplate.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);

        this.cols = [
            { field: 'fullName', header: this.props.t('Full Name') },
            { field: 'employeeType', header: this.props.t('Employee Type') },
            { field: 'txnId', header: this.props.t('Transaction Id') },
            { field: 'account', header: this.props.t('Account') },
            { field: 'salaryAmount', header: this.props.t('Salary Amount') },
            { field: 'disbursedAmount', header: this.props.t('Disbursed Amount') },
            { field: 'sentByName', header: this.props.t('Sent By') },
            { field: 'createdAt', header: this.props.t('Created At') },
        ];
        this.excelCols = {header: ['fullName', 'employeeType', 'txnId', 'account', 'salaryAmount', 'disbursedAmount', 'sentByName', 'createdAt']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    getDataList = () => {
        let {employeeId, txnId, account, month, status, search, pageIndex, pageSize, sortField, sortOrder} = this.state;
        this.setState({loading: true});
        return this.lgaService.getLgaSalaries({employeeId: employeeId, txnId: txnId, account: account, month: month, status: status, search: search, page: pageIndex, limit: pageSize, sort: sortField, order: sortOrder === 1 ? 'asc' : 'desc'})
        .then(data => {
            this.setState({loading: false});
            const dataContent = data.content.map(d => ({
                ...d,
                employeeName: d.employee.fullName + " (" + d.employee.employeeType + ")",
                fullName: d.employee.fullName,
                employeeType: d.employee.employeeType,
                salaryAmount: this.adalipaUtil.amountFormat(d.salaryAmount),
                disbursedAmount: this.adalipaUtil.amountFormat(d.disbursedAmount),
                sentByName: d.sentBy.fullName
            }))
            this.setState({dataTableValue: dataContent});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    componentDidMount() {
        this.getDataList();
        const jsonParams = { search: '', page: 0, limit: 100, sort: "id", order: 'asc' }
        this.lgaService.getLgaEmployees(jsonParams)
        .then(data => {
            const dataContent = data.content;
            const options = dataContent.map(d => ({
                "id" : d.id,
                "name" : d.fullName + " (" + d.employeeType + ")"
            }))
            this.setState({employees: options});
        });
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            })
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });     
    }

    handleDropdownChange(event) {
        const target = event;
        const inputName = target.target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });
    }

    resendSalary(salaryId) {
        this.lgaService.resendLgaSalary(salaryId)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    export() {
        this.dt.exportCSV();
    }

    createdAtTemplate(rowData, column) {
        return format(Date.parse(rowData['createdAt']), 'yyyy-MM-dd HH:mm:ss');
    }

    actionTemplate(rowData, column) {
        return (
            <div style={{whiteSpace: "nowrap"}}>
                { rowData["id"] !== "SENT" ?
                    <Button type="button" onClick={() => this.resendSalary(rowData["id"])} icon="pi pi-arrow-right" className="p-button-danger" title={this.props.t("Resend") + " " + this.props.t("Salary")}/>
                    :
                    <Button type="button" icon="pi pi-arrow-right" className="p-button-default" style={{backgroundColor: "#EEEFFF"}} title={this.props.t("Resend") + " " + this.props.t("Salary")}/>
                }
            </div>
        );
    }

    render() {

        let header = (
            <div style={{textAlign:'left'}}>
                <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
                <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel(this.props.t("Salaries"), this.excelCols, this.state.dataTableValue)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
                <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable(this.props.t("List of Salaries"), this.exportColumns, this.state.dataTableValue)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
            </div>
        );

        let fullNameFilter = <Dropdown name="employeeId" style={{width: '100%'}} placeholder={this.props.t("Choose") + "..."} options={this.state.employees} value={this.state.employeeId} onChange={this.handleDropdownChange} optionLabel="name" optionValue="id" filter filterBy="name" autoWidth={false} />
        let txnIdFilter = <InputText type="text" name="txnId" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.txnId} onChange={this.handleInputChange}/>
        let accountFilter = <InputText type="text" name="account" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.account} onChange={this.handleInputChange}/>
        let monthFilter = <InputText type="text" name="month" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.month} onChange={this.handleInputChange}/>
        let statusFilter = <InputText type="text" name="status" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.status} onChange={this.handleInputChange}/>
        let sentByNameFilter = <Dropdown name="sentById" style={{width: '100%'}} placeholder={this.props.t("Choose") + "..."} options={this.state.users} value={this.state.userId} onChange={this.handleDropdownChange} optionLabel="name" optionValue="id" filter filterBy="name" autoWidth={false} />

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>{this.props.t('List of Salaries')}</h2></div>
                            <div className="p-col-8 p-md-8">
                                <Link to="/wallet/lga/salaries/disburse" style={{float: "right"}}><Button label={this.props.t("Disburse Salaries")} icon="pi pi-plus"/></Link>
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})} 
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="employeeName" header={this.props.t("Employee")} sortable={true} filter={true} filterElement={fullNameFilter}/>
                            <Column field="txnId" header={this.props.t("Transaction Id")} sortable={true} filter={true} filterElement={txnIdFilter}/>
                            <Column field="account" header={this.props.t("Account")} sortable={true} filter={true} filterElement={accountFilter}/>
                            <Column field="salaryAmount" header={this.props.t("Salary")} sortable={true} filter={false} style={{textAlign: "right"}}/>
                            <Column field="disbursedAmount" header={this.props.t("Disbursed Amount")} sortable={true} filter={false} style={{textAlign: "right"}}/>
                            <Column field="sentByName" header={this.props.t("Sent By")} sortable={true} filter={true} filterElement={sentByNameFilter}/>
                            <Column field="month" header={this.props.t("Month")} sortable={true} filter={true} filterElement={monthFilter}/>
                            <Column field="status" header={this.props.t("Status")} sortable={true} filter={true} filterElement={statusFilter}/>
                            <Column field="createdAt" header={this.props.t("Sent At")} body={this.createdAtTemplate} sortable={true} filter={false}/>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '4em'}}/>
                        </DataTable>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(LgaSalaries)