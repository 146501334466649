import { BI } from '../api';
import {getRequest, queryParams } from './CoreService';

export class BiService{

    getSettlementCharges(jsonParams){
        return getRequest({
            url: BI +'/settlement/charges'+ queryParams(jsonParams),
            method: 'GET'
        });
    }

    

    getSettlementChargesTotal(jsonParams){
        return getRequest({
            url: BI +'/settlement/charges/totals'+ queryParams(jsonParams),
            method: 'GET'
        });
    }

    getSettlementChargesList(jsonParams){
        return getRequest({
            url: BI +'/settlement/charges/export'+ queryParams(jsonParams),
            method: 'GET'
        })

    }

    getPaymentCharges(jsonParams){
        return getRequest({
            url: BI +'/payment/charges'+ queryParams(jsonParams),
            method: 'GET'
        });
    }

    getPaymentChargesTotal(jsonParams){
        return getRequest({
            url: BI +'/payment/charges/totals'+ queryParams(jsonParams),
            method: 'GET'
        });
    }

    getPaymentChargesList(jsonParams){
        return getRequest({
            url: BI +'/payment/charges/export'+ queryParams(jsonParams),
            method: 'GET'
        })

    }

}