import React, {Component} from 'react';
import { renderToString } from 'react-dom/server';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { withTranslation } from "react-i18next";
import jsPDF from 'jspdf';
import { WalletService } from '../../service/WalletService';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import { BeatLoader } from 'react-spinners';
import { ClientService } from '../../service/ClientService';
import { Dropdown } from 'primereact/dropdown';

class CharesReport extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.abortController = new AbortController();
        
        this.state = {
            dataTableValue:[],
            pdfData:[],
            excelData:[],
            institutios: [],
            institutionId: 0,
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            totalCollection: 0,
            totalMlipaCharges: 0,
            totalDviCharges: 0,
            institutionName: '',
            institutionCode: '',           
            Status: null,
            error: null,
            failed: false,
            reqTimeFrom: '',
            reqTimeTo: '',
            sortOrder: -1,
            sortField: 'institutionName',
            report_category: 'INSTITUTION_REPORT',
            pdf_report_name: "institutionPaymentReport.pdf",
            xlsx_report_name: "institutionPaymentReport.xlsx",
            csv_report_name: "institutionPaymentReport.csv",
            reqTime: 'All',

            loading: false,
            displayDateFilter: false
        }
        this.walletService = new WalletService();
        this.adalipaUtil = new AdalipaUtil();
        this.clientService = new ClientService();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.amountTemplate = this.amountTemplate.bind(this);
        this.institutionChargesTemplate = this.institutionChargesTemplate.bind(this);
        this.chargesTemplate = this.chargesTemplate.bind(this);
        this.dviChargesTemplate = this.dviChargesTemplate.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.export = this.export.bind(this);
        this.dialogFooter = this.dialogFooter.bind(this);

        this.cols = [
            { field: 'reqTime', header: 'Request Time' },
            {field:  'institutionName', header: 'Customer Name'},
            { field: 'amountCollected', header: 'Collected Amount' },          
            { field: 'mlipaCharges', header: 'Mlipa Charges' },
            { field: 'dviCharges', header: 'Adalipa Charges' },
            { field: 'instituteCharges', header: 'Client Charges' }
            

        ];
        this.excelCols = {header: ['reqTime', 'institutionName', 'amountCollected', 'mlipaCharges','dviCharges','instituteCharges']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    getDataList=()=>{
        let {institutionId, reqTimeFrom, reqTimeTo, pageIndex, pageSize, sortField, sortOrder} = this.state;
        const rqFr = this.adalipaUtil.cleanDateFormat(reqTimeFrom);
        const rqTo = this.adalipaUtil.cleanDateFormat(reqTimeTo);
        const paramsData = {institutionId: institutionId, reqTimeFrom: rqFr, reqTimeTo: rqTo, page: pageIndex, limit: pageSize, sort: sortField, order: sortOrder === 1 ? 'asc' : 'desc'};
        return this.walletService.getTotalInsttutionalCharges(paramsData,this.abortController.signal)
        .then(data => {
            let sumOfCollections = 0;
            let sumOfMlipaCharges = 0;
            let sumOfDviCharges = 0;
            data.chargesList.forEach(element => {
                sumOfCollections = element.amountCollected + sumOfCollections;
                sumOfMlipaCharges = element.mlipaCharges + sumOfMlipaCharges;
                sumOfDviCharges = element.dviCharges + sumOfDviCharges;
            });
            console.log("Total Collections",sumOfCollections);
            this.setState({ totalCollection: sumOfCollections });
            this.setState({ totalMlipaCharges: sumOfMlipaCharges });
            this.setState({ totalDviCharges: sumOfDviCharges });

            this.setState({loading: false});
            if(reqTimeFrom.toString().length > 0 && reqTimeTo.toString().length > 0){
                this.setState({dataTableValue: data.chargesPage.content.map(d => ({reqTime: (this.dateTemplate(this.state.reqTimeFrom)+" - "+this.dateTemplate(this.state.reqTimeTo)),institutionName: d.institutionName, amountCollected: d.amountCollected, mlipaCharges: d.mlipaCharges,dviCharges: d.dviCharges,instituteCharges:d.instituteCharges})) });
                this.setState({excelData: data.chargesList.map(d => ({reqTime: (this.dateTemplate(this.state.reqTimeFrom)+" - "+this.dateTemplate(this.state.reqTimeTo)),institutionName: d.institutionName, amountCollected: d.amountCollected, mlipaCharges: d.mlipaCharges,dviCharges: d.dviCharges,instituteCharges:d.instituteCharges})) });
                this.setState({pdfData: data.chargesList.map(d => ({reqTime: (this.dateTemplate(this.state.reqTimeFrom)+" - "+this.dateTemplate(this.state.reqTimeTo)),institutionName: d.institutionName, amountCollected: d.amountCollected, mlipaCharges: d.mlipaCharges,dviCharges: d.dviCharges,instituteCharges:d.instituteCharges})) });
            }else if(reqTimeFrom.toString().length > 0){
                this.setState({dataTableValue: data.chargesPage.content.map(d => ({reqTime: (this.dateTemplate(this.state.reqTimeFrom)+" - To Now"),institutionName: d.institutionName,  amountCollected: d.amountCollected, mlipaCharges: d.mlipaCharges,dviCharges: d.dviCharges,instituteCharges:d.instituteCharges})) });
                this.setState({excelData: data.chargesList.map(d => ({reqTime: (this.dateTemplate(this.state.reqTimeFrom)+" - To Now"),institutionName: d.institutionName,  amountCollected: d.amountCollected, mlipaCharges: d.mlipaCharges,dviCharges: d.dviCharges,instituteCharges:d.instituteCharges})) });
                this.setState({pdfData: data.chargesList.map(d => ({reqTime: (this.dateTemplate(this.state.reqTimeFrom)+" - To Now"),institutionName: d.institutionName,  amountCollected: d.amountCollected, mlipaCharges: d.mlipaCharges,dviCharges: d.dviCharges,instituteCharges:d.instituteCharges})) });
            }else if(reqTimeTo.toString().length > 0){
                this.setState({dataTableValue: data.chargesPage.content.map(d => ({reqTime: ("All Tnxs to - "+this.dateTemplate(this.state.reqTimeTo)),institutionName: d.institutionName,  amountCollected: d.amountCollected, mlipaCharges: d.mlipaCharges,dviCharges: d.dviCharges,instituteCharges:d.instituteCharges})) });
                this.setState({excelData: data.chargesList.map(d => ({reqTime: ("All Tnxs to - "+this.dateTemplate(this.state.reqTimeTo)),institutionName: d.institutionName,  amountCollected: d.amountCollected, mlipaCharges: d.mlipaCharges,dviCharges: d.dviCharges,instituteCharges:d.instituteCharges})) });
                this.setState({pdfData: data.chargesList.map(d => ({reqTime: ("All Tnxs to - "+this.dateTemplate(this.state.reqTimeTo)),institutionName: d.institutionName,  amountCollected: d.amountCollected, mlipaCharges: d.mlipaCharges,dviCharges: d.dviCharges,instituteCharges:d.instituteCharges})) });
            }else{
                this.setState({dataTableValue: data.chargesPage.content.map(d => ({reqTime: ("All Time Transactions"),institutionName: d.institutionName,  amountCollected: d.amountCollected, mlipaCharges: d.mlipaCharges,dviCharges: d.dviCharges,instituteCharges:d.instituteCharges})) });
                this.setState({excelData: data.chargesList.map(d => ({reqTime: ("All Time Transactions"),institutionName: d.institutionName,  amountCollected: d.amountCollected, mlipaCharges: d.mlipaCharges,dviCharges: d.dviCharges,instituteCharges:d.instituteCharges})) });
                this.setState({pdfData: data.chargesList.map(d => ({reqTime: ("All Time Transactions"),institutionName: d.institutionName,  amountCollected: d.amountCollected, mlipaCharges: d.mlipaCharges,dviCharges: d.dviCharges,instituteCharges:d.instituteCharges})) });
            }            
            
            this.setState({
                pageIndex: data.chargesPage.pageable.pageNumber,
                // pageSize: data.chargesPage.pageable.pageSize,
                totalPages: data.chargesPage.totalPages,
                numberOfElements: data.chargesPage.numberOfElements,
                totalElements: data.chargesPage.totalElements,
                first: (data.chargesPage.pageable.pageNumber * data.chargesPage.totalPages) + 1
            });
            // const excelOptions = data.content.map(d => ({

           this.getAllInstitutions();
            
        })
        .catch(error => {
            console.log("Error",error.message);
            this.setState({failed: true});
            this.setState({error: error.message});
        })
    }

    getAllInstitutions= ()=>{
        const jsonParams = { page: 0, limit: 100, sort: "id", order: 'asc' }
        this.clientService.getAllInstitutions(jsonParams)
        .then(resp =>{
           const institutions = resp.map(d => ({
            "name": d.instituteName+"-"+d.id,
            "id": d.id
           }));
           this.setState({institutions: institutions});
        })
    }

    componentDidMount() {
        this._isMounted = true;
        this.getDataList();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        // Clean up subscriptions or cancel async tasks here
        this.abortController.abort();
      }
    

    onPageChange(event) {
        if(this._isMounted){
            console.log("Event", event);
            this.setState({pageIndex: event.page, pageSize: event.rows}, () => {                
                this.getDataList().then(res => {
                   this.setState({first: event.first, pageSize: event.rows});
                }).catch(error => {
                    console.log("Error",error.message);
                })
            });
        }        
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });   
    }

    handleDropdownChange(event) {
        const target = event;
        const inputName = target.target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });
    }

    onDateFilter() {
        this.setState({displayDateFilter: false});
        this.getDataList();
    }

    clearDateFilter = () => {
        this.setState({
            reqTimeFrom: "",
            reqTimeTo: ""
        }, () => {
            this.getDataList()
        });
    }

    dialogFooter = () => <div className="p-grid">
        <div className="p-col-6 p-text-left">
            <Button type="button" label="Dismiss" onClick={() => this.setState({displayDateFilter: false})} icon="pi pi-times-circle" className="p-button-warning" />
        </div>
    </div>;

    dateTemplate(rowData) {
        return new Intl.DateTimeFormat('sw', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.parse(rowData));
    }
    amountTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['amountCollected']);
    }
    chargesTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['mlipaCharges']);
    }
    dviChargesTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['dviCharges']);
    }
    institutionChargesTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['instituteCharges']);
    }

    collectionTemplate(collection){
        console.log("Colection",collection);
        return this.adalipaUtil.amountFormat(collection);
    }

    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
            <Button type="button" onClick={() => this.onPrinterClick(rowData)} icon="pi pi-print" className="p-button p-button-sm p-button-info" style={{padding: "0px"}} title={this.props.t("Print")}/>
            { this.props.institutionType === "Local Government Authority" && 
                <Button type="button" onClick={() => this.onTransferClick(rowData)} icon="pi pi-arrow-circle-right" className="p-button p-button-sm p-button-danger" style={{padding: "0px"}} title={this.props.t("Transfer")}/>
            }
        </div>;
    }

    export() {
        this.dt.exportCSV();
    }

  

    onTransferClick(data) {
        this.props.history.push("/wallet/lga/pay/transfer/0/" + data["id"]);
    }

    render() { 
        let header = (
            <div style={{textAlign:'left'}}>
                <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
                <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel("Charges Summary", this.excelCols, this.state.excelData)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
                <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable("Charges Summary", this.exportColumns, this.state.pdfData)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
            </div>
        );
        let dateFilter = <div style={{padding: "0px", margin: "0px"}}>
            <Link to="#" onClick={() => this.setState({displayDateFilter: true})}><i className="pi pi-calendar" style={{marginLeft: "0px", paddingLeft: "0px", verticalAlign: "bottom"}}></i><small>{this.props.t("Filter")}</small></Link>&nbsp;&nbsp;
            <Link to="#" onClick={() => this.clearDateFilter()}><i className="pi pi-times-circle" style={{marginLeft: "0px", paddingLeft: "0px", verticalAlign: "bottom"}}></i><small>{this.props.t("Clear")}</small></Link>
        </div>     
        // let institutionCodeFilter = <InputText type="text" name="institutionCode" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.institutionCode} onChange={this.handleInputChange}/>
        //let institutionNameFilter = <InputText type="text" name="institutionName" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.institutionName} onChange={this.handleInputChange}/>
        let institutionNameFilter = <Dropdown name="institutionId" style={{width: '100%'}} placeholder={this.props.t("Choose Institution")} filterPlaceholder={this.props.t("Search") + " " + this.props.t("Institution")} options={this.state.institutions} value={this.state.institutionId} onChange={this.handleDropdownChange} optionLabel="name" optionValue="id" filter filterBy="name" autoWidth={false} />
        // let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>{this.props.t('Charges Summary')}</h2></div>
                            <div className="p-col-8 p-md-8">
                                <h2 style={{float: 'right'}}>{this.collectionTemplate(this.state.totalMlipaCharges)}</h2>
                                {/* <Link to="/wallet/payments" style={{float: "right"}}><strong style={{color: '#3B007B'}}>{this.props.t('Mlipa Account')}: {this.props.mlipaAccount}</strong></Link> */}
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})} 
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="reqTime" header={this.props.t("Date Range")} sortable={true} filter={true} filterElement={dateFilter}/>
                            <Column field="institutionName" header={this.props.t("Institution Name")} sortable={true} filter={true} filterElement={institutionNameFilter} filterPlaceholder="Contains"/>
                            {/* <Column field="institutionCode" header={this.props.t("Institution Code")} sortable={true} filter={true} filterElement={institutionCodeFilter} filterPlaceholder="Contains"/>                            */}
                            <Column field="amountCollected" header={this.props.t("Total Amount Paid")} body={this.amountTemplate} sortable={true} filter={false}/>
                            <Column field="mlipaCharges" header={this.props.t("Total Mlipa Charges")} body={this.chargesTemplate} sortable={true} filter={false}/>
                            <Column field="dviCharges" header={this.props.t("Total Adalipa Charges")} body={this.dviChargesTemplate} sortable={true} filter={false}/>
                            <Column field="instituteCharges" header={this.props.t("Total Client Charges")} body={this.institutionChargesTemplate} sortable={true} filter={false}/>
                            {/* <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', fontSize: 'small', width: "10em"}}/> */}
                        </DataTable>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Dialog visible={this.state.displayDateFilter} className="p-col-12 p-md-6 p-lg-6 p-md-offset-1 p-lg-offset-2" modal onHide={() => this.setState({displayDateFilter: false})}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12 p-text-center">
                        <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">{this.props.t("Start From")}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Calendar name="reqTimeFrom" placeholder={this.props.t("Choose Date")} value={this.state.reqTimeFrom} onChange={(e) => this.setState({reqTimeFrom: e.value})} dateFormat="yy-mm-d" showIcon showButtonBar showOnFocus icon="pi pi-calendar" appendTo={document.body}/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">{this.props.t("End To")}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Calendar name="reqTimeTo" placeholder={this.props.t("Choose Date")} value={this.state.reqTimeTo} onChange={(e) => this.setState({reqTimeTo: e.value})} dateFormat="yy-mm-d" showIcon showButtonBar showOnFocus icon="pi pi-calendar" appendTo={document.body}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-5 p-md-2">
                                        <Button type="button" label="Filter" icon="pi pi-filter" onClick={() => this.onDateFilter()} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}
 
export default withTranslation()(CharesReport);
