import React, {Component} from 'react';
import {DataView} from 'primereact/dataview';
import { RealEstateService } from '../../service/RealEstateService';
import ResponseAlert from '../../Utilities/ResponseAlert';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

export class installments extends Component {

    constructor() {
        super();
        this.state = {
            installment: [],
            installments: [],
            installmentId: 0,
            initialAmount: 0,
            dataId: 0,
            layout: 'list'
        };

        this.realEstateService = new RealEstateService();
        this.adalipaUtil = new AdalipaUtil();
    }

    componentDidMount() {
        if (+this.props.match.params.dataId > 0) {
            this.setState({dataId: +this.props.match.params.dataId});
            this.realEstateService.getInstallment(+this.props.match.params.dataId)
            .then(data => {
                const installment = [];
                installment.push(data);
                this.setState({installment: installment, installmentId: data.id, initialAmount: data.initialAmount});
                let options = [];
                for (var i = 0; i < data.installments; i++) {
                    var startDate = new Date(Date.parse(data.startDate));
                    options.push({installment: format(new Date(startDate.setMonth(startDate.getMonth()+i)), "yyyy-MM-dd"), amount: data.amount, interest: ((data.amount * data.interest)/100)});
                }
                this.setState({installments: options});
            });
        }
    }

    itemTemplate(installment, layout) {
        let formatter = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'TZS',});
        if (layout === 'list') {
            return (
                <div className="p-col-12">
                    <div className="car-details">
                        <div>
                            <div className="p-grid">
                                <div className="p-col-4">Installment: <span>{installment.installment}</span></div>
                                <div className="p-col-4">Amount: <span>{formatter.format(installment.amount)}</span></div>
                                <div className="p-col-4">Interest: <span>{formatter.format(installment.interest)}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if (layout === 'grid') {
            return (
                <div className="p-col-12 p-md-3">
                    <div>{installment.amount}</div>
                </div>
            );
        }
    }

    summeryTemplate(installment, layout) {
        const totalInstallment = installment.customerFee.cost - installment.initialAmount;
        const totalIterest = ((installment.customerFee.cost - installment.initialAmount) * installment.interest) / 100;
        let formatter = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'TZS',});
        if (layout === 'list') {
            return (
                <div className="p-col-12" style={{paddingTop: "1em"}}>
                    <div className="car-details">
                        <div>
                            <div className="p-grid" style={{fontWeight: "bold", fontSize: 16}}>
                                <div className="p-col-3">Initial Amount: <span>{formatter.format(installment.initialAmount)}</span></div>
                                <div className="p-col-3">Tatal Installment: <span>{formatter.format(totalInstallment)}</span></div>
                                <div className="p-col-3">Tatal Interest: <span>{formatter.format(totalIterest)}</span></div>
                                <div className="p-col-3">Tatal Cost: <span>{formatter.format(installment.initialAmount + totalInstallment + totalIterest)}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if (layout === 'grid') {
            return (
                <div className="p-col-12 p-md-3">
                    <div>{installment.amount}</div>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <ResponseAlert failed={this.state.failed} alert={this.state.alert} />
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>Real Estate Sales</h2></div>
                            <div className="p-col-8 p-md-8">
                                <Link to={"/wallet/real/estate/installment/0/" + +this.props.match.params.dataId} style={{float: "right"}}><Button label="Add Installments" icon="pi pi-plus"/></Link>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>Payment Installment</h2></div>
                            {/* <div className="p-col-8 p-md-8">
                                <Link to={"/wallet/real/estate/installment/" + this.state.installmentId + "/" + this.state.dataId} style={{float: "right"}}><Button label="Add Installment" icon="pi pi-plus"/></Link>
                            </div> */}
                        </div>
                        <DataView value={this.state.installments} layout={this.state.layout} itemTemplate={this.itemTemplate}></DataView>
                        <DataView value={this.state.installment} layout={this.state.layout} itemTemplate={this.summeryTemplate} style={{paddingTop: "1em"}}></DataView>
                    </div>
                </div>
            </div>
        );
    }
}
