import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { TabPanel, TabView } from 'primereact/tabview';
import { InputTextarea } from 'primereact/inputtextarea';
import { WalletService } from '../../service/WalletService';
import { LgaService } from '../../service/LgaService';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";
import { Dialog } from 'primereact/dialog';
import { format } from 'date-fns';
import { ClientService } from '../../service/ClientService';
import ResponseAlert from '../../Utilities/ResponseAlert';

class MonthBills extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataJson: {cell: {id: 0}, name: '', houseNumber: '', reference: '', phoneNumber: this.props.t('None'), tenants: 0, tenantType: ''},
            monthJson: {id: 0, paidAmount: 0, month: "2021-12", months: []},
            revokeJson: {id: 0, remainedAmount: 0, month: "2021-12", fundedMonth: "2021-12", fundedMonthAmount: 0},
            dataTableValue:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            houseHolderId: 0,
            name: '',
            month: '',
            monthName: '',
            monthCode: '',
            status: '',
            search: '',
            sortOrder: -1,
            sortField: 'month',
            loading: false,
            updating: false,
            sending: false,
            displayModal: false,
            displaySplitBillModal: false,
            displayRevokePaymentModal: false,
            displaySmsModal: false,
            message: '',
            smsType: 'info',
            activeIndex: 0,
            failed: null,
            alert: "",
        };

        this.walletService = new WalletService();
        this.lgaService = new LgaService();
        this.clientService = new ClientService();
        this.adalipaUtil = new AdalipaUtil();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.updateHouseHoldersData = this.updateHouseHoldersData.bind(this);
        this.updateHouseHolderData = this.updateHouseHolderData.bind(this);
        this.handleSplitBill = this.handleSplitBill.bind(this);
        this.getMonthBill = this.getMonthBill.bind(this);
        this.handleItemChange = this.handleItemChange.bind(this);
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.cancelSplit = this.cancelSplit.bind(this);
        this.cancelRevoke = this.cancelRevoke.bind(this);
        this.accountInfoSubmit = this.accountInfoSubmit.bind(this);
        this.groupSmsSubmit = this.groupSmsSubmit.bind(this);
        this.booleanTemplate = this.booleanTemplate.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.amountTemplate = this.amountTemplate.bind(this);
        this.cellTemplate = this.cellTemplate.bind(this);
        this.paidAmountTemplate = this.paidAmountTemplate.bind(this);
        this.export = this.export.bind(this);
        this.goTotenants = this.goTotenants.bind(this);

        this.cols = [
            { field: 'name', header: this.props.t('House Holder') },
            { field: 'month', header: this.props.t('Month') },
            { field: 'monthName', header: this.props.t('Details') },
            { field: 'monthCode', header: this.props.t('Month Code') },
            { field: 'paidAmount', header: this.props.t('Paid Amount') },
            { field: 'status', header: this.props.t('Status') }
        ];
        this.excelCols = {header: ['name', 'month', 'monthName', 'monthCode', 'paidAmount', 'status']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    getDataList = (houseHolderId) => {
        this.setState({loading: true});
        return this.lgaService.getMonthBills({houseHolderId: houseHolderId, month: this.state.month, monthName: this.state.monthName, monthCode: this.state.monthCode, status: this.state.status, search: this.state.search, page: this.state.pageIndex, limit: this.state.pageSize, sort: this.state.sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc'})
        .then(data => {
            this.setState({loading: false});
            const dataContent = data.content.map(d => ({
                ...d,
                name: d.houseHolder.name,
                paidAmount: d.paidAmount
            }))
            this.setState({dataTableValue: dataContent});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    componentDidMount() {
        if (+this.props.match.params.dataId > 0) {
            this.setState({houseHolderId: +this.props.match.params.dataId});
            this.lgaService.getHouseHolder(+this.props.match.params.dataId)
            .then(data => {
                this.setState({dataJson: {id: data.id, cell: {id: data.cell.id}, name: data.name, houseNumber: data.houseNumber, reference: data.reference, phoneNumber: data.phoneNumber, tenants: data.tenants, tenantType: data.tenantType}});
            });
        }
        this.getDataList(+this.props.match.params.dataId);
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList(this.state.houseHolderId).then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            })
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList(this.state.houseHolderId);
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList(this.state.houseHolderId);
        });     
    }

    handleDropdownChange(event) {
        const target = event;
        const inputName = target.target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList(this.state.houseHolderId);
        });
    }

    updateHouseHoldersData(e) {
        e.preventDefault();
        this.setState({updating: true});
        this.lgaService.getHouseHoldersMonthBills(this.state.month)
        .then(response => {
            this.setState({displayModal: false});
            this.setState({updating: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.getDataList(this.state.houseHolderId);
        }).catch(error => {
            this.setState({displayModal: false});
            this.setState({updating: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    updateHouseHolderData(e) {
        e.preventDefault();
        this.setState({updating: true});
        this.lgaService.getHouseHolderMonthBills(this.state.month, this.state.houseHolderId)
        .then(response => {
            this.setState({displayModal: false});
            this.setState({updating: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.getDataList(this.state.houseHolderId);
        }).catch(error => {
            this.setState({displayModal: false});
            this.setState({updating: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    handleSplitBill = (event) => {
        event.preventDefault();
        this.setState({updating: true});
        this.lgaService.splitHouseBill(this.state.monthJson)
        .then(response => {
            this.setState({displaySplitBillModal: false});
            this.setState(prevJson => ({ monthJson: {...prevJson.monthJson, months: []} }));
            this.setState({updating: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.getDataList(this.state.houseHolderId);
        }).catch(error => {
            this.setState({displaySplitBillModal: false});
            this.setState({updating: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

    getMonthBill = (data) => {
        this.setState(prevJson => ({
            monthJson: {...prevJson.monthJson, id: data.id, month: data.month, paidAmount: data.paidAmount}
        }));
        this.setState({displaySplitBillModal: true});
    }

    handleItemChange = (e, index) => {
        const months = this.state.monthJson.months;
        if (e.target.name === "paidAmount") {
            const existingAmount = this.state.monthJson.paidAmount;
            const remainedAmount = months[index][e.target.name] < +e.value ? ((existingAmount + months[index][e.target.name]) - (+e.value)) : (months[index][e.target.name] > +e.value ? (existingAmount + months[index][e.target.name]) - (+e.value) : existingAmount);
            months[index][e.target.name] = remainedAmount >= 1000 ? +e.value : months[index][e.target.name];
            this.setState(prevJson => ({
                monthJson: {...prevJson.monthJson, paidAmount: remainedAmount >= 1000 ? remainedAmount : existingAmount, months: months}
            }));
        } else {
            months[index][e.target.name] = format(e.value, 'yyyy-MM');
            this.setState(prevJson => ({
                monthJson: {...prevJson.monthJson, months: months}
            }));
        }
    }

    addItem = () => {
        let prevItems = this.state.monthJson.months
        prevItems.push({month: "2022-01", paidAmount: 0});
        this.setState(prevJson => ({
            monthJson: {...prevJson.monthJson, months: prevItems}
        }));
    }

    removeItem = (index) => {
        const months = this.state.monthJson.months;
        const existingAmount = this.state.monthJson.paidAmount;
        const remainedAmount = months[index]["paidAmount"] !== 0 ? (existingAmount + months[index]["paidAmount"]) : existingAmount;
        this.setState(prevJson => ({
            monthJson: {...prevJson.monthJson, paidAmount: remainedAmount, months: this.state.monthJson.months.filter((item, i) => i !== index)}
        }));
    }

    cancelSplit = () => {
        this.setState(prevJson => ({ monthJson: {...prevJson.monthJson, months: []} }));
        this.setState({displaySplitBillModal: false});
    }

    handleRevokeInputChange(event) {
        let {revokeJson} = this.state;
        const target = event.target;
        if (target.name === "fundedMonth") {
            this.setState(prevJson => ({
                revokeJson: {...prevJson.revokeJson, fundedMonth: format(event.value, 'yyyy-MM')}
            })); 
        } else {
            this.setState(prevJson => ({
                revokeJson: {...prevJson.revokeJson, remainedAmount: (((revokeJson.remainedAmount + revokeJson.fundedMonthAmount) - target.value) >= 0 ? (revokeJson.fundedMonthAmount !== target.value ? ((revokeJson.remainedAmount + revokeJson.fundedMonthAmount) - target.value) : revokeJson.remainedAmount) : revokeJson.remainedAmount)}
            }), () => {
                this.setState(prevJson => ({
                    revokeJson: {...prevJson.revokeJson, fundedMonthAmount: (((revokeJson.remainedAmount + revokeJson.fundedMonthAmount) - target.value) >= 0 ? target.value : revokeJson.fundedMonthAmount)}
                }))
            }); 
        }
    }

    handleRevokePayment = (event) => {
        event.preventDefault();
        this.setState({updating: true});
        this.lgaService.revokeMonthPayment(this.state.revokeJson)
        .then(response => {
            this.setState({displayRevokePaymentModal: false});
            this.setState({revokeJson: {id: 0, remainedAmount: 0, month: "2021-12", fundedMonth: "2021-12", fundedMonthAmount: 0}});
            this.setState({updating: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.getDataList(this.state.houseHolderId);
        }).catch(error => {
            this.setState({displayRevokePaymentModal: false});
            this.setState({updating: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

    getMonthPayment = (data) => {
        this.setState(prevJson => ({
            revokeJson: {...prevJson.revokeJson, id: data.id, month: data.month, remainedAmount: data.paidAmount}
        }));
        this.setState({displayRevokePaymentModal: true});
    }

    cancelRevoke = () => {
        this.setState({revokeJson: {id: 0, remainedAmount: 0, month: "2021-12", fundedMonth: "2021-12", fundedMonthAmount: 0}});
        this.setState({displayRevokePaymentModal: false});
    }

    accountInfoSubmit(event) {
        event.preventDefault();
        this.setState({sending: true});
        const smsJson = {
            message: this.state.message,
            houseHolderId: this.state.houseHolderId
        };
        this.clientService.sendAccountInfoSms(smsJson)
        .then(response => {
            this.setState({displaySmsModal: false});
            this.setState({sending: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
        }).catch(error => {
            this.setState({displaySmsModal: false});
            this.setState({sending: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

    groupSmsSubmit(event) {
        event.preventDefault();
        this.setState({sending: true});
        const smsJson = {
            message: this.state.message,
            houseHolderId: this.state.houseHolderId
        };
        this.clientService.sendGroupSms(smsJson)
        .then(response => {
            this.setState({displaySmsModal: false});
            this.setState({sending: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
        }).catch(error => {
            this.setState({displaySmsModal: false});
            this.setState({sending: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

    booleanTemplate(rowData, column) {
        return <span className={`p-button ${rowData['status'] === 'Imelipwa' ? 'p-button-success' : 'p-button-danger'}`} style={{display: 'block', textAlign: "center"}}>{this.props.t(rowData['status'])}</span>;
    }

    cellTemplate(rowData, column) {
        return <span>{rowData['houseHolder'].cell.cellNumber} - {rowData['houseHolder'].cell.zone.name}</span>;
    }
    amountTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['amount']);
    }
    paidAmountTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['paidAmount']);
    }

    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
            <Link to="#"><Button type="button" onClick={() => this.getMonthBill(rowData)} icon="pi pi-share-alt" className="p-button-warning" style={{marginRight: '.2em'}} title={this.props.t("Split Bill")}/></Link>
            <Link to="#"><Button type="button" onClick={() => this.getMonthPayment(rowData)} icon="pi pi-undo" className="p-button-danger" style={{marginRight: '.2em'}} title={this.props.t("Revoke Payments")}/></Link>
        </div>;
    }

    export() {
        this.dt.exportCSV();
    }

    goTotenants() {
        this.props.history.push("/wallet/lga/tenants/" + this.state.houseHolderId);
    }

    dialogFooter = <div className="p-grid">
        <div className="p-col-6" style={{textAlign: "right"}}>
            <Button type="button" label={this.props.t("Cancel")} onClick={() => this.setState({displayModal: false})} icon="pi pi-times-circle" className="p-button-sm" style={{backgroundColor: "#EEEFFF", color: "#3B007B"}} />
        </div>
        <div className="p-col-6" style={{textAlign: "left"}}>
            <Button type="submit" label={this.props.t("Generate")} onClick={(e) => this.updateHouseHolderData(e)} icon="pi pi-check-circle" className="p-button-sm" />
        </div>
    </div>;

    // DIALOGUE EVENTS

    splitBillDialogFooter = <div className="p-grid">
        <div className="p-col-6" style={{textAlign: "right"}}>
            <Button type="button" label={this.props.t("Cancel")} onClick={() => this.cancelSplit()} icon="pi pi-times-circle" className="p-button-sm" style={{backgroundColor: "#EEEFFF", color: "#3B007B"}} />
        </div>
        <div className="p-col-6" style={{textAlign: "left"}}>
            <Button type="submit" label={this.props.t("Save")} onClick={(e) => this.handleSplitBill(e)} icon="pi pi-check-circle" className="p-button-sm" />
        </div>
    </div>;

    revokePaymentDialogFooter = <div className="p-grid">
        <div className="p-col-6" style={{textAlign: "right"}}>
            <Button type="button" label={this.props.t("Cancel")} onClick={() => this.cancelRevoke()} icon="pi pi-times-circle" className="p-button-sm" style={{backgroundColor: "#EEEFFF", color: "#3B007B"}} />
        </div>
        <div className="p-col-6" style={{textAlign: "left"}}>
            <Button type="submit" label={this.props.t("Save")} onClick={(e) => this.handleRevokePayment(e)} icon="pi pi-check-circle" className="p-button-sm" />
        </div>
    </div>;

    smsDialogFooter = <div className="p-grid">
        <div className="p-col-6" style={{textAlign: "right"}}>
            <Button type="button" label={this.props.t("Cancel")} onClick={() => this.setState({displaySmsModal: false})} icon="pi pi-times-circle" className="p-button-sm" style={{backgroundColor: "#EEEFFF", color: "#3B007B"}} />
        </div>
        <div className="p-col-6" style={{textAlign: "left"}}>
            <Button type="submit" label={this.props.t("Send Sms")} onClick={(e) => (this.state.activeIndex === 0 ? this.accountInfoSubmit(e) : this.groupSmsSubmit(e))} icon="pi pi-check-circle" className="p-button-sm" />
        </div>
    </div>;

    render() {

        let {revokeJson} = this.state;

        // REPORT ACTIONS

        let header = (
        <div style={{textAlign:'left'}}>
            <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
            <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel(this.props.t("Month Bills"), this.excelCols, this.state.excelData)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
            <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable(this.props.t("List of Month Bills"), this.exportColumns, this.state.dataTableValue)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
        </div>
        );

        //  ON CHANGE OR ARRANGE EVENTS

        let statusFilter = <InputText type="text" name="status" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.status} onChange={this.handleInputChange}/>
        let monthFilter = <InputText type="text" name="month" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.month} onChange={this.handleInputChange}/>
        let monthNameFilter = <InputText type="text" name="monthName" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.monthName} onChange={this.handleInputChange}/>
        let monthCodeFilter = <InputText type="text" name="monthCode" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.monthCode} onChange={this.handleInputChange}/>

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        
                        {/* START HEADER BUTTONS */}
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6"><h2>{this.props.t('Month Bills')} {this.props.t('ofs')} {this.state.dataJson.name}: <span style={{color: "#3B007B"}}>{this.state.dataJson.reference}</span></h2></div>
                            <div className="p-col-12 p-md-6">
                                <Link to="#" style={{float: "right"}}>
                                    <Button onClick={() => this.setState({displayModal: true})} label={this.props.t(this.state.updating ? <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} /> : "Update Month Bills")} icon="pi pi-plus"/>
                                </Link>
                                <Link to="#" style={{float: "right"}}>
                                    <Button onClick={() => this.setState({displaySmsModal: true})} label={this.props.t(this.state.updating ? <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} /> : "Send Sms")} icon="pi pi-envelope"/>
                                </Link>
                                <Link to="#" style={{float: "right"}}>
                                    <Button onClick={() => this.goTotenants()} label={this.props.t(this.state.updating ? <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} /> : "Tenants")} icon="pi pi-plus"/>
                                </Link>
                            </div>
                        </div>
                        <ResponseAlert failed={this.state.failed} alert={this.state.alert} setFailed={(value) => this.setState({failed: value})} />
                       
                       
                       {/* START DATA TABLE */}
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})} 
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="name" header={this.props.t("House Holder")} sortable={true} filter={true}/>
                            <Column field="month" header={this.props.t("Month")} sortable={true} filter={true} filterElement={monthFilter}/>
                            <Column field="monthName" header={this.props.t("Details")} sortable={true} filter={true} filterElement={monthNameFilter}/>
                            <Column field="monthCode" header={this.props.t("Month Code")} sortable={true} filter={true} filterElement={monthCodeFilter}/>
                            <Column field="paidAmount" header={this.props.t("Paid Amount")} body={this.paidAmountTemplate} sortable={true} filter={false}/>
                            <Column field="status" header={this.props.t("Status")} body={this.booleanTemplate} sortable={true} filter={true} filterElement={statusFilter}/>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>

                         {/* LOADING ACTION */}

                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {/* DIALOGUE BOXES */}

                <Dialog header={this.props.t("Update Month Bills")} visible={this.state.displayModal} className="p-col-4 p-offset-2" modal footer={this.dialogFooter} onHide={() => this.setState({displayModal: false})}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="name">{this.props.t('Month')}</label>
                            <Calendar name="month" placeholder={this.props.t("Choose") + " " + this.props.t("Month")} value={this.state.month} onChange={(e) => this.setState({month: format(e.value, 'yyyy-MM')})} view='month' dateFormat='MM, yy' yearNavigator={true} yearRange='2021:2030' appendTo={document.body}/>
                        </div>
                    </div>
                </Dialog>
                <Dialog header={this.props.t("Split Bills") + " " + this.props.t("for") + " " + this.props.t(this.adalipaUtil.monthFormat(this.state.monthJson.month))} visible={this.state.displaySplitBillModal} className="p-col-6" modal footer={this.splitBillDialogFooter} onHide={() => this.setState({displaySplitBillModal: false})}>
                    <div className="p-fluid p-formgrid p-grid p-md-12">
                        <div className="p-field p-col-12 p-md-12">
                            <span>{`${this.props.t("Remained Amount for")} ${this.props.t(this.adalipaUtil.monthFormat(this.state.monthJson.month))}: `}<strong>{this.adalipaUtil.amountFormat(this.state.monthJson.paidAmount)}</strong></span>
                        </div>
                    </div>
                    { this.state.monthJson.months.length > 0 &&
                        <div className="p-fluid p-formgrid p-grid p-md-12">
                            <div className="p-field p-col-5 p-md-5">
                                <label htmlFor="name">{this.props.t('Month')}</label>
                            </div>
                            <div className="p-field p-col-5 p-md-5">
                                <label htmlFor="name">{this.props.t('Amount')}</label>
                            </div>
                        </div>
                    }
                    { this.state.monthJson.months.map((item, index) => (
                        <div className="p-fluid p-formgrid p-grid p-md-12">
                            <div className="p-field p-col-5 p-md-5">
                                <Calendar id="month" name="month" value={item.month} onChange={(e) => this.handleItemChange(e, index)} view='month' dateFormat='MM, yy' yearNavigator={true} yearRange='2021:2030' placeholder={this.props.t("Choose") + " " + this.props.t("Month")} appendTo={document.body}/>
                            </div>
                            <div className="p-field p-col-5 p-md-5">
                                <InputNumber id="paidAmount" name="paidAmount" value={item.paidAmount} onChange={(e) => this.handleItemChange(e, index)} mode="decimal" minFractionDigits={2} required placeholder={this.props.t("Enter") + " " + this.props.t("Paid Amount")} />
                            </div>
                            <div className="p-col-2 p-md-2">
                                <Button type="button" icon="pi pi-minus" onClick={() => this.removeItem(index)}/>
                            </div>
                        </div>
                    ))}
                    <div className="p-fluid p-formgrid p-grid p-md-12">
                        <div className="p-col-12 p-md-6">
                            <Button type="button" icon="pi pi-plus" label={this.props.t("Add Another Month")} onClick={() => this.addItem()}/>
                        </div>
                    </div>
                </Dialog>
                <Dialog header={this.props.t("Revoke Payments") + " " + this.props.t("for") + " " + this.props.t(this.adalipaUtil.monthFormat(this.state.revokeJson.month))} visible={this.state.displayRevokePaymentModal} className="p-col-6" modal footer={this.revokePaymentDialogFooter} onHide={() => this.setState({displayRevokePaymentModal: false})}>
                    <div className="p-fluid p-formgrid p-grid p-md-12">
                        <div className="p-field p-d-flex p-col-12 p-md-12" style={{whiteSpace: "nowrap"}}>
                            <span>{`${this.props.t("Remained Amount for")} ${this.props.t(this.adalipaUtil.monthFormat(this.state.revokeJson.month))}: `}<strong>{this.adalipaUtil.amountFormat(this.state.revokeJson.remainedAmount)}</strong></span>
                            <Button type="button" label={this.props.t("Copy")} onClick={() => this.state.revokeJson.remainedAmount > 0 ? this.setState(prevJson => ({revokeJson: {...prevJson.revokeJson, fundedMonthAmount: (this.state.revokeJson.fundedMonthAmount + this.state.revokeJson.remainedAmount), remainedAmount: 0}})) : ""} icon="pi pi-copy" className="p-button p-button-sm p-button-raised p-button-secondary" style={{width: "5em", marginLeft: "1em"}} />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid p-md-12">
                        <div className="p-field p-col-5 p-md-5">
                            <label htmlFor="fundedMonth">{this.props.t('Fund Month')}</label>
                        </div>
                        <div className="p-field p-col-5 p-md-5">
                            <label htmlFor="fundedMonthAmount">{this.props.t('Fund Amount')}</label>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid p-md-12">
                        <div className="p-field p-col-5 p-md-5">
                            <Calendar id="fundedMonth" name="fundedMonth" value={revokeJson.fundedMonth} onChange={(e) => this.handleRevokeInputChange(e)} view='month' dateFormat='MM, yy' yearNavigator={true} yearRange='2021:2030' placeholder={this.props.t("Choose") + " " + this.props.t("Month")} appendTo={document.body}/>
                        </div>
                        <div className="p-field p-col-5 p-md-5">
                            <InputNumber id="fundedMonthAmount" name="fundedMonthAmount" value={revokeJson.fundedMonthAmount} onChange={(e) => this.handleRevokeInputChange(e)} mode="decimal" minFractionDigits={2} required placeholder={this.props.t("Enter") + " " + this.props.t("Revoked Amount")} />
                        </div>
                    </div>
                </Dialog>
                <Dialog header={this.props.t("Send Sms")} visible={this.state.displaySmsModal} className="p-col-6 p-offset-2" modal footer={this.smsDialogFooter} onHide={() => this.setState({displaySmsModal: false})}>  
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                                    <TabPanel header={this.props.t("Account Info Sms")} leftIcon="pi pi-user">
                                        <div className="card card-w-title">
                                            <div className="p-col-12 p-md-12">
                                                <p>{this.props.t('In this panel you are able to send automated sms which contains important details of house holder, these details includes controll number and help contact number')}</p>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel header={this.props.t("Write Message")} leftIcon="pi pi-users">
                                        <div className="card card-w-title">
                                            <div className="p-col-12 p-md-12">
                                                <p>{this.props.t('In this panel you are able to write and send sms to a house holder, here you can write what best suits your motive')}</p>
                                            </div>
                                            <div className="p-col-12 p-md-12" style={{paddingTop: "0px", marginTop: "0px"}}>
                                                <div className="p-col-12 p-md-offset-1 p-md-10" style={{paddingTop: "0px", marginTop: "0px"}}>
                                                    <h4>{this.props.t('Message')}</h4>
                                                    <InputTextarea name="message" value={this.state.message} onChange={this.handleInputChange} required rows={3} cols={30} placeholder={this.props.t("Your Message")} autoResize={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                            {this.state.sending && 
                                <div className="p-grid p-fluid p-col-12" style={{textAlign:'center', padding: "2em"}}>
                                    <div className="p-col-12" style={{textAlign:'center'}}>
                                        <BeatLoader color={"#3B007B"} sending={this.state.sending} css={""} size={10} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default withTranslation()(MonthBills)
