import React,{ Component } from "react";
import { renderToString } from 'react-dom/server';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link,NavLink } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { WalletService } from "../../service/WalletService";
import { AdalipaUtil } from "../../Utilities/AdalipaUtil";
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";
import 'primeicons/primeicons.css';
import jsPDF from "jspdf";
import SettlementReceipt from "../balance/SettlementReceipt";

class CashCollection extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            dataTableValue:[],
            pdfData:[],
            excelData:[],
            referenceId: 0,
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            layout: 'list',
            sortOptions: [
                {label: 'Newest First', value: '!year'},
                {label: 'Oldest First', value: 'year'},
                {label: 'Brand', value: 'brand'}
            ],
            collectionId: '',
            accountId: '',
            clientName: '',
            phoneNumber: '',
            collectionStatus: '',
            collectedBy: '',
            amount: 0,
            collectionType: '',
            Status: null,
            sortOrder: -1,
            sortField: 'id',
            loading: false,
            receiptData: {collectionTime: "", collectionId: "", accountId: "", clientName: "", amount: 0, collectionType: "", phoneNumber: "",collectedBy: ""},
            displayReceipt: false,
            isAdmin: false,
            CashAllowed: true,
            userEmail: '',
            error: ""
         }

        this.walletService = new WalletService();
        this.adalipaUtil = new AdalipaUtil();

        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.amountTemplate = this.amountTemplate.bind(this);
        this.chargesTemplate = this.chargesTemplate.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.onPrinterClick = this.onPrinterClick.bind(this);
        this.export = this.export.bind(this);

        this.cols = [
            { field: 'collectionTime', header: this.props.t('Time') },
            { field: 'collectionId', header: this.props.t('Receipt Number') },
            { field: 'accountId', header: this.props.t('Account') },
            { field: 'clientName', header: this.props.t('Account Name') },
            { field: 'collectionType', header: this.props.t('Collection Type') },
            { field: 'amount', header: this.props.t('Amount') },
            { field: 'phoneNumber', header: this.props.t('Phone Number') },
            { field: 'collectedBy', header: this.props.t('Collected By') }
        ];
        this.excelCols = {header: ['collectionTime', 'collectionId', 'accountId', 'clientName',"collectionType", 'amount', 'phoneNumber','collectedBy']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));

    }
    getDataList() {
        // console.log("ROLE LIST",this.props.roles);
        const role = this.props.roles;
        if(role.includes("ADMIN")){
            this.setState({isAdmin:true});
        }
        this.setState({referenceId: this.props.match.params.reference})
        let {collectedBy,collectionId,accountId,clientName, collectionType, amount, phoneNumber,collectionStatus, pageIndex, pageSize, sortField, sortOrder} = this.state;
        this.setState({loading: true});
        return this.walletService.getCashCollections({collectedBy:collectedBy,collectionId:collectionId, accountId:accountId, clientName: clientName, collectionType: collectionType, amount: amount, phoneNumber: phoneNumber,collectionStatus:collectionStatus, page: pageIndex, limit: pageSize, sort: sortField, order: sortOrder === 1 ? 'asc' : 'desc'})
        .then(data => {
            this.setState({loading: false});
            const dataContent = data.content.map(d => ({
                ...d ,collectionTime: this.adalipaUtil.timestampFormat(d.collectionTime)
            }));
            this.setState({dataTableValue: dataContent});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                pageSize: data.pageable.pageSize,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
            const pdfOptions = data.content.map(d => ({
                ...d, collectionTime: this.adalipaUtil.timestampFormat(d.collectionTime), amount: this.adalipaUtil.toDecimal(d.amount)
            }));
            this.setState({pdfData: pdfOptions});
            const excelOptions = data.content.map(d => ({
                ...d,collectionTime: this.adalipaUtil.timestampFormat(d.collectionTime), amount: this.adalipaUtil.toDecimal(d.amount)
            }));
            this.setState({excelData: excelOptions});
        })
        .catch(error =>{
          this.setState({error: error.message});
        });
    }

    getCollectionStatus(){        
        const jsonParam = {institutionId: this.props.instituteId}
        return this.walletService.getCashCollectionStatus(jsonParam)
        .then(data => {
            this.setState({userEmail: this.props.email});
           if("CollectionAllowed" in data){
               if(data.CollectionAllowed === "Yes"){
                this.setState({CashAllowed: true})
               }else{
                this.setState({CashAllowed: false})
               }               
           }
        })
        .catch(error => {
            this.setState({error: error.message});
        })
    }

    componentDidMount() {
        this.getDataList();
        if(!this.state.isAdmin){
           this.getCollectionStatus();
        }
    }
    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            });
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });     
    }

    dateTemplate(rowData, column) {
        var statuses = ["NEW", "INITIATED", "INCOMPLETE"];
        if(statuses.includes(rowData['collectionStatus'])){
            return <div style={{color: "red", fontStyle: "bold"}}>
            {new Intl.DateTimeFormat('sw', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.parse(rowData['collectionTime']))}
        </div>;
        }else{
            return new Intl.DateTimeFormat('sw', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.parse(rowData['collectionTime']));  
        }
    }
    amountTemplate(rowData, column) {
        var statuses = ["NEW", "INITIATED", "INCOMPLETE"];
        if(statuses.includes(rowData['collectionStatus'])){
            return <div style={{color: "red", fontStyle: "bold"}}>
            {this.adalipaUtil.amountFormat(rowData['amount'])}
        </div>;
        }else{
            return this.adalipaUtil.amountFormat(rowData['amount']);
        }
       
    }
    chargesTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['charges']);
    }

    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
            <NavLink to={{pathname:"/print/payment/receipts"  ,          
                receiptProps: {
                    instituteName: localStorage.getItem("instituteName"),
                    phoneNumb: rowData["phoneNumber"],
                    receiptNumber: rowData["collectionId"],
                    receiptDate: rowData["collectionTime"],
                    accountNumber: rowData["accountId"],
                    amount: rowData["amount"],
                    managerPhoneNumber: rowData["managerPhoneNumber"],
                    charges: rowData["charges"],
                }            
             }} exact > 
             <Button type="button" icon="pi pi-print" className="p-button p-button-sm p-button-info" style={{padding: "0px"}} title={this.props.t("Print")}/>
             </NavLink>
            {/* <Button type="button" onClick={() => this.onPrinterClick(rowData)} icon="pi pi-print" className="p-button p-button-sm p-button-info" style={{padding: "0px"}} title="Print"/> */}
        </div>;
    }

    collectionStatusTemplate(rowData,column){        

        if(rowData['collectionStatus'] === "NEW"){
            return <div style={{color: "red", fontStyle: "bold"}}>
            {rowData['collectionStatus']}
        </div>;
        }else

        if(rowData['collectionStatus'] === "INITIATED"){
            return <div style={{color: "red", fontStyle: "bold"}}>
            {rowData['collectionStatus']}
        </div>;
        }else

        if(rowData['collectionStatus'] === "INCOMPLETE"){
            return <div style={{color: "red", fontStyle: "bold"}}>
            {rowData['collectionStatus']}
        </div>;
        }

        else{
            return <div>
            {rowData['collectionStatus']}
        </div>;
        }
             
    }

    export() {
        this.dt.exportCSV();
    }
    onPrinterClick(data) {
        this.setState({receiptData: data}, () => {
            const doc = new jsPDF();
            const htmlElement = renderToString(<SettlementReceipt receiptData={this.state.receiptData} />);
            doc.html(htmlElement).then(() => {
                doc.setFont("helvetica");
                doc.setFontSize(2);
                doc.autoPrint();
                doc.output('dataurlnewwindow');
            });
        });
    }

    render() { 
        let header = (
            <div style={{textAlign:'left'}}>
                <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
                <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel("Settlements", this.excelCols, this.state.excelData)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
                <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable("Settlements", this.exportColumns, this.state.pdfData)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
            </div>
        );
        let collectionIdFilter = <InputText type="text" name="collectionId" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.collectionId} onChange={this.handleInputChange}/>
        let accountNumberFilter = <InputText type="text" name="accountId" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.accountId} onChange={this.handleInputChange}/>
        let clientNameFilter = <InputText type="text" name="instituteName" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.clientName} onChange={this.handleInputChange}/>
        let collectionTypeFilter = <InputText type="text" name="collectionType" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.collectionType} onChange={this.handleInputChange}/>
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.phoneNumber} onChange={this.handleInputChange}/>
        let collectionStatusFilter = <InputText type="text" name="collectionStatus" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.collectionStatus} onChange={this.handleInputChange}/>
        let collectedByFilter = <InputText type="text" name="collectedBy" style={{width: '100%'}} placeholder={this.props.t("True or False")} value={this.state.collectedBy} onChange={this.handleInputChange}/>

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>{this.props.t('Cash Collections')} </h2> <h2> {this.props.t('Reference')}: <b>{this.state.referenceId}</b></h2></div>
                            <div className="p-col-8 p-md-8">
                                {this.state.CashAllowed && !this.state.isAdmin && <Link to="/wallet/collect/cash" style={{float: "right"}}><Button label={this.props.t("Collect Cash")} icon="pi pi-plus"/></Link>}
                                {!this.state.CashAllowed && (this.state.userEmail !== "lga1@adalipa.co.tz" && this.state.userEmail !== "kivuyo@adalipa.co.tz") && <Link to="/wallet/gen/payment/reference" style={{float: "right"}}><Button label={this.props.t("Complete Your Payment")} icon="pi pi-plus" className="p-button-danger p-button-sm" /></Link>}
                                {!this.state.CashAllowed && (this.state.userEmail === "lga1@adalipa.co.tz" || this.state.userEmail === "kivuyo@adalipa.co.tz") && <Link to="/wallet/collect/cash" style={{float: "right"}}><Button label={this.props.t("Collect Cash")} icon="pi pi-plus"  /></Link>}
                                {!this.state.CashAllowed && (this.state.userEmail === "lga1@adalipa.co.tz" || this.state.userEmail === "kivuyo@adalipa.co.tz") && <Link to="/wallet/gen/payment/reference" style={{float: "right"}}><Button label={this.props.t("Payment Reference")} icon="pi pi-plus" /></Link>}
                                {this.state.CashAllowed && <Link to="/wallet/gen/payment/reference" style={{float: "right"}}><Button label={this.props.t("Payment Reference")} icon="pi pi-plus"/></Link>}
                                {/* {this.state.isAdmin && <Link to="/wallet/extra/charges/settlement" style={{float: "right"}}><Button label={this.props.t("Extra Charges Withdraw")} icon="pi pi-plus"/></Link>} */}
                                
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})} 
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="collectionTime" body={this.dateTemplate} header={this.props.t("Time")} sortable={true} filter={false}/>
                            <Column field="collectionId" header={this.props.t("Receipt")} sortable={true} filter={true} filterElement={collectionIdFilter} filterPlaceholder="Contains"/>
                            <Column field="accountId" header={this.props.t("Account")} sortable={true} filter={true} filterElement={accountNumberFilter} filterPlaceholder="Contains"/>
                            <Column field="clientName" header={this.props.t("Client Name")} sortable={false} filter={true} filterElement={clientNameFilter} filterPlaceholder="Contains"/>
                            <Column field="collectionType" header={this.props.t("Collection Type")} sortable={true} filter={true} filterElement={collectionTypeFilter} filterPlaceholder="Contains"/>
                            <Column field="collectedBy" header={this.props.t("Collected By")} sortable={true} filter={true} filterElement={collectedByFilter} filterPlaceholder="Contains"/>
                            <Column field="amount" header={this.props.t("Amount")} body={this.amountTemplate} sortable={true} filter={false}/>
                            <Column field="phoneNumber" header={this.props.t("Phone Number")} sortable={true} filter={true} filterElement={phoneNumberFilter} filterPlaceholder="Contains"/>
                            <Column field="collectionStatus" body={this.collectionStatusTemplate} header={this.props.t("Collection Status")} sortable={true} filter={true} filterElement={collectionStatusFilter} filterPlaceholder="Contains" />
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', fontSize: 'small', width: "5em"}}/>
                        </DataTable>
                        {/* <Paginator currentPageReportTemplate={"Data " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator> */}
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
 
export default withTranslation()(CashCollection);
// style={{backgroundColor: "red",color: "white"}}