import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { Paginator } from 'primereact/paginator';
import { UserService } from '../service/UserService';

export class ListPartners extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            sortOrder: -1,
            sortField: 'id',
            regionId: null,
            regions: [],
            categoryId: null,
            categories: [],
            partnerId: null,
            partners: [],
            partnerName: '',
            mlipaCode: '',
            emailAddress: '',
            phoneNumber: '',
            failed: null,
            alert: ''
        };

        this.userService = new UserService();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
    }

    componentDidMount() {
        this.userService.getPartners(this.state.partnerName, this.state.mlipaCode, this.state.emailAddress, this.state.phoneNumber, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
        .then(data => {
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page}, () => {
            this.userService.getPartners(this.state.partnerName, this.state.mlipaCode, this.state.emailAddress, this.state.phoneNumber, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
                this.setState({first: event.first, pageSize: event.rows});
            });
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.userService.getPartners(this.state.partnerName, this.state.mlipaCode, this.state.emailAddress, this.state.phoneNumber, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.userService.getPartners(this.state.partnerName, this.state.mlipaCode, this.state.emailAddress, this.state.phoneNumber, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });
        });     
    }

    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
            <Button type="button" icon="pi pi-eye" className="p-button-adalipa" style={{marginRight: '.2em'}} title="View Partner"/>
            <Link to={"/partner/update/partner/" + rowData["id"]}><Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.2em'}} title="Edit Partner"/></Link>
        </div>;
    }

    render() {

        let partnerNameFilter = <InputText type="text" name="partnerName" style={{width: '100%'}} placeholder="Contains" value={this.state.partnerName} onChange={this.handleInputChange}/>
        let mlipaCodeFilter = <InputText type="text" name="mlipaCode" style={{width: '100%'}} placeholder="Contains" value={this.state.mlipaCode} onChange={this.handleInputChange}/>
        let emailAddressFilter = <InputText type="text" name="emailAddress" style={{width: '100%'}} placeholder="Contains" value={this.state.emailAddress} onChange={this.handleInputChange}/>
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder="Contains" value={this.state.phoneNumber} onChange={this.handleInputChange}/>

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-messages p-component p-messages-warning" style={{margin: '0 0 1em 0', display: 'block'}}>
                        { this.state.failed === true &&
                            <div className="p-messages-wrapper rounded">
                                <ul>
                                    <li>
                                        <span className="p-messages-icon pi pi-fw pi-2x pi-exclamation-triangle"></span>
                                        <span className="p-messages-detail">{this.state.alert}</span>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                    <div className="p-messages p-component p-messages-success" style={{margin: '0 0 1em 0', display: 'block'}}>
                        { this.state.failed === false &&
                            <div className="p-messages-wrapper rounded">
                                <ul>
                                    <li>
                                        <span className="p-messages-icon pi pi-fw pi-2x pi-check-circle"></span>
                                        <span className="p-messages-detail">{this.state.alert}</span>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                    <div className="card card-w-title datatable-demo">
                        <h1>List of Partners</h1>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" paginator={false} 
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="partnerName" header="Partner" sortable={true} filter={true} filterElement={partnerNameFilter}/>
                            <Column field="mlipaCode" header="M-Lipa Prefix" sortable={true} filter={true} filterElement={mlipaCodeFilter}/>
                            <Column field="emailAddress" header="Email Address" sortable={true} filter={true} filterElement={emailAddressFilter}/>
                            <Column field="phoneNumber" header="Phone Number" sortable={true} filter={true} filterElement={phoneNumberFilter}/>
                            <Column field="revenueRatio" header="Revenue Ratio" sortable={true} filter={false}/>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '10em'}}/>
                        </DataTable>
                        <Paginator currentPageReportTemplate={"Showing " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator>
                    </div>
                </div>
            </div>
        );
    }
}
