import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { LgaService } from '../../service/LgaService';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import ResponseAlert from '../../Utilities/ResponseAlert';
import { Dropdown } from 'primereact/dropdown';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";
import {RadioButton} from 'primereact/radiobutton';
import { WalletService } from '../../service/WalletService';
import { Calendar } from 'primereact/calendar';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import { Dialog } from 'primereact/dialog';

class HouseHolder extends Component {

	constructor(props) {
        super(props);
        this.state = {
            dataJson: {road: {id: 0},roadName:{name: ''} , houseCount: {count: 0}, roadNumber:{rNumber: ''}, roadDetails: {details:''}, cell: {id: 0},dateOfBirth: {dob: ''}, name: '',nameOfRoad: '',gender: '', maritalStatus: '',age: 0, houseNumber: '', phoneNumber: this.props.t('None'), tenants: 0, frames: 0, tenantType: 'Familia', sendSms: false},
            dataId: 0,
            cells: [],
            roads: [],
            genders: [{label: 'Male', value: 'MALE'}, {label: 'Female', value: 'FEMALE'}, {label: 'Others', value: 'OTHER'}],
            maritalStatuses: [{label: 'Single', value: 'SINGLE'}, {label: 'Married', value: 'MARRIED'}, {label: 'Widowed', value: 'WIDOWED'}],
            premisesTypes: [{label: 'Tenats', value: 'TENANTS'},{label: 'Business', value: 'BUSINESS'},{label:'Hotels', value: 'HOTELS' },{label: 'Bar',value: 'BAR'}],
            perHousePremisesTypes: [{label: 'Business', value: 'BUSINESS'},{label:'Hotels', value: 'HOTELS' },{label: 'Bar',value: 'BAR'}], 
            premises_count: '0',
            premisesList: [{premisesName: '',premisesCount: 0}],
            dateOfBirth: '',
            payment_type: '',
            displayRoadCreator: false,
            failed: null,
            alert: '',
            loading: false
        };
        this.lgaService = new LgaService();
        this.adalipaUtil = new AdalipaUtil();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleRoadChange = this.handleRoadChange.bind(this);
        this.handleHouseCountChange = this.handleHouseCountChange.bind(this);
        this.handleRoadDetailsChange = this.handleRoadDetailsChange.bind(this);
        this.handleRoadNumberChange = this.handleRoadNumberChange.bind(this);        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRoadSubmit = this.handleRoadSubmit.bind(this);
        this.walletService = new WalletService();
        this.handelEdit = this.handelEdit.bind(this);
	}
	
	componentDidMount() {
        if (this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        if (+this.props.match.params.dataId > 0) {
            this.setState({dataId: +this.props.match.params.dataId});
            this.lgaService.getHouseHolder(+this.props.match.params.dataId)
            .then(data => {
                if(data.road === null){
                    data.road = {id: 0, name: 'None', details: 'None', roadNumber: 0};                
                  }
                if(data.others === null){
                    data.others = [{premisesName: '',premisesCount: 0}]
                }
                this.setState({premisesList: [...data.others]})
                this.setState({dataJson: {id: data.id, road: {id: data.road.id+"-"+ data.cell.id}, cell: {id: data.cell.id},dateOfBirth: {dob: data.dob}, name: data.name, gender: data.gender, maritalStatus: data.maritalStatus, age: data.age, houseNumber: data.houseNumber, phoneNumber: data.phoneNumber, tenants: data.tenants, frames: data.frames, tenantType: data.tenantType, sendSms: data.sendSms,roadName: {name: data.road.name}, nameOfRoad: data.road.name}});
                console.log("Premises Data", this.state.dataJson)
            });


            const jsonParam = {houseHolderId:this.props.match.params.dataId, page: 0, limit: 100, sort: "id", order: 'asc' }
            this.lgaService.getAuditableList(jsonParam)
            .then(data => {
                const options = data.content.map(d => ({
                    "premisesCount": d.premisesCount,
                    "premisesName": d.premisesName

                }))
                if(data.content.length === 0){
                    this.setState({premisesList: [{premisesName: '',premisesCount: 0}]})
                }else{
                    this.setState({premisesList: options})                    
                }
                
            })
        }
        const jsonParams = { page: 0, limit: 100, sort: "id", order: 'asc' }
        this.lgaService.getCells(jsonParams)
        .then(data => {
            const dataContent = data.content;
            const options = dataContent.map(d => ({
                "id" : d.id,
                "name" : "Shina namba " + d.cellNumber + " - " + (d.zone.name)
            }))
            this.setState({cells: options});
        });

        this.lgaService.getRoads(jsonParams)
        .then(data => {
            const dataContent = data.content;
            const options = dataContent.map(d =>({
                "id" : d.road.id+"-"+ d.cell.id,
                "name" : d.road.name + " - " + (d.cell.name)
            }))           
            this.setState({roads: options});
            // console.log("ROADS", options)
        });

        this.walletService.getPaymentPlans(0, 900, 'id', 'asc')
        .then(response => {
            const data = response.content
            data.map((d,i) => {
                if(i === 0){
                   this.setState({payment_type: d.paymentType})
                //    console.log("PTYPES", this.state.payment_type)
                   return null
                }else{
                    return null
                }               
            })

            // const options = data.map(data => ({
            //     "premisesName": data.premisesName,
            //     "paymentAmount": data.paymentAmount+""
            // }))
            // this.setState({premisesList: options});

            // this.setState({premisesList: [{ premisesName: "", paymentAmount: "" }]});
            
        }).catch(error => {
            this.setState({failed: true})
            this.setState({alert: error.message})
        })
       
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({
            dataJson: {
                ...prevJson.dataJson, [inputName] : inputValue
            }
        }));
        console.log("Send Sms?: " + this.state.dataJson.sendSms);     
    }
    handleDateChange(event){
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({dataJson: {...prevJson.dataJson, [inputName]: {dob: inputValue}}}));

    }

    handleRoadChange(event){
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({dataJson: {...prevJson.dataJson, [inputName]: {name: inputValue}}}));      
        
    }

    handleHouseCountChange(event){        
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({dataJson: {...prevJson.dataJson, [inputName]: {count: inputValue}}})); 
    }

    handleRoadNumberChange(event){        
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({dataJson: {...prevJson.dataJson, [inputName]: {rNumber: inputValue}}})); 
    }

    handleRoadDetailsChange(event){        
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({dataJson: {...prevJson.dataJson, [inputName]: {details: inputValue}}})); 
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        const premises_list = [...this.state.premisesList]
        const dob = this.adalipaUtil.cleanDateFormat(this.state.dataJson.dateOfBirth.dob);
        const jsonParams = {...this.state.dataJson,premises_list,dateOfBirth: {dob: dob}};
        this.lgaService.addHouseHolder(jsonParams)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/lga/house/holders");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

    handelEdit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        const premises_list = [...this.state.premisesList]
        const dob = this.adalipaUtil.cleanDateFormat(this.state.dataJson.dateOfBirth.dob);
        const jsonParams = {...this.state.dataJson,premises_list,dateOfBirth: {dob: dob}};
        this.lgaService.editHouseHolder(jsonParams)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/lga/house/holders");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    handleRoadSubmit = (event) =>{
        event.preventDefault();
        this.setState({loading: true});
        const jsonParams = {...this.state.dataJson};
        this.lgaService.addHouseRoad(jsonParams)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
            this.setState({displayRoadCreator: false});
			this.setState({alert: response.message});
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });

    }

    addPremises = () =>{
     const newPremises = [...this.state.premisesList, {premisesName: ''}];
     this.setState({premisesList: newPremises});
    }
    removePremises = (index)=>{
        const newPremisesList = [...this.state.premisesList];
        newPremisesList.splice(index,1);
        this.setState({premisesList: newPremisesList});

    }

    handlePremisesChanges = (event,index) =>{
        const {name,value} = event.target;
        const newPemisesList = this.state.premisesList.map((data,i) => {
            if(index === i){
                return {...data, [name]:value};
            }else{
                return data;
            }
           
        })
        this.setState({premisesList: newPemisesList})
        console.log("Input List",newPemisesList)
    }

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/lga/house/holder/0",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <ResponseAlert failed={this.state.failed} alert={this.state.alert} />
                        <div className="card card-w-title">
                            <div className="p-grid">
                                <div className="p-col-4 p-md-4"><h1>{this.props.t('House Holder')}</h1></div>
                                <div className="p-col-8 p-md-8">
                                    <Link to="/wallet/lga/house/holders/batch" style={{float: "right"}}><Button label={this.props.t("Add House Holders Using Excel")} icon="pi pi-plus"/></Link>
                                </div>
                            </div>
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>{this.props.t(this.state.dataId === 0 ? "New House Holder" : "Edit House Holder")}</h2>
                            </div>
                            {this.state.loading && 
                                <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                    <div className="p-col-12" style={{textAlign:'center'}}>
                                        <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                    </div>
                                </div>
                            }
                            {!this.state.loading && 
                            <form onSubmit={this.state.dataId === 0 ? this.handleSubmit : this.handelEdit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Road')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                        <div style={{flexDirection: 'row',display:'flex',justifyContent: 'space-between',width: "324px"}}>                                            
                                            <Dropdown options={this.state.roads} value={this.state.dataJson.road.id} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, road: {id: event.value}}}))} optionLabel="name" optionValue="id" filter filterBy="name" placeholder={this.props.t("Choose Road")} autoWidth={false} />
                                            {this.state.dataId === 0 && <Button type="button" label={this.props.t("Road")} icon="pi pi-plus" onClick={() => this.setState({displayRoadCreator: true})}/>}
                                            </div>
                                        </div>
                                    </div>                                   
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Full Name')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="name" placeholder={this.props.t("Enter") + " " + this.props.t("Full Name")} value={this.state.dataJson.name} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('House Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="houseNumber" placeholder={this.props.t("Enter") + " " + this.props.t("House Number")} value={this.state.dataJson.houseNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Phone Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="phoneNumber" placeholder={this.props.t("Enter") + " " + this.props.t("Phone Number")} value={this.state.dataJson.phoneNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Gender')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                        {/* {console.log("Gender Name", this.state.dataJson.gender)} */}
                                            <Dropdown options={this.state.genders} value={this.state.dataJson.gender} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, gender: event.value}}))} optionLabel="label" optionValue="value" filter filterBy="label" placeholder={this.props.t("Choose") + " " + this.props.t("Gender")} autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Marital Status')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.maritalStatuses} value={this.state.dataJson.maritalStatus} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, maritalStatus: event.value}}))} optionLabel="label" optionValue="value" filter filterBy="label" placeholder={this.props.t("Choose") + " " + this.props.t("Marital Status")} autoWidth={false} />
                                        </div>  
                                    </div>
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Date Of Birth')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            {+this.props.match.params.dataId === 0 && <Calendar name="dateOfBirth" placeholder={this.props.t("Choose Date")} value={this.state.dataJson.dateOfBirth.dob} onChange={(event) => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, dateOfBirth: {dob: event.value}}}))} dateFormat="yy-mm-d" showIcon showButtonBar showOnFocus icon="pi pi-calendar" appendTo={document.body}/>}
                                            {+this.props.match.params.dataId > 0 && <InputText type="text" name="dateOfBirth" placeholder={this.props.t("2022-01-11")} value={this.state.dataJson.dateOfBirth.dob} onChange={this.handleDateChange} />}
                                        </div>
                                    </div>                          



                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-6 p-md-6">
                                            <label htmlFor="input">{this.props.t('Send Sms After Adding')}:</label>
                                        </div>
                                        <div className="p-col-6 p-md-6">
                                            <div className="p-grid">
                                                <div className="p-col-6 p-md-6">
                                                    <RadioButton value={true} inputId="yes" onChange={(e) => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, sendSms: e.value}}))} checked={this.state.dataJson.sendSms}/>
                                                    <label htmlFor="yes" className="p-radiobutton-label">{this.props.t('Yes')}</label>
                                                </div>
                                                <div className="p-col-6 p-md-6">
                                                    <RadioButton value={false} inputId="no" onChange={(e) => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, sendSms: e.value}}))} checked={!this.state.dataJson.sendSms}/>
                                                    <label htmlFor="no" className="p-radiobutton-label">{this.props.t('No')}</label>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                    
                                </div>
                                <div className="divider-container"><div className="divider-border" />
                                <span className="divider-content"><h3 style={{textAlign: "center"}}>{this.props.t('Premises Use')}</h3></span>
                                <div className="divider-border" /></div>
                                {/* <div className="p-grid">                                 */}

                                     {/* <div className="p-col-12 p-md-12" style={{paddingLeft: "100px",paddingRight: "100px"}}> */}
                                   
                                        {this.state.payment_type === "PER_HOUSE" && this.state.premisesList.map((singlePremiseName,index) => {
                                                return<div className="p-grid"> 
                                                    {/* <div className="p-col-12 p-md-12" style={{flexDirection: 'row',display:'flex',justifyContent: 'space-between'}}> */}
                                     <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Premises Name')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                        {/* {console.log("Gender Name", this.state.dataJson.gender)} */}
                                        <Dropdown name ="premisesName" options={this.state.perHousePremisesTypes} value={this.state.premisesList[index].premisesName} onChange={event => this.handlePremisesChanges(event,index)} optionLabel="label" optionValue="value" filter filterBy="label" placeholder={this.props.t("Choose") + " " + this.props.t("Premises")} autoWidth={false} />
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Premises Count')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                        <InputText type="text" name="premisesCount" placeholder={this.props.t("Enter") + " " + this.props.t("Property Count")} value={this.state.premisesList[index].premisesCount} onChange={(event) => this.handlePremisesChanges(event,index)} required/>
                                        </div>  
                                    </div>
                                                      
                                             {this.state.premisesList.length - 1 === index && <div className="p-col-12 p-md-offset-1 p-md-5"><Button  type="button" label={this.props.t("Add")} icon="pi pi-plus" onClick={()=> {this.addPremises()}} style={{width:"100px"}}/></div>}
                                             </div>
                                            })} 


                                           {(this.state.payment_type === "PER_HOUSE_PER_TENANT" || this.state.payment_type === "PER_TENANT") && this.state.premisesList.map((singlePremiseName,index) => {
                                                return<div className="p-grid"> 

                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Premises Name')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                        {/* {console.log("Gender Name", this.state.dataJson.gender)} */}
                                        <Dropdown name ="premisesName" options={this.state.premisesTypes} value={this.state.premisesList[index].premisesName} onChange={event => this.handlePremisesChanges(event,index)} optionLabel="label" optionValue="value" filter filterBy="label" placeholder={this.props.t("Choose") + " " + this.props.t("Premises")} autoWidth={false} />
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Premises Count')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                        <InputText type="text" name="premisesCount" placeholder={this.props.t("Enter") + " " + this.props.t("Property Count")} value={this.state.premisesList[index].premisesCount} onChange={(event) => this.handlePremisesChanges(event,index)} required/>
                                        {this.state.premisesList.length - 1 && <Button type="button" label={this.props.t("Remove")} icon="pi pi-trash" onClick={() =>{this.removePremises(index)}} className="p-button-text p-button-danger"/>} 
                                        </div>  
                                    </div>
                                                
                                                {/* <div className="p-col-12 p-md-12" style={{flexDirection: 'row',display:'flex',justifyContent: 'space-between'}}>
                                                    <div style={{width:"1050px",paddingRight: "20px"}}><Dropdown name ="premisesName" options={this.state.premisesTypes} value={this.state.premisesList[index].premisesName} onChange={event => this.handlePremisesChanges(event,index)} optionLabel="label" optionValue="value" filter filterBy="label" placeholder={this.props.t("Choose") + " " + this.props.t("Premises")} autoWidth={false} /> </div>                                         
                                                                                            
                                                    <div style={{width:"900px",paddingRight: "20px"}}><InputText type="text" name="premisesCount" placeholder={this.props.t("Enter") + " " + this.props.t("Property Count")} value={this.state.premisesList[index].premisesCount} onChange={(event) => this.handlePremisesChanges(event,index)} required/></div>
                                                 <div style={{flexDirection: 'row',display:'flex',justifyContent: 'space-between'}}>                                                 
                                                 
                                                 
                                                 {this.state.premisesList.length - 1 && <Button type="button" label={this.props.t("Remove")} icon="pi pi-trash" onClick={() =>{this.removePremises(index)}} className="p-button-text p-button-danger"/>}                                                   
                                                 </div>
                                                 
                                             </div> */}
                                             {this.state.premisesList.length - 1 === index && <div className="p-col-12 p-md-offset-1 p-md-5"><Button  type="button" label={this.props.t("Add")} icon="pi pi-plus" onClick={()=> {this.addPremises()}} style={{width:"100px"}}/></div>}
                                             </div>
                                            })}
                                                                                  
                                        {/* </div>                                   */}

                                    
                                {/* </div> */}
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={this.props.t(this.state.dataId === 0 ? "Add House Holder" : "Edit House Holder")} icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                            }
                        </div>
                    </div>
                </div>

                {this.state.dataId === 0 && <Dialog visible={this.state.displayRoadCreator} className="p-col-12 p-md-6 p-lg-6 p-md-offset-1 p-lg-offset-2" modal onHide={() => this.setState({displayRoadCreator: false})} maximized={true}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12 p-text-center">
                        <form onSubmit={this.handleRoadSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">{this.props.t("Cell Name")}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.cells} value={this.state.dataJson.cell.id} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, cell: {id: event.value}}}))} optionLabel="name" optionValue="id" filter filterBy="name" placeholder={this.props.t("Choose Cell")} autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">{this.props.t("Location Name")}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                           <InputText type="text" name="roadName" placeholder={this.props.t("Enter")+" "+this.props.t("Road")} value={this.state.dataJson.roadName.name} onChange={this.handleRoadChange} required/>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('House Count')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="number" name="houseCount" placeholder={this.props.t("Enter") + " " + this.props.t("House Count")} value={this.state.dataJson.houseCount.count} onChange={this.handleHouseCountChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Road Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="roadNumber" placeholder={this.props.t("Enter") + " " + this.props.t("Road Number")} value={this.state.dataJson.roadNumber.rNumber} onChange={this.handleRoadNumberChange} required/>
                                        </div>
                                    </div>
                                    
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Details')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="roadDetails" placeholder={this.props.t("Enter") + " " + this.props.t("Details")} value={this.state.dataJson.roadDetails.details} onChange={this.handleRoadDetailsChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-5 p-md-2">
                                        <Button type="submit" label="Record" icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Dialog>}
            </div>
        );
	}
}
export default withTranslation()(HouseHolder)
