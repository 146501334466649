import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { HomeService } from '../service/HomeService';
import { UserService } from '../service/UserService';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns';
import "../adalipa/form.css";

const stringValidation = (fieldName, fieldValue) => {
    if (fieldValue.trim() === '') {
      return `${fieldName} is required`;
    }
    if (/[^a-zA-Z -]/.test(fieldValue)) {
      return 'Invalid string';
    }
    if (fieldValue.trim().length < 3) {
      return `${fieldName} needs to be at least three characters`;
    }
    return null;
  };
  
  const numberValidation = (fieldName, fieldValue) => {
    if (fieldValue.trim() === 0) {
      return `${fieldName} is required`;
    }
    if (/[^0-9-]/.test(fieldValue)) {
      return 'Invalid number';
    }
    return null;
  };
  
  const emailValidation = email => {
    if (
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      return null;
    }
    if (email.trim() === '') {
      return 'Email is required';
    }
    return 'Please enter a valid email';
  };

export default class AddPartner extends Component {

	constructor(props) {
        super(props);
        this.state = {
            partnerName: '',
            emailAddress: '',
            phoneNumber: '',
            revenueRatio: 0,
            districtId: 1,
            categoryId: 3,
            commRange: '',
            cutOffDate: '',
            fullName: '',
            password: '',
            confirm: '',
            regionId: 1,
			alert: '',
            districts: [],
            categories: []
        };
        this.homeService = new HomeService();
        this.userService = new UserService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.homeService.getDistricts('', 0, 200, 'id', 'asc')
        .then(response => {
            const data = response.content;
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.districtName + ", " + d.region.regionName
            }))
            this.setState({districts: options});
        });
        this.userService.getPartnerCategories('', 0, 100, 'id', 'asc')
        .then(response => {
            const data = response.content;
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.categoryName
            }))
            this.setState({categories: options});
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        const partnerJson = {
            partnerName: this.state.partnerName,
            emailAddress: this.state.emailAddress,
            phoneNumber: this.state.phoneNumber,
            revenueRatio: this.state.revenueRatio,
            districtId: this.state.districtId,
            categoryId: this.state.categoryId,
            commRange: this.state.commRange,
            cutOffDate: this.state.cutOffDate,
            fullName: this.state.fullName,
            password: this.state.password
        };
        this.userService.addPartner(partnerJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/partner/list/partners");
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/partner/list/partners",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                            { this.state.failed === true &&
                                <div className="p-messages-wrapper rounded">
                                    <ul>
                                        <li>
                                            <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                            <span className="p-messages-detail">{this.state.alert}</span>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <h1 style={{textAlign: "center", padding: "1%"}}>Partner Registration</h1>
                            </div>
                            <div className="divider-container"><div className="divider-border" />
                            <span className="divider-content"><h3 style={{textAlign: "center"}}>User Details</h3></span>
                            <div className="divider-border" /></div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Username:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="email" name="emailAddress" placeholder="eg: user@adalipa.co.tz" value={this.state.emailAddress} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="password">Password:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="password" name="password" placeholder="Password" value={this.state.password} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="password">Confirm:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="password" name="confirm" placeholder="Password" value={this.state.confirm} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Full Name:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="fullName" placeholder="eg: John Doe" value={this.state.fullName} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Phone Number:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="phoneNumber" placeholder="eg: +25570000000" value={this.state.phoneNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider-container"><div className="divider-border" />
                                <span className="divider-content"><h3 style={{textAlign: "center"}}>Partner Details</h3></span>
                                <div className="divider-border" /></div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Partner Name:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="partnerName" placeholder="eg: Bank of Tanzania" value={this.state.partnerName} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="dropdown">Partner Category:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.categories} value={this.state.categoryId} onChange={event => this.setState({categoryId: event.value})} autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="dropdown">District: </label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.districts} value={this.state.districtId} onChange={event => this.setState({districtId: event.value})} autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Revenue Ratio:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="revenueRatio" placeholder="eg: 35.00" value={this.state.revenueRatio} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Commission Range: </label>
                                        </div>
                                        <div className="p-grid p-col-12 p-md-12" style={{display: "inline !important"}}>
                                            <div className="p-col-3 p-md-3">
                                                <InputText type="text" name="commRange" placeholder="eg: 7" value={this.state.commRange} onChange={this.handleInputChange} required/>
                                            </div>
                                            <div className="p-col-6 p-md-6">
                                                <span>Days</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">Cut Off Date:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Calendar name="cutOffDate" placeholder="Choose Date" value={this.state.cutOffDate} onChange={(e) => this.setState({cutOffDate: format(e.value, 'yyyy-MM-dd')})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid" style={{marginTop: "1%"}}>
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label="Register Partner" icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}
