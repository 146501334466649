import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { LgaService } from '../../service/LgaService';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import ResponseAlert from '../../Utilities/ResponseAlert';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";
import { TabPanel, TabView } from 'primereact/tabview';

class Road extends Component{
    constructor(props){
        super(props);
        this.state = {
            dataJson: {cell: {id: 0}, road: {id:0} ,name: '',houses: 0,roadNumber:'', details: this.props.t('None')},
            dataId: 0,
            cells:[],
            roads: [],
            failed: null,
            alert: '',
            roadJson: {id: 0,roadName: '',cellName: ''},
            isMapping: false,
            loading: false
        };
        this.lgaService = new LgaService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handelEdit = this.handelEdit.bind(this);
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        if (+this.splitString(this.props.match.params.dataId,0) > 0) {
            this.setState({dataId: +this.splitString(this.props.match.params.dataId,0)});
            this.lgaService.getRoad(+this.splitString(this.props.match.params.dataId,0),this.splitString(this.props.match.params.dataId,1))
            .then(data => {
                this.setState({dataJson: {road: {id: data.road.id}, cell: {id: data.cell.id}, name: data.road.name,roadNumber :data.road.roadNumber,houses: data.road.houses, details: data.road.details}});   
            });
        }
        const jsonParams = { page: 0, limit: 100, sort: "id", order: 'asc' }
        this.lgaService.getCells(jsonParams)
        .then(data => {
            data.content.map(obj => {                
                obj.name = obj.name+"-"+obj.zone.name;
                return obj;
            })            
            this.setState({cells: data.content});
        });

        this.lgaService.getRoadList(jsonParams)
        .then(data => {
            data.content.map(obj => {                
                obj.name = obj.roadName;
                return obj;
            })  
            this.setState({roadJson: {...data.content}});                    
            this.setState({roads: data.content});
        })
    }

    splitString = (text,index) =>{
        const myArray = text.split("-");
        let word = myArray[index];
        return word
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({
            dataJson: {
                ...prevJson.dataJson, [inputName] : inputValue
            }
        }));        
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        const jsonParams = {...this.state.dataJson, isMapping: this.state.isMapping,institution: this.props.instituteId}
        this.lgaService.addRoad(jsonParams)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/lga/roads");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

    handelEdit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        const roadId = this.splitString(this.props.match.params.dataId,0);
        const cellId = this.splitString(this.props.match.params.dataId,1);
        const jsonParams = {...this.state.dataJson, isMapping: this.state.isMapping,institution: this.props.instituteId}
        this.lgaService.editRoad(jsonParams,roadId,cellId)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/lga/roads");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/lga/road/0",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <ResponseAlert failed={this.state.failed} alert={this.state.alert} />
                        <TabView>
                        <TabPanel header={this.props.t("New Road Info")} leftIcon="pi pi-user">

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>{this.props.t(this.state.dataId === 0 ? "New Road" : "Edit Road")}</h2>
                            </div>
                            {this.state.loading && 
                                <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                    <div className="p-col-12" style={{textAlign:'center'}}>
                                        <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                    </div>
                                </div>
                            }
                            {!this.state.loading && 
                            <form onSubmit={this.state.dataId === 0 ? this.handleSubmit : this.handelEdit}>
                                <div className="p-grid">                                    
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Cell')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            {this.state.dataId === 0 && <Dropdown options={this.state.cells} value={this.state.dataJson.cell.id} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, cell: {id: event.value}}}))} optionLabel="name" optionValue="id" filter filterBy="name" placeholder={this.props.t("Choose Cell")} autoWidth={false} />}
                                            {this.splitString(this.props.match.params.dataId,0) === 0 && <Dropdown options={this.state.cells} value={this.state.dataJson.cell.id} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, cell: {id: event.value}}}))} optionLabel="name" optionValue="id" filter filterBy="name" placeholder={this.props.t("Choose Cell")} autoWidth={false} />}
                                            {this.splitString(this.props.match.params.dataId,0) > 0 && <Dropdown options={this.state.cells} value={this.state.dataJson.cell.id} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, cell: {id: event.value}}}))} optionLabel="name" optionValue="id" filter filterBy="name" placeholder={this.props.t("Choose Cell")} autoWidth={false} disabled={true} />}
                                            
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Road Name')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="name" placeholder={this.props.t("Enter") + " " + this.props.t("Road Name")} value={this.state.dataJson.name} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Road Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="roadNumber" placeholder={this.props.t("Enter") + " " + this.props.t("Road Number")} value={this.state.dataJson.roadNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('House Count')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="number" name="houses" placeholder={this.props.t("Enter") + " " + this.props.t("House Count")} value={this.state.dataJson.houses} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Details')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="details" placeholder={this.props.t("Enter") + " " + this.props.t("Details")} value={this.state.dataJson.details} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={this.props.t(this.state.dataId === 0 ? "Add Road" : "Edit Road")} icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                            }
                        </div>
                       </TabPanel>


                       <TabPanel header={this.props.t("Map Road and Cell")} leftIcon="pi pi-user">

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>{this.props.t(this.state.dataId === 0 ? "New Road" : "Edit Road")}</h2>
                            </div>
                            {this.state.loading && 
                                <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                    <div className="p-col-12" style={{textAlign:'center'}}>
                                        <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                    </div>
                                </div>
                            }
                            {!this.state.loading && 
                            <form onSubmit={this.state.dataId === 0 ? this.handleSubmit : this.handelEdit}>
                                <div className="p-grid">                                    
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Cell')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            {/* {console.log("Key:",this.state.cell)} */}
                                            <Dropdown options={this.state.cells} value={this.state.dataJson.cell.id} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, cell: {id: event.value}}}))} optionLabel="name" optionValue="id" filter filterBy="name" placeholder={this.props.t("Choose Cell")} autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Road Name')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                           {this.state.dataId === 0 && <Dropdown options={this.state.roads} value={this.state.dataJson.road.id} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, road: {id: event.value}}, isMapping: true}))} optionLabel="name" optionValue="id" filter filterBy="name" placeholder={this.props.t("Choose Road")} autoWidth={false} />}
                                           {this.splitString(this.props.match.params.dataId,0) === 0 && <Dropdown options={this.state.roads} value={this.state.dataJson.road.id} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, road: {id: event.value}}, isMapping: true}))} optionLabel="name" optionValue="id" filter filterBy="name" placeholder={this.props.t("Choose Road")} autoWidth={false} disabled={false}/>} 
                                           {this.splitString(this.props.match.params.dataId,0) > 0 && <Dropdown options={this.state.roads} value={this.state.dataJson.road.id} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, road: {id: event.value}}, isMapping: true}))} optionLabel="name" optionValue="id" filter filterBy="name" placeholder={this.props.t("Choose Road")} autoWidth={false} disabled={true}/>} 
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Road Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="roadNumber" placeholder={this.props.t("Enter") + " " + this.props.t("Road Number")} value={this.state.dataJson.roadNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('House Count')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="number" name="houses" placeholder={this.props.t("Enter") + " " + this.props.t("House Count")} value={this.state.dataJson.houses} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Details')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="details" placeholder={this.props.t("Enter") + " " + this.props.t("Details")} value={this.state.dataJson.details} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={this.props.t(this.state.dataId === 0 ? "Add Road" : "Edit Road")} icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                            }
                        </div>
                       </TabPanel>
                        </TabView>

                        


                    </div>
                </div>
            </div>
        );
	}
}
export default withTranslation()(Road)