import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { RealEstateService } from '../../service/RealEstateService';
import ResponseAlert from '../../Utilities/ResponseAlert';

export class ProjectPayments extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            projectId: 0,
            customerId: 0,
            accountId: '',
            sortOrder: -1,
            sortField: 'id'
        };

        this.realEstateService = new RealEstateService();
        this.getFees = this.getFees.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
    }

    getFees = () => {
        const jsonParams = {projectId: this.state.projectId, customerId: this.state.customerId, accountId: this.state.accountId, page: this.state.pageIndex, limit: this.state.pageSize, sort: this.state.sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc' }
        return this.realEstateService.getCustomerFees(jsonParams)
        .then(data => {
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    componentDidMount() {
        this.getFees();
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getFees().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            })
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getFees();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getFees();
        });     
    }

    booleanTemplate(rowData, column) {
        return rowData['enabled'] === true ? 'Active' : 'Inactive';
    }

    actionTemplate(rowData, column) {
        return <div>
            <Link to={"/wallet/real/estate/installments/" + rowData['id']}><Button type="button" icon="pi pi-eye" title="View Installments" className="p-button-adalipa" style={{marginRight: '.5em'}}/></Link>
            <Link to={"/wallet/real/estate/installment/0/" + rowData['id']}><Button type="button" icon="pi pi-plus" title="Add Installments" className="p-button-adalipa" style={{marginRight: '.5em'}}/></Link>
        </div>;
    }

    render() {

        let accountIdFilter = <InputText type="text" name="accountId" style={{width: '100%'}} placeholder="Contains" value={this.state.accountId} onChange={this.handleInputChange}/>

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <ResponseAlert failed={this.state.failed} alert={this.state.alert} />
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>Real Estate Payments</h2></div>
                            {/* <div className="p-col-8 p-md-8">
                                <Link to="/wallet/real/estate/payment/0" style={{float: "right"}}><Button label="Add Payment" icon="pi pi-plus"/></Link>
                            </div> */}
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" paginator={false} 
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="project.projectName" header="Project" sortable={true} filter={false}/>
                            <Column field="customer.fullName" header="Customer" sortable={true} filter={false}/>
                            <Column field="accountId" header="Reference" sortable={true} filter={true} filterElement={accountIdFilter}/>
                            <Column field="cost" header="Cost" sortable={true} filter={false}/>
                            <Column field="amount" header="Paid Amount" sortable={true} filter={false}/>
                            <Column field="id" header="Installments" sortable={false} filter={false} body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        <Paginator currentPageReportTemplate={"Showing " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator>
                    </div>
                </div>
            </div>
        );
    }
}
