import { API_BASE_URL } from '../constants';
import { getRequest } from './CoreService';

export class GraduateService {

    getCourses(serachKey, page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/graduate/get/courses?search=" + serachKey + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    getLevels(page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/graduate/get/levels?page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    getStudentInfo(accountId) {
        return getRequest({
            url: API_BASE_URL + "/graduate/info?accountId=" + accountId,
            method: 'GET'
        });
    }
    getStudents(courseId, gradeId, firstName, middleName, lastName, phoneNumber, regNumber, accountId, Status, page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/graduate/list?courseId = " + courseId + "&gradeId = " + gradeId + "&firstName=" + firstName + "&middleName=" + middleName + "&lastName=" + lastName + "&phoneNumber=" + phoneNumber + "&regNumber=" + regNumber + "&accountId=" + accountId + "&Status=" + Status + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }
}