import React, {Component} from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { WalletService } from '../../service/WalletService';
import { Paginator } from 'primereact/paginator';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';

export class Transactions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            businessId: 0,
            businessName: "",
            accountId: '',
            phoneNumber: '',
            receiptNumber: '',
            client: '',
            customer: '',
            reqTimeFrom: '',
            reqTimeTo: '',
            Status: null,
            sortOrder: -1,
            sortField: 'id',
            displayDateFilter: false
        };

        this.walletService = new WalletService();
        this.adalipaUtil = new AdalipaUtil();
        this.getTransactions = this.getTransactions.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onDateFilter = this.onDateFilter.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.feeTemplate = this.feeTemplate.bind(this);
        this.amountTemplate = this.amountTemplate.bind(this);
        this.paidAmtTemplate = this.paidAmtTemplate.bind(this);
        this.setDisplayDateFilter = this.setDisplayDateFilter.bind(this);
        this.clearDateFilter = this.clearDateFilter.bind(this);
        this.panelHeader = this.panelHeader.bind(this);
        this.dialogFooter = this.dialogFooter.bind(this);
    }

    getTransactions = () => {
        return this.walletService.getCustomerTransactions(this.state.businessId, this.state.accountId, this.state.phoneNumber, this.state.receiptNumber, this.state.client, this.state.customer, this.state.reqTimeFrom.toString().length > 0 ? format(this.state.reqTimeFrom, 'yyyy-MM-dd') : "", this.state.reqTimeTo.toString().length > 0 ? format(this.state.reqTimeTo, 'yyyy-MM-dd') : "", 0, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
        .then(data => {
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    componentDidMount() {
        this.setState({businessId: +this.props.match.params.businessId});
        this.walletService.getCustomerTransactions(+this.props.match.params.businessId, this.state.accountId, this.state.phoneNumber, this.state.receiptNumber, this.state.client, this.state.customer, this.state.reqTimeFrom.toString().length > 0 ? format(this.state.reqTimeFrom, 'yyyy-MM-dd') : "", this.state.reqTimeTo.toString().length > 0 ? format(this.state.reqTimeTo, 'yyyy-MM-dd') : "", 0, this.state.pageSize, 'id', 'asc')
        .then(data => {
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                pageSize: data.pageable.pageSize,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
        if (+this.props.match.params.businessId > 0) {
            this.walletService.businessInfo(+this.props.match.params.businessId)
            .then(data => {
                this.setState({businessName: data.business});
            });
        }
    }

    onPageChange(event) {
        this.getTransactions().then(() => {
            this.setState({first: event.first, pageSize: event.rows})
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getTransactions();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getTransactions();
        });     
    }

    onDateFilter() {
        this.setDisplayDateFilter(false);
        this.getTransactions();
    }

    setDisplayDateFilter = (value) => {
        this.setState({displayDateFilter: value});
    }

    clearDateFilter = () => {
        this.setState({
            reqTimeFrom: "",
            reqTimeTo: ""
        }, () => {
            this.getTransactions()
        });
    }

    panelHeader = () => <span className="p-text-center p-text-primary"><i className="pi pi-exclamation-triangle"></i>Choose range of date</span>;

    dialogFooter = () => <div className="p-grid">
        {/* <div className="p-col-6 p-text-right">
            <Button type="button" label="Reset now" onClick={() => resetPassword()} icon="pi pi-check-circle" className="p-button-success" />
        </div> */}
        <div className="p-col-6 p-text-left">
            <Button type="button" label="Dismiss" onClick={() => this.setDisplayDateFilter(false)} icon="pi pi-times-circle" className="p-button-warning" />
        </div>
    </div>;

    dateTemplate(rowData, column) {
        return new Intl.DateTimeFormat('sw', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.parse(rowData['updatedTime']));
    }

    amountTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['amount']);
    }
    feeTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['feeAmt']);
    }
    paidAmtTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['paidAmt']);
    }

    render() {

        let dateFilter = <div style={{padding: "0px", margin: "0px"}}>
                <Link to="#" onClick={() => this.setDisplayDateFilter(true)}><i className="pi pi-calendar" style={{marginLeft: "0px", paddingLeft: "0px", verticalAlign: "bottom"}}></i><small>Filter</small></Link>&nbsp;&nbsp;
                <Link to="#" onClick={() => this.clearDateFilter()}><i className="pi pi-times-circle" style={{marginLeft: "0px", paddingLeft: "0px", verticalAlign: "bottom"}}></i><small>Clear</small></Link>
            </div>
        let accountIdFilter = <InputText type="text" name="accountId" style={{width: '100%'}} placeholder="Contains" value={this.state.accountId} onChange={this.handleInputChange}/>
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder="Contains" value={this.state.phoneNumber} onChange={this.handleInputChange}/>
        let receiptNumberFilter = <InputText type="text" name="receiptNumber" style={{width: '100%'}} placeholder="Contains" value={this.state.receiptNumber} onChange={this.handleInputChange}/>
        let clientFilter = <InputText type="text" name="client" style={{width: '100%'}} placeholder="Contains" value={this.state.client} onChange={this.handleInputChange}/>
        let customerFilter = <InputText type="text" name="customer" style={{width: '100%'}} placeholder="Contains" value={this.state.customer} onChange={this.handleInputChange}/>

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-12"><h2>{this.state.businessId > 0 ? this.state.businessName : "All"} Customer Payments</h2></div>
                            {/* <div className="p-col-8 p-md-8">
                                <Link to="/wallet/business/transactions/0" style={{float: "right"}}><strong style={{color: '#3B007B'}}>All Customer Transactions</strong></Link>
                            </div> */}
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header="Customer Payments" paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})} 
                            currentPageReportTemplate={"Data " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                            <Column field="reqTime" body={this.dateTemplate} header="Time" sortable={true} filter={true} filterElement={dateFilter} style={{padding: "0px", align: "center"}} />
                            <Column field="customerName" header="Customer" sortable={true} filter={true} filterElement={customerFilter} filterPlaceholder="Contains"/>
                            <Column field="accountId" header="Reference" sortable={true} filter={true} filterElement={accountIdFilter} filterPlaceholder="Contains"/>
                            <Column field="phoneNumber" header="Phone Number" sortable={true} filter={true} filterElement={phoneNumberFilter} filterPlaceholder="Contains"/>
                            <Column field="receiptNumber" header="Receipt Number" sortable={true} filter={true} filterElement={receiptNumberFilter} filterPlaceholder="Contains"/>
                            <Column field="client" header="Partner" sortable={true} filter={true} filterElement={clientFilter} filterPlaceholder="Contains"/>
                            <Column field="feeAmt" header="Fee Amount" body={this.feeTemplate} sortable={true} filter={false}/>
                            <Column field="amount" header="Paid Amount" body={this.amountTemplate} sortable={true} filter={false}/>
                            <Column field="paidAmt" header="Total Paid Amount" body={this.paidAmtTemplate} sortable={true} filter={false}/>
                        </DataTable>
                        <Paginator currentPageReportTemplate={"Showing " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator>
                    </div>
                </div>
                <Dialog visible={this.state.displayDateFilter} className="p-col-12 p-md-6 p-lg-6 p-md-offset-1 p-lg-offset-2" modal onHide={() => this.setDisplayDateFilter(false)}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12 p-text-center">
                        <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">Start From:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Calendar name="reqTimeFrom" placeholder="Choose Date" value={this.state.reqTimeFrom} onChange={(e) => this.setState({reqTimeFrom: e.value})} dateFormat="yy-mm-d" showIcon showButtonBar showOnFocus icon="pi pi-calendar" appendTo={document.body}/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">End To:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Calendar name="reqTimeTo" placeholder="Choose Date" value={this.state.reqTimeTo} onChange={(e) => this.setState({reqTimeTo: e.value})} dateFormat="yy-mm-d" showIcon showButtonBar showOnFocus icon="pi pi-calendar" appendTo={document.body}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-5 p-md-2">
                                        <Button type="button" label="Filter" icon="pi pi-filter" onClick={() => this.onDateFilter()} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}
