import React,{ Component } from "react";
import { AdalipaUtil } from "../Utilities/AdalipaUtil";
import { ManagerialService } from "../service/ManagerialService";
import { WalletService } from "../service/WalletService";
import { Redirect } from 'react-router-dom';
import ViewDetails from "../components/ViewDetails";

class CrisesInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            crises: []
        };
        this.walletService = new WalletService();
        this.managerialService = new ManagerialService();
        this.adalipaUtil = new AdalipaUtil();
    }

    componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.managerialService.getSpecificCrises(this.props.match.params.crisesId)
        .then(data => {
             if(data.road === null){
                data.road = {id: 0, name: 'None', details: 'None'};                
              }
              if(data.cell === null){
                data.cell = {id: 0, name: 'None', details: 'None'};                
              }
              if(data.zone === null){
                data.zone = {id: 0, name: 'None', details: 'None'};                
              }
            const crisesInfo = [
                { name: data.zone.name, title: "Zone"},
                { name: data.cell.name, title: "Cell"},
                { name: data.road.name, title: "Road"},
                { name: data.crisesCategory, title: "Category"},
                { name: data.crisesDetails, title: "Details"},
                { name: data.reporterName, title: "Reporter Name"},
                { name: data.reporterPhoneNumber, title: "Reporter Phone"},
                { name: data.numberOfOccurrence, title: "Occurrence"},
                { name: data.crisesStatus, title: "Status"},
                { name: data.institution.instituteName, title: "Institution"},
                { name: this.adalipaUtil.timestampFormat(data.creationDate), title: "Date"},
                { name: data.createdBy, title: "Created By"}

            ];
            this.setState({crises: crisesInfo});
        });        
    }

    render() { 
        if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/lga/crises",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-10 p-md-offset-1">
                        <div className="card card-w-title">
                            <div className="p-card-title p-text-center" style={{backgroundColor: "#3B007B", color: "#FFFFFF", textAlign: "center"}}>
                                <span style={{textAlign: "center", padding: "0.5em", fontSize: "large", fontWeight: "bold"}}>Crises Details</span>
                            </div>
                            <ViewDetails attributes={this.state.crises} />
                            {/* <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <small style={{textAlign: "center", padding: "0.5em"}}>&nbsp;</small>
                            </div> */}
                            {/* <div className="p-card-title p-text-center" style={{backgroundColor: "#3B007B", color: "#FFFFFF", textAlign: "center", marginTop: "2em"}}>
                                <span style={{textAlign: "center", padding: "0.5em", fontSize: "large", fontWeight: "bold"}}>Balance Details</span>
                            </div>
                            <ViewDetails attributes={this.state.balance} /> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default CrisesInfo;