import React, {Component} from 'react';
import { GraduateService } from '../service/GraduateService';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import NumberFormat from 'react-number-format';

export class GraduateInfo extends Component {

    constructor() {
        super();
        this.state = {
            graduate: {},
            paid: 0,
            due: 0,
            dataTableValue:[],
            layout: 'list',
            sortOptions: [
                {label: 'Newest First', value: '!year'},
                {label: 'Oldest First', value: 'year'},
                {label: 'Brand', value: 'brand'}
            ],
        };

        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '768px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '560px',
                numVisible: 1,
                numScroll: 1
            }
        ];

        this.graduateService = new GraduateService();

        // this.onCourseChange = this.onCourseChange.bind(this);
    }

    async componentDidMount() {
        this.graduateService.getStudentInfo(this.props.match.params.accountid)
        .then(data => this.setState(
            {
                graduate: data.graduate,
                paid: data.paid,
                due: data.due,
                dataTableValue: [{paid: data.paid, due: data.due}]
            })
        );
    }

    onCourseChange(event) {
        this.dt.filter(event.value, 'id', 'equals');
        this.setState({courseId: event.value});
    }
    
    feeAmountTemplate(rowData, column) {
        return <span><NumberFormat value={(rowData['due'] + rowData['paid'])} displayType={'text'} thousandSeparator={true} prefix={'TZS'} />/=</span>
    }
    
    paidAmountTemplate(rowData, column) {
        return <span><NumberFormat value={rowData['paid']} displayType={'text'} thousandSeparator={true} prefix={'TZS'} />/=</span>
    }
    
    dueAmountTemplate(rowData, column) {
        return <span><NumberFormat value={rowData['due']} displayType={'text'} thousandSeparator={true} prefix={'TZS'} />/=</span>
    }

    feeStatusTemplate(rowData, column) {
        return <ProgressBar value={((rowData['paid']/(rowData['paid'] + rowData['due']))*100).toFixed(2)} showValue={true}></ProgressBar>;
    }

    render() {
        const {graduate} = this.state;
        return !!graduate?.course &&
            <div className="p-grid">
                <div className="p-col-12 p-md-4">
                    { this.state.graduate.course !== null &&
                        <div className="p-col-12 p-md-12">
                            <span><strong>Course:</strong>&nbsp;{this.state.graduate.course.courseName}</span>
                        </div>
                    }
                    <div className="p-col-12 p-md-12">
                        <span><strong>Level:</strong>&nbsp;{this.state.graduate.grade.gradeName}</span>
                    </div>
                    <div className="p-col-12 p-md-12">
                        <span><strong>Parent/Student Phone:</strong>&nbsp;{this.state.graduate.phoneNumber}</span>
                    </div>
                </div>
                <div className="p-col-12 p-md-8">
                    <div className="card">
                        <h2 className="centerText">Student fees</h2>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" paginator={true} rows={10} responsive={true}>
                            <Column field="fee" body={this.feeAmountTemplate} header="Fee Amount" sortable={true} filter={false}/>
                            <Column field="paid" body={this.paidAmountTemplate} header="Paid Amount" sortable={true} filter={false}/>
                            <Column field="due" body={this.dueAmountTemplate} header="Due Amount" sortable={true} filter={false}/>
                            <Column body={this.feeStatusTemplate} header="Progress" sortable={false} filter={false}/>
                        </DataTable>
                    </div>
                </div>
            </div>
    }
}
