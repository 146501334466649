import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { WalletService } from '../../service/WalletService';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { withTranslation } from 'react-i18next';

class AddMember extends Component {

	constructor(props) {
        super(props);
        this.state = {
            groupId: 0,
            memberName: '',
            phoneNumber: '',
            sendSms: false,
            alert: '',
            failed: null,
            groups: []
        };
        this.walletService = new WalletService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        const jsonParams = {groupName: '',groupStatus:"Active", page: 0, limit: 100, sort: 'id', order: 'asc'};
        this.walletService.getGroups(jsonParams)
        .then(response => {
            const data = response.content;
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.groupName
            }))
            this.setState({groups: [{value: 0, label: "Select Group"}].concat(options)});
        }).catch(error => {
            this.setState({groups: [{value: 0, label: "Select Group"}]});
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        let memberJson = {
            groupId: this.state.groupId,
            memberName: this.state.memberName,
            phoneNumber: this.state.phoneNumber,
            sendSms: this.state.sendSms
        };
        this.walletService.member(memberJson, 'POST')
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/list/members");
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/add/member",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                            { this.state.failed === true &&
                                <div className="p-messages-wrapper rounded">
                                    <ul>
                                        <li>
                                            <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                            <span className="p-messages-detail">{this.state.alert}</span>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>

                        <div className="card card-w-title">
                            <div className="p-grid">
                                <div className="p-col-4 p-md-4"><h1>{this.props.t('New Members')}</h1></div>
                                <div className="p-col-8 p-md-8">
                                    <Link to="/wallet/add/member/batch" style={{float: "right"}}><Button label={this.props.t("Add Members Using Excel")} icon="pi pi-plus"/></Link>
                                </div>
                            </div>
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>New Member</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4 p-md-offset-2">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="dropdown">Choose Group: </label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.groups} value={this.state.groupId} onChange={event => this.setState({groupId: event.value})} autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Meber Name:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="memberName" placeholder="eg: Form One" value={this.state.memberName} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-md-offset-2">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Phone Number:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="phoneNumber" placeholder="eg: 1,000,000" value={this.state.phoneNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-6 p-md-6">
                                            <label htmlFor="input">{this.props.t('Send Sms After Adding')}:</label>
                                        </div>
                                        <div className="p-col-6 p-md-6">
                                            <div className="p-grid">
                                                <div className="p-col-6 p-md-6">
                                                    <RadioButton value={true} inputId="yes"  onChange={(e) => this.setState({sendSms: e.value})} checked={this.state.sendSms}/>
                                                    <label htmlFor="yes" className="p-radiobutton-label">{this.props.t('Yes')}</label>
                                                </div>
                                                <div className="p-col-6 p-md-6">
                                                    <RadioButton value={false} inputId="no" onChange={(e) => this.setState({sendSms: e.value})} checked={!this.state.sendSms}/>
                                                    <label htmlFor="no" className="p-radiobutton-label">{this.props.t('No')}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label="Add Member" icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}

export default withTranslation()(AddMember)