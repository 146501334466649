import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { WalletService } from '../../service/WalletService';
import { Paginator } from 'primereact/paginator';
import { TabPanel, TabView } from 'primereact/tabview';
import { WhatsappService } from '../../service/whatsappService';
import { withTranslation } from 'react-i18next';
import BeatLoader from "react-spinners/BeatLoader";
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
        

class ListWhatsapp extends Component {

    constructor(props) {
        super(props);
        /*console.log(this.props.roles)*/
        this.state = {
            dataTableValue: [],
            messageCategory: "choose",
            messageCategories: [{ label: this.props.t("Custom Message"), value: "YES" }, { label: this.props.t("System Message"), value: "NO" }],
            isCustom: "",
            groupName: '',
            institution: 0,
            message: '',
            instituteId: 0,
            reference: '',
            phoneNumber: '',
            memberName: '',
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            error: '',
            isManager: false,
            grouStatus: "Active",
            groupId: 0,
            memberId: 0,
            memberStatus: 'Active',
            contributionStatus: 'ON',
            memberType: '',
            sortOrder: -1,
            displayWhatsAppMessageBox: false,
            alertType: '',
            alertMessage: '',
            sending: false,
            sortField: 'id',
            groups: []
        };

        this.walletService = new WalletService();
        this.whatsappService = new WhatsappService();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onGroupChange = this.onGroupChange.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getMemberRole = () => {
        const roles = this.props.roles;
        if (roles.includes("MANAGER")) {
            this.setState({ isManager: true })
        }

    }

    componentDidMount() {
        let { groupId, pageIndex, pageSize, sortField, sortOrder, memberName, reference } = this.state;
        this.whatsappService.getWhatsappList({ groupId: groupId, memberName: memberName, reference: reference, page: pageIndex, limit: pageSize, sort: sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc' }, this.props.instituteId)
            .then(data => {
                this.setState({ dataTableValue: data.content });
                // this.getMemberRole();
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            })
            .catch(error => {
                this.setState({ failed: true });
                this.setState({ alert: error.message });
            });


        const jsonParams = { groupStatus: this.state.grouStatus, page: this.state.pageIndex, limit: 200, sort: this.state.sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc' }
        this.walletService.getGroups(jsonParams)
            .then(response => {
                const data = response.content;
                const options = data.map(d => ({
                    "value": d.id,
                    "label": d.groupName
                }))
                this.setState({ groups: options });
            });
    }

    onPageChange(event) {
        this.setState({ pageIndex: event.page }, () => {
            let { groupId, pageIndex, pageSize, sortField, sortOrder, memberName, reference } = this.state;
            this.whatsappService.getWhatsappList({ groupId: groupId, memberName: memberName, reference: reference, page: pageIndex, limit: pageSize, sort: sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc' }, this.props.instituteId)
                /*this.walletService.getMembers(this.state.groupId, this.state.memberName, this.state.phoneNumber, this.state.memberType, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')*/
                .then(data => {
                    this.setState({ dataTableValue: data.content });
                    this.getMemberRole();
                    this.setState({
                        pageIndex: data.pageable.pageNumber,
                        totalPages: data.totalPages,
                        numberOfElements: data.numberOfElements,
                        totalElements: data.totalElements,
                        first: (data.pageable.pageNumber * data.totalPages) + 1
                    });
                    this.setState({ first: event.first, pageSize: event.rows });
                });
        });
    }

    onSortChange(event) {
        this.setState({ sortOrder: event.sortOrder, sortField: event.sortField }, () => {
            let { groupId, pageIndex, pageSize, sortField, sortOrder, memberName, reference } = this.state;
            this.whatsappService.getWhatsappList({ groupId: groupId, memberName: memberName, reference: reference, page: pageIndex, limit: pageSize, sort: sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc' }, this.props.instituteId)
                /*this.walletService.getMembers(this.state.groupId, this.state.memberName, this.state.phoneNumber, this.state.memberType, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')*/
                .then(data => {
                    this.setState({ dataTableValue: data.content });
                    this.getMemberRole();
                    this.setState({
                        pageIndex: data.pageable.pageNumber,
                        totalPages: data.totalPages,
                        numberOfElements: data.numberOfElements,
                        totalElements: data.totalElements,
                        first: (data.pageable.pageNumber * data.totalPages) + 1
                    });
                });
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;
        this.setState({
            [inputName]: inputValue
        }, () => {
            let { groupId, pageIndex, pageSize, sortField, sortOrder, memberName, reference } = this.state;
            this.whatsappService.getWhatsappList({ groupId: groupId, memberName: memberName, reference: reference, page: pageIndex, limit: pageSize, sort: sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc' }, this.props.instituteId)
                /*this.walletService.getMembers(this.state.groupId, this.state.memberName, this.state.phoneNumber, this.state.memberType, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')*/
                .then(data => {
                    this.setState({ dataTableValue: data.content });
                    this.getMemberRole();
                    this.setState({
                        pageIndex: data.pageable.pageNumber,
                        totalPages: data.totalPages,
                        numberOfElements: data.numberOfElements,
                        totalElements: data.totalElements,
                        first: (data.pageable.pageNumber * data.totalPages) + 1
                    });
                });
        });
    }

    onGroupChange(event) {
        this.dt.filter(event.value, 'id', 'equals');
        this.setState({
            groupId: event.value
        }, () => {
            let { groupId, pageIndex, pageSize, sortField, sortOrder, memberName, reference } = this.state;
            this.whatsappService.getWhatsappList({ groupId: groupId, memberName: memberName, reference: reference, page: pageIndex, limit: pageSize, sort: sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc' }, this.props.instituteId)
                /*this.walletService.getMembers(this.state.groupId, this.state.memberName, this.state.phoneNumber, this.state.memberType, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')*/
                .then(data => {
                    this.setState({ dataTableValue: data.content });
                    this.getMemberRole();
                    this.setState({
                        pageIndex: data.pageable.pageNumber,
                        totalPages: data.totalPages,
                        numberOfElements: data.numberOfElements,
                        totalElements: data.totalElements,
                        first: (data.pageable.pageNumber * data.totalPages) + 1
                    });
                });
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        const whatsappJson = {
            message: this.state.message,
            isCustom: this.state.isCustom,
            reference: this.state.reference,
        };

        if (this.state.messageCategory !== "choose") {
            this.whatsappService.sendSingleWhatsappMessage(whatsappJson)
                .then(response => {
                    this.setState({ failed: false });
                    this.setState({ alert: response.message });
                    this.setState({ hasAlert: true });
                }).catch(error => {
                    this.setState({ failed: true });
                    this.setState({ alert: error.message });
                    this.setState({ hasAlert: true });
                });

        } else {

            this.setState({ failed: true });
            this.setState({ alert: "WhatsApp Message Failed, Invalid Message Category selected" });
            this.setState({ hasAlert: true });
        }
    }



    booleanTemplate(rowData, column) {
        return rowData['enabled'] === true ? 'Active' : 'Inactive';
    }

    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-comment" className="p-button-success" style={{ marginRight: '.2em' }} onClick={() => this.setState({ displayWhatsAppMessageBox: true,reference: rowData['reference'] })} title="Send Whatsapp Message" />
        </div>;
    }

    disableDialogFooter = <div className="p-grid">
        <div className="p-col-6" style={{ textAlign: "right" }}>
            <Button type="button" label={this.props.t("Cancel")} onClick={() => this.setState({ displayWhatsAppMessageBox: false })} icon="pi pi-times-circle" className="p-button-sm" style={{ backgroundColor: "#EEEFFF", color: "#3B007B" }} />
        </div>
        <div className="p-col-6" style={{ textAlign: "left" }}>
            <Button type="submit" label={this.props.t("Send Message")} onClick={(e) => this.handleSubmit(e)} icon="pi pi-check-circle" className="p-button-sm" />
        </div>
    </div>;



    render() {

        let groupFilter = <Dropdown style={{ width: '100%' }} placeholder="Select a Group" value={this.state.groupId} options={this.state.groups} onChange={this.onGroupChange} filter={true} />
        let memberNameFilter = <InputText type="text" name="memberName" style={{ width: '100%' }} placeholder="Contains" value={this.state.memberName} onChange={this.handleInputChange} />
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{ width: '100%' }} placeholder="Contains" value={this.state.phoneNumber} onChange={this.handleInputChange} />
        let referenceFilter = <InputText type="text" name="reference" style={{ width: '100%' }} placeholder="Contains" value={this.state.reference} onChange={this.handleInputChange} />

        let actionHeader = <Link to="#"><Button type="button" icon="pi pi-cog" label={this.props.t("Switch")} /></Link>;


        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>{this.props.t("List of WhatApp Members")}</h2></div>
                            <div className="p-col-8 p-md-8">
                                <Link to="/wallet/initiate/whatsapp" style={{ float: "right" }}><Button label={this.props.t("Initiate WhatsApp Payment")} icon="pi pi-plus" /></Link>
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" paginator={false}
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({ dataTableSelection1: event.value })}>
                            <Column field="groupName" header={this.props.t("Group")} sortable={true} filter={true} filterElement={groupFilter} />
                            <Column field="memberName" header={this.props.t("Member")} sortable={true} filter={true} filterElement={memberNameFilter} />
                            <Column field="phoneNumber" header={this.props.t("Phone Number")} sortable={true} filter={true} filterElement={phoneNumberFilter} />
                            <Column field="reference" header={this.props.t("Payment Reference")} sortable={true} filter={true} filterElement={referenceFilter} />
                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '12em' }} />
                        </DataTable>
                        <Paginator currentPageReportTemplate={"Showing " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements}
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10, 20, 30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator>

                        <Dialog header={this.props.t("WhatsApp Message Box")} visible={this.state.displayWhatsAppMessageBox} className="p-col-6 p-offset-2" modal footer={this.disableDialogFooter} onHide={() => this.setState({ displayWhatsAppMessageBox: false })}>
                            <div className="p-fluid">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                                            <TabPanel header={this.props.t("Send")} leftIcon="pi pi-user">
                                                <form>

                                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                                        <div className="p-col-12 p-md-12">
                                                            <label htmlFor="input">{this.props.t('WhatsApp Message Category')}:</label>
                                                        </div>
                                                        <div className="p-col-12 p-md-12">
                                                            <Dropdown options={this.state.messageCategories} value={this.state.messageCategory} onChange={(e) => { this.setState({ isCustom: e.target.value, messageCategory: e.target.value }) }} filter={true} required />
                                                        </div>
                                                    </div>


                                                    {this.state.isCustom === "YES" && <div className="p-col-12 p-md-offset-2 p-md-8">
                                                        <div className="p-col-12 p-md-12">
                                                            <label htmlFor="input">{this.props.t('WhatsApp Message')}:</label>
                                                        </div>
                                                        <div className="p-col-12 p-md-12">
                                                            <InputTextarea type="text" name="message" placeholder={this.props.t("Enter") + " " + this.props.t("Message")} value={this.state.message} onChange={this.handleInputChange} required />
                                                        </div>
                                                    </div>}


                                                </form>
                                            </TabPanel>
                                        </TabView>
                                    </div>
                                    {this.state.sending &&
                                        <div className="p-grid p-fluid p-col-12" style={{ textAlign: 'center', padding: "2em" }}>
                                            <div className="p-col-12" style={{ textAlign: 'center' }}>
                                                <BeatLoader color={"#3B007B"} sending={this.state.sending} css={""} size={10} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Dialog>




                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ListWhatsapp);
