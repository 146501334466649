import React,{ Component } from "react";
import { renderToString } from 'react-dom/server';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";
import jsPDF from "jspdf";
import { ManagerialService } from "../service/ManagerialService";
import { WalletService } from "../service/WalletService";
import { AdalipaUtil } from "../Utilities/AdalipaUtil";
import ResponseAlert from "../Utilities/ResponseAlert";

class Crises extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            dataTableValue:[],
            pdfData:[],
            excelData:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            layout: 'list',
            sortOptions: [
                {label: 'Newest First', value: '!year'},
                {label: 'Oldest First', value: 'year'},
                {label: 'Brand', value: 'brand'}
            ],
            institutionId: 0,
            roadId: 0,
            districtId: 0,
            zoneId: 0,
            cellId: 0,
            reporter: '',
            crisesCategory:'',
            phoneNumber: '',
            reporterName: '',
            numberOfOccurrence: 0,
            crisesStatus: '',
            institutionName: '',
            zoneName: '',
            cellName: '',
            roadName: '',
            createdBy: '',
            crisesTime:'',

            
            Status: null,
            sortOrder: -1,
            sortField: 'id',
            loading: false,
            // receiptData: {crisesTime: "", reporterName: "", phoneNumber: "", numberOfOccurrence: 0, crisesStatus: "",institutionName: "",createdBy: ""},
            displayReceipt: false,
            isAdmin: false,
            isDistrictManager: false,
            CashAllowed: true,
            userEmail: '',
            error: "",
            alert: '',
            failed: null
         }

         this.walletService = new WalletService();
         this.managerialService = new ManagerialService();
         this.adalipaUtil = new AdalipaUtil();
 
         this.onSortChange = this.onSortChange.bind(this);
         this.onPageChange = this.onPageChange.bind(this);
         this.handleInputChange = this.handleInputChange.bind(this);
         this.actionTemplate = this.actionTemplate.bind(this);
         this.onDeleteButtonClick = this.onDeleteButtonClick.bind(this);
         this.export = this.export.bind(this);
 
         this.cols = [
             { field: 'crisesTime', header: this.props.t('Time') },
             { field: 'reporterName', header: this.props.t('Reporter Name') },
             { field: 'reporterPhoneNumber', header: this.props.t('Phone Number') },
             { field: 'numberOfOccurrence', header: this.props.t('Number Of Occurrence') },
             { field: 'crisesStatus', header: this.props.t('Status') },
             { field: 'institutionName', header: this.props.t('Institution Name') },
             { field: 'crisesCategory', header: this.props.t('Crises Category') },
             { field: 'createdBy', header: this.props.t('Created By') }
         ];
         this.excelCols = {header: ['crisesTime', 'reporterName', 'reporterPhoneNumber', 'numberOfOccurrence',"crisesStatus", 'institutionName', 'crisesCategory','createdBy']};
         this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    getDataList() {
        // console.log("ROLE LIST",this.props.roles);
        let {roadId,zoneId, cellId, crisesCategory, reporter,phoneNumber, pageIndex, pageSize, sortField, sortOrder} = this.state;
        const role = this.props.roles;
        let jsonParams;
        if(role.includes("ADMIN")){
            this.setState({isDistrictManager: false});
            this.setState({isAdmin:true});
        }
        if(role.includes("DISTRICT_MANAGER")){
            this.setState({isAdmin:false});
            this.setState({isDistrictManager: true});
            this.setState({districtId: this.props.districtId});
            jsonParams = {institutionId:this.state.institutionId,districtId: this.props.districtId,roadId:roadId, zoneId: zoneId, cellId: cellId, crisesCategory: crisesCategory, phoneNumber: phoneNumber,reporter:reporter, page: pageIndex, limit: pageSize, sort: sortField, order: sortOrder === 1 ? 'asc' : 'desc'};
        }
        if(role.includes("MANAGER")){
            this.setState({isAdmin:false});
            this.setState({isDistrictManager: false});
            this.setState({institutionId: +this.props.instituteId});
            jsonParams = {institutionId:this.props.instituteId,districtId: this.state.districtId,roadId:roadId, zoneId: zoneId, cellId: cellId, crisesCategory: crisesCategory, phoneNumber: phoneNumber,reporter:reporter, page: pageIndex, limit: pageSize, sort: sortField, order: sortOrder === 1 ? 'asc' : 'desc'};  

        }

        if(role.includes("STAFF")){
            this.setState({isAdmin:false});
            this.setState({isDistrictManager: false});
            this.setState({institutionId: +this.props.instituteId});
            jsonParams = {institutionId:this.props.instituteId,districtId: this.state.districtId,roadId:roadId, zoneId: zoneId, cellId: cellId, crisesCategory: crisesCategory, phoneNumber: phoneNumber,reporter:reporter, page: pageIndex, limit: pageSize, sort: sortField, order: sortOrder === 1 ? 'asc' : 'desc'};  
        }
          
        if (+this.props.match.params.institutionId > 0) {
         return this.managerialService.getCrisesByInstitution(this.props.match.params.institutionId)
         .then(data => {
            this.setState({failed: null});

            data.content.map(d => {                
                if(d.road === null){
                  d.road = {id: 0, name: 'None', details: 'None', roadNumber: 0};                
                }
                return d;
              })
              
            this.setState({loading: false});
            const dataContent = data.content.map(d => ({
                ...d ,crisesTime: this.adalipaUtil.timestampFormat(d.creationDate),institutionName: d.institution.instituteName,zoneName: d.zone.name,cellName: d.cell.name,roadName: d.road.name
            }));
            this.setState({dataTableValue: dataContent});

            
            this.setState({
                pageIndex: data.pageable.pageNumber,
                pageSize: data.pageable.pageSize,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
            const pdfOptions = data.content.map(d => ({
                ...d ,crisesTime: this.adalipaUtil.timestampFormat(d.creationDate),institutionName: d.institution.instituteName,zoneName: d.zone.name,cellName: d.cell.name,roadName: d.road.name
            }));
            this.setState({pdfData: pdfOptions});
            const excelOptions = data.content.map(d => ({
                ...d ,crisesTime: this.adalipaUtil.timestampFormat(d.creationDate),institutionName: d.institution.instituteName,zoneName: d.zone.name,cellName: d.cell.name,roadName: d.road.name
            }));
            this.setState({excelData: excelOptions});
              
         })
         .catch(error => {
            this.setState({failed: true});
            this.setState({alert: error.message});
         });

        }else{
            this.setState({loading: true});
            return this.managerialService.getReportedCrises(jsonParams)
            .then(data => {
                this.setState({failed: null});
    
                data.content.map(d => {                
                    if(d.road === null){
                      d.road = {id: 0, name: 'None', details: 'None', roadNumber: 0};                
                    }
                    return d;
                  })
                  
                this.setState({loading: false});
                const dataContent = data.content.map(d => ({
                    ...d ,crisesTime: this.adalipaUtil.timestampFormat(d.creationDate),institutionName: d.institution.instituteName,zoneName: d.zone.name,cellName: d.cell.name,roadName: d.road.name
                }));
                this.setState({dataTableValue: dataContent});
    
                
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    pageSize: data.pageable.pageSize,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
                const pdfOptions = data.content.map(d => ({
                    ...d ,crisesTime: this.adalipaUtil.timestampFormat(d.creationDate),institutionName: d.institution.instituteName,zoneName: d.zone.name,cellName: d.cell.name,roadName: d.road.name
                }));
                this.setState({pdfData: pdfOptions});
                const excelOptions = data.content.map(d => ({
                    ...d ,crisesTime: this.adalipaUtil.timestampFormat(d.creationDate),institutionName: d.institution.instituteName,zoneName: d.zone.name,cellName: d.cell.name,roadName: d.road.name
                }));
                this.setState({excelData: excelOptions});
            })
            .catch(error =>{
              this.setState({failed: true});
              this.setState({alert: error.message});
            });
        }
        
    }

    componentDidMount() {
        this.getDataList();
        // if(!this.state.isAdmin){
        //    this.getCollectionStatus();
        // }
    }
    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            });
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });     
    }

    onDeleteButtonClick(rowData){
        console.log("ID",rowData.id);
        this.managerialService.removeCrises(rowData.id)
        .then(data => {
           this.getDataList();
        })
        .catch(error => {
            // console.log("Message",error.message);
            this.setState({failed: true});
          this.setState({alert: error.message});
        });

    }

    dateTemplate(rowData, column) {
        
            return new Intl.DateTimeFormat('sw', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.parse(rowData['crisesTime']));  
        
    }

    // actionTemplate(rowData, column) {
    //     return <div style={{whiteSpace: "nowrap"}}>
    //         <Button type="button" onClick={() => this.onPrinterClick(rowData)} icon="pi pi-print" className="p-button p-button-sm p-button-info" style={{padding: "0px"}} title="Print"/>
    //     </div>;
    // }

    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
            {/* <Link to={"/wallet/lga/house/holder/details/" + rowData["id"]}><Button type="button" icon="pi pi-eye" className="p-button p-button-sm p-button-info" style={{marginRight: '.2em', fontSize: "10px"}} title={this.props.t("View House Holder Details")}/></Link> */}
            <Link to={"/wallet/lga/crises/details/" + rowData["id"]}><Button type="button" icon="pi pi-eye" className="p-button p-button-sm p-button-success" style={{marginRight: '.2em', fontSize: "10px"}} title={this.props.t("View Month Bills")}/></Link>
            {!this.props.roles.includes("DISTRICT_MANAGER") && <Link to={"/wallet/lga/house/holder/" + rowData["id"]}><Button type="button" icon="pi pi-pencil" className="p-button p-button-sm p-button-warning" style={{marginRight: '.2em', fontSize: "10px"}} title={this.props.t("Edit House Holder")}/></Link>}
            {!this.props.roles.includes("DISTRICT_MANAGER") &&<Button type="button"onClick={() => this.onDeleteButtonClick(rowData)} icon="pi pi-trash" className="p-button p-button-sm p-button-danger" style={{fontSize: "10px"}}/>}
        </div>;
    }

    export() {
        this.dt.exportCSV();
    }
   

    render() { 
        let header = (
            <div style={{textAlign:'left'}}>
                <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
                <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel("Crises Report", this.excelCols, this.state.excelData)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
                <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable("Crises Report", this.exportColumns, this.state.pdfData)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
            </div>
        );
        let reporterNameFilter = <InputText type="text" name="reporterName" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.reporterName} onChange={this.handleInputChange}/>
        let institutionNameFilter = <InputText type="text" name="institutionName" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.institutionName} onChange={this.handleInputChange}/>
        let zoneNameFilter = <InputText type="text" name="zoneName" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.zoneName} onChange={this.handleInputChange}/>
        let cellNameFilter = <InputText type="text" name="cellName" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.cellName} onChange={this.handleInputChange}/>
        let roadNameFilter = <InputText type="text" name="roadName" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.roadName} onChange={this.handleInputChange}/>



        let crisesCategoryFilter = <InputText type="text" name="crisesCategory" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.crisesCategory} onChange={this.handleInputChange}/>
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.phoneNumber} onChange={this.handleInputChange}/>
        //let createdByFilter = <InputText type="text" name="collectedBy" style={{width: '100%'}} placeholder={this.props.t("Contais")} value={this.state.createdBy} onChange={this.handleInputChange}/>

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                   <ResponseAlert failed={this.state.failed} alert={this.state.alert} />
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-8 p-md-8">{!this.props.roles.includes("DISTRICT_MANAGER") ? <h2>{this.props.t('Crises List')} {this.props.t('of')} {this.props.instituteName}</h2> : <h2>{this.props.districtName} {this.props.t('Crises List')} </h2> } </div>
                            <div className="p-col-4 p-md-4">
                            {!this.props.roles.includes("DISTRICT_MANAGER") &&<Link to="/wallet/lga/add/crises" style={{float: "right"}}><Button label={this.props.t("Add Crises")} icon="pi pi-plus"/></Link>}
                                
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})} 
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="crisesTime" body={this.dateTemplate} header={this.props.t("Time")} sortable={true} filter={false}/>
                            <Column field="reporterName" header={this.props.t("Reporter Name")} sortable={true} filter={true} filterElement={reporterNameFilter} filterPlaceholder="Contains"/>
                            <Column field="reporterPhoneNumber" header={this.props.t("Reporter Phone")} sortable={true} filter={true} filterElement={phoneNumberFilter} filterPlaceholder="Contains"/>
                            <Column field="crisesCategory" header={this.props.t("Category")} sortable={true} filter={true} filterElement={crisesCategoryFilter} filterPlaceholder="Contains"/>
                            <Column field="numberOfOccurrence" header={this.props.t("Occurrences")} sortable={false} filter={false} style={{textAlign:'center', fontSize: 'small', width: '7em'}}/>
                            <Column field="institutionName" header={this.props.t("Institution Name")} sortable={true} filter={true} filterElement={institutionNameFilter} filterPlaceholder="Contains"/>
                            <Column field="zoneName" header={this.props.t("Zone Name")} sortable={true} filter={true} filterElement={zoneNameFilter} filterPlaceholder="Contains"/>
                            <Column field="cellName" header={this.props.t("Cell Name")} sortable={true} filter={true} filterElement={cellNameFilter} filterPlaceholder="Contains"/>
                            <Column field="roadName" header={this.props.t("Road Name")} sortable={true} filter={true} filterElement={roadNameFilter} filterPlaceholder="Contains"/>                            
                            {/* <Column field="createdBy" header={this.props.t("Created By")} sortable={true} filter={false} filterElement={createdByFilter} filterPlaceholder="Contains"/> */}
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', fontSize: 'small', width: '6em'}}/>
                            {/* <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', fontSize: 'small', width: "5em"}}/> */}
                        </DataTable>
                        {/* <Paginator currentPageReportTemplate={"Data " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator> */}
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
 
export default withTranslation()(Crises);