import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { HomeService } from '../../service/HomeService';
import { LgaService } from '../../service/LgaService';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import ResponseAlert from '../../Utilities/ResponseAlert';
import { Dropdown } from 'primereact/dropdown';
// import { AutoComplete } from 'primereact/autocomplete';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';

class LgaTenant extends Component {

	constructor(props) {
        super(props);
        this.state = {
            dataJson: {tenant: {id: 0}, road: {id: 0},houseCount: {count: 0},roadName:{name: ''},roadNumber:{rNumber: ''}, roadDetails: {details:''}, cell: {id: 0} ,fullName: '', idType: {id: 0}, idNumber: '', phoneNumber: '', gender: '', maritalStatus: '', houseHolder: {id: 0, name: "", reference: ""}, tenantType: {id: 0}, permanentAddress: '',sendSms: false},
            dataId: 0,
            houseHolders: [],
            tenantTypes: [],
            idTypes: [],
            roads: [],
            genders: [{label: 'Male', value: 'MALE'}, {label: 'Female', value: 'FEMALE'}, {label: 'Others', value: 'OTHER'}],
            maritalStatuses: [{label: 'Single', value: 'SINGLE'}, {label: 'Married', value: 'MARRIED'}, {label: 'Widowed', value: 'WIDOWED'}],
            houseHolderSearch: '',
            displayRoadCreator: false,
            failed: null,
            alert: '',
            loading: false
        };
        
        this.homeService = new HomeService();
        this.lgaService = new LgaService();
        this.getHouseHolders = this.getHouseHolders.bind(this);
        this.toggleHouseHolderOptions = this.toggleHouseHolderOptions.bind(this);
        this.houseHolderItemOptionTemplate = this.houseHolderItemOptionTemplate.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handelEdit = this.handelEdit.bind(this);

        this.handleRoadChange = this.handleRoadChange.bind(this);
        this.handleRoadDetailsChange = this.handleRoadDetailsChange.bind(this);
        this.handleRoadNumberChange = this.handleRoadNumberChange.bind(this);  
        this.handleRoadSubmit = this.handleRoadSubmit.bind(this);
	}

    getHouseHolders(searchParams) {
        this.lgaService.getHouseHolders(searchParams)
        .then(data => {
            const dataContent = data.content.map(d => ({
                ...d,
                name: (d.name + " - " + d.reference)
            }));
            this.setState({houseHolders: dataContent});
        });
    }
	
	componentDidMount() {
        if (this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        if (+this.props.match.params.dataId > 0) {
            this.setState({dataId: +this.props.match.params.dataId});
            this.lgaService.getTenant(+this.props.match.params.dataId)
            .then(data => {
                if(data.idType === null){
                    data.idType = { id: 0, type: 'None', description: 'None' } //{id: 0, name: 'None', details: 'None', roadNumber: 0};   
                             
                }
                if(data.tenantType === null){
                    data.tenantType = { id: 0, type: "None", description: "None" }   
                }
                if(data.state === null){
                    data.state = "0%"
                }
                if(data.houseHolder === null){
                    data.houseHolder = {id: 0, name: "None"}
                }
                if(data.road === null){
                    data.road = {id: 0, name: 'None', details: 'None', roadNumber: 0};  
                }
                
                this.setState({dataJson: {tenant: {id:this.state.dataId},fullName: data.fullName, idType: {id: data.idType.id}, idNumber: data.idNumber, phoneNumber: data.phoneNumber, gender: data.gender, maritalStatus: data.maritalStatus, houseHolder: {id: data.houseHolder.id, name: data.houseHolder.name},road: {id: data.road.id, name: data.road.name, details: data.road.details, roadNumber: data.road.roadNumber}, tenantType: {id: data.tenantType.id}, permanentAddress: data.permanentAddress}});
            });
        } 
        
        else {
            if (+this.props.match.params.houseHolderId > 0) {
                this.lgaService.getHouseHolder(+this.props.match.params.houseHolderId)
                .then(data => {
                    this.setState(prevJson => ({dataJson: {...prevJson.dataJson, houseHolder: {id : data.id, name: (data.name + " - " + data.reference)}}}));
                });
            } else {
                this.setState(prevJson => ({dataJson: {...prevJson.dataJson, houseHolder: {id : +this.props.match.params.houseHolderId}}}));
            }
        }

        let {houseHolderSearch} = this.state;
        const jsonParams = { page: 0, limit: 100, sort: "id", order: 'asc' }
        this.lgaService.getTenantTypes(jsonParams)
        .then(data => {
            this.setState({tenantTypes: data.content});
        });
        this.getHouseHolders({search: houseHolderSearch, ...jsonParams});
        this.homeService.getIdTypes(jsonParams)
        .then(data => {
            this.setState({idTypes: data.content});
            if (data.content.length === 0) {
                this.setState({idTypes: [{id: 1, type: 'NIN'}, {id: 2, type: 'Voter ID'}, {id: 3, type: 'Driving License'}]});
            }
        });

        this.lgaService.getCells(jsonParams)
        .then(data => {
            const dataContent = data.content;
            const options = dataContent.map(d => ({
                "id" : d.id,
                "name" : "Shina namba " + d.cellNumber + " - " + (d.zone.name)
            }))
            this.setState({cells: options});
        });

        this.lgaService.getRoads(jsonParams)
        .then(data => {
            const dataContent = data.content;
            const options = dataContent.map(d =>({
                "id" : d.road.id+"-"+ d.cell.id,
                "name" : d.road.name + " - " + (d.cell.name)
            }))           
            this.setState({roads: options});
            console.log("ROADS", options)
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let {dataJson} = this.state;
        if (prevState.dataJson.houseHolder.name !== dataJson.houseHolder.name) {
            const jsonParams = { search: (dataJson.houseHolder.name === undefined ? "" : dataJson.houseHolder.name), page: 0, limit: 100, sort: "id", order: 'asc' }
            this.getHouseHolders(jsonParams);
        }
    }

    handleRoadChange(event){
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({dataJson: {...prevJson.dataJson, [inputName]: {name: inputValue}}}));      
        
    }

    handleRoadNumberChange(event){        
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({dataJson: {...prevJson.dataJson, [inputName]: {rNumber: inputValue}}})); 
    }

    handleRoadDetailsChange(event){        
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({dataJson: {...prevJson.dataJson, [inputName]: {details: inputValue}}})); 
    }

    handleRoadSubmit = (event) =>{
        event.preventDefault();
        this.setState({loading: true});
        const jsonParams = {...this.state.dataJson};
        this.lgaService.addHouseRoad(jsonParams)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
            this.setState({displayRoadCreator: false});
			this.setState({alert: response.message});
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });

    }


    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({
            dataJson: {
                ...prevJson.dataJson, [inputName] : inputValue
            }
        }));
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        this.lgaService.addTenant(this.state.dataJson)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/lga/tenants/0");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

    handelEdit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        this.lgaService.editTenant(this.state.dataJson)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/lga/tenants/0");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    toggleHouseHolderOptions = (e, name) => {
        e.originalEvent.stopPropagation();
        const jsonParams = { search: (name === undefined ? "" : name), page: 0, limit: 100, sort: "id", order: 'asc' }
        this.getHouseHolders(jsonParams);
    }

    houseHolderItemOptionTemplate = (option, search) => {
        return (
            <div className="country-item">
                <div>{option[[search]]}</div>
            </div>
        );
    }

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/lga/tenant/0",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <ResponseAlert failed={this.state.failed} alert={this.state.alert} />
                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>{this.props.t(this.state.dataId === 0 ? "New Tenant" : "Edit Tenant")}</h2>
                            </div>
                            {this.state.loading && 
                                <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                    <div className="p-col-12" style={{textAlign:'center'}}>
                                        <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                    </div>
                                </div>
                            }
                            {!this.state.loading && 
                            <form onSubmit={this.state.dataId === 0 ? this.handleSubmit : this.handelEdit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Full Name')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="fullName" placeholder={this.props.t("Enter") + " " + this.props.t("Full Name")} value={this.state.dataJson.fullName} onChange={this.handleInputChange}/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('ID Type')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.idTypes} value={this.state.dataJson.idType.id} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, idType: {id: event.value}}}))} optionLabel="type" optionValue="id" filter filterBy="type" placeholder={this.props.t("Choose") + " " + this.props.t("ID Type")} autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('ID Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="idNumber" placeholder={this.props.t("Enter") + " " + this.props.t("ID Number")} value={this.state.dataJson.idNumber} onChange={this.handleInputChange}/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Phone Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="phoneNumber" placeholder={this.props.t("Enter") + " " + this.props.t("Phone Number")} value={this.state.dataJson.phoneNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Gender')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.genders} value={this.state.dataJson.gender} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, gender: event.value}}))} optionLabel="label" optionValue="value" filter filterBy="label" placeholder={this.props.t("Choose") + " " + this.props.t("Gender")} autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Marital Status')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.maritalStatuses} value={this.state.dataJson.maritalStatus} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, maritalStatus: event.value}}))} optionLabel="label" optionValue="value" filter filterBy="label" placeholder={this.props.t("Choose") + " " + this.props.t("Marital Status")} autoWidth={false} />
                                        </div>  
                                    </div>
                                    {/* <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('House Holder')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <AutoComplete id="houseHolderId" name="houseHolderId" value={this.state.dataJson.houseHolder.name || ""} suggestions={this.state.houseHolders} onSelect={e => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, houseHolder: {id: e.value.id, name: e.value.name}}}))} delay={2000} 
                                            completeMethod={(e) => e.query.length > 0 ? this.setState(prevJson => ({dataJson: {...prevJson.dataJson, houseHolder: {name: e.query}}})) : this.setState({houseHolders: this.state.houseHolders})} onClear={(e) => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, houseHolder: {name: e.query}}}))} 
                                            dropdown={true} onDropdownClick={(e) => this.toggleHouseHolderOptions(e, this.state.dataJson.houseHolder.name)} itemTemplate={(data) => this.houseHolderItemOptionTemplate(data, "name")} placeholder={this.props.t("Choose") + " " + this.props.t("House Holder")} 
                                            // onChange={(e) => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, houseHolder: {name: e.value}}}), () => this.toggleHouseHolderOptions(e, e.value))} 
                                            />
                                        </div>
                                    </div> */}
                                     <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Road')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                        <div style={{flexDirection: 'row',display:'flex',justifyContent: 'space-between',width: "324px"}}>  
                                        {/* {console.log("Road Results >>>> ",this.state.dataJson.road)}                                           */}
                                            <Dropdown options={this.state.roads} value={this.state.dataJson.road.id} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, road: {id: event.value}}}))} optionLabel="name" optionValue="id" filter filterBy="name" placeholder={this.props.t("Choose Road")} autoWidth={false} />
                                            {this.state.dataId === 0 && <Button type="button" label={this.props.t("Road")} icon="pi pi-plus" onClick={() => this.setState({displayRoadCreator: true})}/>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Tenant Type')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.tenantTypes} value={this.state.dataJson.tenantType.id} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, tenantType: {id: event.value}}}))} optionLabel="type" optionValue="id" filter filterBy="type" placeholder={this.props.t("Choose") + " " + this.props.t("Tenant Type")} autoWidth={false} />
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-6 p-md-6">
                                            <label htmlFor="input">{this.props.t('Send Sms After Adding')}:</label>
                                        </div>
                                        <div className="p-col-6 p-md-6">
                                            <div className="p-grid">
                                                <div className="p-col-6 p-md-6">
                                                    <RadioButton value={true} inputId="yes" onChange={(e) => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, sendSms: e.value}}))} checked={this.state.dataJson.sendSms}/>
                                                    <label htmlFor="yes" className="p-radiobutton-label">{this.props.t('Yes')}</label>
                                                </div>
                                                <div className="p-col-6 p-md-6">
                                                    <RadioButton value={false} inputId="no" onChange={(e) => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, sendSms: e.value}}))} checked={!this.state.dataJson.sendSms}/>
                                                    <label htmlFor="no" className="p-radiobutton-label">{this.props.t('No')}</label>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={this.props.t(this.state.dataId === 0 ? "Add Tenant" : "Edit Tenant")} icon="pi pi-plus" />
                                       
                                    </div>
                                </div>
                            </form>
                            }
                        </div>
                    </div>
                </div>

                {this.state.dataId === 0 && <Dialog visible={this.state.displayRoadCreator} className="p-col-12 p-md-6 p-lg-6 p-md-offset-1 p-lg-offset-2" modal onHide={() => this.setState({displayRoadCreator: false})} maximized={true}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12 p-text-center">
                        <form onSubmit={this.handleRoadSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">{this.props.t("Cell Name")}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.cells} value={this.state.dataJson.cell.id} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, cell: {id: event.value}}}))} optionLabel="name" optionValue="id" filter filterBy="name" placeholder={this.props.t("Choose Cell")} autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">{this.props.t("Location Name")}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                           <InputText type="text" name="roadName" placeholder={this.props.t("Enter")+" "+this.props.t("Road")} value={this.state.dataJson.roadName.name} onChange={this.handleRoadChange} required/>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('House Count')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="number" name="houseCount" placeholder={this.props.t("Enter") + " " + this.props.t("House Count")} value={this.state.dataJson.houseCount.count} onChange={this.handleHouseCountChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Road Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="roadNumber" placeholder={this.props.t("Enter") + " " + this.props.t("Road Number")} value={this.state.dataJson.roadNumber.rNumber} onChange={this.handleRoadNumberChange} required/>
                                        </div>
                                    </div>
                                    
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Details')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="roadDetails" placeholder={this.props.t("Enter") + " " + this.props.t("Details")} value={this.state.dataJson.roadDetails.details} onChange={this.handleRoadDetailsChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-5 p-md-2">
                                        <Button type="submit" label="Record" icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Dialog>}

            </div>
        );
	}
}
export default withTranslation()(LgaTenant)