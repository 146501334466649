import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { WalletService } from '../../service/WalletService';
import { LgaService } from '../../service/LgaService';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";

class Zones extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            name: '',
            search: '',
            details: '',
            sortOrder: -1,
            sortField: 'id',
            loading: false
        };

        this.walletService = new WalletService();
        this.lgaService = new LgaService();
        this.adalipaUtil = new AdalipaUtil();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.removeData = this.removeData.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.expectedIncomeTemplate = this.expectedIncomeTemplate.bind(this);
        this.collectedIncomeTemplate = this.collectedIncomeTemplate.bind(this);
        this.export = this.export.bind(this);

        this.cols = [
            { field: 'name', header: this.props.t('Zone') },
            { field: 'details', header: this.props.t('Details') },
            { field: 'cells', header: this.props.t('Cells') },
        ];
        this.excelCols = {header: ['name', 'details', 'Cells']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    getDataList = () => {
        this.setState({loading: true});
        return this.lgaService.getZones({name: this.state.name, search: this.state.search, details: this.state.details, page: this.state.pageIndex, limit: this.state.pageSize, sort: this.state.sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc'})
        .then(data => {
            this.setState({loading: false});
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    componentDidMount() {
        this.getDataList();
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            })
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });     
    }

    removeData(dataId) {
        this.lgaService.removeZone(dataId, "DELETE")
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.getDataList();
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    booleanTemplate(rowData, column) {
        return rowData['enabled'] === true ? 'Active' : 'Inactive';
    }

    expectedIncomeTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['expectedIncome']);
    }
    collectedIncomeTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['collectedIncome']);
    }

    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
            <Link to={"/wallet/lga/zone/" + rowData["id"]}><Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.2em'}} title={this.props.t("Edit Zone")}/></Link>
            <Button type="button"onClick={() => this.removeData(rowData["id"])} icon="pi pi-trash" className="p-button-danger"/>
        </div>;
    }

    export() {
        this.dt.exportCSV();
    }

    render() {

        let header = (
        <div style={{textAlign:'left'}}>
            <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
            <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel(this.props.t("Zones"), this.excelCols, this.state.dataTableValue)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
            <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable(this.props.t("List of Zones"), this.exportColumns, this.state.dataTableValue)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
        </div>
        );

        let nameFilter = <InputText type="text" name="name" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.name} onChange={this.handleInputChange}/>
        let detailsFilter = <InputText type="text" name="details" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.details} onChange={this.handleInputChange}/>

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>{this.props.t('List of Zones')}</h2></div>
                            <div className="p-col-8 p-md-8">
                                <Link to="/wallet/lga/zone/0" style={{float: "right"}}><Button label={this.props.t('Add Zone')} icon="pi pi-plus"/></Link>
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="name" header={this.props.t("Zone")} sortable={true} filter={true} filterElement={nameFilter}/>
                            <Column field="details" header={this.props.t("Details")} sortable={true} filter={true} filterElement={detailsFilter}/>
                            <Column field="cells" header={this.props.t("Cells")} sortable={true} filter={false}/>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(Zones)
