import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { LgaService } from '../../service/LgaService';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import ResponseAlert from '../../Utilities/ResponseAlert';
import { Dropdown } from 'primereact/dropdown';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";

class CellLeader extends Component {

	constructor(props) {
        super(props);
        this.state = {
            dataJson: {cell: {id: 0}, name: '', houseNumber: '', phoneNumber: this.props.t('None')},
            dataId: 0,
            cells: [],
            failed: null,
            alert: '',
            loading: false
        };
        this.lgaService = new LgaService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handelEdit = this.handelEdit.bind(this);
	}
	
	componentDidMount() {
        if (this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        if (+this.props.match.params.dataId > 0) {
            this.setState({dataId: +this.props.match.params.dataId});
            this.lgaService.getCellLeader(+this.props.match.params.dataId)
            .then(data => {
                this.setState({dataJson: {id: data.id, cell: {id: data.cell.id}, name: data.name, houseNumber: data.houseNumber, phoneNumber: data.phoneNumber}});
            });
        }
        const jsonParams = { page: 0, limit: 100, sort: "id", order: 'asc' }
        this.lgaService.getCells(jsonParams)
        .then(data => {
            const dataContent = data.content;
            const options = dataContent.map(d => ({
                "id" : d.id,
                "name" : "Shina namba " + d.cellNumber + " - " + (d.zone.name)
            }))
            this.setState({cells: options});
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({
            dataJson: {
                ...prevJson.dataJson, [inputName] : inputValue
            }
        }));        
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        this.lgaService.addCellLeader(this.state.dataJson)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/lga/cell-leaders");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

    handelEdit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        this.lgaService.editCellLeader(this.state.dataJson)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/lga/cell-leaders");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/lga/cell-leader/0",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <ResponseAlert failed={this.state.failed} alert={this.state.alert} />
                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>{this.props.t(this.state.dataId === 0 ? "Cell Leader" : "Edit Cell Leader")}</h2>
                            </div>
                            {this.state.loading && 
                                <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                    <div className="p-col-12" style={{textAlign:'center'}}>
                                        <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                    </div>
                                </div>
                            }
                            {!this.state.loading && 
                            <form onSubmit={this.state.dataId === 0 ? this.handleSubmit : this.handelEdit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Cell')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.cells} value={this.state.dataJson.cell.id} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, cell: {id: event.value}}}))} optionLabel="name" optionValue="id" filter filterBy="name" placeholder={this.props.t("Choose Cell")} autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Full Name')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="name" placeholder={this.props.t("Enter") + " " + this.props.t("Full Name")} value={this.state.dataJson.name} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('House Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="houseNumber" placeholder={this.props.t("Enter") + " " + this.props.t("House Number")} value={this.state.dataJson.houseNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Phone Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="phoneNumber" placeholder={this.props.t("Enter") + " " + this.props.t("Phone Number")} value={this.state.dataJson.phoneNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={this.props.t(this.state.dataId === 0 ? "Add Cell Leader" : "Edit Cell Leader")} icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}
export default withTranslation()(CellLeader)
