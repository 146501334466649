import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { HomeService } from '../../service/HomeService';
import { LgaService } from '../../service/LgaService';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";
import { ProgressBar } from 'primereact/progressbar';

class LgaTenants extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            houseHolders: [],
            tenantTypes: [],
            idTypes: [],
            zones: [],
            cells: [],
            roads: [],
            genders: [{label: 'Male', value: 'MALE'}, {label: 'Female', value: 'FEMALE'}, {label: 'Others', value: 'OTHER'}],
            maritalStatuses: [{label: 'Single', value: 'SINGLE'}, {label: 'Married', value: 'MARRIED'}, {label: 'Widowed', value: 'WIDOWED'}],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            houseHolderId: 0,
            tenantTypeId: 0,
            idTypeId: 0,
            zoneId: 0,
            cellId: 0,
            roadId: 0,
            gender: '',
            maritalStatus: '',
            fullName: '',
            reference: '',
            nin: '',
            state: '',
            idNumber: '',
            phoneNumber: '',
            permanentAddress: '',
            search: '',
            houseHolderSearch: '',
            sortOrder: -1,
            sortField: 'id',
            loading: false
        };

        this.homeService = new HomeService();
        this.lgaService = new LgaService();
        this.adalipaUtil = new AdalipaUtil();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.toggleHouseHolderOptions = this.toggleHouseHolderOptions.bind(this);
        this.houseHolderItemOptionTemplate = this.houseHolderItemOptionTemplate.bind(this);
        this.removeData = this.removeData.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.export = this.export.bind(this);

        this.cols = [
            { field: 'fullName', header: this.props.t('Tenant') },
            { field: 'idNumber', header: this.props.t('ID Number') },
            { field: 'phoneNumber', header: this.props.t('Phone Number') },
            { field: 'houseNumber', header: this.props.t('House Number') },
            { field: 'reference', header: this.props.t('Reference') },
            { field: 'state', header: this.props.t('Malipo') },
            { field: 'gender', header: this.props.t('Gender') },
            { field: 'roadName', header: this.props.t('Road') },
            { field: 'cellName', header: this.props.t('Cell') },
            { field: 'zoneName', header: this.props.t('Zone') },
        ];
        this.excelCols = {header: ['fullName', 'idNumber', 'phoneNumber','houseNumber','reference', 'gender', 'roadName', 'cellName', 'zoneName', 'houseHolderName', 'tenantTypeName', 'permanentAddress']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    getDataList = () => {
        let {houseHolderId, tenantTypeId, idTypeId, gender, maritalStatus, fullName, nin, idNumber, phoneNumber, permanentAddress, search, pageIndex, pageSize, sortField, sortOrder} = this.state;
        this.setState({loading: true});
        return this.lgaService.getTenants({zoneId: this.state.zoneId, cellId: this.state.cellId,houseHolderId: houseHolderId, tenantTypeId: tenantTypeId, idTypeId: idTypeId, gender: gender, maritalStatus: maritalStatus, fullName: fullName, nin: nin, idNumber: idNumber, phoneNumber: phoneNumber, permanentAddress: permanentAddress, search: search, page: pageIndex, limit: pageSize, sort: sortField, order: sortOrder === 1 ? 'asc' : 'desc'})
        .then(data => {
            this.setState({loading: false});

            data.content.map(d => {  
                            
                if(d.idType === null){
                  d.idType = { id: 0, type: 'None', description: 'None' } //{id: 0, name: 'None', details: 'None', roadNumber: 0};   
                         
                }
                if(d.tenantType === null){
                    d.tenantType = { id: 0, type: "None", description: "None" }    
                }
                if(d.road === null){
                    d.road = {id: 0, name: 'None', details: 'None', roadNumber: 0};  
                }
                if(d.cell === null){
                    d.cell = {id: 0, name: 'None', details: 'None', zone:{name:""}};   
                }

                if(d.zone === null){
                    d.zone = {id: 0, name: 'None', details: 'None'};   
                }
                
                if(d.state === null){
                  d.state = "0%"
                }
                return d;
              })           

            const dataContent = data.content.map(d => ({
                ...d,
                // houseHolderName: d.houseHolder.name,
                roadName: d.road.roadNumber +" - "+ d.road.name,
                cellName: d.cell.name + " - " + d.cell.zone.name,
                zoneName: d.zone.name,
                tenantTypeName: d.tenantType.type,
                idTypeName: d.idType.type
            }))
            this.setState({dataTableValue: dataContent});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        }).catch(error => {
            this.setState({loading: false});
        });
    }

    getHouseHolders(searchParams) {
        this.lgaService.getHouseHolders(searchParams)
        .then(data => {
            const dataContent = data.content.map(d => ({
                ...d,
                name: (d.name + " - " + d.reference)
            }));
            this.setState({houseHolders: dataContent});
        });
    }

    getCells = () => {
        const jsonParams = { zoneId: this.state.zoneId, page: 0, limit: 100, sort: "id", order: 'asc' }
        this.lgaService.getCells(jsonParams)
        .then(data => {
            const dataContent = data.content;
            const defaultOptions = [{"id": 0, "name": this.props.t("All Cells")},];
            const options = dataContent.map(d => ({
                "id" : d.id,
                "name" : d.name + " - " + (d.zone.name)
            }));
            this.setState({cells: defaultOptions.concat(options)});
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    getRoads = () =>{
        const jsonParams = {cellId: this.state.cellId, zoneId: this.state.zoneId, page: 0, limit: 100, sort: "id", order: 'asc' }
        this.lgaService.getRoads(jsonParams)
        .then(data => {
            const dataContent = data.content;
            const defaultOptions = [{"id": 0, "name": this.props.t("All Roads")},];
            const options = dataContent.map(d => ({
                "id" : d.id,
                "name" : d.roadNumber + " - " + (d.cell.name)
            }));
            this.setState({roads: defaultOptions.concat(options)});
        }).catch(error => {
            this.setState({failed: true});
			this.setState({alert: error.message});
        })
    }

    componentDidMount() {
        if (+this.props.match.params.dataId > 0) {
            this.lgaService.getHouseHolder(+this.props.match.params.dataId)
            .then(data => {
                this.setState({houseHolderId : data.id, houseHolderSearch: (data.name + " - " + data.reference)}, () => this.getDataList());
            });
        } else {
            this.setState({houseHolderId: +this.props.match.params.dataId});
            this.getDataList();
        }
        let {houseHolderSearch} = this.state;
        const jsonParams = { page: 0, limit: 100, sort: "id", order: 'asc' }
        this.lgaService.getTenantTypes(jsonParams)
        .then(data => {
            this.setState({tenantTypes: data.content});
        });
        this.getHouseHolders({search: houseHolderSearch, ...jsonParams});
        this.homeService.getIdTypes(jsonParams)
        .then(data => {
            this.setState({idTypes: data.content});
            if (data.content.length === 0) {
                this.setState({idTypes: [{id: 1, type: 'NIN'}, {id: 2, type: 'Voter ID'}, {id: 3, type: 'Driving License'}]});
            }
        });

        const zonejsonParams = { page: 0, limit: 100, sort: "id", order: 'asc' }
        this.lgaService.getZones(zonejsonParams)
        .then(data => {
            const dataContent = data.content;
            const defaultOptions = [{"id": 0, "name": this.props.t("All Zones")}];
            const options = dataContent.map(d => ({
                "id" : d.id,
                "name" : d.name+"-"+d.id
            }));
            this.setState({zones: defaultOptions.concat(options)});
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });

        this.getCells();
    }

    componentDidUpdate(prevProps, prevState) {
        let {houseHolderSearch} = this.state;
        if (prevState.houseHolderSearch !== houseHolderSearch) {
            const jsonParams = { search: (houseHolderSearch === undefined ? "" : houseHolderSearch), page: 0, limit: 100, sort: "id", order: 'asc' }
            this.getHouseHolders(jsonParams);
        }
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            })
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });     
    }

    handleDropdownChange(event) {
        const target = event;
        const inputName = target.target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            if(inputName === "zoneId") {
                this.getCells();
                this.getRoads();
                this.setState({cellId: 0,roadId: 0}, () => {this.getDataList();});                
            } else {
                this.getDataList();
            }
        });
    }

    removeData(dataId) {
        this.lgaService.removeTenant(dataId, "DELETE")
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.getDataList();
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    toggleHouseHolderOptions = (e, name) => {
        e.originalEvent.stopPropagation();
        const jsonParams = { search: (name === undefined ? "" : name), page: 0, limit: 100, sort: "id", order: 'asc' }
        this.getHouseHolders(jsonParams);
    }

    houseHolderItemOptionTemplate = (option, search) => {
        return (
            <div className="country-item">
                <div>{option[[search]]}</div>
            </div>
        );
    }

    booleanTemplate(rowData, column) {
        return rowData['enabled'] === true ? 'Active' : 'Inactive';
    }
    barTemplate(rowData, column) {
        return <ProgressBar value={rowData['state'].replace("%","")} showValue={true} unit="%" className="p-bg-success p-ml-0 p-pl-0" />;
    }
    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
            
            <Link to={"/wallet/lga/tenantinfo/details/" + rowData["id"]}><Button type="button" icon="pi pi-eye" className="p-button p-button-sm p-button-info" style={{marginRight: '.2em', fontSize: "10px"}} title={this.props.t("View Tenant Details")}/></Link>
            <Link to={"/wallet/lga/tenants/month/bills/" + rowData["id"]}><Button type="button" icon="pi pi-eye" className="p-button p-button-sm p-button-success" style={{marginRight: '.2em', fontSize: "10px"}} title={this.props.t("View Month Bills")}/></Link>
            <Link to={"/wallet/lga/tenant/" + rowData["id"]+"/0"}><Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.2em', fontSize: "10px"}} title={this.props.t("Edit Tenant")}/></Link>
            <Button type="button"onClick={() => this.removeData(rowData["id"])} icon="pi pi-trash" className="p-button-danger" title={this.props.t("Remove Tenant")} style={{marginRight: '.2em', fontSize: "10px"}}/>
        </div>;
    }
    export() {
        this.dt.exportCSV();
    }

    render() {

        let header = (
        <div style={{textAlign:'left'}}>
            <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
            <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel(this.props.t("Cells"), this.excelCols, this.state.dataTableValue)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
            <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable(this.props.t("List of Tenants"), this.exportColumns, this.state.dataTableValue)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
        </div>
        );

        let fullNameFilter = <InputText type="text" name="fullName" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.fullName} onChange={this.handleInputChange}/>
        // let idNumberFilter = <InputText type="text" name="idNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.idNumber} onChange={this.handleInputChange}/>
        // let idTypeFilter = <Dropdown name="idTypeId" style={{width: '100%'}} placeholder={this.props.t("Choose") + "..."} options={this.state.idTypes} value={this.state.idTypeId} onChange={this.handleDropdownChange} optionLabel="type" optionValue="id" filter filterBy="type" autoWidth={false} />
        let cellFilter = <Dropdown name="cellId" style={{width: '100%'}} placeholder={this.props.t("Choose Cell")} filterPlaceholder={this.props.t("Search") + " " + this.props.t("Cell")} options={this.state.cells} value={this.state.cellId} onChange={this.handleDropdownChange} optionLabel="name" optionValue="id" filter filterBy="name" autoWidth={false} />
        let roadFilter = <Dropdown name="roadId" style={{width: '100%'}} placeholder={this.props.t("Choose Road")} filterPlaceholder={this.props.t("Search") + " " + this.props.t("Road")} options={this.state.roads} value={this.state.roadId} onChange={this.handleDropdownChange} optionLabel="name" optionValue="id" filter filterBy="name" autoWidth={false} />
        let zoneFilter = <Dropdown name="zoneId" style={{width: '100%'}} placeholder={this.props.t("Choose Zone")} filterPlaceholder={this.props.t("Search") + " " + this.props.t("Zone")} options={this.state.zones} value={this.state.zoneId} onChange={this.handleDropdownChange} optionLabel="name" optionValue="id" filter filterBy="name" autoWidth={false} />
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.phoneNumber} onChange={this.handleInputChange}/>
        let genderFilter = <Dropdown name="gender" style={{width: '100%'}} placeholder={this.props.t("Choose") + "..."} options={this.state.genders} value={this.state.gender} onChange={this.handleDropdownChange} optionLabel="label" optionValue="value" filter filterBy="label" autoWidth={false} />
        // let maritalStatusFilter = <Dropdown name="maritalStatus" style={{width: '100%'}} placeholder={this.props.t("Choose") + "..."} options={this.state.maritalStatuses} value={this.state.maritalStatus} onChange={this.handleDropdownChange} optionLabel="label" optionValue="value" filter filterBy="label" autoWidth={false} />
        let referenceFilter = <InputText type="text" name="reference" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.reference} onChange={this.handleInputChange}/>
        let stateFilter = <InputText type="text" name="state" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.state} onChange={this.handleInputChange}/>
        /* let houseHolderFilter = <Dropdown name="houseHolderId" style={{width: '100%'}} placeholder={this.props.t("Choose...")} options={this.state.houseHolders} value={this.state.houseHolderId} onChange={this.handleDropdownChange} optionLabel="name" optionValue="id" filter filterBy="name" autoWidth={false} /> */
        // let houseHolderFilter = <AutoComplete id="houseHolderId" name="houseHolderId" value={this.state.houseHolderSearch || ""} suggestions={this.state.houseHolders} onSelect={e => this.setState({houseHolderId: e.value.id, houseHolderSearch: e.value.name})} delay={2000} 
        // completeMethod={(e) => e.query.length > 0 ? this.setState({houseHolderSearch: e.query}) : this.setState({houseHolders: this.state.houseHolders})} onClear={(e) => this.setState({houseHolderSearch: e.query})} 
        // dropdown={true} onDropdownClick={(e) => this.toggleHouseHolderOptions(e, this.state.houseHolderSearch)} itemTemplate={(data) => this.houseHolderItemOptionTemplate(data, "name")} placeholder={this.props.t("Choose") + " " + this.props.t("House Holder")} />
        // let tenantTypeFilter = <Dropdown name="tenantTypeId" style={{width: '100%'}} placeholder={this.props.t("Choose") + "..."} options={this.state.tenantTypes} value={this.state.tenantTypeId} onChange={this.handleDropdownChange} optionLabel="type" optionValue="id" filter filterBy="type" autoWidth={false} />

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>{this.props.t("List") + " " + this.props.t("of") + " " + this.props.t("Tenants")}</h2></div>
                            <div className="p-col-8 p-md-8">
                                <Link to={"/wallet/lga/tenant/0/" + this.state.houseHolderId} style={{float: "right"}}><Button label={this.props.t("Add Tenant")} icon="pi pi-plus"/></Link>
                                <Link to="/wallet/lga-tenants/batch" style={{float: "right"}}><Button label={this.props.t("Add Tenant Using Excel")} icon="pi pi-plus"/></Link>
                            </div>                          
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})} 
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="fullName" header={this.props.t("Tenant")} sortable={true} filter={true} filterElement={fullNameFilter}/>
                            <Column field="reference" header={this.props.t("Reference")} sortable={true} filter={true} filterElement={referenceFilter}/>
                            <Column field="state" header={this.props.t("Payments")} body={this.barTemplate} sortable={true} sortField='status' filterElement={stateFilter} filter={true} style={{width: "8%", ...tdStyles}}/>
                            {/* <Column field="idNumber" header={this.props.t("ID Number")} sortable={true} filter={true} filterElement={idNumberFilter}/>
                            <Column field="idType.type" header={this.props.t("ID Type")} sortable={true} filter={true} filterElement={idTypeFilter}/> */}
                            <Column field="phoneNumber" header={this.props.t("Phone Number")} sortable={true} filter={true} filterElement={phoneNumberFilter}/>
                            <Column field="gender" header={this.props.t("Gender")} sortable={true} filter={true} filterElement={genderFilter} style={{width: "10%"}}/>
                            {/* <Column field="maritalStatus" header={this.props.t("Marital Status")} sortable={true} filter={true} filterElement={maritalStatusFilter} style={{width: "10%"}}/> */}
                            <Column field="zone.name" header={this.props.t("Zone")} sortable={true} filter={true} sortField='zone' filterElement={zoneFilter} style={{width: "12%"}}/>
                            <Column field="cell.name" header={this.props.t("Cell")} sortable={true} filter={true} sortField='cell' filterElement={cellFilter} style={{width: "12%"}}/>
                            <Column field="road.name" header={this.props.t("Road")} sortable={true} filter={true} sortField='road' filterElement={roadFilter} style={{width: "12%"}}/>
                            {/* <Column field="houseHolder.name" header={this.props.t("House Holder")} sortable={true} filter={true} filterElement={houseHolderFilter}/> */}
                            {/* <Column field="tenantType.type" header={this.props.t("Tenant Type")} sortable={true} filter={true} filterElement={tenantTypeFilter} style={{width: "10%"}}/> */}
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(LgaTenants)

const tdStyles = {overflow: "auto", paddingRight: 5, paddingLeft: 5, textAlign: "center"}

