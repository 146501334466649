import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import { ClientService } from '../../service/ClientService';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { withTranslation } from "react-i18next";
import { RealEstateService } from '../../service/RealEstateService';
import ResponseAlert from '../../Utilities/ResponseAlert';
import { InputTextarea } from 'primereact/inputtextarea';
import {Dialog} from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import BeatLoader from "react-spinners/BeatLoader";

class Tenants extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            fullName: '',
            phoneNumber: '',
            sortOrder: -1,
            sortField: 'id',
            recordId: '',
            message: '',
            sending: "",
            mFailed: "",
            mAlert: "",
            displayTenantSmsModal: false
        };

        this.clientService = new ClientService();
        this.realEstateService = new RealEstateService();
        this.getCustomers = this.getCustomers.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.removeData = this.removeData.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.sendMessageToTenant = this.sendMessageToTenant.bind(this);
        this.submitDirectMessage = this.submitDirectMessage.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.getTenantSmsDetails = this.getTenantSmsDetails.bind(this);
    }

    getCustomers = () => {
        const jsonParams = { fullName: this.state.fullName, phoneNumber: this.state.phoneNumber, page: this.state.pageIndex, limit: this.state.pageSize, sort: this.state.sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc' }
        this.realEstateService.getCustomers(jsonParams)
        .then(data => {
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    componentDidMount() {
        this.getCustomers();
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page}, () => {
            this.getCustomers();
            this.setState({first: event.first, pageSize: event.rows});
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getCustomers();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getCustomers();
        });     
    }

    getTenantSmsDetails = (data) => {
        this.setState({smsType: 'tenants_payment_reminder'});
        this.setState({accountId: data.accountId, recordId:data.id})
        this.setState({displayTenantSmsModal: true});
    }

    submitDirectMessage(event){
        event.preventDefault();
        this.setState({sending: true});
        const smsJson = {
            accountId: this.state.accountId,
            recordId: this.state.recordId,
            message: this.state.message,
            smsType: 'custom_sms'
        }
        this.clientService.sendCustomSms(smsJson).then(response => {
            this.setState({displayTenantSmsModal: false});
            this.setState({sending: false});
			this.setState({mFailed: false});
			this.setState({mAlert: response.message});
        }).catch(error => {
            this.setState({displayTenantSmsModal: false});
            this.setState({sending: false});
			this.setState({mFailed: true});
			this.setState({mAlert: error.message});
        });


    }

    onInputChange = event =>{
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;
        this.setState(
            {[inputName]: inputValue}
            );
    }

    sendMessageToTenant(event){
        event.preventDefault();
        this.setState({sending: true});
        const smsJson = {
            accountId: this.state.accountId,
            recordId: this.state.recordId,
            smsType: this.state.smsType
        }
        this.clientService.sendQuickSms(smsJson)
        .then(response => {
            this.setState({displayTenantSmsModal: false});
            this.setState({sending: false});
			this.setState({mFailed: false});
			this.setState({mAlert: response.message});
        }).catch(error => {
            this.setState({displayTenantSmsModal: false});
            this.setState({sending: false});
			this.setState({mFailed: true});
			this.setState({mAlert: error.message});
        });
        
    }

    removeData(dataId,projectId) {
        this.realEstateService.removeCustomerWithProjectId(dataId,projectId)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.getCustomers();
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    booleanTemplate(rowData, column) {
        return rowData['enabled'] === true ? 'Active' : 'Inactive';
    }

    actionTemplate(rowData, column) {
        return <div>
            <Link to={"/wallet/real/estate/customer/" + rowData["id"]}><Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.2em'}} title="Edit Customer"/></Link>
            <Button type="button"onClick={() => this.removeData(rowData["id"],rowData["project"]["id"])} icon="pi pi-trash" className="p-button-danger" title="Remove Customer"/>
            <Link to="#"><Button type="button" onClick={()=>this.getTenantSmsDetails(rowData)} icon="pi pi-comment" title="Send Tenant Sms" className="p-button p-button-sm p-button" style={{marginRight: '.5em'}}/></Link>
        </div>;
    }

    tenantsSmsDialogFooter = <div className="p-grid">
      <div className="p-col-6" style={{textAlign: "right"}}>
          <Button type="button" label={this.props.t("Cancel")} onClick={() => this.setState({displayTenantSmsModal: false})} icon="pi pi-times-circle" className="p-button-sm" style={{backgroundColor: "#EEEFFF", color: "#3B007B"}} />
      </div>
      <div className="p-col-6" style={{textAlign: "left"}}>
          <Button type="submit" label={this.props.t("Send Sms")} onClick={(e) =>  (this.state.activeIndex === 0 ? this.sendMessageToTenant(e) : this.submitDirectMessage(e))} icon="pi pi-check-circle" className="p-button-sm" />
      </div>
      </div>;

    render() {

        let fullNameFilter = <InputText type="text" name="fullName" style={{width: '100%'}} placeholder="Contains" value={this.state.fullName} onChange={this.handleInputChange}/>
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder="Contains" value={this.state.phoneNumber} onChange={this.handleInputChange}/>
        let plotHouseNumberFilter = <InputText type="text" name="plotHouseNumber" style={{width: '100%'}} placeholder="Contains" value={this.state.plotHouseNumber} onChange={this.handleInputChange}/>

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <ResponseAlert failed={this.state.failed} alert={this.state.alert} />
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>Real Estate Customers</h2></div>
                            <div className="p-col-8 p-md-8">
                                <Link to="/wallet/real/estate/customer/0" style={{float: "right"}}><Button label="Add Customer" icon="pi pi-plus"/></Link>
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" paginator={false} 
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="project.projectName" header="Project" sortable={true} filter={false}/>
                            <Column field="fullName" header="Customer" sortable={true} filter={true} filterElement={fullNameFilter}/>
                            <Column field="phoneNumber" header="Phone Number" sortable={true} filter={true} filterElement={phoneNumberFilter}/>
                            <Column field="plotHouseNumber" header="Property Number" sortable={true} filter={false} filterElement={plotHouseNumberFilter}/>
                            <Column field="cost" header="Cost" sortable={true} filter={false}/>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '10em'}}/>
                        </DataTable>
                        <Paginator currentPageReportTemplate={"Showing " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator>


                        <Dialog header={this.props.t("Send Sms")} visible={this.state.displayTenantSmsModal} className="p-col-6 p-offset-2" modal footer={this.tenantsSmsDialogFooter} onHide={() => this.setState({displayTenantSmsModal: false})}>  
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                                    <TabPanel header={this.props.t("Account Info Sms")} leftIcon="pi pi-user">
                                        <div className="card card-w-title">
                                            <div className="p-col-12 p-md-12">
                                                <p>{this.props.t('In this panel you are able to send automated sms which contains important details of students, these details includes controll number and help contact number')}</p>
                                            </div>
                                        </div>
                                    </TabPanel> 
                                     <TabPanel header={this.props.t("Write Message")} leftIcon="pi pi-users">
                                        <div className="card card-w-title">
                                            <div className="p-col-12 p-md-12">
                                                <p>{this.props.t('In this panel you are able to write and send sms to a parent, here you can write what best suits your motive')}</p>
                                            </div>
                                            <div className="p-col-12 p-md-12" style={{paddingTop: "0px", marginTop: "0px"}}>
                                                <div className="p-col-12 p-md-offset-1 p-md-10" style={{paddingTop: "0px", marginTop: "0px"}}>
                                                    <h4>{this.props.t('Message')}</h4>
                                                    <InputTextarea name="message" value={this.state.message} onChange={this.onInputChange} required rows={3} cols={30} placeholder={this.props.t("Your Message")} autoResize={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>                                
                                </TabView>
                            </div>
                            {this.state.sending && 
                                <div className="p-grid p-fluid p-col-12" style={{textAlign:'center', padding: "2em"}}>
                                    <div className="p-col-12" style={{textAlign:'center'}}>
                                        <BeatLoader color={"#3B007B"} sending={this.state.sending} css={""} size={10} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </Dialog>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(Tenants)
