import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { WalletService } from '../service/WalletService';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import ResponseAlert from '../Utilities/ResponseAlert';

export default class FundsTransfer extends Component {

	constructor(props) {
        super(props);
        this.state = {
            walletName: '',
            collectionId: 0,
            transferAmount: 0,
            alert: '',
            collections: []
        };
        this.walletService = new WalletService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.setState({collectionId: +this.props.match.params.collectionId});
        this.walletService.getCollections("", "", "", 0, 1000, "id", "asc")
        .then(data => {
            this.setState({collections: data.content});
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        const fundsJson = {
            collectionId: this.state.collectionId,
            transferAmount: this.state.transferAmount
        };
        this.walletService.fundsTransfer(fundsJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/funds/transfer/" + this.state.collectionId);
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/funds/transfer/0" + this.state.collectionId,
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <ResponseAlert failed={this.state.failed} alert={this.state.alert} />

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>Transfer Collections Funds to Main Account</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="collectionId">Collection:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown name="collectionId" options={this.state.collections} value={this.state.collectionId || 0} onChange={event => this.setState({collectionId: event.value})} optionLabel="walletName" optionValue="id" autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="transferAmount">Transfer Amount:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="transferAmount" placeholder="eg: 1000" value={this.state.transferAmount} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label="Tansfer" icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}