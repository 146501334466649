import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { WalletService } from '../../../service/WalletService';
import { LgaService } from '../../../service/LgaService';
import { AdalipaUtil } from '../../../Utilities/AdalipaUtil';
import { Dropdown } from 'primereact/dropdown';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";

class LgaGeneralAnalyticalReport extends Component{
    constructor(props){
        super(props);
        this.state = {
            dataTableValue:[],
            institutions: [],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            institutionId: 0,
            totalCollection: 0,
            cellId: 0,
            roadId: 0,           
            search: '',
            sortOrder: -1,
            sortField: 'i.id',
            report_category: 'LGA_GENERAL_ANALYTICS_REPORT',
            pdf_report_name: "lgaGeneralAnalyticsReport.pdf",
            xlsx_report_name: "lgaGeneralAnalyticsReport.xlsx",
            csv_report_name: "lgaGeneralAnalyticsReport.csv",
            loading: false
        }

        this.walletService = new WalletService();
        this.lgaService = new LgaService();
        this.adalipaUtil = new AdalipaUtil();
        this.amountTemplate = this.amountTemplate.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
       
    }

    getTotalCollection(){
        const jsnParams = {institutionId: this.state.institutionId, page: this.state.pageIndex, limit: this.state.pageSize, sort: this.state.sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc'};
        this.lgaService.getTotalSummaryAnalyticsReport(jsnParams)
        .then(data => {
            let sumOfCollections = 0;
            data.content.forEach(element => {
                const elemValue = !element.collectedAmount ? parseFloat(0) : parseFloat(element.collectedAmount);
                sumOfCollections = elemValue + sumOfCollections;
            });  
            this.setState({ totalCollection: sumOfCollections });
        })
        
    }

    getDataList = () => {
        this.setState({loading: true});
        return this.lgaService.getLgaGeneralAnalyticsReport({institutionId: this.state.institutionId, page: this.state.pageIndex, limit: this.state.pageSize, sort: this.state.sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc'})
        .then(data => {
            this.setState({loading: false});
            
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            },() => {
                this.getTotalCollection();
            });
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    componentDidMount() {    
        this.getDataList();    
        const jsonParams = { page: 0, limit: 100, sort: "id", order: 'asc' }
        this.lgaService.getInstitutions(jsonParams)
        .then(data => {
            this.setState({institutions: data.content});
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });        
        
    }   

    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            })
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });     
    }

    handleDropdownChange(event) {
        const target = event;
        const inputName = target.target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {        
            this.getDataList();
        });
    }

    
    amountTemplate(rowData, column) {   
        return this.adalipaUtil.amountFormat(!rowData['collectedAmount']  ? 0 : rowData['collectedAmount']);
    }

    collectionTemplate(collection){
        console.log("Colection",collection);
        return this.adalipaUtil.amountFormat(collection);
    }

    onDataViewClick(rowData, column) {
        this.props.history.push("/wallet/lga/reports/data/" + rowData["institutionId"]);
    }

    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
            <Button type='button' onClick={()=> {this.onDataViewClick(rowData)}} icon='pi pi-eye' className='p-button p-button-sm p-button-info' style={{padding: "0px"}} title={this.props.t("View")}/>
            {/* <Button type="button" onClick={() => this.onPrinterClick(rowData)} icon="pi pi-print" className="p-button p-button-sm p-button-info" style={{padding: "0px"}} title={this.props.t("Print")}/>
            { this.props.institutionType === "Local Government Authority" && 
                <Button type="button" onClick={() => this.onTransferClick(rowData)} icon="pi pi-arrow-circle-right" className="p-button p-button-sm p-button-danger" style={{padding: "0px"}} title={this.props.t("Transfer")}/>
            } */}
        </div>;
    }


    render() {

        let header = (
        <div style={{textAlign:'left'}}>
            <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={() => this.adalipaUtil.exportGeneralAnalyticsReport(this.state.institutionId,this.state.csv_report_name,this.state.report_category,"csv")}></Button>
            <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.exportGeneralAnalyticsReport(this.state.institutionId,this.state.xlsx_report_name,this.state.report_category,"xlsx")} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
            <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.exportGeneralAnalyticsReport(this.state.institutionId,this.state.pdf_report_name,this.state.report_category,"pdf")} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
        </div>
        );

        let institutionFilter = <Dropdown name="institutionId" style={{width: '100%'}} placeholder={this.props.t("Choose Institution")} options={this.state.institutions} value={this.state.institutionId} onChange={this.handleDropdownChange} optionLabel="instituteName" optionValue="id" filter filterBy="instituteName" autoWidth={false} />
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4">{this.props.roles.includes("DISTRICT_MANAGER") ? <h2>{this.props.districtName} {this.props.t(' Analytics Summary')}</h2> : <h2>{this.props.t('Client Analytics Summary')}</h2>}</div>
                            <div className="p-col-8 p-md-8">
                                <h2 style={{float: "right"}}>{this.collectionTemplate(this.state.totalCollection)}</h2>
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})} 
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="institutionName" header={this.props.t("Institution Name")} sortable={true} filter={true} filterElement={institutionFilter}/>
                            <Column field="houseHolderCount" header={this.props.t("Land Loads")} sortable={true} filter={false}/>
                            <Column field="housingTenantsCount" header={this.props.t("Number Of Tenants")} sortable={true} filter={false}/>
                            <Column field="businessTenantsCount" header={this.props.t("Number Of Frames")} sortable={true} filter={false}/>
                            <Column field="collectedAmount" header={this.props.t("Collected Amount")} body={this.amountTemplate} sortable={true} filter={false}/>
                            {/* <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', fontSize: 'small', width: "10em"}}/> */}
                        </DataTable>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

}
export default withTranslation()(LgaGeneralAnalyticalReport);