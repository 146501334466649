import {LGA_ANALYTICS_REPORT, ROAD,ZONE,CRISES, CELL, CELL_LEADER, HOUSE_HOLDER, HOUSE_BILL, MONTH_BILL, LGA_TERNANT, LGA, LGA_EMPLOYEE, LGA_SALARY, ANALYTICS } from '../api';
import { API_BASE_URL } from '../constants';
import { postRequest, postFiles, getRequest,getRequestTwo, queryParams } from './CoreService';

export class ManagerialService  {

    getReportedCrises(jsonParams){
        return getRequest({
            url: CRISES +"/"+ queryParams(jsonParams),
            method: 'GET'
        });
    }

    getCrisesByInstitution(institutionId){
        return getRequest({
            url: CRISES +"/by/institution/"+ institutionId,
            method: 'GET'
        });
    }

    // addCrises(){

    // }
    getAllTenants(){}

    getAllLgaInstitutions(jsonParams){
        return getRequest({
            url: CRISES +"/institutions"+ queryParams(jsonParams),
            method: 'GET'
        });
    }

    addCrisesReport(crisesJson){
        return postRequest({
            url: CRISES + "/",
            method: 'POST',
            body: JSON.stringify(crisesJson)
        });
    }

    getInstiutionalZones(jsonParams){
        return getRequest({
            url: CRISES +"/zones"+ queryParams(jsonParams),
            method: 'GET'
        });
    }

    getZonalCells(jsonParams){
        return getRequest({
            url: CRISES +"/cells"+ queryParams(jsonParams),
            method: 'GET'
        });
    }

    getCellRoads(jsonParams){
        return getRequest({
            url: CRISES +"/roads"+ queryParams(jsonParams),
            method: 'GET'
        });
    }

    getSpecificCrises(crisesId){
        return getRequest({
            url: CRISES +"/"+ crisesId,
            method: 'GET'
        });
    }

    removeCrises(crisesId){
        return getRequest({
            url: CRISES +"/remove/"+ crisesId,
            method: 'DELETE'
        });
    }

    getCrisesSummary(){
        return getRequest({
            url: CRISES +"/summary",
            method: 'GET'
        });
    }

    getInsitutionsCount(){
        return getRequest({
            url: ANALYTICS +"/institutions",
            method: 'GET'
        });
    }

    getCrisesCount(){
        return getRequest({
            url: CRISES +"/count",
            method: 'GET'
        });
    }
    
}
 
