import React,{Component} from 'react';
import ReactToPrint from 'react-to-print';
import {Button} from 'primereact/button';
 
import DataComponent from './DataComponent';

const buttonStyle = {
    print:{
        marginLeft: "0px",
        width: "155px"
    }
}

class PdfComponent extends Component {
    constructor(props){
        super(props);
        this.state = { 
            instName: "",
            phone: "",
            receiptNumber: "",
            receiptDate: "",
            accountNumber: "",
            amount:"",
            managerPhoneNumber: "",
            charges: 0
         } 

        
    }
    componentDidMount(){
        if(this.props.location.receiptProps && this.props.location.receiptProps.instituteName) {
            this.setState({instName: this.props.location.receiptProps.instituteName})           
        }
        if(this.props.location.receiptProps && this.props.location.receiptProps.phoneNumb) {
            this.setState({phone: this.props.location.receiptProps.phoneNumb})
        }
        if(this.props.location.receiptProps && this.props.location.receiptProps.receiptNumber) {
            this.setState({receiptNumber: this.props.location.receiptProps.receiptNumber})
        }
        if(this.props.location.receiptProps && this.props.location.receiptProps.receiptDate) {
            this.setState({receiptDate: this.props.location.receiptProps.receiptDate})
        }
        if(this.props.location.receiptProps && this.props.location.receiptProps.accountNumber) {
            this.setState({accountNumber: this.props.location.receiptProps.accountNumber})
        }

        if(this.props.location.receiptProps && this.props.location.receiptProps.amount) {
            this.setState({amount: this.props.location.receiptProps.amount})
        }        

        if(this.props.location.receiptProps && this.props.location.receiptProps.managerPhoneNumber) {
            this.setState({managerPhoneNumber: this.props.location.receiptProps.managerPhoneNumber})
        }

        if(this.props.location.receiptProps && this.props.location.receiptProps.charges) {
            this.setState({charges: this.props.location.receiptProps.charges})
        }
        // const  instituteProps  = this.props.location.receiptProps;
        //const insituteName = receiptProps.instituteName;
        // this.setState({phone: this.props.location.receiptProps.phoneNumber})
        
       
    }
   
    render() { 
       
        return (
            <div className='print' style={buttonStyle.print}>
                
            <DataComponent ref={(response) => (this.componentRef = response)} mtaa={this.state.instName} phoneNumber={this.state.phone} receiptNumber={this.state.receiptNumber} receiptDate={this.state.receiptDate} accountNumber={this.state.accountNumber} amount={this.state.amount} managerPhoneNumber={this.state.managerPhoneNumber} charges={this.state.charges} />
            <ReactToPrint
              
              content={() => this.componentRef}
              trigger={() => <Button label='PRINT PDF' className="p-button-primary p-button-sm" data-pr-tooltip="PDF"/>}
              
            />
            
          </div>
        );
    }
}
 
export default PdfComponent;