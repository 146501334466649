import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { WalletService } from '../../service/WalletService';
import { Paginator } from 'primereact/paginator';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import { Dropdown } from 'primereact/dropdown';

export class ListCustomers extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue:[],
            businesses: [],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            businessId: 0,
            customerName: '',
            phoneNumber: '',
            paymentId: '',
            feeAmt: 0,
            paidAmt: 0,
            sortOrder: -1,
            sortField: 'id'
        };

        this.walletService = new WalletService();
        this.adalipaUtil = new AdalipaUtil();
        this.getCustomers = this.getCustomers.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onBusinessChange = this.onBusinessChange.bind(this);
        this.removeBuyer = this.removeBuyer.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.feeTemplate = this.feeTemplate.bind(this);
        this.paidAmtTemplate = this.paidAmtTemplate.bind(this);
    }

    getCustomers = () => {
        return this.walletService.getCustomers(this.state.businessId, this.state.customerName, this.state.phoneNumber, this.state.paymentId, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
        .then(data => {
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    componentDidMount() {
        this.getCustomers();
        this.walletService.getBusinesses('', '', 0, 100, 'id', 'asc')
        .then(response => {
            const data = response.content;
            let options = data.map(d => ({
                "value" : d.id,
                "label" : d.business
            }))
            options.push({
                "value" : 0,
                "label" : "All Businesses"
            })
            this.setState({businesses: options});
        });
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page}, () => {
            this.getCustomers()
            .then(() => {
                this.setState({first: event.first, pageSize: event.rows});
            });
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getCustomers();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getCustomers();
        });     
    }

    onBusinessChange(event) {
        this.dt.filter(event.value, 'id', 'equals');
        this.setState({businessId: event.value, }, () => {
            this.getCustomers();
        });
    }

    removeBuyer(memberId) {
        this.walletService.buyerInfo(memberId, "DELETE")
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.getCustomers();
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    booleanTemplate(rowData, column) {
        return rowData['enabled'] === true ? 'Active' : 'Inactive';
    }

    actionTemplate(rowData, column) {
        return <div>
            <Link to={"/wallet/business/edit/customer/" + rowData["id"]}><Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.2em'}} title="Edit Customer"/></Link>
            <Button type="button" onClick={() => this.removeBuyer(rowData["id"])} icon="pi pi-trash" className="p-button-danger" title="Remove Customer"/>
        </div>;
    }

    businessTemplate(rowData, column) {
        return rowData['buyer'] !== null ? (rowData['buyer']?.buyerBusiness?.business || "General Business") : "General Business";
    }
    feeTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['feeAmt']);
    }
    paidAmtTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['paidAmt']);
    }

    render() {

        let customerNameFilter = <InputText type="text" name="customerName" style={{width: '100%'}} placeholder="Contains" value={this.state.customerName} onChange={this.handleInputChange}/>
        let businessFilter = <Dropdown style={{width: '100%'}} placeholder="Choose Business" value={this.state.businessId} options={this.state.businesses} onChange={this.onBusinessChange}/>
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder="Contains" value={this.state.phoneNumber} onChange={this.handleInputChange}/>
        let paymentIdFilter = <InputText type="text" name="paymentId" style={{width: '100%'}} placeholder="Contains" value={this.state.paymentId} onChange={this.handleInputChange}/>

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        { this.state.failed === false &&
                            <div className="p-messages p-component p-messages-success" style={{margin: '0 0 1em 0', display: 'block'}}>
                                    <div className="p-messages-wrapper rounded">
                                        <ul>
                                            <li>
                                                <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                                <span className="p-messages-detail">{this.state.alert}</span>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        }
                        { this.state.failed === true &&
                            <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                                    <div className="p-messages-wrapper rounded">
                                        <ul>
                                            <li>
                                                <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                                <span className="p-messages-detail">{this.state.alert}</span>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        }
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>List of Customers</h2></div>
                            <div className="p-col-8 p-md-8">
                                <Link to="/wallet/business/add/customer" style={{float: "right"}}><Button label="Add Customer" icon="pi pi-plus"/></Link>
                                <Link to="/wallet/business/archived/customers" style={{float: "right"}}><Button label="Archived Customers" icon="pi pi-list"/></Link>
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" paginator={false} 
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="buyer.customerName" header="Customer" sortable={true} filter={true} filterElement={customerNameFilter}/>
                            <Column field="buyer.buyerBusiness" header="Business" body={this.businessTemplate} sortable={true} filter={true} filterElement={businessFilter}/>
                            <Column field="buyer.phoneNumber" header="Phone Number" sortable={true} filter={true} filterElement={phoneNumberFilter}/>
                            <Column field="paymentId" header="Payment Reference" sortable={true} filter={true} filterElement={paymentIdFilter}/>
                            <Column field="feeAmt" header="Fee Amount" body={this.feeTemplate} sortable={true} filter={false}/>
                            <Column field="paidAmt" header="Paid Amount" body={this.paidAmtTemplate} sortable={true} filter={false}/>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        <Paginator currentPageReportTemplate={"Showing " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator>
                    </div>
                </div>
            </div>
        );
    }
}
