import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { RealEstateService } from '../../service/RealEstateService';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns';
import ResponseAlert from '../../Utilities/ResponseAlert';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';

export default class Installment extends Component {

	constructor(props) {
        super(props);
        this.state = {
            dataJson: {customerFee: {id: 0, cost: 0}, startDate: new Date(), installments: 1, daysPerInstallment: 0, initialAmount: 0, amount: 0, interest: 0},
            dataId: 0,
            customerFeeId: 0,
            customerFees: [],
            failed: null,
            alert: ''
        };
        this.realEstateService = new RealEstateService();
        this.adalipaUtil = new AdalipaUtil();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handelEdit = this.handelEdit.bind(this);
        this.customerFeeTemplate = this.customerFeeTemplate.bind(this);
        this.selectedCustomerFeeTemplate = this.selectedCustomerFeeTemplate.bind(this);
	}
	
	componentDidMount() {
        if (this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.setState({customerFeeId: +this.props.match.params.customerFeeId});
        this.setState(prevJson => ({dataJson: {...prevJson.dataJson, customerFee: {id: +this.props.match.params.customerFeeId}}}));
        if (+this.props.match.params.dataId > 0) {
            this.setState({dataId: +this.props.match.params.dataId});
            this.realEstateService.getInstallment(+this.props.match.params.dataId)
            .then(data => {
                this.setState({dataJson: {id: data.id, customerFee: {id: data.customerFee.id}, startDate: new Date(Date.parse(data.startDate)), installments: data.installments, daysPerInstallment: data.daysPerInstallment, amount: data.amount, interest: data.interest, initialAmount: data.initialAmount}});
            });
        }
        this.realEstateService.getInstallment(+this.props.match.params.customerFeeId)
        .then(data => {
            if (data !== null) {
                this.setState({dataId: +this.props.match.params.customerFeeId});
                this.setState({dataJson: {id: data.id, customerFee: {id: data.customerFee.id, cost: data.customerFee.cost}, startDate: new Date(Date.parse(data.startDate)), installments: data.installments, daysPerInstallment: data.daysPerInstallment, amount: data.amount, interest: data.interest, initialAmount: data.initialAmount}});
            } else {
                this.realEstateService.getCustomerFee(+this.props.match.params.customerFeeId)
                .then(data => {
                    this.setState(prevJson => ({dataJson: {...prevJson.dataJson, customerFee: {id: data.id, cost: data.cost}, amount: data.cost}}));
                });
            }
        }).catch(error => {
            this.realEstateService.getCustomerFee(+this.props.match.params.customerFeeId)
            .then(data => {
                this.setState(prevJson => ({dataJson: {...prevJson.dataJson, customerFee: {id: data.id, cost: data.cost}, amount: data.cost}}));
            });
        });
        const jsonParams = { page: 0, limit: 100, sort: "id", order: 'asc' }
        this.realEstateService.getCustomerFees(jsonParams)
        .then(data => {
            const options = data.content.map(d => ({
                label: d.customer.fullName + " - " + d.project.projectName,
                value: d.id
            }))
            this.setState({customerFees: options});
        });
    }

    handleCustomerFeeChange(event) {
        this.realEstateService.getCustomerFee(event.value)
        .then(data => {
            this.setState(prevJson => ({dataJson: {...prevJson.dataJson, customerFee: {id: event.value, cost: data.cost}, amount: data.cost}}));
        });
    }

    handleInputChange(event) {
        const target = event?.target || event;
        const inputName = target.name;        
        const inputValue = target.value;
        if (target.name === "installments" && +target.value > 0) {
            const principalInstallment = (this.state.dataJson.customerFee.cost - this.state.dataJson.initialAmount) / +target.value;
            this.setState(prevJson => ({
                dataJson: {
                    ...prevJson.dataJson, [inputName] : inputValue, amount : (principalInstallment + ((principalInstallment*this.state.dataJson.interest)/100))
                }
            }));
        } else if (target.name === "initialAmount") {
            const initialAmount = +inputValue;
            const principalInstallment = (this.state.dataJson.customerFee.cost - initialAmount) / this.state.dataJson.installments;
            this.setState(prevJson => ({
                dataJson: {
                    ...prevJson.dataJson, [inputName] : inputValue, amount : (principalInstallment + ((principalInstallment*this.state.dataJson.interest)/100))
                }
            }));
        } else if (target.name === "interest" && target.value > 0) {
            const principalInstallment = (this.state.dataJson.customerFee.cost - this.state.dataJson.initialAmount) / this.state.dataJson.installments;
            this.setState(prevJson => ({
                dataJson: {
                    ...prevJson.dataJson, [inputName] : inputValue, amount : (principalInstallment + ((principalInstallment*target.value)/100))
                }
            }));
        } else if ((target.name === "amount" || target.name === "initialAmount") && +inputValue > 0) {
            this.setState(prevJson => ({
                dataJson: {
                    ...prevJson.dataJson, [inputName] : +inputValue
                }
            }));
        } else {
            this.setState(prevJson => ({
                dataJson: {
                    ...prevJson.dataJson, [inputName] : inputValue
                }
            }));
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.realEstateService.addInstallment(this.state.dataJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/real/estate/installments/" + this.state.dataJson.customerFee.id);
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

    handelEdit = (event) => {
        event.preventDefault();
        this.realEstateService.addInstallment(this.state.dataJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    customerFeeTemplate = (option, props) => {
        if (!option.id) {
            return props.placeholder;
        } else {
            return (
                <div className="country-item" key={option.id}>
                    <div>{option.customer.fullName} - {option.project.projectName}</div>
                </div>
            );
        }
    }
    selectedCustomerFeeTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.customer.fullName} - {option.project.projectName}</div>
                </div>
            );
        }
        return (
            <span>{props.placeholder}</span>
        );
    }

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/real/estate/installments/" + this.state.customerFeeId,
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <ResponseAlert failed={this.state.failed} alert={this.state.alert} setFailed={(value) => this.setState({failed: value})} />
                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>{this.state.dataId === 0 ? "New" : "Edit"} Installment</h2>
                            </div>
                            <form onSubmit={this.state.dataId === 0 ? this.handleSubmit : this.handelEdit}>
                                <div className="p-grid">
                                    <div className="p-md-offset-1 p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Fee:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown id="customerFee" options={this.state.customerFees} value={this.state.dataJson.customerFee.id} onChange={event => this.handleCustomerFeeChange} optionLabel="label" optionValue="value" filter filterBy="label" placeholder="Choose customer" autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="initialAmount">Initial Amount (TZS):</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputNumber id="initialAmount" name="initialAmount" value={this.state.dataJson.initialAmount} onChange={(e) => this.handleInputChange(e)} mode="decimal" minFractionDigits={2} required placeholder="Enter initial amount" />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-md-offset-1 p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Installments:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="installments" placeholder="Enter installments" value={this.state.dataJson.installments} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Days Per Installment:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="daysPerInstallment" placeholder="Enter days per installment" value={this.state.dataJson.daysPerInstallment} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-md-offset-1 p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Amount (TZS):</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            {/* <InputText type="text" name="amount" placeholder="Enter amount" value={this.adalipaUtil.toDecimal(this.state.dataJson.amount)} onChange={this.handleInputChange} disabled required/> */}
                                            <InputNumber id="amount" name="amount" value={this.state.dataJson.amount} onChange={(e) => this.handleInputChange(e)} mode="decimal" minFractionDigits={2} disabled required placeholder="Enter amount" />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">interest (%):</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="interest" placeholder="Enter interest" value={this.state.dataJson.interest} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-md-offset-1 p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">Start From:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Calendar name="startDate" placeholder="Choose Date" value={this.state.dataJson.startDate} onChange={(e) => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, startDate: format(e.value, 'yyyy-MM-dd')}}))}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={(this.state.dataId === 0 ? "Add" : "Update") + " Installment"} icon="pi pi-plus" className="p-button p-button-lg p-p-5 p-pl" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}