import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { HomeService } from '../../service/HomeService';
import { LgaService } from '../../service/LgaService';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import { Dropdown } from 'primereact/dropdown';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";

class LgaEmployees extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            idTypes: [],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            idTypeId: 0,
            fullName: '',
            employeeType: '',
            idNumber: '',
            phoneNumber: '',
            gender: '',
            genders: [{label: this.props.t("Male"), value: "MALE"}, {label: this.props.t("Female"), value: "FEMALE"}, {label: this.props.t("Other"), value: "OTHER"}],
            maritalStatus: '',
            maritalStatuses: [{label: this.props.t("Single"), value: "SINGLE"}, {label: this.props.t("Married"), value: "MARRIED"}, {label: this.props.t("Widowed"), value: "WIDOWED"}],
            permanentAddress: '',
            search: '',
            sortOrder: -1,
            sortField: 'id',
            loading: false
        };

        this.homeService = new HomeService();
        this.lgaService = new LgaService();
        this.adalipaUtil = new AdalipaUtil();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.removeData = this.removeData.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.idTypeTemplate = this.idTypeTemplate.bind(this);
        this.salaryTemplate = this.salaryTemplate.bind(this);
        this.export = this.export.bind(this);

        this.cols = [
            { field: 'fullName', header: this.props.t('Full Name') },
            { field: 'employeeType', header: this.props.t('Employee Type') },
            { field: 'idTypeName', header: this.props.t('Id Type') },
            { field: 'idNumber', header: this.props.t('Id Number') },
            { field: 'employeeNumber', header: this.props.t('Employee Number') },
            { field: 'phoneNumber', header: this.props.t('Phone Number') },
            { field: 'salary', header: this.props.t('Salary') },
            { field: 'gender', header: this.props.t('Gender') },
            { field: 'maritalStatus', header: this.props.t('Marital Status') },
        ];
        this.excelCols = {header: ['fullName', 'employeeType', 'idTypeName', 'idNumber', 'employeeNumber', 'phoneNumber', 'salary', 'gender', 'maritalStatus']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    getDataList = () => {
        let {idTypeId, fullName, idNumber, phoneNumber, gender, maritalStatus, permanentAddress, search, pageIndex, pageSize, sortField, sortOrder} = this.state;
        this.setState({loading: true});
        return this.lgaService.getLgaEmployees({idTypeId: idTypeId, fullName: fullName, idNumber: idNumber, phoneNumber: phoneNumber, gender: gender, maritalStatus: maritalStatus, permanentAddress: permanentAddress, search: search, page: pageIndex, limit: pageSize, sort: sortField, order: sortOrder === 1 ? 'asc' : 'desc'})
        .then(data => {
            this.setState({loading: false});
            const dataContent = data.content.map(d => ({
                ...d,
                idTypeName: d.idType.type,
                salary: this.adalipaUtil.amountFormat(d.salary)
            }))
            this.setState({dataTableValue: dataContent});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    componentDidMount() {
        this.getDataList();
        const jsonParams = { page: 0, limit: 100, sort: "id", order: 'asc' }
        this.homeService.getIdTypes(jsonParams)
        .then(data => {
            const dataContent = data.content;
            const options = dataContent.map(d => ({
                "id" : d.id,
                "name" : this.props.t(d.type)
            }))
            this.setState({idTypes: options});
        });
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            })
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });     
    }

    handleDropdownChange(event) {
        const target = event;
        const inputName = target.target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });
    }

    removeData(dataId) {
        this.lgaService.removeLgaEmployee(dataId, "DELETE")
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.getDataList();
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    booleanTemplate(rowData, column) {
        return rowData['enabled'] === true ? 'Active' : 'Inactive';
    }

    idTypeTemplate(rowData, column) {
        return <span>{rowData['idType'].idTypeNumber} - {rowData['idType'].zone.name}</span>;
    }
    salaryTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['salary']);
    }

    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
            <Link to={"/wallet/lga/employee/" + rowData["id"]}><Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.2em'}} title={this.props.t("Edit") + " " + this.props.t("Employee")}/></Link>
            <Button type="button"onClick={() => this.removeData(rowData["id"])} icon="pi pi-trash" className="p-button-danger" title={this.props.t("Remove") + " " + this.props.t("Employee")}/>
        </div>;
    }

    export() {
        this.dt.exportCSV();
    }

    render() {

        let header = (
        <div style={{textAlign:'left'}}>
            <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
            <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel(this.props.t("Employees"), this.excelCols, this.state.dataTableValue)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
            <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable(this.props.t("List of Employees"), this.exportColumns, this.state.dataTableValue)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
        </div>
        );

        let fullNameFilter = <InputText type="text" name="fullName" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.fullName} onChange={this.handleInputChange}/>
        let employeeTypeFilter = <InputText type="text" name="employeeType" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.employeeType} onChange={this.handleInputChange}/>
        let idTypeFilter = <Dropdown name="idTypeId" style={{width: '100%'}} placeholder={this.props.t("Choose") + "..."} options={this.state.idTypes} value={this.state.idTypeId} onChange={this.handleDropdownChange} optionLabel="name" optionValue="id" filter filterBy="name" autoWidth={false} />
        let idNumberFilter = <InputText type="text" name="idNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.idNumber} onChange={this.handleInputChange}/>
        let employeeNumberFilter = <InputText type="text" name="employeeNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.employeeNumber} onChange={this.handleInputChange}/>
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.phoneNumber} onChange={this.handleInputChange}/>
        let genderFilter = <Dropdown name="gender" style={{width: '100%'}} placeholder={this.props.t("Choose") + "..."} options={this.state.genders} value={this.state.gender} onChange={this.handleDropdownChange} optionLabel="label" optionValue="value" filter filterBy="label" autoWidth={false} />
        let maritalStatusFilter = <Dropdown name="maritalStatus" style={{width: '100%'}} placeholder={this.props.t("Choose") + "..."} options={this.state.maritalStatuses} value={this.state.maritalStatus} onChange={this.handleDropdownChange} optionLabel="label" optionValue="value" filter filterBy="label" autoWidth={false} />

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>{this.props.t('List of Employees')}</h2></div>
                            <div className="p-col-8 p-md-8">
                                <Link to="/wallet/lga/employee/0" style={{float: "right"}}><Button label={this.props.t("Add Employee")} icon="pi pi-plus"/></Link>
                                <Link to="/wallet/lga/salaries" style={{float: "right"}}><Button label={this.props.t("Salaries")} icon="pi pi-list"/></Link>
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})} 
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="fullName" header={this.props.t("Full Name")} sortable={true} filter={true} filterElement={fullNameFilter}/>
                            <Column field="employeeType" header={this.props.t("Employee Type")} sortable={true} filter={true} filterElement={employeeTypeFilter}/>
                            <Column field="idType.type" header={this.props.t("Id Type")} sortable={true} filter={true} filterElement={idTypeFilter}/>
                            <Column field="idNumber" header={this.props.t("Id Number")} sortable={true} filter={true} filterElement={idNumberFilter}/>
                            <Column field="employeeNumber" header={this.props.t("Employee Number")} sortable={true} filter={true} filterElement={employeeNumberFilter}/>
                            <Column field="phoneNumber" header={this.props.t("Phone Number")} sortable={true} filter={true} filterElement={phoneNumberFilter}/>
                            <Column field="salary" header={this.props.t("Salary")} sortable={true} filter={false} style={{textAlign: "right"}}/>
                            <Column field="gender" header={this.props.t("Gender")} sortable={true} filter={true} filterElement={genderFilter}/>
                            <Column field="maritalStatus" header={this.props.t("Marital Status")} sortable={true} filter={true} filterElement={maritalStatusFilter}/>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(LgaEmployees)