import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { LgaService } from '../../service/LgaService';
import { HomeService } from '../../service/HomeService';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import ResponseAlert from '../../Utilities/ResponseAlert';
import { Dropdown } from 'primereact/dropdown';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";

class LgaEmployee extends Component {

	constructor(props) {
        super(props);
        this.state = {
            dataJson: {fullName: '', phoneNumber: this.props.t('None'), idType: {id: 0}, idNumber: this.props.t('None'), salary: 0, employeeNumber: this.props.t('None'), employeeType: this.props.t('Guard'), gender: '', maritalStatus: '', permanentAddress: this.props.t('None')},
            dataId: 0,
            idTypes: [],
            genders: [{label: this.props.t("Male"), value: "MALE"}, {label: this.props.t("Female"), value: "FEMALE"}, {label: this.props.t("Other"), value: "OTHER"}],
            maritalStatuses: [{label: this.props.t("Single"), value: "SINGLE"}, {label: this.props.t("Married"), value: "MARRIED"}, {label: this.props.t("Widowed"), value: "WIDOWED"}],
            failed: null,
            alert: '',
            loading: false
        };
        this.lgaService = new LgaService();
        this.homeService = new HomeService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handelEdit = this.handelEdit.bind(this);
	}
	
	componentDidMount() {
        if (this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        if (+this.props.match.params.dataId > 0) {
            this.setState({dataId: +this.props.match.params.dataId});
            this.lgaService.getLgaEmployee(+this.props.match.params.dataId)
            .then(data => {
                this.setState({dataJson: {id: data.id, fullName: data.fullName, phoneNumber: data.phoneNumber, idType: {id: data.idType.id}, idNumber: data.idNumber, salary: data.salary, employeeNumber: data.employeeNumber, gender: data.gender, maritalStatus: data.maritalStatus, permanentAddress: data.permanentAddress}});
            });
        }
        const jsonParams = { page: 0, limit: 100, sort: "id", order: 'asc' }
        this.homeService.getIdTypes(jsonParams)
        .then(data => {
            const dataContent = data.content;
            const options = dataContent.map(d => ({
                "id" : d.id,
                "name" : this.props.t(d.type)
            }))
            this.setState({idTypes: options});
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({
            dataJson: {
                ...prevJson.dataJson, [inputName] : inputValue
            }
        }));        
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        this.lgaService.addLgaEmployee(this.state.dataJson)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/lga/employees");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

    handelEdit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        this.lgaService.editLgaEmployee(this.state.dataJson)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/lga/employees");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/lga/employee/0",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <ResponseAlert failed={this.state.failed} alert={this.state.alert} />
                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>{this.props.t(this.state.dataId === 0 ? "Add Employee" : "Edit Employee")}</h2>
                            </div>
                            {this.state.loading && 
                                <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                    <div className="p-col-12" style={{textAlign:'center'}}>
                                        <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                    </div>
                                </div>
                            }
                            {!this.state.loading && 
                            <form onSubmit={this.state.dataId === 0 ? this.handleSubmit : this.handelEdit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Full Name')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="fullName" placeholder={this.props.t("Enter") + " " + this.props.t("Full Name")} value={this.state.dataJson.fullName} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Employee Type')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="employeeType" placeholder={this.props.t("Enter") + " " + this.props.t("Employee Type")} value={this.state.dataJson.employeeType} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Id Type')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.idTypes} value={this.state.dataJson.idType.id} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, idType: {id: event.value}}}))} optionLabel="name" optionValue="id" filter filterBy="name" placeholder={this.props.t("Choose") + "..."} autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Id Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="idNumber" placeholder={this.props.t("Enter") + " " + this.props.t("Id Number")} value={this.state.dataJson.idNumber} onChange={this.handleInputChange}/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Salary')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputNumber id="salary" name="salary" value={this.state.dataJson.salary} onChange={this.handleInputChange} mode="decimal" minFractionDigits={2} required placeholder={this.props.t("Enter") + " " + this.props.t("Salary")} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Employee Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="employeeNumber" placeholder={this.props.t("Enter") + " " + this.props.t("Employee Number")} value={this.state.dataJson.employeeNumber} onChange={this.handleInputChange}/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Gender')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.genders} value={this.state.dataJson.gender} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, gender: event.value}}))} optionLabel="label" optionValue="value" filter filterBy="label" placeholder={this.props.t("Choose") + "..."} autoWidth={false} required />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Marital Status')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.maritalStatuses} value={this.state.dataJson.maritalStatus} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, maritalStatus: event.value}}))} optionLabel="label" optionValue="value" filter filterBy="label" placeholder={this.props.t("Choose") + "..."} autoWidth={false} required />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Phone Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="phoneNumber" placeholder={this.props.t("Enter") + " " + this.props.t("Phone Number")} value={this.state.dataJson.phoneNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Permanent Address')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="permanentAddress" placeholder={this.props.t("Enter") + " " + this.props.t("Permanent Address")} value={this.state.dataJson.permanentAddress} onChange={this.handleInputChange}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={this.props.t(this.state.dataId === 0 ? "Add Employee" : "Edit Employee")} icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}
export default withTranslation()(LgaEmployee)
