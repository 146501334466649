import { Button } from 'primereact/button';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class AppFooter extends Component {

    render() {
        return  (
            <div className="layout-footer p-text-center">
                <div className="clearfix">
                    <div className="p-grid" style={{marginTop: "1em"}}>
                        <div className="p-offset-1 p-col-5 p-md-offset-2 p-md-4" style={{textAlign: "right"}}>
                            <Link to="/privacy/policy"><Button label="Privacy Policy" className="p-button p-button-sm p-button-block" /></Link>
                        </div>
                        <div className="p-offset-0 p-col-5 p-md-offset-0 p-md-4" style={{textAlign: "left"}}>
                            <Link to="/terms/of/service"><Button label="Terms of Service" className="p-button p-button-sm" /></Link>
                        </div>
                    </div>
                    <span className="p-text-center">All Rights Reserved. © M-Lipa AdaLipa</span>
                    {/* <span className="footer-text-left">M-Lipa AdaLipa</span>
                    <span className="footer-text-right">All Rights Reserved</span> */}
                </div>
            </div>
        )
    }
}