import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { WalletService } from '../../service/WalletService';
import { Paginator } from 'primereact/paginator';
import { TabPanel, TabView } from 'primereact/tabview';
import { withTranslation } from 'react-i18next';
import BeatLoader from "react-spinners/BeatLoader";
import {Dialog} from 'primereact/dialog';
import { ClientService } from '../../service/ClientService';

class MembersWithNoContributions extends Component{
    constructor(props){
        super(props);
        this.state={
            dataTableValue:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            error: '',
            isManager: false,
            groupId: 0,
            memberId: 0,
            recordId: 0,
            accountId: 0,
            memberName: '',
            memberStatus: 'Active',
            phoneNumber: '',
            memberType: '',
            message: '',
            alert: '',
            smsType: '',
            failed: null,
            sortOrder: -1,
            loading: false,
            displayDiactivationAlert: false,
            displayNoContribSmsModal: false,
            alertType: '',
            alertMessage: '',
            sending: false,
            sortField: 'id',
            groups: []
        };

        this.walletService = new WalletService();
        this.clientService = new ClientService();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onGroupChange = this.onGroupChange.bind(this);
        this.removeMember = this.removeMember.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.checkMemberContributions = this.checkMemberContributions.bind(this);
        this.memberSmsDetails = this.memberSmsDetails.bind(this);
        this.sendDirectMessage = this.sendDirectMessage.bind(this);
        this.submitCustomMessage = this.submitCustomMessage.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    getMemberRole = () =>{
        const roles = this.props.roles;
        if(roles.includes("MANAGER")) {
            this.setState({isManager: true})
        }    
        
    }


    componentDidMount(){
        this.setState({loading: !this.state.loading});
        let {groupId,memberName,phoneNumber,memberType,memberStatus,pageIndex,pageSize,sortField,sortOrder} = this.state;       
        this.walletService.getNonContributedMembers({groupId:groupId,memberName:memberName,phoneNumber:phoneNumber,memberType:memberType,memberStatus:memberStatus,pageIndex:pageIndex,pageSize:pageSize,sortField:sortField,sortOrder:this.state.sortOrder === 1? 'asc': 'desc'})
        .then(data => {
            this.setState({loading: false});
            this.setState({dataTableValue: data.content});
            this.getMemberRole();
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
        const jsonParams = {groupStatus:this.state.grouStatus, page: this.state.pageIndex, limit: this.state.pageSize, sort: this.state.sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc'}
        this.walletService.getGroups(jsonParams)
        .then(response => {
            const data = response.content;
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.groupName
            }))
            this.setState({groups: options});
        });

    }

    memberSmsDetails =(memberId)=>{
        this.setState({smsType: 'members_with_no_contribution'});
        this.setState({memberId:memberId})
        this.setState({displayNoContribSmsModal: true});
    }

    submitCustomMessage(event){
        event.preventDefault();
        this.setState({sending: true});
        const smsJson = {
            accountId: this.state.accountId,
            recordId: this.state.memberId,
            message: this.state.message,
            smsType: 'custom_sms'
        }
        this.clientService.sendCustomSms(smsJson).then(response => {
            this.setState({displayNoContribSmsModal: false});
            this.setState({sending: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
        }).catch(error => {
            this.setState({displayNoContribSmsModal: false});
            this.setState({sending: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });


    }

    sendDirectMessage(event){
        event.preventDefault();
        this.setState({sending: true});
        const smsJson = {
            accountId: this.state.accountId,
            recordId: this.state.recordId,
            smsType: this.state.smsType
        }
        this.clientService.sendQuickSms(smsJson)
        .then(response => {
            this.setState({displayNoContribSmsModal: false});
            this.setState({sending: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
        }).catch(error => {
            this.setState({displayNoContribSmsModal: false});
            this.setState({sending: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
        
    }

    onInputChange = event =>{
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;
        this.setState(
            {[inputName]: inputValue}
            );
    }

    onPageChange(event) {
        this.setState({loading: !this.state.loading});
        this.setState({pageIndex: event.page}, () => {
            let {groupId,memberName,phoneNumber,memberType,memberStatus,pageIndex,pageSize,sortField,sortOrder} = this.state;       
            this.walletService.getNonContributedMembers({groupId:groupId,memberName:memberName,phoneNumber:phoneNumber,memberType:memberType,memberStatus:memberStatus,pageIndex:pageIndex,pageSize:pageSize,sortField:sortField,sortOrder:this.state.sortOrder === 1? 'asc': 'desc'})
            /*this.walletService.getMembers(this.state.groupId, this.state.memberName, this.state.phoneNumber, this.state.memberType, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')*/
            .then(data => {
                this.setState({loading: false});;
                this.setState({dataTableValue: data.content});
                this.getMemberRole();
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
                this.setState({first: event.first, pageSize: event.rows});
            });
        });
    }

    onSortChange(event) {
        this.setState({loading: !this.state.loading});
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            let {groupId,memberName,phoneNumber,memberType,memberStatus,pageIndex,pageSize,sortField,sortOrder} = this.state;       
            this.walletService.getNonContributedMembers({groupId:groupId,memberName:memberName,phoneNumber:phoneNumber,memberType:memberType,memberStatus:memberStatus,pageIndex:pageIndex,pageSize:pageSize,sortField:sortField,sortOrder:this.state.sortOrder === 1? 'asc': 'desc'})
            /*this.walletService.getMembers(this.state.groupId, this.state.memberName, this.state.phoneNumber, this.state.memberType, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')*/
            .then(data => {
                this.setState({loading: false});;
                this.setState({dataTableValue: data.content});
                this.getMemberRole();
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });
        });
    }

    handleInputChange(event) {
        this.setState({loading: !this.state.loading});
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            let {groupId,memberName,phoneNumber,memberType,memberStatus,pageIndex,pageSize,sortField,sortOrder} = this.state;       
            this.walletService.getNonContributedMembers({groupId:groupId,memberName:memberName,phoneNumber:phoneNumber,memberType:memberType,memberStatus:memberStatus,pageIndex:pageIndex,pageSize:pageSize,sortField:sortField,sortOrder:this.state.sortOrder === 1? 'asc': 'desc'})
            /*this.walletService.getMembers(this.state.groupId, this.state.memberName, this.state.phoneNumber, this.state.memberType, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')*/
            .then(data => {
                this.setState({loading: false});;
                this.setState({dataTableValue: data.content});
                this.getMemberRole();
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });
        });     
    }

    onGroupChange(event) {
        this.setState({loading: !this.state.loading});
        this.dt.filter(event.value, 'id', 'equals');
        this.setState({
            groupId: event.value
        }, () => {
            let {groupId,memberName,phoneNumber,memberType,memberStatus,pageIndex,pageSize,sortField,sortOrder} = this.state;       
            this.walletService.getNonContributedMembers({groupId:groupId,memberName:memberName,phoneNumber:phoneNumber,memberType:memberType,memberStatus:memberStatus,pageIndex:pageIndex,pageSize:pageSize,sortField:sortField,sortOrder:this.state.sortOrder === 1? 'asc': 'desc'})
            /*this.walletService.getMembers(this.state.groupId, this.state.memberName, this.state.phoneNumber, this.state.memberType, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')*/
            .then(data => {
                this.setState({loading: false});;
                this.setState({dataTableValue: data.content});
                this.getMemberRole();
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });
        });
    }

    removeMember(event) {
        event.preventDefault();
        this.setState({loading: !this.state.loading});
        this.setState({displayDiactivationAlert: false});
        this.setState({sending: true});
        const memberJson = {memberId:this.state.memberId}
        this.walletService.deactivateMember(memberJson,"POST")
        .then(response => {            
            this.setState({alert: response.message});            
            this.setState({failed: false});
            this.setState({sending: false});
            let {groupId,memberName,phoneNumber,memberType,memberStatus,pageIndex,pageSize,sortField,sortOrder} = this.state;       
            this.walletService.getNonContributedMembers({groupId:groupId,memberName:memberName,phoneNumber:phoneNumber,memberType:memberType,memberStatus:memberStatus,pageIndex:pageIndex,pageSize:pageSize,sortField:sortField,sortOrder:this.state.sortOrder === 1? 'asc': 'desc'})
            .then(data => {
                this.setState({loading: false});;
                this.setState({dataTableValue: data.content});
                this.getMemberRole();
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });
        }).catch(error =>{
            this.setState({failed: true});
            this.setState({alert: error.message});
    });
        
       
    }

    checkMemberContributions(memberId){
        this.setState({memberId: memberId})
        
        this.walletService.getMemberContributionHistory({memberId: this.state.memberId})
        .then(data => {
            this.setState({displayDiactivationAlert: true});            
            this.setState({alertMessage: data.message});

            let {groupId,memberName,phoneNumber,memberType,memberStatus,pageIndex,pageSize,sortField,sortOrder} = this.state;       
            this.walletService.getNonContributedMembers({groupId:groupId,memberName:memberName,phoneNumber:phoneNumber,memberType:memberType,memberStatus:memberStatus,pageIndex:pageIndex,pageSize:pageSize,sortField:sortField,sortOrder:this.state.sortOrder === 1? 'asc': 'desc'})
            .then(data => {
                this.setState({dataTableValue: data.content});
                this.getMemberRole();
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });

        }).catch(error => {
            this.setState({failed: true});
			this.setState({alert: error.message});
        });
        
    }


    booleanTemplate(rowData, column) {
        return rowData['enabled'] === true ? 'Active' : 'Inactive';
    }

    actionTemplate(rowData, column) {
        return <div>
            {/* <Link to={"/wallet/edit/member/" + rowData["id"]}><Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.2em'}} title="Edit Member"/></Link> */}
            {this.state.isManager && <Button type="button"onClick={() => this.memberSmsDetails(rowData["id"])} icon="pi pi-comment" className="p-button-warning"/>}
            {this.state.isManager && <Button type="button"onClick={() => this.checkMemberContributions(rowData["id"])} icon="pi pi-trash" className="p-button-danger"/>}            
        </div>;
    }

    disableDialogFooter = <div className="p-grid">
      <div className="p-col-6" style={{textAlign: "right"}}>
         <Button type="button" label={this.props.t("Cancel")} onClick={() => this.setState({displayDiactivationAlert: false})} icon="pi pi-times-circle" className="p-button-sm" style={{backgroundColor: "#EEEFFF", color: "#3B007B"}} />
      </div>
      <div className="p-col-6" style={{textAlign: "left"}}>
        <Button type="submit" label={this.props.t("Remove Member")} onClick={(e) =>  this.removeMember(e)} icon="pi pi-check-circle" className="p-button-sm" />
      </div>
      </div>;

    memberSmsDialogFooter = <div className="p-grid">
      <div className="p-col-6" style={{textAlign: "right"}}>
         <Button type="button" label={this.props.t("Cancel")} onClick={() => this.setState({displayNoContribSmsModal: false})} icon="pi pi-times-circle" className="p-button-sm" style={{backgroundColor: "#EEEFFF", color: "#3B007B"}} />
      </div>
      <div className="p-col-6" style={{textAlign: "left"}}>
        <Button type="submit" label={this.props.t("Send Sms")} onClick={(e) =>  (this.state.activeIndex === 0 ? this.sendDirectMessage(e) : this.submitCustomMessage(e))} icon="pi pi-check-circle" className="p-button-sm" />
      </div>
      </div>;


render() {

    let groupFilter = <Dropdown style={{width:'100%'}} placeholder="Select a Group" value={this.state.groupId} options={this.state.groups} onChange={this.onGroupChange}/>
    let memberNameFilter = <InputText type="text" name="memberName" style={{width: '100%'}} placeholder="Contains" value={this.state.memberName} onChange={this.handleInputChange}/>
    let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder="Contains" value={this.state.phoneNumber} onChange={this.handleInputChange}/>
    let memberTypeFilter = <InputText type="text" name="memberType" style={{width: '100%'}} placeholder="Contains" value={this.state.memberType} onChange={this.handleInputChange}/>

    let actionHeader = <Link to="/wallet/list/members"><Button type="button" icon="pi pi-cog" label={this.props.t("Switch")}/></Link>;

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card card-w-title datatable-demo">
                    <div className="p-grid">
                        <div className="p-col-4 p-md-4"><h2>List of Members With No Contribution</h2></div>
                        <div className="p-col-8 p-md-8">
                            <Link to="/wallet/add/member/batch" style={{float: "right"}}><Button label={this.props.t("Add Members Using Excel")} icon="pi pi-plus"/></Link>
                            <Link to="/wallet/add/member" style={{float: "right"}}><Button label="Add Member" icon="pi pi-plus"/></Link>
                        </div>
                    </div>
                    <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" paginator={false} 
                        sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                        responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                        <Column field="group.groupName" header="Group" sortable={true} filter={true} filterElement={groupFilter}/>
                        <Column field="memberName" header="Member" sortable={true} filter={true} filterElement={memberNameFilter}/>
                        <Column field="phoneNumber" header="Phone Number" sortable={true} filter={true} filterElement={phoneNumberFilter}/>
                        <Column field="memberType" header="Member Type" sortable={true} filter={true} filterElement={memberTypeFilter}/>
                        <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                    </DataTable>
                    <Paginator currentPageReportTemplate={"Showing " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                        first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                    </Paginator>
                    {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }


                <Dialog header={this.props.t("Deactivation Alert")} visible={this.state.displayDiactivationAlert} className="p-col-6 p-offset-2" modal footer={this.disableDialogFooter} onHide={() => this.setState({displayDiactivationAlert: false})}>  
                <div className="p-fluid">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                                <TabPanel header={this.props.t("Pre Deactivation Alert")} leftIcon="pi pi-user">
                                    <div className="card card-w-title">
                                        <div className="p-col-12 p-md-12">
                                            <p>{this.state.alertMessage}</p>
                                        </div>
                                    </div>
                                </TabPanel>                                 
                            </TabView>
                        </div>
                        {this.state.sending && 
                            <div className="p-grid p-fluid p-col-12" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} sending={this.state.sending} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Dialog>

            <Dialog header={this.props.t("Send Sms")} visible={this.state.displayNoContribSmsModal} className="p-col-6 p-offset-2" modal footer={this.memberSmsDialogFooter} onHide={() => this.setState({displayNoContribSmsModal: false})}>  
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                                    <TabPanel header={this.props.t("Account Info Sms")} leftIcon="pi pi-user">
                                        <div className="card card-w-title">
                                            <div className="p-col-12 p-md-12">
                                                <p>{this.props.t('In this panel you are able to send automated sms to members who have never contributed')}</p>
                                            </div>
                                        </div>
                                    </TabPanel> 
                                     <TabPanel header={this.props.t("Write Message")} leftIcon="pi pi-users">
                                        <div className="card card-w-title">
                                            <div className="p-col-12 p-md-12">
                                                <p>{this.props.t('In this panel you are able to write and send sms to a members who have never contributed, here you can write what best suits your motive')}</p>
                                            </div>
                                            <div className="p-col-12 p-md-12" style={{paddingTop: "0px", marginTop: "0px"}}>
                                                <div className="p-col-12 p-md-offset-1 p-md-10" style={{paddingTop: "0px", marginTop: "0px"}}>
                                                    <h4>{this.props.t('Message')}</h4>
                                                    <InputTextarea name="message" value={this.state.message} onChange={this.onInputChange} required rows={3} cols={30} placeholder={this.props.t("Your Message")} autoResize={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>                                
                                </TabView>
                            </div>
                            {this.state.sending && 
                                <div className="p-grid p-fluid p-col-12" style={{textAlign:'center', padding: "2em"}}>
                                    <div className="p-col-12" style={{textAlign:'center'}}>
                                        <BeatLoader color={"#3B007B"} sending={this.state.sending} css={""} size={10} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </Dialog>

                </div>
            </div>
        </div>
    );
}

}
export default withTranslation()(MembersWithNoContributions)