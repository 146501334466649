import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Redirect } from 'react-router-dom';
import { WalletService } from "../../service/WalletService";
import React,{ Component } from "react";
import { withTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";

class CashPaymentReference extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: "",
            amount: 0,
            sendPushUssd: false,
            alert: ''
        };
        this.walletService = new WalletService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        const paymentReferenceJson = {
            phoneNumber: this.state.phoneNumber,
            amount: this.state.amount,
            sendPushUssd:this.state.sendPushUssd
        };
        
            this.walletService.createCashSettlement(paymentReferenceJson)
            .then(response => {
                this.setState({failed: false});
                this.setState({alert: response.message});
                this.props.history.push("/wallet/cashcollections/"+response.reference);
            }).catch(error => {
                this.setState({failed: true});
                this.setState({alert: error.message});
            });
      
	}
    render() { 
        if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/gen/payment/reference",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        { this.state.failed === true &&
                            <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                                    <div className="p-messages-wrapper rounded">
                                        <ul>
                                            <li>
                                                <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                                <span className="p-messages-detail">{this.state.alert}</span>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        }

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF", padding: "1%"}}>
                                <h2 style={{textAlign: "center"}}>{this.props.t('Payment Reference')}</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Phone Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="phoneNumber" placeholder={this.props.t("Enter") + " " + this.props.t("Phone Number")} value={this.state.phoneNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>  
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Cash Settlement Amount')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputNumber type="text" name="amount" placeholder={this.props.t("Enter") + " " + this.props.t("Amount")} value={this.state.amount} mode="decimal" minFractionDigits={2} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>  
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-6 p-md-6">
                                            <label htmlFor="input">{this.props.t('Send Push Ussd Instead')}:</label>
                                        </div>
                                        <div className="p-col-6 p-md-6">
                                            <div className="p-grid">
                                                <div className="p-col-6 p-md-6">
                                                    <RadioButton value={true} inputId="yes" onChange={(e) => this.setState({sendPushUssd: e.value})} checked={this.state.sendPushUssd}/>
                                                    <label htmlFor="yes" className="p-radiobutton-label">{this.props.t('Yes')}</label>
                                                </div>
                                                <div className="p-col-6 p-md-6">
                                                    <RadioButton value={false} inputId="no" onChange={(e) => this.setState({sendPushUssd: e.value})} checked={!this.state.sendPushUssd}/>
                                                    <label htmlFor="no" className="p-radiobutton-label">{this.props.t('No')}</label>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>                               
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={this.props.t("Confirm Generation")} icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default withTranslation()(CashPaymentReference);