import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { WalletService } from '../../service/WalletService';
import { LgaService } from '../../service/LgaService';
import ViewDetails from '../../components/ViewDetails';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';

export default  class LgaTenantInfo extends Component{
    constructor(props) {
        super(props);
        this.state = {
            tenant: []
        };
        this.walletService = new WalletService();
        this.lgaService = new LgaService();
        this.adalipaUtil = new AdalipaUtil();
	}

    componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.lgaService.getLgaTenant(this.props.match.params.lgaTenantId)
        .then(data => {
             if(data.road === null){
                data.road = {id: 0, name: 'None', details: 'None', roadNumber: 0};                
              }
            const tenantInfo = [
                { name: data.zone.name, title: "Zone"},
                { name: data.cell.name, title: "Cell"},
                { name: data.road.name, title: "Road"},
                { name: data.fullName, title: "Name"},
                { name: data.reference, title: "Payment Reference"},
                { name: data.phoneNumber, title: "Phone Number"},
                { name: data.houseNumber, title: "House Number"},
                { name: data.gender, title: "Gender"},
                { name: data.gender, title: "Gender"},
                { name: data.maritalStatus, title: "Marital Status"},
                { name: data.dob, title: "Date Of Birth"}

            ];
            this.setState({tenant: tenantInfo});
        });        
    }

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/lga/tenants/0",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-10 p-md-offset-1">
                        <div className="card card-w-title">
                            <div className="p-card-title p-text-center" style={{backgroundColor: "#3B007B", color: "#FFFFFF", textAlign: "center"}}>
                                <span style={{textAlign: "center", padding: "0.5em", fontSize: "large", fontWeight: "bold"}}>House Holder Details</span>
                            </div>
                            <ViewDetails attributes={this.state.tenant} />
                            {/* <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <small style={{textAlign: "center", padding: "0.5em"}}>&nbsp;</small>
                            </div> */}
                            {/* <div className="p-card-title p-text-center" style={{backgroundColor: "#3B007B", color: "#FFFFFF", textAlign: "center", marginTop: "2em"}}>
                                <span style={{textAlign: "center", padding: "0.5em", fontSize: "large", fontWeight: "bold"}}>Balance Details</span>
                            </div>
                            <ViewDetails attributes={this.state.balance} /> */}
                        </div>
                    </div>
                </div>
            </div>
        );
	}

}