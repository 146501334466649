import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { useHistory } from 'react-router-dom';
import ResponseAlert from '../Utilities/ResponseAlert';
import { passwordResetRequest } from '../service/LoginService';

export const ForgotPassword = () => {

    const [email, setEmail] = useState("");
    const [alert, setAlert] = useState("");
    const [failed, setFailed] = useState(null);

	const history = useHistory();

	const goDashboard = () => {
		history.push('/reset/info');
	}

    const handleSubmit = (e) => {
        e.preventDefault();
        const resetJson = {
            email: email
        }
        passwordResetRequest(resetJson)
        .then(response => {
			setFailed(false);
			setAlert(response.message);
            goDashboard();
        }).catch(error => {
            setFailed(true);
			setAlert(error.message);
        });
	}

    const goToLogin = () => {
        history.push("/login");
    }

	return (
        <div className="p-grid" style={{height: "48em", overflow: "hidden"}}>
            <div className="login-wrapper" style={{overflow: "hidden"}}>
                <div className="login-container" style={{border: "solid #3B007B", marginTop: "10em", paddingTop: "2em", paddingBottom: "2em", backgroundColor: "#ffffff"}}>
                    <img src="assets/layout/images/adalipa.png" alt="AdaLipa" className="logo" style={{padding: "0px", width: "8em"}} />
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-text-center" style={{textAlign: "center"}}>
                            <span className="title p-text-center" style={{color: "#00a14b", textAlign: "center"}}>Enter email to request password reset</span>
                        </div>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-10 p-offset-1 p-text-center" style={{textAlign: "center"}}>
                            <ResponseAlert failed={failed} alert={alert} setFailed={setFailed} />
                        </div>
                    </div>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-8 p-offset-2">
                                <InputText type="text" name="email" value={email} onChange={e => setEmail(e.target.value)} required placeholder="Email" />
                            </div>
                            <div className="p-col-8 p-offset-2">
                                <Button type="submit" label="Send" icon="pi pi-check" autoFocus={true}/>
                            </div>
                            <div className="p-col-8 p-offset-2">
                                <Button type="button" label="Back to Login" onClick={()=>goToLogin()} icon="pi pi-fw pi-arrow-circle-left"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
	)
}
