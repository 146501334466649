/* eslint-disable react/style-prop-object */
import { Button } from 'primereact/button';
import React, {Component} from 'react';
import { withTranslation } from "react-i18next";

class TermsOfService extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            message: '',
            failed: null,
            alert: "",
        };
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="p-grid" style={{paddingLeft: "1em"}}>
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid" style={{textAlign: "center"}}>
                            <div className="p-col-12 p-md-12"><h2><span style={{textAlign: "center"}}>AdaLipa Terms of Service</span></h2></div>
                        </div>
                    </div>
                </div>
                <div className="p-offset-1 p-col-10 p-grid">
                    <div class="p-col-12">
                        <span>AdaLipa is an electronic fee payment solution which enables parents/students/guardians and customers to pay
                        academic institutions as well as other businesses in Tanzania via cashless channels namely: TigoPesa, Vodacom M-Pesa, Airtel
                        Money and Halopesa. </span>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span><strong>1.0</strong></span></div>
                        <div class="p-col-11">
                            <span><strong> OBJECTIVES:</strong></span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>1.1</span></div>
                        <div class="p-col-11"><span>To enable the CLIENT to collect fees in a more efficient and well comprehensive manner
                        via AdaLipa and mobile money platforms;</span></div>
                    </div>
                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>1.2</span></div>
                        <div class="p-col-11"><span>To enable the CLIENT to send customized SMS notifications to the payers and payees at
                        any point in time i.e. Fee reminders</span></div>
                    </div>
                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>1.3</span></div>
                        <div class="p-col-11"><span>To enable the CLIENT to do timely and accurate reconciliation of the payments made in
                        his favor for this particular category. This includes timely identification of payees;</span></div>
                    </div>
                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>1.4</span></div>
                        <div class="p-col-11"><span>To minimize the risk of security infringement in areas where there is a big amount of cash
                        in hand.</span></div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span><strong>2.0</strong></span></div>
                        <div class="p-col-11">
                            <span><strong>CONSIDERATIONS</strong></span>
                            <br />
                            <span>In consideration of their mutual interests, the parties agree as follows:</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span><strong>2.1</strong></span></div>
                        <div class="p-col-11">
                            <span><strong>SERVICE PROVIDER</strong></span>
                            <br />
                            <span>The obligations of the SP shall be as mentioned hereunder:-</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>2.1.1</span></div>
                        <div class="p-col-11"><span>Provision of the AdaLipa web platform to the administrators;</span></div>
                    </div>
                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>2.1.2</span></div>
                        <div class="p-col-11"><span>Conduct System Setup, Hosting;</span></div>
                    </div>
                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>2.1.3</span></div>
                        <div class="p-col-11"><span>Handle the updating and maintenance of the system whenever necessary;</span></div>
                    </div>
                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>2.1.4</span></div>
                        <div class="p-col-11"><span>Ensure availability of the systems at all times;</span></div>
                    </div>
                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>2.1.5</span></div>
                        <div class="p-col-11"><span>Provision of training, from time to time, to the CLIENT on how to use system and its
                        associated capabilities; the training will only be charged if the SP Personnel will be
                        required to travel to the client’s premises.</span></div>
                    </div>
                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>2.1.6</span></div>
                        <div class="p-col-11"><span>Keep the confidentiality of CLIENT by not exposing or passing over the information to any
                        third party;</span></div>
                    </div>
                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>2.1.7</span></div>
                        <div class="p-col-11"><span>Provision of technical support, via email and telephone, to ensure that the CLIENT
                        receives adequate support responses to any questions and problems within twelve (12)
                        working hours</span></div>
                    </div>
                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>2.1.8</span></div>
                        <div class="p-col-11"><span>Assuring deposits are done accordingly within the specified and agreed time; any
                        changes/obstacles should be communicated prior. The settlement can take up to 3
                        working days depending on the third parties (Mobile Network Operators and Banking
                        Institutions)</span></div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span><strong>2.2</strong></span></div>
                        <div class="p-col-11">
                            <span><strong>PRODUCT CLIENT</strong></span>
                            <br />
                            <span>The obligations of the CLIENT shall be as mentioned hereunder:-</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>2.2.1</span></div>
                        <div class="p-col-11"><span>The CLIENT shall facilitate the enabling environment to ensure that the SP delivers the
                        necessary services as per agreement. This includes making available himself/herself for
                        the discussion relating to this agreement;</span></div>
                    </div>
                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>2.2.2</span></div>
                        <div class="p-col-11"><span>The CLIENT should be responsible for appointing personnel (s) who will be allowed to
                        operate the system at any time after provision;</span></div>
                    </div>
                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>2.2.3</span></div>
                        <div class="p-col-11"><span>The CLIENT shall be responsible for assigning credentials to the personnel (s) responsible
                        for the disbursement and account top up;</span></div>
                    </div>
                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>2.2.4</span></div>
                        <div class="p-col-11"><span>The client shall transfer the collected amount on specific schedule to the bank account.</span></div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span><strong>3.0</strong></span></div>
                        <div class="p-col-11">
                            <span><strong>CHARGES AND COLLECTION TARIFF</strong></span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>3.1</span></div>
                        <div class="p-col-11">
                            <span>Below are the charges per the transaction tariff band</span>
                            <table style={{width: "100%", border: "1px solid #000000"}}>
                                <tr style={{border: "1px solid #000000", fontSize: "18px"}}>
                                    <th style={{width: "60%", border: "1px solid #000000", padding: "0.3em"}}><strong>Transaction Type</strong></th>
                                    <th style={{width: "40%", border: "1px solid #000000", padding: "0.3em"}}><strong>Charges</strong></th>
                                </tr>
                                <tr style={{border: "1px solid #000000", fontSize: "16px"}}>
                                    <td style={{width: "60%", border: "1px solid #000000", padding: "0.3em", fontWeight: "normal"}}>Account Creation/ Configuration (Once Off)</td>
                                    <td style={{width: "40%", border: "1px solid #000000", padding: "0.3em", fontWeight: "normal"}}>TZS 250,000</td>
                                </tr>
                                <tr style={{border: "1px solid #000000", fontSize: "16px"}}>
                                    <td style={{width: "60%", border: "1px solid #000000", padding: "0.3em", fontWeight: "normal"}}>Collection TXN (Inbound)</td>
                                    <td style={{width: "40%", border: "1px solid #000000", padding: "0.3em", fontWeight: "normal"}}>3%</td>
                                </tr>
                                <tr style={{border: "1px solid #000000", fontSize: "16px"}}>
                                    <td style={{width: "60%", border: "1px solid #000000", padding: "0.3em", fontWeight: "normal"}}>Withdrawal TXN</td>
                                    <td style={{width: "40%", border: "1px solid #000000", padding: "0.3em", fontWeight: "normal"}}><span>From TZS 5,000 to TZS 199,999 = TZS 1,000</span><br/><span>From TZS 200,000 to TZS 399,999 = TZS 2,000</span><br/><span>From TZS 400,000 to TZS 599,999 = TZS 3,000</span><br/><span>From TZS 600,000 to TZS 799,999 = TZS 4,000</span><br/><span>From TZS 800,000 to TZS 10,000,000 = TZS 5,000</span></td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span><strong>4.0</strong></span></div>
                        <div class="p-col-11">
                            <span><strong>CONFIDENTIALITY AND CONTRACT DURATION</strong></span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>4.1</span></div>
                        <div class="p-col-11">
                            <span>Either party, at any time, shall not make use or communicate any of the business
                            confidential information or any part of the Systems to any unauthorized person or third
                            party personnel.</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span><strong>5.0</strong></span></div>
                        <div class="p-col-11">
                            <span><strong>TAXATION</strong></span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>5.1</span></div>
                        <div class="p-col-11">
                            <span>Each party will be responsible to the appropriate Authorities for the payment of Tax on
                            Income and payment of any duties, fees, levies and other imposition imposed on any
                            amount realised or spent in a course of executing this agreement.</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span><strong>6.0</strong></span></div>
                        <div class="p-col-11">
                            <span><strong>CONFLICT OF INTEREST</strong></span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>6.1</span></div>
                        <div class="p-col-11">
                            <span>Either party shall not accept, on behalf of another party, benefit or any trade commission,
                            discount or similar payment in connection with activities pursuant to this Agreement or
                            the discharge of duties hereunder.</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>6.2</span></div>
                        <div class="p-col-11">
                            <span>Either party shall not engage in any business or professional activities, directly or
                            indirectly, that would lead to conflict of interest with the activities assigned under this
                            Agreement.</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span><strong>7.0</strong></span></div>
                        <div class="p-col-11">
                            <span><strong>TERMINATION/ ACCOUNT DEACTIVATION</strong></span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>7.1</span></div>
                        <div class="p-col-11">
                            <span>In an event of over 90 days inactivity the user account will be deactivated upon prior
                            communication – The user will be prompted to initiate a new registration upon joining
                            AdaLipa again.</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span><strong>8.0</strong></span></div>
                        <div class="p-col-11">
                            <span><strong>FORCE MAJEURE</strong></span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>8.1</span></div>
                        <div class="p-col-11">
                            <span>In case of events that are unpredictable, unforeseeable, irresistible and beyond THE
                            PARTIES control, shall include but not limited to acts of God, acts or regulations of any
                            government or supranational authority, war or national emergency, accident, fire, strikes,
                            lock-outs, industrial disputes or epidemics.</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span><strong>9.0</strong></span></div>
                        <div class="p-col-11">
                            <span><strong>STANDARDS OR CONDUCT</strong></span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>9.1</span></div>
                        <div class="p-col-11">
                            <span>While carrying out the obligations underlined in this agreement and the related ones, the
                            SP shall act at all times in a manner befitting his relationship with the CLIENT and shall not
                            engage in any activity that is incompatible therewith.</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span><strong>10.0</strong></span></div>
                        <div class="p-col-11">
                            <span><strong>TITLE RIGHTS</strong></span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>10.1</span></div>
                        <div class="p-col-11">
                            <span>All rights including title copyrights patent rights and all other rights of whatsoever nature
                            in any material produced under the provisions of this contract shall be vested to the SP.</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span><strong>11.0</strong></span></div>
                        <div class="p-col-11">
                            <span><strong>LANGUAGE AND GOVERNING LAW</strong></span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>11.1</span></div>
                        <div class="p-col-11">
                            <span>This agreement shall be interpreted in English.</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"><span>11.2</span></div>
                        <div class="p-col-11">
                            <span>The construction, validity and performance of this agreement shall be governed and
                            construed under the laws of the United Republic of Tanzania. For all matters arising
                            under, out of or in connection with this Agreement the parties shall submit to the
                            exclusive jurisdiction of the courts of the United Republic of Tanzania.</span>
                        </div>
                    </div>

                    <div class="p-col-12">
                        <p><strong>Last updated:</strong> February 02, 2020</p>
                    </div>
                </div>
                <div className="p-col-12 p-grid">
                    <Button type="button" onClick={() => this.goBack()} label=" Go Back" icon="pi pi-arrow-circle-left" style={buttonStyle.float} />
                </div>
            </div>
        );
    }
}

export default withTranslation()(TermsOfService)

export const buttonStyle = {
    float: {
        position: "fixed",
        bottom: "1em",
        left: "1em"
    }
}