import React, {Component} from 'react';
import {Route, withRouter} from 'react-router-dom';
import App from "./App";
import Login from "./pages/Login";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";
import Galaxy from './pages/Galaxy';
import PrivacyPolicy from './clients/PrivacyPolicy';
import TermsOfService from './clients/TermsOfService';
import { ResetPassword } from './pages/ResetPassword';
import { ResetInfo } from './pages/ResetInfo';
import { ForgotPassword } from './pages/ForgotPassword';

class AppWrapper extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}

	render() {
		if(window.location.href.indexOf("galaxy") > 2) {
			return <Route path="/galaxy/:token" component={Galaxy}/>
		}
		switch(this.props.location.pathname) {
			case "/login":
				return <Route path="/login" component={Login}/>
			case "/error":
				return <Route path="/error" component={Error}/>
			case "/notfound":
				return <Route path="/notfound" component={NotFound}/>
			case "/access":
				return <Route path="/access" component={Access}/>
            case "/forgot/password":
                return <Route path="/forgot/password" exact component={ForgotPassword} />
            case "/reset/password":
                return <Route path="/reset/password" exact component={ResetPassword} />
            case "/reset/info":
                return <Route path="/reset/info" exact component={ResetInfo} />
			case "/privacy/policy":
				return <Route path="/privacy/policy" component={PrivacyPolicy}/>
			case "/terms/of/service":
				return <Route path="/terms/of/service" component={TermsOfService}/>
			default:
				return <App/>;
		}
	}
}

export default withRouter(AppWrapper);