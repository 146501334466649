import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { ClientService } from '../service/ClientService';
import ViewDetails from '../components/ViewDetails';
import { AdalipaUtil } from '../Utilities/AdalipaUtil';

export default class ClientInfo extends Component {

	constructor(props) {
        super(props);
        this.state = {
            client: [],
            balance: []
        };
        this.clientService = new ClientService();
        this.adalipaUtil = new AdalipaUtil();
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.clientService.getClientDetails(this.props.match.params.clientid)
        .then(data => {
            const clientInfo = [
                { name: data.instituteName, title: "Client"},
                { name: data.mlipaAccount, title: "Till Number"},
                { name: data.region.regionName, title: "Region"},
                { name: data.address, title: "Address"},
                { name: data.business.businessType, title: "Category"},
                { name: data.partner.partnerName, title: "Partner"},
                { name: data.regNo, title: "Registration Number"},
            ];
            this.setState({client: clientInfo});
        });
        this.clientService.getClientBalances(this.props.match.params.clientid)
        .then(data => {
            const clientInfo = [
                { name: this.adalipaUtil.amountFormat(data.Fee), title: "Main Balance"},
                { name: this.adalipaUtil.amountFormat(data.OCs), title: "Other Collections"},
                { name: this.adalipaUtil.amountFormat(JSON.parse(data?.Sms)?.funds || 0), title: "Sms Balance"},
                { name: this.adalipaUtil.toDecimal(JSON.parse(data?.Sms)?.sms || 0) + " Messages", title: "Sms Remained"},
                { name: this.adalipaUtil.amountFormat(data.Actual), title: "Collection Track"},
            ];
            this.setState({balance: clientInfo});
        });
    }

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/client/details",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-10 p-md-offset-1">
                        <div className="card card-w-title">
                            <div className="p-card-title p-text-center" style={{backgroundColor: "#3B007B", color: "#FFFFFF", textAlign: "center"}}>
                                <span style={{textAlign: "center", padding: "0.5em", fontSize: "large", fontWeight: "bold"}}>Client Details</span>
                            </div>
                            <ViewDetails attributes={this.state.client} />
                            {/* <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <small style={{textAlign: "center", padding: "0.5em"}}>&nbsp;</small>
                            </div> */}
                            <div className="p-card-title p-text-center" style={{backgroundColor: "#3B007B", color: "#FFFFFF", textAlign: "center", marginTop: "2em"}}>
                                <span style={{textAlign: "center", padding: "0.5em", fontSize: "large", fontWeight: "bold"}}>Balance Details</span>
                            </div>
                            <ViewDetails attributes={this.state.balance} />
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}