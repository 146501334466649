import React, { Component } from 'react';
import { ClientService } from '../service/ClientService';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";

export class FeeTypes extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue:[],
            layout: 'list',
            sortOptions: [
                {label: 'Newest First', value: '!year'},
                {label: 'Oldest First', value: 'year'},
                {label: 'Brand', value: 'brand'}
            ],
            loading: false
        };

        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '768px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '560px',
                numVisible: 1,
                numScroll: 1
            }
        ];

        this.clientService = new ClientService();

        this.onSortChange = this.onSortChange.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
    }

    componentDidMount() {
        this.setState({loading: true});
        this.clientService.getFeeTypes('none', 0, 100, 'id', 'asc')
        .then(data => {
            this.setState({loading: false});
            this.setState({dataTableValue: data.content})
        });
    }

    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0)
            this.setState({sortOrder: -1, sortField:value.substring(1, value.length), sortKey: value});
        else
            this.setState({sortOrder: 1, sortField:value, sortKey: value});
    }

    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-search" className="p-button-success" style={{marginRight: '.5em'}}/>
            <Button type="button" icon="pi pi-pencil" className="p-button-warning"/>
        </div>;
    }

    render() {

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-8 p-md-8"><h1>Fee Types</h1></div>
                            <div className="p-col-4 p-md-4"><Link to="/fee/add-fee-type" style={{float: "right"}}><Button label="Add Fee Type"/></Link></div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header="List of Fee Types" paginator={true} rows={10}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="feeType" header="Fee Type" sortable={true} filter={true} filterPlaceholder="Contains"/>
                            <Column field="description" header="Details" sortable={true} filter={true} filterPlaceholder="Contains"/>
                            <Column field="currency" header="Currency" sortable={true} filter={true} filterPlaceholder="Contains"/>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}