import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { HomeService } from '../../service/HomeService';
import { LgaService } from '../../service/LgaService';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";
import { Dialog } from 'primereact/dialog';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import ResponseAlert from '../../Utilities/ResponseAlert';
import { Link } from 'react-router-dom';

class LgaDisburseSalaries extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            dataTableSelected: [],
            totalSalary: 0,
            first: 0,
            pageIndex: 0,
            pageSize: 10000,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            dataJson: {salaries: [], month: format(new Date(), 'yyyy-MM'), details: this.props.t('Disburse Salaries')},
            idTypeId: 0,
            fullName: '',
            employeeType: '',
            idNumber: '',
            phoneNumber: '',
            gender: '',
            genders: [{label: this.props.t("Male"), value: "MALE"}, {label: this.props.t("Female"), value: "FEMALE"}, {label: this.props.t("Other"), value: "OTHER"}],
            maritalStatus: '',
            maritalStatuses: [{label: this.props.t("Single"), value: "SINGLE"}, {label: this.props.t("Married"), value: "MARRIED"}, {label: this.props.t("Widowed"), value: "WIDOWED"}],
            permanentAddress: '',
            search: '',
            sortOrder: -1,
            sortField: 'id',
            loading: false,
            displayModal: false,
            failed: null,
            alert: ''
        };

        this.homeService = new HomeService();
        this.lgaService = new LgaService();
        this.adalipaUtil = new AdalipaUtil();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDisbursement = this.handleDisbursement.bind(this);
        this.slaryEditor = this.slaryEditor.bind(this);
        this.idTypeTemplate = this.idTypeTemplate.bind(this);
        this.salaryTemplate = this.salaryTemplate.bind(this);
    }

    getDataList = () => {
        let {idTypeId, fullName, employeeType, idNumber, phoneNumber, gender, maritalStatus, permanentAddress, search, pageIndex, pageSize, sortField, sortOrder} = this.state;
        this.setState({loading: true});
        return this.lgaService.getLgaEmployees({idTypeId: idTypeId, fullName: fullName, employeeType: employeeType, idNumber: idNumber, phoneNumber: phoneNumber, gender: gender, maritalStatus: maritalStatus, permanentAddress: permanentAddress, search: search, page: pageIndex, limit: pageSize, sort: sortField, order: sortOrder === 1 ? 'asc' : 'desc'})
        .then(data => {
            this.setState({loading: false});
            const dataContent = data.content.map(d => ({
                ...d,
                idTypeName: d.idType.type
            }))
            this.setState({dataTableValue: dataContent});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    componentDidMount() {
        this.getDataList();
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            })
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });     
    }

    handleDisbursement(event) {
        event.preventDefault();
        this.lgaService.addLgaSalary(this.state.dataJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.setState({displayModal: false, dataTableSelected: []});
            this.getDataList();
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    onOpenModal() {
        const salaries = this.state.dataTableSelected.map(d => ({employee: {id: d.id}, disbursedAmount: d.salary}));
        const totalSalary = salaries.map(({disbursedAmount}) => parseFloat(disbursedAmount)).reduce((totalAmt, disbursedAmount) => totalAmt + disbursedAmount, 0);
        this.setState({totalSalary: totalSalary});
        this.setState(prevJson => ({dataJson: {...prevJson.dataJson, salaries: salaries}}));
        this.setState({displayModal: true});
    }

    /* Cell Editing */
    onEditorValueChange(props, value) {
        let updatedEmployees = [...props.value];
        updatedEmployees[props.rowIndex][props.field] = value;
        this.setState({dataTableValue: updatedEmployees});
    }

    inputNumberEditor(props, field) {
        return <InputNumber value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }

    slaryEditor(props) {
        return this.inputNumberEditor(props, 'salary');
    }

    idTypeTemplate(rowData, column) {
        return <span>{rowData['idType'].idTypeNumber} - {rowData['idType'].zone.name}</span>;
    }
    salaryTemplate(rowData, column) {
        // return this.adalipaUtil.amountFormat(rowData['salary']);
        return <NumberFormat value={rowData['salary']} displayType={'text'} thousandSeparator={true} prefix={''} />;
    }

    dialogFooter = <div className="p-grid">
        <div className="p-col-6" style={{textAlign: "right"}}>
            <Button type="button" label={this.props.t("Cancel")} onClick={() => this.setState({displayModal: false})} icon="pi pi-times-circle" className="p-button-sm" style={{backgroundColor: "#EEEFFF", color: "#3B007B"}} />
        </div>
        <div className="p-col-6" style={{textAlign: "left"}}>
            <Button type="submit" label={this.props.t("Save")} onClick={(e) => this.handleDisbursement(e)} icon="pi pi-check-circle" className="p-button-sm" />
        </div>
    </div>;

    render() {

        let fullNameFilter = <InputText type="text" name="fullName" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.fullName} onChange={this.handleInputChange}/>
        let employeeTypeFilter = <InputText type="text" name="employeeType" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.employeeType} onChange={this.handleInputChange}/>
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.phoneNumber} onChange={this.handleInputChange}/>

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <ResponseAlert failed={this.state.failed} alert={this.state.alert} setFailed={() => this.setState({failed: null})} />
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-6 p-md-6"><h2>{this.props.t('Disburse Salaries')}</h2></div>
                            <div className="p-col-6 p-md-6">
                                <Link to="/wallet/lga/salaries" style={{float: "right"}}><Button label={this.props.t("Salaries")} icon="pi pi-list"/></Link>
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="multiple" header="" paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelected} onSelectionChange={event => this.setState({dataTableSelected: event.value})} 
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10000,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column selectionMode="multiple" style={{width:'3em'}}/>
                            <Column field="fullName" header={this.props.t("Full Name")} sortable={true} filter={true} filterElement={fullNameFilter}/>
                            <Column field="employeeType" header={this.props.t("Employee Type")} sortable={true} filter={true} filterElement={employeeTypeFilter}/>
                            <Column field="phoneNumber" header={this.props.t("Phone Number")} sortable={true} filter={true} filterElement={phoneNumberFilter}/>
                            <Column field="salary" header={this.props.t("Salary") + " (TZS)"} sortable={true} filter={false} editor={this.slaryEditor} body={this.salaryTemplate} style={{textAlign: "right"}}/>
                        </DataTable>
                        <div className="p-grid" style={{paddingTop: "1em"}}>
                            <div className="p-col-12 p-md-12" style={{textAlign: "right"}}>
                                <Button onClick={() => this.onOpenModal()} label={this.props.t("Disburse Salaries")} icon="pi pi-arrow-right"/>
                            </div>
                        </div>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Dialog header={this.props.t("Disburse Salaries") + ", " + this.props.t("Total") + ": " + this.adalipaUtil.amountFormat(this.state.totalSalary)} visible={this.state.displayModal} className="p-col-6" modal footer={this.dialogFooter} onHide={() => this.setState({displayModal: false})}>
                    <div className="p-fluid p-formgrid p-grid p-md-12">
                        <div className="p-field p-col-5 p-md-5">
                            <label htmlFor="month">{this.props.t('Month')}</label>
                        </div>
                        <div className="p-field p-col-5 p-md-5">
                            <label htmlFor="details">{this.props.t('Details')}</label>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid p-md-12">
                        <div className="p-field p-col-5 p-md-5">
                            <Calendar id="month" name="month" value={this.state.dataJson.month} onChange={(e) => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, month: format(e.value, 'yyyy-MM')}}))} view='month' dateFormat='MM, yy' yearNavigator={true} yearRange='2021:2030' placeholder={this.props.t("Choose") + " " + this.props.t("Month")} appendTo={document.body}/>
                        </div>
                        <div className="p-field p-col-5 p-md-5">
                            <InputText type="text" id="details" name="details" value={this.state.dataJson.details} onChange={(e) => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, details: e.value}}))} required placeholder={this.props.t("Enter") + " " + this.props.t("Details")} />
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}
export default withTranslation()(LgaDisburseSalaries)