import { API_BASE_URL } from '../constants';
import { getRequest, queryParams } from './CoreService';

export class HomeService {

    getFeeAnalysis() {
        return getRequest({
            url: API_BASE_URL + "/chart/fee",
            method: 'GET'
        });
    }

    getPaymentsAnalysis(months) {
        return getRequest({
            url: API_BASE_URL + "/chart/payments?months=" + months,
            method: 'GET'
        });
    }

    getLgaBillsAnalysis(months) {
        return getRequest({
            url: API_BASE_URL + "/lga/monthly/chart?months=" + months,
            method: 'GET'
        });
    }

    getRegions(serachKey, page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/home/get/regions?serachKey=" + serachKey + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    getDistricts(serachKey, page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/home/get/districts?serachKey=" + serachKey + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }
    

    getBusinesses(serachKey, page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/home/get/businesses?serachKey=" + serachKey + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    getCategories(serachKey, page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/home/get/categories?serachKey=" + serachKey + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    getIdTypes(jsonParams) {
        return getRequest({
            url: API_BASE_URL + "/home/id/types" + queryParams(jsonParams),
            method: 'GET'
        });
    }
}