import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {InputSwitch} from 'primereact/inputswitch';
import { signout } from './service/LoginService';
import { Link } from 'react-router-dom';
import i18n from "./i18n";
import languages from "./Utilities/languages";
import { get, map } from "lodash"

export class AppTopbar extends Component {

    static defaultProps = {
        onMenuButtonClick: null,
        onTopbarMenuButtonClick: null,
        onLanguageMenuButtonClick: null,
        onTopbarItemClick: null,
        topbarMenuActive: false,
        languageMenuActive: false,
        activeTopbarItem: null,
        darkTheme: null,
        onThemeChange: null
    }

    static propTypes = {
        onMenuButtonClick: PropTypes.func.isRequired,
        onTopbarMenuButtonClick: PropTypes.func.isRequired,
        onLanguageMenuButtonClick: PropTypes.func.isRequired,
        onTopbarItemClick: PropTypes.func.isRequired,
        topbarMenuActive: PropTypes.bool.isRequired,
        languageMenuActive: PropTypes.bool.isRequired,
        activeTopbarItem: PropTypes.string,
        darkTheme: PropTypes.bool,
        onThemeChange: PropTypes.func
    }

    constructor() {
        super();
        this.state = {
            selectedLang: localStorage.getItem("I18N_LANGUAGE")
        };
        this.changeLanguageAction = this.changeLanguageAction.bind(this);
    }

    onTopbarItemClick(event, item) {
        if(this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    changeLanguageAction = lang => {
        i18n.changeLanguage(lang);
        localStorage.setItem("I18N_LANGUAGE", lang);
        this.setState({selectedLang: lang});
        window.location.reload(true);
    }

    handleLogout() {
        signout()
        .then(response => {
            localStorage.clear();
			this.setState({failed: false});
			this.setState({alert: "You're successfully logged out!"});
            this.props.history.push("/login");
        }).catch(error => {
            this.props.history.push("/login");
			this.setState({failed: true});
			this.setState({alert: "Invalid username or password!"});
        });
    }

    render() {
        let topbarItemsClassName = classNames('topbar-menu fadeInDown', {'topbar-menu-visible': this.props.topbarMenuActive});
        let languageItemsClassName = classNames('topbar-menu fadeInDown', {'topbar-menu-visible': this.props.languageMenuActive});

        return <div className="topbar clearfix">

            <button className="p-link" id="menu-button" onClick={this.props.onMenuButtonClick}>
                <i className="pi pi-bars"></i>
            </button>

            <img className="logo hidden-mobile" alt="apollo-layout" src="assets/layout/images/adalipa_logo.png" />

            <span className="hidden-mobile" style={{color: "#FFFFFF", marginLeft: "5%", verticalAlign: "top", fontSize: "large", textTransform: "uppercase"}}>{this.props.instituteName}</span>

            <button className="p-link profile" onClick={this.props.onTopbarMenuButtonClick}>
                <span className="username">{this.props.fullName}</span>
                <img src="assets/layout/images/avatar/user.png" alt="apollo-layout" />
                <i className="pi pi-angle-down"></i>
            </button>

            <span className="topbar-themeswitcher hidden-mobile">
                <InputSwitch checked={this.props.darkTheme} onChange={this.props.onThemeChange}></InputSwitch>
            </span>

            <ul className={topbarItemsClassName}>
                {/* <li role="menuitem">
                    <Link to="/user/profile">
                        <button type="button" className="p-link">
                            <i className="pi pi-fw pi-user-edit"></i>
                            <span>{this.props.lang.t('My Profile')}</span>
                        </button>
                    </Link>
                </li> */}
                <li role="menuitem">
                    <Link to="/user/reseting/password">
                        <button type="button" className="p-link">
                            <i className="pi pi-fw pi-cog"></i>
                            <span>{this.props.lang.t('Change')} {this.props.lang.t('Password')}</span>
                        </button>
                    </Link>
                </li>
                {/* <li role="menuitem">
                    <Link to="/user/reseting/password">
                        <button type="button" className="p-link">
                            <i className="pi pi-fw pi-power-off"></i>
                            <span>Logout</span>
                        </button>
                    </Link>
                </li> */}
            </ul>
            
            <button className="p-link profile" style={{marginRight: "1em", paddingTop: "0.8em"}} onClick={this.props.onLanguageMenuButtonClick}>
                <img src={get(languages, `${this.state.selectedLang}.flag`)} style={{width: "40%"}} alt="Translation" />
                <i className="pi pi-angle-down"></i>
            </button>
            
            <ul className={languageItemsClassName} style={{marginRight: "6%"}}>
                <li role="menuitem">
                    <button type="button" className="p-link" onClick={() => this.changeLanguageAction("sw")}>
                        <img src={get(languages, `sw.flag`)} style={{width: "10%"}} alt="Swahili" />
                        <span>{this.props.lang.t('Swahili')}</span>
                    </button>
                </li>
                <li role="menuitem">
                    <button type="button" className="p-link" onClick={() => this.changeLanguageAction("en")}>
                        <img src={get(languages, `en.flag`)} style={{width: "10%"}} alt="English" />
                        <span>{this.props.lang.t('English')}</span>
                    </button>
                </li>
            </ul>
        </div>;
    }
}
