import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { WalletService } from '../../service/WalletService';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";
import { RadioButton } from 'primereact/radiobutton';

class ContributionMemberBatch extends Component {

	constructor(props) {
        super(props);
        this.state = {
            file: '',
            groupId: 0,
            sendSms: false,
			alert: '',
            failed: null,
            groups: [],
            loading: false
        };
        this.walletService = new WalletService();
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        const jsonParams = { groupStatus: "Active", page: 0, limit: 100, sort: "id", order: 'asc' }
        this.walletService.getGroups(jsonParams)
        .then(data => {
            const dataContent = data.content;
            const options = dataContent.map(d => ({
                "value" : d.id,
                "label" : d.groupName
            }))
            this.setState({groups: [{value: 0, label: "Select Group"}].concat(options)});
        }).catch(error => {
            this.setState({groups: [{value: 0, label: "Select Group"}]});
        });
    }

    handleGroupChange = event => { 
        this.setState({groupId: event.value});
    }
    handleFileChange = event => {
        this.setState({ file: event.target.files[0] });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});
        this.walletService.addMemberBatch(this.state.file, this.state.groupId, this.state.sendSms)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/list/members");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/list/members",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                            { this.state.failed === true &&
                                <div className="p-messages-wrapper rounded">
                                    <ul>
                                        <li>
                                            <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                            <span className="p-messages-detail">{this.state.alert}</span>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>

                        <div className="card card-w-title">
                            <div className="p-grid">
                                <div className="p-col-4 p-md-4"><h1>{this.props.t('New Members')}</h1></div>
                                <div className="p-col-8 p-md-8">
                                    <Link to="/wallet/add/member" style={{float: "right"}}><Button label={this.props.t("Add Member")} icon="pi pi-plus"/></Link>
                                </div>
                            </div>
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>{this.props.t('Add Members Using Excel')}</h2>
                            </div>
                            {this.state.loading && 
                                <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                    <div className="p-col-12" style={{textAlign:'center'}}>
                                        <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                    </div>
                                </div>
                            }
                            {!this.state.loading && 
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-12" style={{textAlign: 'center'}}><span>{this.props.t('Excel Arrangements')}</span></div>
                                    <div className="p-col-12 p-md-12" style={{textAlign: 'center'}}>
                                        <img src="assets/layout/images/ContributionExcelFormat.png" alt="M-Lipa" className="logo"/>
                                    </div>
                                </div>
                                <div className="p-grid" style={{padding: '3%'}}>
                                    <div className="p-col-12 p-md-3 p-formgrid p-grid">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="dropdown">{this.props.t('Group')}: <span style={{color: '#E00000'}}>*</span></label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.groups} value={this.state.groupId} onChange={this.handleGroupChange} optionLabel="label" optionValue="value" filter filterBy="label" placeholder={this.props.t("Choose Group")} autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6 p-formgrid p-grid">
                                        <div className="p-offset-1 p-col-11 p-md-offset-1 p-md-11 p-mb-4 p-mb-md-4">
                                            <label htmlFor="file">{this.props.t('Excel File')}: <span style={{color: '#E00000'}}>*</span></label>
                                        </div>
                                        <div className="p-offset-1 p-col-11 p-md-offset-1 p-md-11 lipa-file-upload">
                                            <input type="file" name="file" onChange={this.handleFileChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-3 p-formgrid p-grid">
                                        <div className="p-offset-2 p-col-10 p-md-offset-2 p-md-10 p-mb-4 p-mb-md-4">
                                            <label htmlFor="input">{this.props.t('Send Sms After Adding')}:</label>
                                        </div>
                                        <div className="p-offset-2 p-col-10 p-md-offset-2 p-md-10">
                                            <div className="p-grid">
                                                <div className="p-col-6 p-md-6">
                                                    <RadioButton value={true} inputId="yes"  onChange={(e) => this.setState({sendSms: e.value})} checked={this.state.sendSms}/>
                                                    <label htmlFor="yes" className="p-radiobutton-label">{this.props.t('Yes')}</label>
                                                </div>
                                                <div className="p-col-6 p-md-6">
                                                    <RadioButton value={false} inputId="no" onChange={(e) => this.setState({sendSms: e.value})} checked={!this.state.sendSms}/>
                                                    <label htmlFor="no" className="p-radiobutton-label">{this.props.t('No')}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={this.props.t("Add Members")} icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}
export default withTranslation()(ContributionMemberBatch)