import { CONTRIBUTION, CONTRIBUTION_GROUP, CONTRIBUTION_MEMBER, OTHER_BUSINESS, OTHER_BUSINESS_BUYER } from '../api';
import { API_BASE_URL, ADALIPA_BASE_URL } from '../constants';
import { postRequest, getRequest,downloadGetRequest, queryParams, postFiles, getRequestWithSignal } from './CoreService';

export class WhatsappService {

    getWhatsappList = (jsonParams,institution) => {
        return getRequest({
            url: API_BASE_URL + "/contribution/subscribers/" +institution+  queryParams(jsonParams),
            method: 'GET'
        });
    }

    sendWhatsappMessage = (jsonParams) => {
        return postRequest({
            url: API_BASE_URL + "/contribution/init/message",
            method: 'POST',
            body: JSON.stringify(jsonParams)
        });
    }

    sendSingleWhatsappMessage = (jsonParams) => {
        return postRequest({
            url: API_BASE_URL + "/contribution/init/single/message",
            method: 'POST',
            body: JSON.stringify(jsonParams)
        });
    }
}

