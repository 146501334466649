import { Button } from 'primereact/button';
import React, {Component} from 'react';
import { withTranslation } from "react-i18next";

class PrivacyPolicy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            message: '',
            failed: null,
            alert: "",
        };
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="p-grid" style={{paddingLeft: "1em"}}>
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid p-text-center" style={{textAlign: "center"}}>
                            <div className="p-col-12 p-md-12 p-text-center"><h2><span style={{textAlign: "center"}}>AdaLipa Privacy Policy</span></h2></div>
                        </div>
                    </div>
                </div>
                <div className="p-offset-1 p-col-10 p-grid">
                    <div class="p-col-12">
                        <span>Welcome to the AdaLipa Privacy Policy – Within this document you will have an in depth understanding of how <strong>ADALIPA</strong> treats your private and personal information taking into account offering the members with ways to control their personal preferences and settings at any time. <strong>ADALIPA</strong> is always focused on gaining trust of their registered members which goes hand in hand with understanding what we can provide them to meet their expectations.</span>
                    </div>

                    <div class="p-col-12">
                        <span><strong>What This Privacy Policy Covers</strong></span>
                    </div>

                    <div class="p-col-12">
                        <span><strong>ADALIPA takes your privacy seriously. Please read the following to learn more about our privacy policy.</strong></span>
                    </div>

                    <div class="p-col-12">
                        <span>The federal government and technology industry have developed practical tips to help you guard against Internet fraud, secure your computer and protect your personal information.</span>
                    </div>

                    <div class="p-col-12">
                        <span><strong>How ADALIPA Uses Your Personal Information</strong></span>
                    </div>

                    <div class="p-col-12">
                        <span>This policy covers how ADALIPA treats personal information that ADALIPA collects and receives, including information related to your past use of ADALIPA products and services. Personal information is information about you that is personally identifiable like your name, address, email address, or phone number, and that is not otherwise publicly available.</span>
                    </div>

                    <div class="p-col-12">
                        <span><strong>This privacy policy only applies to ADALIPA</strong></span>
                    </div>

                    <div class="p-col-12">
                        <span>This policy does not apply to the practices of companies that ADALIPA is not affiliated with or does not own or control, or to people that ADALIPA does not employ or manage.</span>
                    </div>

                    <div class="p-col-12">
                        <span><strong>Data Transfer</strong></span>
                    </div>

                    <div class="p-col-12">
                        <span>Your personal information may be transferred to countries other than your own to process and store data in accordance with our Privacy Policy and to provide you with products and services. Some of these countries may not have the same data protection safeguards as the country where you reside.</span>
                    </div>

                    <div class="p-col-12">
                        <span>By using our products and services, you consent to us transferring your data to these countries. We are committed to ensuring your information is protected and apply safeguards in accordance with applicable law.</span>
                    </div>

                    <div class="p-col-12">
                        <span><strong>Information Collection & Use</strong></span>
                    </div>

                    <div class="p-col-12">
                        <span><strong>General</strong></span>
                    </div>

                    <div class="p-col-12">
                        <span>ADALIPA collects personal information when you register with the ADALIPA project, when you use ADALIPA products and in-built services such as Bank Settlement and Reconciliations, Bulk Messaging via SMS Mtandao etc. ADALIPA may combine information about you that we have with information we obtain from business partners or other companies to check the consistency.</span>
                    </div>

                    <div class="p-col-12">
                        <span>When you register we ask for information such as your name, email address, birth date, residential area, working area and designation. For some financial purposes such as bank transfers and settlements we might also ask for your mobile money track record, national identification number or any other legal identification e.g. Driver’s License and information about your association within which you have registered in ADALIPA with. When you register with ADALIPA and sign in to our services, you are not anonymous to us.</span>
                    </div>

                    <div class="p-col-12">
                        <span>ADALIPA collects information about your transactions with us and with some of our business partners, including information about your use of the system and audit trails.</span>
                    </div>

                    {/* <div class="p-col-12">
                        <span>ADALIPA automatically receives and records information from your computer and bp-col-12ser, including IP Address, mobile phone IMEI number of the payments, cookie information, software and hardware attributes, and the page you request.</span>
                    </div> */}

                    <div class="p-col-12">
                        <span>ADALIPA uses information for the following general purposes: to customize the services and content you see, fulfill your requests for products and services, improve our services, contact you, conduct research, and provide anonymous reporting for internal and external clients to further enhance our offerings.</span>
                    </div>

                    <div class="p-col-12">
                        <span><strong>Information Sharing & Disclosure</strong></span>
                    </div>

                    <div class="p-col-12">
                        <span>ADALIPA does not rent, sell, or share personal information about you with other people or non-affiliated companies except to provide products or services you've requested, when we have your permission, or under the following circumstances:</span>
                    </div>

                    <div class="p-col-12 p-grid">
                        <div class="p-md-1"><span><strong>.</strong></span></div>
                        <div class="p-md-11"><span>We provide the information to trusted partners who work on behalf of or with ADALIPA under confidentiality agreements. These companies may use your personal information to help ADALIPA communicate with you about offers from ADALIPA and our marketing partners. However, these companies do not have any independent right to share this information.</span></div>
                    </div>
                    <div class="p-col-12 p-grid">
                        <div class="p-md-1"><span><strong>.</strong></span></div>
                        <div class="p-md-11"><span>We respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims.</span></div>
                    </div>
                    <div class="p-col-12 p-grid">
                        <div class="p-md-1"><span><strong>.</strong></span></div>
                        <div class="p-md-11"><span>We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of ADALIPA's terms of use, or as otherwise required by law.</span></div>
                    </div>
                    <div class="p-col-12 p-grid">
                        <div class="p-md-1"><span><strong>.</strong></span></div>
                        <div class="p-md-11"><span>We transfer information about you if ADALIPA is acquired by or merged with another company. In this event, ADALIPA will notify you before information about you is transferred and becomes subject to a different privacy policy.</span></div>
                    </div>

                    <div class="p-col-12">
                        <span>ADALIPA works with vendors, partners, and other service providers in different industries and categories of business. </span>
                    </div>

                    <div class="p-col-12">
                        <span><strong>Cookies & Similar Technologies</strong></span>
                    </div>

                    <div class="p-col-12">
                        <span>ADALIPA may set and access ADALIPA cookies on your browser or mobile. We may also set and access device identifiers which could include IP address, user agent information (bp-col-12ser version, OS type and version), and device provided identifiers. Once you log into ADALIPA on your device, ADALIPA may recognize your device to provide you with a personalized experience, independent of your device settings.</span>
                    </div>

                    <div class="p-col-12">
                        <span><strong>Your Ability to Edit and Delete Your Account Information and Preferences</strong></span>
                    </div>

                    <div class="p-col-12">
                        <span>You can edit your ADALIPA Account Information at any time. You can also modify information you have provided to ADALIPA through the ADALIPA products and services you may use.</span>
                     </div>

                    <div class="p-col-12">
                        <span>We reserve the right to send you certain communications relating to the ADALIPA products and services, such as service announcements, administrative messages and the ADALIPA Newsletter, that are considered part of your ADALIPA account, without offering you the opportunity to opt out of receiving them. You can delete your ADALIPA account by contacting us via our office mobile number: +255 (0) 753 10 11 12.</span>
                     </div>

                    <div class="p-col-12">
                        <span><strong>Confidentiality & Security</strong></span>
                    </div>

                    <div class="p-col-12">
                        <span>We limit access to personal information about you to employees who we believe reasonably need to come into contact with that information to provide products or services to you or in order to do their jobs.</span>
                    </div>

                    <div class="p-col-12">
                        <span>We have physical, electronic, and procedural safeguards that comply with federal regulations to protect personal information about you.</span>
                    </div>

                    <div class="p-col-12">
                        <span><strong>Changes to This Policy</strong></span>
                    </div>

                    <div class="p-col-12">
                        <span>ADALIPA may update this policy. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address, residential address or mobile number specified in your ADALIPA account or by placing a prominent notice on our site.</span>
                    </div>

                    <div class="p-col-12">
                        <span><strong>Questions & Suggestions</strong></span>
                    </div>

                    <div class="p-col-12">
                        <span>If you have questions, suggestions, or wish to make a complaint, please contact us via:</span>
                    </div>

                    <div class="p-col-12">
                        <span>ADALIPA (Product of DataVision International Ltd)<br/>
                        Customer Care - Privacy Policy Issues<br/>
                        Garden Road, Mikocheni Area<br/>
                        PO BOX 9983<br/>
                        +255 (0) 754 86 94 02</span>
                    </div>

                    <div class="p-col-12">
                        <p><strong>Last updated:</strong> February 02, 2020</p>
                    </div>
                </div>
                <div className="p-col-12 p-grid">
                    <Button type="button" onClick={() => this.goBack()} label=" Go Back" icon="pi pi-arrow-circle-left" style={buttonStyle.float} />
                </div>
            </div>
        );
    }
}

export default withTranslation()(PrivacyPolicy)

export const buttonStyle = {
    float: {
        position: "fixed",
        bottom: "1em",
        left: "1em"
    }
}