import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { WalletService } from '../../service/WalletService';
import { WhatsappService } from '../../service/whatsappService';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
class InitiateWhatsapp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            messageCategory: "choose",
            messageCategories: [{ label: this.props.t("Custom Message"), value: "YES" }, { label: this.props.t("System Message"), value: "NO" }],
            groupName: '',
            message: '',
            groupId: 0,
            isCustom: "",
            alert: '',
            failed: false,
            activity: "WhatsApp Message",
            institutionId: 0,

            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            error: '',
            isManager: false,
            grouStatus: "Active",
            memberId: 0,
            memberStatus: 'Active',
            contributionStatus: 'ON',
            memberType: '',
            sortOrder: -1,
            sortField: 'id',
            hasAlert: false


        };
        this.walletService = new WalletService();
        this.whatsappService = new WhatsappService();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleGroups = this.handleGroups.bind(this);
    }


    componentDidMount() {
        const jsonParams = { groupStatus: this.state.grouStatus, page: this.state.pageIndex, limit: 200, sort: this.state.sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc' }
        this.walletService.getGroups(jsonParams)
            .then(response => {
                const data = response.content;
                const options = data.map(d => ({
                    "value": d.id,
                    "label": d.groupName
                }))
                this.setState({ groups: options });
            })
            .catch(error => {
                this.setState({ failed: true });
                this.setState({ alert: error.message });
                this.setState({ hasAlert: true });
            });
    }

    handleGroups(event) {
        const value = event.target.value;
        this.setState({ groupId: value });
    }

    handleSubmit(event) {
        event.preventDefault();
        const whatsappJson = {
            message: this.state.message,
            groupId: this.state.groupId,
            isCustom: this.state.isCustom,
            institutionId: +this.props.instituteId
        };

        if (this.state.messageCategory !== "choose") {
            this.whatsappService.sendWhatsappMessage(whatsappJson)
                .then(response => {
                    this.setState({ failed: false });
                    this.setState({ alert: response.message });
                    this.setState({ hasAlert: true });
                }).catch(error => {
                    this.setState({ failed: true });
                    this.setState({ alert: error.message });
                    this.setState({ hasAlert: true });
                });

        } else {

            this.setState({ failed: true });
            this.setState({ alert: "WhatsApp Message Failed, Invalid Message Category selected" });
            this.setState({ hasAlert: true });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;
        this.setState({
            [inputName]: inputValue
        });
    }

    render() {
        return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        {this.state.failed === true && this.state.hasAlert &&
                            <div className="p-messages p-component p-messages-warn" style={{ margin: '0 0 1em 0', display: 'block' }}>
                                <div className="p-messages-wrapper rounded">
                                    <ul>
                                        <li>
                                            <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                            <span className="p-messages-detail">{this.state.alert}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }

                        {this.state.failed === false && this.state.hasAlert &&
                            <div className="p-messages p-component p-messages-success" style={{ margin: '0 0 1em 0', display: 'block' }}>
                                <div className="p-messages-wrapper rounded">
                                    <ul>
                                        <li>
                                            <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                            <span className="p-messages-detail">{this.state.alert}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{ backgroundColor: "#3B007B", color: "#FFFFFF", padding: "1%" }}>
                                <h2 style={{ textAlign: "center" }}>{this.props.t(this.state.activity)}</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>

                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Group Name')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.groups} value={this.state.groupId} onChange={this.handleGroups} filter={true} required />
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('WhatsApp Message Category')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.messageCategories} value={this.state.messageCategory} onChange={(e) => { this.setState({ isCustom: e.target.value, messageCategory: e.target.value }) }} filter={true} required />
                                        </div>
                                    </div>

                                </div>



                                {this.state.isCustom === "YES" && <div><div className="p-grid">

                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('WhatsApp Message')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputTextarea type="text" name="message" placeholder={this.props.t("Enter") + " " + this.props.t("Message")} value={this.state.message} onChange={this.handleInputChange} required />
                                        </div>
                                    </div>

                                    {/* <div className="p-col-12 p-md-4">
                                       <div className="p-col-12 p-md-12">
                                           <label htmlFor="input">{this.props.t('Amount')}:</label>
                                       </div>
                                       <div className="p-col-12 p-md-12">
                                           <InputText type="text" name="message" placeholder={this.props.t("Enter") + " " + this.props.t("Message")} value={this.state.message} onChange={this.handleInputChange} required/>
                                       </div>
                                   </div> */}

                                </div>




                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-offset-4 p-md-4">
                                            <Button type="submit" label={this.props.t("Send WhatsApp Message")} icon="pi pi-plus" />
                                        </div>
                                    </div></div>}

                                {this.state.isCustom === "NO" && <div>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-offset-4 p-md-4">
                                            <Button type="submit" label={this.props.t("Send WhatsApp Message")} icon="pi pi-plus" />
                                        </div>
                                    </div></div>}


                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(InitiateWhatsapp);