import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { ClientService } from '../service/ClientService';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

export default class AddLevel extends Component {

	constructor(props) {
        super(props);
        this.state = {
            levelName: '',
            courseId: 0,
            alert: '',
            courses: []
        };
        this.clientService = new ClientService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        
        if (this.props.institutionType === "Collages") {
            this.clientService.getCourses('none', 0, 100, 'id', 'asc')
            .then(response => {
                const data = response.content;
                const options = data.map(d => ({
                    "value" : d.id,
                    "label" : d.courseName + " (" + d.courseCode + ")"
                }))
                this.setState({courses: options});
            });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        let levelJson = {
            levelName: this.state.levelName
        };
        if (this.props.institutionType === "Collages") {
            levelJson = {
                levelName: this.state.levelName,
                courseId: this.state.courseId
            };
        }
        this.clientService.addLevel(levelJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/levels");
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/add-level",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        { this.state.failed === true &&
                            <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                                    <div className="p-messages-wrapper rounded">
                                        <ul>
                                            <li>
                                                <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                                <span className="p-messages-detail">{this.state.alert}</span>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        }

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>New Level</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Level:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="levelName" placeholder="eg: Form One" value={this.state.levelName} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    { this.props.institutionType === "Collages" &&
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-col-12 p-md-12">
                                                <label htmlFor="dropdown">Choose Course:</label>
                                            </div>
                                            <div className="p-col-12 p-md-12">
                                                <Dropdown options={this.state.courses} value={this.state.courseId} onChange={event => this.setState({courseId: event.value})} autoWidth={false} />
                                            </div>
                                        </div>
                                    }
                                    { this.props.institutionType === "Schools" &&
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-col-12 p-md-12">
                                                <label htmlFor="dropdown">&nbsp;</label>
                                            </div>
                                            <div className="p-col-12 p-md-offset-4 p-md-4">
                                                <Button type="submit" label="Add Level" icon="pi pi-plus" />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="p-grid">
                                    { this.props.institutionType === "Collages" &&
                                        <div className="p-col-12 p-md-offset-4 p-md-4">
                                            <Button type="submit" label="Add Level" icon="pi pi-plus" />
                                        </div>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}