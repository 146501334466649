import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { ClientService } from '../../service/ClientService';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import "../../adalipa/form.css";
import { TabPanel, TabView } from 'primereact/tabview';
import { InputTextarea } from 'primereact/inputtextarea';
import ResponseAlert from '../../Utilities/ResponseAlert';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';

class Sms extends Component {

	constructor(props) {
        super(props);
        this.state = {
            message: '',
            courseId: 0,
            levelId: 0,
            cellId: 0,
            groupId: 0,
            smsType: 'info',
            housingId: 'ALL_CATEGORIES',
            housingList: [{label: this.props.t('Tenants'), value: 'TENANTS'}, {label: this.props.t('House Holders'), value: 'HOUSE_HOLDERS'},{label: this.props.t('All Categories'), value: 'ALL_CATEGORIES'}],
            smsGroups: [],
            courses: [],
            levels: [],
            groupsType: 'schools',
            reminderType: 'schools',
            failed: null,
			alert: '',
            loading: false,
            eventMessageEnabled: false,
            debtMessageEnabled: false
        };
        this.clientService = new ClientService();
        this.adalipaUtil = new AdalipaUtil();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.accountInfoSubmit = this.accountInfoSubmit.bind(this);
        this.debtAccountInfoSubmit = this.debtAccountInfoSubmit.bind(this);
        this.groupSmsSubmit = this.groupSmsSubmit.bind(this);
        this.serviceSmsSubmit = this.serviceSmsSubmit.bind(this);
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.clientService.getSmsScope()
        .then(response => {
            this.setState({groupsType: response.groupsType});
            this.setState({reminderType: response.reminderType});
            const data = response.smsGroups;
            if (data !== null) {
                const options = data.map(d => ({
                    "value" : d.id,
                    "label" : d.name,
                    "type" : d.type
                }))
                this.setState({smsGroups: options});
            }
            // console.log("groupsType: " + this.state.reminderType)
            if(this.state.groupsType === "contributions_sms"){
                this.setState({eventMessageEnabled: true});
                this.setState({smsType: this.state.smsType});
            }
            if(this.state.groupsType === "lga_sms"){
                this.setState({debtMessageEnabled: true});
            }
        });
        if (this.props.institutionType === "Collages") {
            this.clientService.getCourses('none', 0, 100, 'id', 'asc')
            .then(response => {
                const data = response.content;
                const options = data.map(d => ({
                    "value" : d.id,
                    "label" : d.courseName + " (" + d.courseCode + ")"
                }))
                this.setState({courses: options});
            });
            this.clientService.getLevels('', 0, 100, 'id', 'asc')
            .then(response => {
                const data = response.content;
                const options = data.map(d => ({
                    "value" : d.id,
                    "label" : d.gradeName + (d.course === null ? "" : " (" + d.course.courseCode + ")")
                }))
                this.setState({levels: options});
            });
        } else {
            this.clientService.getLevels('', 0, 100, 'id', 'asc')
            .then(response => {
                const data = response.content;
                const options = data.map(d => ({
                    "value" : d.id,
                    "label" : d.gradeName
                }))
                this.setState({levels: options});
            });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    debtAccountInfoSubmit(event){
        event.preventDefault();
        this.setState({loading: true});
        let smsJson = {
            smsType: this.state.smsType,
            cellId: this.state.cellId,
            housingId: this.state.housingId,
            debtIfoSms: 'true'
        }
        this.clientService.sendAccountInfoSms(smsJson)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/sms/notifications");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });

    }

    accountInfoSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});
        let smsJson = {
            smsType: this.state.smsType
        };
        if (this.state.courseId !== 0) {
            smsJson = {
                smsType: this.state.smsType,
                courseId: this.state.courseId
            };
        } else if (this.state.levelId !== 0) {
            smsJson = {
                smsType: this.state.smsType,
                levelId: this.state.levelId
            };
        } else if (this.state.groupId !== 0) {
            smsJson = {
                smsType: this.state.smsType,
                groupId: this.state.groupId
            };
        } else {
            smsJson = {
                message: this.state.message,
                cellId: this.state.cellId,
                housingId: this.state.housingId
            };
        }
        this.clientService.sendAccountInfoSms(smsJson)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/sms/notifications");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

    groupSmsSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});
        let smsJson = {
            message: this.state.message
        };
        if (this.state.courseId !== 0) {
            smsJson = {
                message: this.state.message,
                courseId: this.state.courseId
            };
        } else if (this.state.levelId !== 0) {
            smsJson = {
                message: this.state.message,
                levelId: this.state.levelId
            };
        } else if (this.state.groupId !== 0) {
            smsJson = {
                message: this.state.message,
                groupId: this.state.groupId
            };
        } else {
            if (this.state.cellId !== 0) {
                smsJson = {
                    message: this.state.message,
                    cellId: this.state.cellId,
                    housingId: this.state.housingId
                };
            } else {
                smsJson = {
                    message: this.state.message
                };
            }
        }
        this.clientService.sendGroupSms(smsJson)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/sms/notifications");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

    serviceSmsSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});
        let smsJson = {
            message: this.state.message
        };
        if (this.state.courseId !== 0) {
            smsJson = {
                message: this.state.message,
                courseId: this.state.courseId
            };
        } else if (this.state.levelId !== 0) {
            smsJson = {
                message: this.state.message,
                levelId: this.state.levelId
            };
        } else if (this.state.groupId !== 0) {
            smsJson = {
                message: this.state.message,
                groupId: this.state.groupId
            };
        } else {
            if (this.state.cellId !== 0) {
                smsJson = {
                    message: this.state.message,
                    cellId: this.state.cellId
                };
            } else {
                smsJson = {
                    message: this.state.message
                };
            }
        }
        this.clientService.sendServiceSms(smsJson)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/sms/notifications");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/sms/notifications",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <ResponseAlert failed={this.state.failed} alert={this.state.alert} setFailed={(e) => this.setState({failed: e})} />
                        <TabView>

         {/* START TAB 1 */}

                            <TabPanel header={this.props.t("Account Info Sms")} leftIcon="pi pi-user">
                                <div className="card card-w-title">
                                    <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                        <h1 style={{textAlign: "center"}}>{this.props.t('Account Info Sms')}</h1>
                                    </div>
                                    <div className="divider-container"><div className="divider-border" />
                                        <span className="divider-content"><h3 style={{textAlign: "center"}}>{this.props.t('Message Details')}</h3></span>
                                    <div className="divider-border" /></div>
                                    {this.state.loading && 
                                        <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                            <div className="p-col-12" style={{textAlign:'center'}}>
                                                <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                            </div>
                                        </div>
                                    }
                                    {!this.state.loading && 
                                    <div className="p-col-12">
                                        { (this.state.groupsType !== "schools" && this.state.groupsType !== "lga_sms") &&  
                                        <form onSubmit={this.accountInfoSubmit}>
                                            <div className="p-grid p-col-12 p-md-offset-4 p-md-4">
                                                <div className="p-col-12 p-md-12 card card-w-title">
                                                    <h3>{this.props.t('Sms Groups')}</h3>
                                                    <Dropdown options={this.state.smsGroups} value={this.state.groupId} onChange={event => this.setState({groupId: event.value})} autoWidth={false} />
                                                </div>
                                            </div>
                                            <div className="p-grid" style={{marginTop: "1%"}}>
                                                <div className="p-col-12 p-md-offset-4 p-md-4">
                                                    <Button type="submit" label={this.props.t("Send Sms")} icon="pi pi-plus" />
                                                </div>
                                            </div>
                                        </form>
                                        }
                                        { this.state.groupsType === "lga_sms" &&  
                                    <div className="p-col-12 p-md-12">
                                        {/* <form onSubmit={this.accountInfoSubmit}>
                                            <div className="p-grid p-col-12 p-md-offset-4 p-md-4">
                                                <div className="p-col-12 p-md-12 card card-w-title">
                                                    <h3>{this.props.t('Cells')}</h3>
                                                    <Dropdown options={this.state.smsGroups} value={this.state.groupId} onChange={event => this.setState({groupId: event.value})} autoWidth={false} placeholder={this.props.t("All Cells")} />
                                                </div>
                                            </div>
                                            <div className="p-grid" style={{marginTop: "1%"}}>
                                                <div className="p-col-12 p-md-offset-4 p-md-4">
                                                    <Button type="submit" label={this.props.t("Send Sms")} icon="pi pi-plus" />
                                                </div>
                                            </div>
                                        </form> */}
                                        <form onSubmit={this.accountInfoSubmit}>
                                            <div className="p-grid p-col-12 p-md-offset-4 p-md-4">
                                                <div className="p-col-12 p-md-12 card card-w-title">
                                                    <h3>{this.props.t('Housing Category')}</h3>
                                                    <Dropdown options={this.state.housingList} value={this.state.housingId} onChange={event => this.setState({housingId: event.value})} autoWidth={false} placeholder={this.props.t("All Categories")} />
                                                </div>
                                                <div className="p-col-12 p-md-12 card card-w-title">
                                                    <h3>{this.props.t('Cells')}</h3>
                                                    <Dropdown options={[{"value" : 0, "label" : this.props.t("All Cells"), "type" : "lga_sms"}].concat(this.state.smsGroups)} value={this.state.cellId} onChange={event => this.setState({cellId: event.value})} autoWidth={false} placeholder={this.props.t("All Cells")} />
                                                </div>
                                            </div>
                                            <div className="p-grid" style={{marginTop: "1%"}}>
                                                <div className="p-col-12 p-md-offset-4 p-md-4">
                                                    <Button type="submit" label={this.props.t("Send Sms")} icon="pi pi-plus" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                        }
                                        { (this.state.groupsType === "schools" && this.props.institutionType === "Collages" ) && 
                                        <form onSubmit={this.accountInfoSubmit}>
                                            <div className="p-grid p-col-12 p-md-offset-4 p-md-4">
                                                <div className="p-col-12 p-md-12 card card-w-title">
                                                    <h3>Courses</h3>
                                                    <Dropdown options={this.state.courses} value={this.state.courseId} onChange={event => this.setState({courseId: event.value})} autoWidth={false} />
                                                </div>
                                            </div>
                                            <div className="p-grid" style={{marginTop: "1%"}}>
                                                <div className="p-col-12 p-md-offset-4 p-md-4">
                                                    <Button type="submit" label={this.props.t("Send Sms")} icon="pi pi-plus" />
                                                </div>
                                            </div>
                                        </form>
                                        }

                                        {/* POINT OF FOCUS */}
                                        { this.state.groupsType === "schools" && 
                                        <form onSubmit={this.accountInfoSubmit}>
                                            <div className="p-grid p-col-12 p-md-offset-4 p-md-4">
                                                <div className="p-col-12 p-md-12 card card-w-title">
                                                    <h3>Levels</h3>
                                                    <Dropdown options={this.state.levels} value={this.state.levelId} onChange={event => this.setState({levelId: event.value})} autoWidth={false} />
                                                </div>
                                            </div>
                                            <div className="p-grid" style={{marginTop: "1%"}}>
                                                <div className="p-col-12 p-md-offset-4 p-md-4">
                                                    <Button type="submit" label={this.props.t("Send Sms")} icon="pi pi-plus" />
                                                </div>
                                            </div>
                                        </form>
                                        }
                                    </div>
                                    }
                                </div>
                            </TabPanel>



            {/* SEPARATE TABS */}


                            <TabPanel header={this.props.t("Debt Info sms")} leftIcon="pi pi-user" disabled={!this.state.debtMessageEnabled}>
                                <div className="card card-w-title">
                                    <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                        <h1 style={{textAlign: "center"}}>{this.props.t('Debt Based Sms')}</h1>
                                    </div>
                                    <div className="divider-container"><div className="divider-border" />
                                        <span className="divider-content"><h3 style={{textAlign: "center"}}>{this.props.t('Message Details')}</h3></span>
                                    <div className="divider-border" /></div>
                                    {this.state.loading && 
                                        <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                            <div className="p-col-12" style={{textAlign:'center'}}>
                                                <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                            </div>
                                        </div>
                                    }
                                    <form onSubmit={this.debtAccountInfoSubmit}>
                                            <div className="p-grid p-col-12 p-md-offset-4 p-md-4">

                                            <div className="p-col-12 p-md-12 card card-w-title">
                                                    <h3>{this.props.t('Housing Category')}</h3>
                                                    <Dropdown options={this.state.housingList} value={this.state.housingId} onChange={event => this.setState({housingId: event.value})} autoWidth={false} placeholder={this.props.t("All Categories")} />
                                                </div>

                                                <div className="p-col-12 p-md-12 card card-w-title">
                                                    <h3>{this.props.t('Cells')}</h3>
                                                    {/* {console.log("Sms Groups: ", this.state.smsGroups)} */}
                                                    <Dropdown options={this.state.smsGroups} value={this.state.cellId} onChange={event => this.setState({cellId: event.value})} autoWidth={false} />
                                                </div>
                                            </div>
                                            <div className="p-grid" style={{marginTop: "1%"}}>
                                                <div className="p-col-12 p-md-offset-4 p-md-4">
                                                    <Button type="submit" label={this.props.t("Send Sms")} icon="pi pi-plus" />
                                                </div>
                                            </div>
                                        </form>
                                        </div>
                            </TabPanel>


                            <TabPanel header={this.props.t("Write Message")} leftIcon="pi pi-users">
                                <div className="card card-w-title">
                                    <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                        <h1 style={{textAlign: "center"}}>{this.props.t('Group Based Short Sms')}</h1>
                                    </div>
                                    <div className="divider-container"><div className="divider-border" />
                                        <span className="divider-content"><h3 style={{textAlign: "center"}}>{this.props.t('Message Details')}</h3></span>
                                    <div className="divider-border" /></div>
                                    {this.state.loading && 
                                        <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                            <div className="p-col-12" style={{textAlign:'center'}}>
                                                <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                            </div>
                                        </div>
                                    }
                                    {!this.state.loading && 
                                    <div className="p-col-12">
                                        { (this.state.groupsType !== "schools" && this.state.groupsType !== "lga_sms") &&
                                        <form onSubmit={this.groupSmsSubmit}>
                                            <div className="p-grid p-col-12 p-md-offset-4 p-md-4">                                           

                                                <div className="p-col-12 p-md-12 card card-w-title">
                                                    <h3>{this.props.t('Sms Groups')}</h3>
                                                    <Dropdown options={this.state.smsGroups} value={this.state.groupId} onChange={event => this.setState({groupId: event.value})} autoWidth={false} />
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-offset-4 p-md-4">
                                                <h3>{this.props.t('Message')}</h3>
                                                <InputTextarea name="message" value={this.state.message} onChange={this.handleInputChange} required rows={3} cols={30} placeholder={this.props.t("Your Message")} autoResize={true} /><br/>
                                                <span style={{fontWeight: "bold", textAlign: "center", marginBottom: 8}}>
                                                    {this.adalipaUtil.numberOfMessages(this.state.message).messageLenth + " (" + this.adalipaUtil.numberOfMessages(this.state.message).messages + ")"}
                                                </span>
                                            </div>
                                            <div className="p-grid" style={{marginTop: "1%"}}>
                                                <div className="p-col-12 p-md-offset-4 p-md-4">
                                                    <Button type="submit" label={this.props.t("Send Sms")} icon="pi pi-plus" />
                                                </div>
                                            </div>
                                        </form>
                                        }
                                        { (this.state.groupsType === "lga_sms") &&
                                        <form onSubmit={this.groupSmsSubmit}>
                                            <div className="p-grid p-col-12 p-md-offset-4 p-md-4">
                                                
                                            <div className="p-col-12 p-md-12 card card-w-title">
                                                    <h3>{this.props.t('Housing Category')}</h3>
                                                    <Dropdown options={this.state.housingList} value={this.state.housingId} onChange={event => this.setState({housingId: event.value})} autoWidth={false} placeholder={this.props.t("All Categories")} />
                                                </div>

                                                <div className="p-col-12 p-md-12 card card-w-title">
                                                    <h3>{this.props.t('Sms Groups')}</h3>
                                                    <Dropdown options={[{"value" : 0, "label" : this.props.t("All Cells"), "type" : "lga_sms"}].concat(this.state.smsGroups)} value={this.state.cellId} onChange={event => this.setState({cellId: event.value})} placeholder={this.props.t("All Cells")} autoWidth={false} />
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-offset-4 p-md-4">
                                                <h3>{this.props.t('Message')}</h3>
                                                <InputTextarea name="message" value={this.state.message} onChange={this.handleInputChange} required rows={3} cols={30} placeholder={this.props.t("Your Message")} autoResize={true} /><br/>
                                                <span style={{fontWeight: "bold", textAlign: "center", marginBottom: 8}}>
                                                    {this.adalipaUtil.numberOfMessages(this.state.message).messageLenth + " (" + this.adalipaUtil.numberOfMessages(this.state.message).messages + ")"}
                                                </span>
                                            </div>
                                            <div className="p-grid" style={{marginTop: "1%"}}>
                                                <div className="p-col-12 p-md-offset-4 p-md-4">
                                                    <Button type="submit" label={this.props.t("Send Sms")} icon="pi pi-plus" />
                                                </div>
                                            </div>
                                        </form>
                                        }
                                        { (this.state.groupsType === "schools" && this.props.institutionType === "Collages" ) &&
                                        <form onSubmit={this.groupSmsSubmit}>
                                            <div className="p-grid p-col-12 p-md-offset-4 p-md-4">
                                                <div className="p-col-12 p-md-12 card card-w-title">
                                                    <h3>Courses</h3>
                                                    <Dropdown options={this.state.courses} value={this.state.courseId} onChange={event => this.setState({courseId: event.value})} autoWidth={false} />
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-offset-4 p-md-4">
                                                <h3>{this.props.t('Message')}</h3>
                                                <InputTextarea name="message" value={this.state.message} onChange={this.handleInputChange} required rows={3} cols={30} placeholder={this.props.t("Your Message")} autoResize={true} /><br/>
                                                <span style={{fontWeight: "bold", textAlign: "center", marginBottom: 8}}>
                                                    {this.adalipaUtil.numberOfMessages(this.state.message).messageLenth + " (" + this.adalipaUtil.numberOfMessages(this.state.message).messages + ")"}
                                                </span>
                                            </div>
                                            <div className="p-grid" style={{marginTop: "1%"}}>
                                                <div className="p-col-12 p-md-offset-4 p-md-4">
                                                    <Button type="submit" label={this.props.t("Send Sms")} icon="pi pi-plus" />
                                                </div>
                                            </div>
                                        </form>
                                        }
                                        { this.state.groupsType === "schools" &&
                                        <form onSubmit={this.groupSmsSubmit}>
                                            <div className="p-grid p-col-12 p-md-offset-4 p-md-4">
                                                <div className="p-col-12 p-md-12 card card-w-title">
                                                    <h3>Levels</h3>
                                                    <Dropdown options={this.state.levels} value={this.state.levelId} onChange={event => this.setState({levelId: event.value})} autoWidth={false} />
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-offset-4 p-md-4">
                                                <h3>{this.props.t('Message')}</h3>
                                                <InputTextarea name="message" value={this.state.message} onChange={this.handleInputChange} required rows={3} cols={30} placeholder={this.props.t("Your Message")} autoResize={true} /><br/>
                                                <span style={{fontWeight: "bold", textAlign: "center", marginBottom: 8}}>
                                                    {this.adalipaUtil.numberOfMessages(this.state.message).messageLenth + " (" + this.adalipaUtil.numberOfMessages(this.state.message).messages + ")"}
                                                </span>
                                            </div>
                                            <div className="p-grid" style={{marginTop: "1%"}}>
                                                <div className="p-col-12 p-md-offset-4 p-md-4">
                                                    <Button type="submit" label={this.props.t("Send Sms")} icon="pi pi-plus" />
                                                </div>
                                            </div>
                                        </form>
                                        }
                                    </div>
                                    }
                                </div>
                            </TabPanel>

  
                            <TabPanel header={this.props.t("Events Reminder")} leftIcon="pi pi-user" disabled={!this.state.eventMessageEnabled}>
                                <div className="card card-w-title">
                                    <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                        <h1 style={{textAlign: "center"}}>{this.props.t('Event Based Sms')}</h1>
                                    </div>
                                    <div className="divider-container"><div className="divider-border" />
                                        <span className="divider-content"><h3 style={{textAlign: "center"}}>{this.props.t('Message Details')}</h3></span>
                                    <div className="divider-border"/></div>
                                    {this.state.loading && 
                                        <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                            <div className="p-col-12" style={{textAlign:'center'}}>
                                                <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                            </div>
                                        </div>
                                    }
                                    <form onSubmit={this.accountInfoSubmit}>
                                            <div className="p-grid p-col-12 p-md-offset-4 p-md-4">
                                                <div className="p-col-12 p-md-12 card card-w-title">
                                                    <h3>{this.props.t('Sms Groups')}</h3>
                                                    <Dropdown options={this.state.smsGroups} value={this.state.groupId} onChange={event => this.setState({groupId: event.value,smsType: "event_sms"})} autoWidth={false}/>
                                                </div>
                                            </div>
                                            <div className="p-grid" style={{marginTop: "1%"}}>
                                                <div className="p-col-12 p-md-offset-4 p-md-4">
                                                    <Button type="submit" label={this.props.t("Send Sms")} icon="pi pi-plus" />
                                                </div>
                                            </div>
                                        </form>
                                        </div>
                            </TabPanel>
                        

                        </TabView>
                    </div>
                </div>
            </div>
        );
	}
}
export default withTranslation()(Sms)