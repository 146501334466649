import React, {Component} from 'react';
import { renderToString } from 'react-dom/server';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { WalletService } from './../../service/WalletService';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";
import SettlementReceipt from './SettlementReceipt';
import jsPDF from 'jspdf';

class Settlements extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            pdfData:[],
            excelData:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            layout: 'list',
            sortOptions: [
                {label: 'Newest First', value: '!year'},
                {label: 'Oldest First', value: 'year'},
                {label: 'Brand', value: 'brand'}
            ],
            transactionId: '',
            settledFrom: '',
            accountNumber: '',
            mlipaResponse: '',
            details: '',
            accountName: '',
            responseComment: '',
            instituteName: '',
            settlementType: '',
            Status: null,
            sortOrder: -1,
            sortField: 'id',
            loading: false,
            receiptData: {settledTime: "", transactionId: "", accountNumber: "", accountName: "", amount: 0, charges: 0, details: "", mlipaResponse: "", responseComment: ""},
            displayReceipt: false,
            isAdmin: false
        };

        this.walletService = new WalletService();
        this.adalipaUtil = new AdalipaUtil();

        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.amountTemplate = this.amountTemplate.bind(this);
        this.chargesTemplate = this.chargesTemplate.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.onPrinterClick = this.onPrinterClick.bind(this);
        this.export = this.export.bind(this);

        this.cols = [
            { field: 'settledTime', header: this.props.t('Time') },
            { field: 'transactionId', header: this.props.t('Receipt Number') },
            { field: 'accountNumber', header: this.props.t('Account') },
            { field: 'accountName', header: this.props.t('Account Name') },
            { field: 'instituteName', header: this.props.t('Institute Name') },
            { field: 'settlementType', header: this.props.t('Settlement Type') },
            { field: 'amount', header: this.props.t('Amount') },
            { field: 'charges', header: this.props.t('Charges') },
            { field: 'details', header: this.props.t('Details') },
            { field: 'mlipaResponse', header: this.props.t('Status') }
        ];
        this.excelCols = {header: ['settledTime', 'transactionId', 'accountNumber', 'accountName',"instituteName","settlementType", 'amount', 'charges', 'details', 'mlipaResponse', 'responseComment']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    getDataList() {
        // console.log("ROLE LIST",this.props.roles);
        const role = this.props.roles;
        if(role.includes("ADMIN")){
            this.setState({isAdmin:true});
        }
        let {instituteName,settlementType,transactionId, settledFrom, accountNumber, details, mlipaResponse, responseComment, pageIndex, pageSize, sortField, sortOrder} = this.state;
        this.setState({loading: true});
        return this.walletService.getSettlements({instituteName:instituteName, settlementType:settlementType, transactionId: transactionId, settledFrom: settledFrom, accountNumber: accountNumber, details: details, mlipaResponse: mlipaResponse, responseComment: responseComment, page: pageIndex, limit: pageSize, sort: sortField, order: sortOrder === 1 ? 'asc' : 'desc'})
        .then(data => {
            this.setState({loading: false});
            const dataContent = data.content.map(d => ({
                ...d, instituteName: d.institution.instituteName ,settledTime: this.adalipaUtil.timestampFormat(d.settledTime), accountNumber: d.settledTo.accountNumber, accountName: d.settledTo.accountName, mlipaResponse: (d.mlipaResponse ? this.props.t("Success") : this.props.t("Failed"))
            }));
            this.setState({dataTableValue: dataContent});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                pageSize: data.pageable.pageSize,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
            const pdfOptions = data.content.map(d => ({
                ...d, instituteName: d.institution.instituteName,settledTime: this.adalipaUtil.timestampFormat(d.settledTime), accountNumber: d.settledTo.accountNumber, accountName: d.settledTo.accountName, amount: this.adalipaUtil.toDecimal(d.amount), charges: this.adalipaUtil.toDecimal(d.charges), mlipaResponse: (d.mlipaResponse ? this.props.t("Success") : this.props.t("Failed"))
            }));
            this.setState({pdfData: pdfOptions});
            const excelOptions = data.content.map(d => ({
                instituteName: d.institution.instituteName,settledTime: this.adalipaUtil.timestampFormat(d.settledTime), transactionId: d.transactionId, accountNumber: d.settledTo.accountNumber, accountName: d.settledTo.accountName, amount: d.amount, charges: d.charges, details: d.details, mlipaResponse: (d.mlipaResponse ? this.props.t("Success") : this.props.t("Failed")), responseComment: d.responseComment
            }));
            this.setState({excelData: excelOptions});
        });
    }

    componentDidMount() {
        this.getDataList();
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            });
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });     
    }

    dateTemplate(rowData, column) {
        return new Intl.DateTimeFormat('sw', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.parse(rowData['updatedTime']));
    }
    amountTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['amount']);
    }
    chargesTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['charges']);
    }

    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
            <Button type="button" onClick={() => this.onPrinterClick(rowData)} icon="pi pi-print" className="p-button p-button-sm p-button-info" style={{padding: "0px"}} title="Print"/>
        </div>;
    }

    export() {
        this.dt.exportCSV();
    }

    onPrinterClick(data) {
        this.setState({receiptData: data}, () => {
            const doc = new jsPDF();
            const htmlElement = renderToString(<SettlementReceipt receiptData={this.state.receiptData} />);
            doc.html(htmlElement).then(() => {
                doc.setFont("helvetica");
                doc.setFontSize(2);
                doc.autoPrint();
                doc.output('dataurlnewwindow');
            });
        });
    }

    render() {
        let header = (
            <div style={{textAlign:'left'}}>
                <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
                <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel("Settlements", this.excelCols, this.state.excelData)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
                <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable("Settlements", this.exportColumns, this.state.pdfData)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
            </div>
        );
        let transactionIdFilter = <InputText type="text" name="transactionId" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.transactionId} onChange={this.handleInputChange}/>
        let accountNumberFilter = <InputText type="text" name="accountNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.accountNumber} onChange={this.handleInputChange}/>
        let instituteNameFilter = <InputText type="text" name="instituteName" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.instituteName} onChange={this.handleInputChange}/>
        let settlementTypeFilter = <InputText type="text" name="settlementType" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.instituteType} onChange={this.handleInputChange}/>
        let detailsFilter = <InputText type="text" name="details" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.details} onChange={this.handleInputChange}/>
        let mlipaResponseFilter = <InputText type="text" name="mlipaResponse" style={{width: '100%'}} placeholder={this.props.t("True or False")} value={this.state.mlipaResponse} onChange={this.handleInputChange}/>

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>{this.props.t('Withdrawals from Payment account')}</h2></div>
                            <div className="p-col-8 p-md-8">
                                <Link to="/wallet/update/account" style={{float: "right"}}><Button label={this.props.t("Withdrawal Account")} icon="pi pi-plus"/></Link>
                                <Link to="/wallet/balance/settlement" style={{float: "right"}}><Button label={this.props.t("Withdraw")} icon="pi pi-plus"/></Link>
                                {this.state.isAdmin && <Link to="/wallet/extra/charges/settlement" style={{float: "right"}}><Button label={this.props.t("Extra Charges Withdraw")} icon="pi pi-plus"/></Link>}
                                
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})} 
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="settledTime" body={this.dateTemplate} header={this.props.t("Time")} sortable={true} filter={false}/>
                            <Column field="transactionId" header={this.props.t("Receipt")} sortable={true} filter={true} filterElement={transactionIdFilter} filterPlaceholder="Contains"/>
                            <Column field="settledTo.accountNumber" header={this.props.t("Account")} sortable={true} filter={true} filterElement={accountNumberFilter} filterPlaceholder="Contains"/>
                            <Column field="instituteName" header={this.props.t("Client Name")} sortable={false} filter={true} filterElement={instituteNameFilter} filterPlaceholder="Contains"/>
                            <Column field="settlementType" header={this.props.t("Settlement Type")} sortable={true} filter={true} filterElement={settlementTypeFilter} filterPlaceholder="Contains"/>
                            <Column field="amount" header={this.props.t("Amount")} body={this.amountTemplate} sortable={true} filter={false}/>
                            <Column field="charges" header={this.props.t("Charges")} body={this.chargesTemplate} sortable={true} filter={false}/>
                            <Column field="details" header={this.props.t("Details")} sortable={true} filter={true} filterElement={detailsFilter}/>
                            <Column field="mlipaResponse" header={this.props.t("Status")} sortable={true} filter={true} filterElement={mlipaResponseFilter} filterPlaceholder="True or False"/>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', fontSize: 'small', width: "5em"}}/>
                        </DataTable>
                        {/* <Paginator currentPageReportTemplate={"Data " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator> */}
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(Settlements)
