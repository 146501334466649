import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { RealEstateService } from '../../service/RealEstateService';
import { InputText } from 'primereact/inputtext';
import { withTranslation } from "react-i18next";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import ResponseAlert from '../../Utilities/ResponseAlert';

class Tenant extends Component {

	constructor(props) {
        super(props);
        this.state = {
            dataJson: {fullName: '', phoneNumber: '', email: 'N/A', cost: 0.0, project: {id: 0}, plotHouseNumber: '', areaSize: ''},
            dataId: 0,
            projects: [],
            failed: null,
            sendSms: false,
            alert: ''
        };
        this.realEstateService = new RealEstateService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handelEdit = this.handelEdit.bind(this);
	}
	
	componentDidMount() {
        if (this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        if (+this.props.match.params.dataId > 0) {
            this.setState({dataId: +this.props.match.params.dataId});
            this.realEstateService.getCustomer(+this.props.match.params.dataId)
            .then(data => {
                this.setState({dataJson: {id: data.id, fullName: data.fullName, phoneNumber: data.phoneNumber, email: data.email, cost: data.cost, project: {id: data.project.id}, plotHouseNumber: data.plotHouseNumber, areaSize: data.areaSize}});
            });
        }
        const jsonParams = { page: 0, limit: 100, sort: "id", order: 'asc' }
        this.realEstateService.getProjects(jsonParams)
        .then(data => {
            this.setState({projects: data.content});
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({
            dataJson: {
                ...prevJson.dataJson,sendSms: this.state.sendSms, [inputName] : inputValue
            }
        }));        
    }

    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state.dataJson)
        this.realEstateService.addCustomer(this.state.dataJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/real/estate/customers");
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

    handelEdit = (event) => {
        event.preventDefault();
        this.realEstateService.editCustomer(this.state.dataJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/real/estate/customers");
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/real/estate/customer/0",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <ResponseAlert failed={this.state.failed} alert={this.state.alert} />
                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>{this.state.dataId === 0 ? "New" : "Edit"} Customer</h2>
                            </div>
                            <form onSubmit={this.state.dataId === 0 ? this.handleSubmit : this.handelEdit}>
                                <div className="p-grid">
                                    <div className="p-md-offset-1 p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Customer Name:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="fullName" placeholder="Enter full name" value={this.state.dataJson.fullName} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Project:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.projects} value={this.state.dataJson.project.id} onChange={event => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, project: {id: event.value}}}))} optionLabel="projectName" optionValue="id" filter filterBy="projectName" placeholder="Choose project" autoWidth={false} />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-md-offset-1 p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Email:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="email" placeholder="Enter email" value={this.state.dataJson.email} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Phone Number:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="phoneNumber" placeholder="Enter phone number" value={this.state.dataJson.phoneNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-md-offset-1 p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Cost:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="cost" placeholder="Enter cost" value={this.state.dataJson.cost} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Plot / House Number:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="plotHouseNumber" placeholder="Enter plot / house number" value={this.state.dataJson.plotHouseNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-md-offset-1 p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Area Size:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="areaSize" placeholder="Enter area size" value={this.state.dataJson.areaSize} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Send Sms After Adding')}:</label>
                                        </div>
                                        
                                           
                                        <div className="p-col-12 p-md-12">
                                                    <RadioButton value={true} inputId="yes"  onChange={(e) => this.setState({sendSms: e.value})} checked={this.state.sendSms}/>
                                                    <label htmlFor="yes" className="p-radiobutton-label">{this.props.t('Yes')}</label>
                                                    <RadioButton value={false} inputId="no" onChange={(e) => this.setState({sendSms: e.value})} checked={!this.state.sendSms}/>
                                                    <label htmlFor="no" className="p-radiobutton-label">{this.props.t('No')}</label>
                                        </div>
                                             
                                    </div>
                                </div>
                               
                                {/* {id: data.id, project: {id: data.project.id}} */}
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={(this.state.dataId === 0 ? "Add" : "Edit") + " Customer"} icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}
export default withTranslation()(Tenant)