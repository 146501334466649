import React, { Component } from 'react';
// import {Chart} from 'primereact/chart';
// import ApexCharts from 'apexcharts';
// import ApexCharts from 'apexcharts'
import Chart from "react-apexcharts";
import { Link } from 'react-router-dom';
import { HomeService } from '../service/HomeService';
import { ClientService } from '../service/ClientService';
import NumberFormat from 'react-number-format';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Panel } from 'primereact/panel';
// import ReactECharts from 'echarts-for-react';
import { WalletService } from '../service/WalletService';
import { AdalipaUtil } from '../Utilities/AdalipaUtil';
import { ManagerialService } from '../service/ManagerialService';
import { Button } from 'primereact/button';
import { withTranslation } from 'react-i18next';

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            institutions: 0,
            alert:"",
            options: {
              },
              series: [],
            months: 6,
            top: 10,
            institutionsCount: 0,
            crisesCount: 0,
            barData: {
                labels: ['Tuition Fee', 'Transport Fee', 'Medical Fee', 'Stationery Fee', 'Graduation Fee', 'Tour Fee'],
                datasets: [
                    {
                        label: this.props.lang.t('Current Amount'),
                        backgroundColor: '#3B007B',
                        borderColor: '#3B007B',
                        data: [59, 80, 81, 56, 55, 40]
                    },
                    {
                        label: this.props.lang.t('Expected Amount'),
                        backgroundColor: '#E00000',
                        borderColor: '#E00000',
                        data: [48, 40, 19, 86, 27, 90]
                    }
                ]
            },
            pieData: {
                labels: ['2022-01', '2022-02', '2022-03', '2022-04','2022-05','2022-06'],
                datasets: [
                    {
                        data: [150, 200, 500, 300, 50, 100],
                        backgroundColor: ["#E00000", "#3B007B", "#03A9F4", "#C75952", "#807BE8", "#74C1D6"],
                        hoverBackgroundColor: ["#FFE082", "#81D4FA", "#A5D6A7", "#FFE082", "#81D4FA", "#A5D6A7"]
                    }]
            },
            topClients: [],
            crisesList: [],
            paymentsData: {
                income:[{name:"2022-01",value:0},{name:"2022-02",value:0},{name:"2022-03",value:0},{name:"2022-04",value:0},{name:"2022-05",value:0},{name:"2022-06",value:0}],
                month:["2022-01","2022-02","2022-03","2022-04","2022-05","2022-06"]
            },
            incomeData: {
                types:["2022-01","2022-02","2022-03","2022-04","2022-05","2022-06"],
                expected:[80,60,100,70,120,100],
                current:[50,60,100,15,90,150]
            }
        };
        this.homeService = new HomeService();
        this.clientService = new ClientService();
        this.waletService = new WalletService();
        this.adalipaUtil = new AdalipaUtil();
        this.managerialService = new ManagerialService();
        this.actionTemplate = this.actionTemplate.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.amountTemplateTwo = this.amountTemplateTwo.bind(this);
        this.amountTemplate = this.amountTemplate.bind(this);
    }

    authorisedRole(roleName) {
        let roles = JSON.parse(this.props.roles);
        const isPresent = roles.some(function(el) {
            return el.name === roleName;
        });
        return isPresent;
    }

    componentDidMount() {
        this.getInstitutions();
        this.getCrisesCount();
        this.getInstitutionsCount();
        this.homeService.getPaymentsAnalysis(this.state.months)
        .then(response => {
            this.setState({paymentsData: response});
        });
        if(!(this.authorisedRole("ADMIN") || this.authorisedRole("PARTNER") || this.authorisedRole("DISTRICT_MANAGER") || this.authorisedRole("ADMINISTRATION"))) {
            if (this.props.category === "Schools") {
                this.homeService.getFeeAnalysis()
                .then(response => {
                    this.setState({incomeData: response});
                });
            } else if (this.props.category === "Local Government Authority") {
                this.fetchData();
                this.homeService.getLgaBillsAnalysis(this.state.months)
                .then(response => {
                    this.setState({incomeData: response});
                });
            }
        }
        if(this.authorisedRole("ADMIN") || this.authorisedRole("PARTNER") || this.authorisedRole("DISTRICT_MANAGER") || this.authorisedRole("ADMINISTRATION")) {
            this.clientService.getTopClients(this.state.top)
            .then(response => {
                this.setState({topClients: response});
            });
            this.fetchData();
            this.getCrisesSummary();
        }
    }

    getInstitutionsCount=()=>{
        this.managerialService.getInsitutionsCount()
        .then(response => {
           this.setState({institutionsCount: response.institutions})
        })
        .catch(error => {
            this.setState({alert: error.message});
        })
    }

    getCrisesCount=()=>{
        this.managerialService.getCrisesCount()
        .then(response => {
           this.setState({crisesCount: response.crises})
        })
        .catch(error => {
            this.setState({alert: error.message});
        })
    }

    getCrisesSummary=()=>{
       this.managerialService.getCrisesSummary()
       .then(response => {
          this.setState({crisesList: response.content});
       })
       .catch(error => {
         this.setState({alert: error.message});
       })
    }

    async fetchData() {
        // const abortCont = new AbortController();
        try {
            let jsonParams;
           if(this.authorisedRole("ADMIN") || this.authorisedRole("ADMINISTRATION")){
                jsonParams = {
                duration: "monthly",
                
           }
        }

           if(this.authorisedRole("MANAGER") || this.authorisedRole("STAFF") || this.authorisedRole("AGENT")){
            jsonParams = {
                duration: "monthly",
                institutionId: this.props.instituteId,
                
            }

           }
            
          
          const response = await this.waletService.geRevenueSummary(jsonParams);
          // Process the returned Balance data
          let paidAmtRev = response.map(data => (
           {x: data.trxnMonth,
            y: data.paidAmount
           } 
            ));       
               
          console.log("Paid Amount List",paidAmtRev);

          const dviChargesRev = response.map(data => ({
            x: data.trxnMonth,
            y: data.dviCharges
            
          }));

          const instChargesRev = response.map(data => ({
            x: data.trxnMonth,
            y: data.instCharges
            
          }));

          const mlipaCharges = response.map(data => ({
            x: data.trxnMonth,
            y: data.mlipaCharges
          }));

        //   this.setState({paidAmtRev:paidAmtRev});
        //   this.setState({instChargesRev:instChargesRev});
        //   this.setState({dviChargesRev:dviChargesRev});  

        if(this.authorisedRole("ADMIN") || this.authorisedRole("ADMINISTRATION")){
          
          this.setState({series:[
            {
                type: 'line',
                name: this.props.t('Collected Amount'),
                data: paidAmtRev
            },{
                type: 'line',
                name: this.props.t('Adalipa Charges'),
                chart: {
                    dropShadow: {
                        enabled: true,
                        enabledOnSeries: undefined,
                        top: 5,
                        left: 0,
                        blur: 3,
                        color: '#000',
                        opacity: 0.1
                    }
                },
                data: dviChargesRev
            },
            {
                type: 'area',
                name: this.props.t('Mlipa Charges'),
                chart: {
                    dropShadow: {
                        enabled: true,
                        enabledOnSeries: undefined,
                        top: 5,
                        left: 0,
                        blur: 3,
                        color: '#000',
                        opacity: 0.1
                    }
                },
                data: mlipaCharges
            }
          ]});
        }

        if(this.authorisedRole("MANAGER") || this.authorisedRole("STAFF") || this.authorisedRole("AGENT")){
            this.setState({series:[
                {
                    type: 'line',
                    name: this.props.t('Collected Amount'),
                    data: paidAmtRev
                }
                
              ]});
        }

          this.setState({options: { 
   
            //data on the x-axis
        
            chart: {
                id: 'salesChart',
                height: 350,
                animations: {
                    speed: 500
                },
                dropShadow: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    top: 8,
                    left: 0,
                    blur: 3,
                    color: '#000',
                    opacity: 0.1
                },
            },
            colors: ["rgb(132, 90, 223)", "rgba(35, 183, 229, 0.85)", "rgba(119, 119, 142, 0.05)"],
            dataLabels: {
                enabled: false
            },
            grid: {
                borderColor: '#f1f1f1',
                strokeDashArray: 3
            },
            stroke: {
                curve: 'smooth',
                width: [2, 2, 0],
                dashArray: [0, 5, 0],
            },
            xaxis: {
                axisTicks: {
                    show: false,
                },
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return "TZS" + value;
                    }
                },
            },
            tooltip: {
                y: [{
                    formatter: function(e) {
                        return void 0 !== e ? "TZS" + e.toFixed(0) : e
                    }
                }, {
                    formatter: function(e) {
                        return void 0 !== e ? "TZS" + e.toFixed(0) : e
                    }
                }, {
                    formatter: function(e) {
                        return void 0 !== e ? "TZS" + e.toFixed(0) : e
                    }
                }]
            },
            legend: {
                show: true,
                customLegendItems: [this.props.t('Collected Amount'), this.props.t('Adalipa Charges'), this.props.t('Mlipa Charges')],
                inverseOrder: true
            },
            title: {
                text: 'Revenue Analytics with sales & profit (TZS)',
                align: 'left',
                style: {
                    fontSize: '.8125rem',
                    fontWeight: 'semibold',
                    color: '#8c9097'
                },
            },
            markers: {
                hover: {
                    sizeOffset: 5
                }
            }
          }});
          
        } catch (error) {
          // Handle any errors that occur during the fetch
          console.log(error.message);
        }
      };
  
      

    getInstitutions(){
        this.clientService.institutionsCount({business_type: "Local Government Authority"})
        .then(response => {
            // console.log("Institutions:",response);
            this.setState({institutions: response});
        }).catch(error => {
            this.setState({failed: true});
        });
    }

 

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    amountTemplateTwo(rowData) {
        
            return this.adalipaUtil.amountFormat(rowData);
        
       
    }

    handleSubmit(event) {
        event.preventDefault();
        this.homeService.getPaymentsAnalysis(this.state.months)
        .then(response => {
            this.setState({paymentsData: response});
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }
    
    amountTemplate(rowData, column) {
        return <span><NumberFormat value={rowData['amount']} displayType={'text'} thousandSeparator={true} prefix={'TZS'} />/=</span>
    }
    onDataViewClick() {
        this.props.history.push("/wallet/lga/reports/institution/list");
    }
    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
            {/* <Link to={"/wallet/lga/house/holder/details/" + rowData["id"]}><Button type="button" icon="pi pi-eye" className="p-button p-button-sm p-button-info" style={{marginRight: '.2em', fontSize: "10px"}} title={this.props.t("View House Holder Details")}/></Link> */}
            <Link to={"/wallet/lga/crises/" + rowData["institutionId"]}><Button type="button" icon="pi pi-eye" className="p-button p-button-sm p-button-success" style={{marginRight: '.2em', fontSize: "10px"}} title={this.props.t("View Crises By Istitution")}/></Link>
            {/* {!this.props.roles.includes("DISTRICT_MANAGER") && <Link to={"/wallet/lga/house/holder/" + rowData["id"]}><Button type="button" icon="pi pi-pencil" className="p-button p-button-sm p-button-warning" style={{marginRight: '.2em', fontSize: "10px"}} title={this.props.t("Edit House Holder")}/></Link>} */}
            {!this.props.roles.includes("DISTRICT_MANAGER") && <Button type="button"onClick={() => this.onDeleteButtonClick(rowData)} icon="pi pi-trash" className="p-button p-button-sm p-button-danger" style={{fontSize: "10px"}}/>}
        </div>;
    }

    render(){

        const payments = {
            title : {
              text: this.props.lang.t('Collections'),
              subtext: this.props.lang.t(''),
              x:'center'
            },
            tooltip : {
              trigger: 'item',
              formatter: "{a} <br/>{b} : {c} ({d}%)"
            },
            legend: {
              orient: 'horizontal',
              left: 'center',
              top: 'bottom',
              data: this.state.paymentsData.month
            },
            toolbox: {
                show: true,
                feature: {
                    mark: {show: true},
                    restore: {
                        show: true,
                        title: 'refresh'
                    },
                    saveAsImage: {
                        show: true,
                        title: 'export'
                    }
                }
            },
            series : [
              {
              name: 'TZS',
              type: 'pie',
              radius : '55%',
              center: ['50%', '50%'],
              data: this.state.paymentsData.income,
              itemStyle: {
                emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0,161,75, 0.5)'
                }
              }
              }
            ]
        };

        const income = {
            tooltip: {},
            legend: {
              data:[this.props.lang.t('Expected Income'), this.props.lang.t('Current Income')]
            },
            toolbox: {
                show: true,
                feature: {
                    mark: {show: true},
                    saveAsImage: {
                        show: true,
                        title: 'export'
                    },
                    restore: {
                        show: true,
                        title: 'refresh'
                    },
                    magicType: {show: true, title: 'change', type: ['line', 'bar']}
                }
            },
            xAxis: {
                axisLabel: {
                    interval: 0,
                    rotate: 30,
                    textStyle: {
                      baseline: "top",
                      color: "#3B007B",
                      fontSize: 12,
                      fontWeight: "bold"
                    }
                },
                data: this.state.incomeData?.types || this.state.incomeData.fee
            },
            yAxis: {
                axisLabel: {
                    rotate: 45
                }
            },
            series: [
                {
                    name: this.props.lang.t('Expected Income'),
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: 'rgba(57,163,244,1)',
                            label: {
                                show: true,
                                rotate: 60,
                                textStyle: {color: '#FFFFFF'}
                            }
                        }
                    },
                    data: this.state.incomeData.expected
                },
                {
                    name: this.props.lang.t('Current Income'),
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: 'rgba(59,0,123,1)',
                            label: {
                                show: true,
                                rotate: 60,
                                textStyle: {color: '#FFFFFF'}
                            }
                        }
                    },
                    data: this.state.incomeData.current
                }
            ]
          }; 
          
      
        
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        return <div className="p-grid dashboard">

            {this.authorisedRole("DISTRICT_MANAGER") && <div className="p-col-12 p-md-4">
                <div className="overview-box overview-box-main"><h1>{this.props.lang.t('TOTAL COLLECTION')}</h1>
                    <div className="overview-value">
                        <NumberFormat value={JSON.parse(this.props.headerBalances).Fee} displayType={'text'} thousandSeparator={true} prefix={'TZS'} />/=
                    </div>
                </div>
                
            </div>}

            {this.authorisedRole("DISTRICT_MANAGER") && <div className="p-col-12 p-md-4">
                <div className="overview-box overview-box-oc">
                    <h1>{this.props.lang.t('NUMBER OF INSTITUTIONS')}</h1>
                    <div className="overview-value">
                        {this.state.institutionsCount}
                    </div>
                </div>
            </div>}

            {this.authorisedRole("DISTRICT_MANAGER") && <div className="p-col-12 p-md-4">
                <div className="overview-box overview-box-sms">
                    <h1>{this.props.lang.t('NUMBER OF CRISES')}</h1>
                    <div className="overview-value">
                        {this.state.crisesCount}
                    </div>
                </div>
            </div>}



            {!this.authorisedRole("ADMINISTRATION") && !this.authorisedRole("DISTRICT_MANAGER") && <div className="p-col-12 p-md-4">
                <div className="overview-box overview-box-main"><h1>{this.props.lang.t('MAIN BALANCE')}</h1>
                    <div className="overview-value">
                        <NumberFormat value={JSON.parse(this.props.headerBalances).Fee} displayType={'text'} thousandSeparator={true} prefix={'TZS'} />/=
                    </div>
                </div>
            </div>}
            {!this.authorisedRole("ADMINISTRATION") && !this.authorisedRole("DISTRICT_MANAGER") && <div className="p-col-12 p-md-4">
                <div className="overview-box overview-box-oc">
                    <h1>{this.props.lang.t('OTHER COLLECTIONS')}</h1>
                    <div className="overview-value">
                        <NumberFormat value={JSON.parse(this.props.headerBalances).OCs} displayType={'text'} thousandSeparator={true} prefix={'TZS'} />/=
                    </div>
                </div>
            </div> }

           
           {/* () if(this.authorisedRole("ADMIN") || this.authorisedRole("PARTNER") || this.authorisedRole("DISTRICT_MANAGER")) { */}
            { !(this.authorisedRole("DISTRICT_MANAGER")) && !this.authorisedRole("ADMINISTRATION") &&
            <div className="p-col-12 p-md-4">
                <div className="overview-box overview-box-sms">
                    <h1>{this.props.lang.t('SMS BALANCE')}</h1>
                    <div className="overview-value">
                        <NumberFormat value={JSON.parse(JSON.parse(this.props.headerBalances).Sms).sms} displayType={'text'} thousandSeparator={true} prefix={''} /> Messages
                    </div>
                </div>
            </div> }
            {/* { (this.authorisedRole("DISTRICT_MANAGER")) && 
            <div className="p-col-12 p-md-4" onClick={()=>{console.log("I was clicked")}}>
                <div className="overview-box overview-box-main"><h1>{this.props.lang.t('INSTITUTIONS')}</h1>
                    <div className="overview-value">
                    <Link to="#" onClick={() => this.onDataViewClick()}>
                       <strong style={{color: '#FFF'}}> <NumberFormat value={JSON.parse(this.state.institutions)} displayType={'text'} thousandSeparator={true}  /></strong>
                        </Link>                        
                    </div>
                </div>
            </div>  } */}
  

           {!this.authorisedRole("DISTRICT_MANAGER") &&  <div className="p-col-12 p-md-9">
                {/* <div className="card">
                    <h2 className="centerText">{this.props.lang.t('Payments Analysis')}</h2>
                    <Chart type="pie" data={this.state.pieData} height="150"/>
                </div> */}
                <Panel header={this.props.lang.t("Monthly Transactions Chart")} style={{width: "100%", height: "100%"}}>
                    {/* <ReactECharts option={payments} style={{ height: 400 }} /> */}
                    <Chart series={this.state.series} options={this.state.options} type="line"/>
                </Panel>
            </div>}

            { !(this.authorisedRole("ADMIN") || this.authorisedRole("PARTNER") || this.authorisedRole("DISTRICT_MANAGER") || this.authorisedRole("ADMINISTRATION")) &&
                <div className="p-col-12 p-md-9">
                    <Panel header={this.props.lang.t("Income Statistics")} className="disk-spaces" style={{height: "100%"}}>
                        {/* <ReactECharts option={income} style={{ height: 400 }} opts={{ renderer: 'svg' }} /> */}
                    </Panel>
                </div>
            }

            { (this.authorisedRole("ADMIN") || this.authorisedRole("PARTNER") || this.authorisedRole("ADMINISTRATION")) &&
                <div className="p-col-12 p-md-3" >
                    <div className="card">
                        <h2 className="centerText">Top {this.state.top} {this.authorisedRole("DISTRICT_MANAGER") ? this.props.lang.t('Institutions By Collections') : this.props.lang.t('Clients By Collections')} </h2>
                        <DataTable ref={(el) => this.dt = el} value={this.state.topClients} selectionMode="single" paginator={true} rows={10} responsive={true}>
                            <Column field="name" header="Client" sortable={false} filter={false}/>
                            <Column field="amount" body={this.amountTemplate} header="Collections" sortable={false} filter={false}/>
                        </DataTable>
                    </div>
                </div>
            }


{ (this.authorisedRole("DISTRICT_MANAGER")) &&
           <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h2 className="centerText">{this.props.districtName} { this.props.lang.t('Crises Summary')} </h2>
                        <DataTable ref={(el) => this.dt = el} value={this.state.crisesList} selectionMode="single" paginator={true} rows={10} responsive={true}>
                            <Column field="institutionName" header="Institution Name" sortable={false} filter={false}/>
                            <Column field="crisesCount"  header="Number of Crises" sortable={false} filter={false}/>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', fontSize: 'small', width: '8em'}}/>
                        </DataTable>
                    </div>
                </div>
            </div>
            }
        </div>
    }
}

export default withTranslation()(HomePage);
