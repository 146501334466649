import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

export const ResetInfo = () => {

    const history = useHistory();

    const resetPassword = () => {
        history.push("/reset/password");
    }

    const goToLogin = () => {
        history.push("/login");
    }

	return (
        <div className="p-grid" style={{height: "56.5em", backgroundColor: "#f5f5f5", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "100% 100%", overflow: "hidden"}}>
            <div className="login-wrapper" style={{overflow: "hidden", width: "80%", marginLeft: "10%"}}>
                <div className="login-container" style={{border: "solid #3B007B", marginTop: "10em", paddingTop: "2em", backgroundColor: "#ffffff"}}>
                    <img src="assets/layout/images/adalipa.png" alt="AdaLipa" className="logo" style={{padding: "0px", width: "8em"}} />
                    <div className="p-message p-component p-message-success" style={{ display: 'block', margin: "2em" }}>
                        <div className="p-message-wrapper">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <span className="p-message-text">Please visit your email or your text messages to <Link to="#" onClick={resetPassword} style={{color: "#3B007B", fontWeight: "bold"}}> Rest password here</Link></span>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'block', margin: "2em" }}>
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <Button type="button" label="Back to Login" onClick={()=>goToLogin()} icon="pi pi-fw pi-arrow-circle-left"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	)
}
