import { CONTRIBUTION, CONTRIBUTION_GROUP, CONTRIBUTION_MEMBER, OTHER_BUSINESS, OTHER_BUSINESS_BUYER } from '../api';
import { API_BASE_URL, ADALIPA_BASE_URL } from '../constants';
import { postRequest, getRequest,downloadGetRequest, queryParams, postFiles, getRequestWithSignal } from './CoreService';

export class WalletService {

    geRevenueSummary = (jsonParams) => {
        return getRequest({
            url: API_BASE_URL + "/analytics/revenue" + queryParams(jsonParams),
            // url: API_BASE_URL + "/analytics/balance",
            method: 'GET'
        });
    }

    getAllTransactions = (jsonParams) => {
        return getRequest({
            url: API_BASE_URL + "/analytics/transactions" + queryParams(jsonParams),
            // url: API_BASE_URL + "/analytics/balance",
            method: 'GET'
        });
    }

    getDailyTransactions = (jsonParams) => {
        return getRequest({
            url: API_BASE_URL + "/analytics/daily/transactions"+ queryParams(jsonParams),
            // url: API_BASE_URL + "/analytics/balance",
            method: 'GET'
        });
    }

    getTotalInsttutionalCharges = (jsonParams,signal) => {
        return getRequestWithSignal({
            url: API_BASE_URL + "/analytics/charges" + queryParams(jsonParams),
            // url: API_BASE_URL + "/analytics/balance",
            method: 'GET',
            signal:signal

        });
    }

    addCollection(collectionJson) {
        return postRequest({
            url: API_BASE_URL + "/collection/add/collection",
            method: 'POST',
            body: JSON.stringify(collectionJson)
        });
    }
    fundsTransfer(fundsJson) {
        return postRequest({
            url: API_BASE_URL + "/collection/funds/transfer",
            method: 'POST',
            body: JSON.stringify(fundsJson)
        });
    }
    getCollections(walletName, walletCode, walletAccount, page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/collection/get/collections?walletName=" + walletName + "&walletCode=" + walletCode + "&walletAccount=" + walletAccount + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }
    getCollectionBalances(account, page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/collection/get/collection/balance?account=" + account + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }
    getCollectionFees(mobileNumber, receipt, state, status, page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/collection/get/collection/fees?mobileNumber=" + mobileNumber + "&receipt=" + receipt + "&state=" + state + "&status=" + status + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    getTransactions(jsonParams,institution) {
        var url = "/fee/none/lga/transactions";
        if(institution === "Local Government Authority"){
             url = "/fee/transactions";
        }
        return getRequest({
            url: API_BASE_URL + url + queryParams(jsonParams),
            method: 'GET'
        });
    }

    

    // getNoneLgaTransactions(jsonParams) {
    //     return getRequest({
    //         url: API_BASE_URL + "/fee/none/lga/transactions" + queryParams(jsonParams),
    //         method: 'GET'
    //     });
    // }

    getTransaction(dataId) {
        return getRequest({
            url: API_BASE_URL + "/fee/transactions/" + dataId,
            method: 'GET'
        });
    }

    getSummaryTransacions(jsonParams){
        return getRequest({
            url: API_BASE_URL + "/fee/cell/monthly/transactions/" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getMonthlyPayments(jsonParams){
        return getRequest({
            url: API_BASE_URL + "/lga/house/holder/monthly-transactions" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getTotalMonthlyCollections(jsonParams){
        return getRequest({
            url: API_BASE_URL + "/lga/house/total/monthly-transactions" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getInstitutionPayments(jsonParams){
        return getRequest({
            url: API_BASE_URL + "/lga/house/holder/institution-transactions" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getTotalInstitutionPayments(jsonParams){
        return getRequest({
            url: API_BASE_URL + "/lga/house/total/institution-transactions" + queryParams(jsonParams),
            method: 'GET'
        });
    }
    

    getRoadSummaryTransacions(jsonParams){
        return getRequest({
            url: API_BASE_URL + "/fee/road/monthly/transactions/" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    // Balance Api
    createAccount(balanceJson) {
        return postRequest({ url: API_BASE_URL + "/balance/create/account", method: 'POST', body: JSON.stringify(balanceJson) });
    }

    updateAccount(balanceJson) {
        return postRequest({ url: API_BASE_URL + "/balance/update/account", method: 'POST', body: JSON.stringify(balanceJson) });
    }

    getWithdrawalAccount() {
        return getRequest({ url: API_BASE_URL + "/balance/withdrawal/account", method: 'GET' });
    }

    createOtherAccount(balanceJson) {
        return postRequest({ url: API_BASE_URL + "/balance/other/withdrawal/account", method: 'POST', body: JSON.stringify(balanceJson) });
    }

    updateOtherAccount(balanceJson) {
        return postRequest({ url: API_BASE_URL + "/balance/other/withdrawal/account", method: 'PUT', body: JSON.stringify(balanceJson) });
    }

    getOtherWithdrawalAccount() {
        return getRequest({ url: API_BASE_URL + "/balance/other/withdrawal/account", method: 'GET' });
    }

    createSettlement(settlementJson) {
        return postRequest({
            url: API_BASE_URL + "/balance/create/settlement",
            method: 'POST',
            body: JSON.stringify(settlementJson)
        });
    }

    createCashSettlement(paymentReferenceJson) {
        return postRequest({
            url: API_BASE_URL + "/cash/",
            method: 'PUT',
            body: JSON.stringify(paymentReferenceJson)
        });
    }

    createExtraChargesSettlement(settlementJson,chargesType) {
        var url = "/balance/create/dvi/extra_charges/settlement";
        if(chargesType === "INSTITUTE_CHARGES"){
             url = "/balance/create/institute/extra_charges/settlement";
        }
        return postRequest({
            url: API_BASE_URL + url,
            method: 'POST',
            body: JSON.stringify(settlementJson)
        });
    }

    createCashCollection(collectionJson) {       
        return postRequest({
            url: API_BASE_URL + "/cash/",
            method: 'POST',
            body: JSON.stringify(collectionJson)
        });
    }

    createPushUssdCollection(collectionJson) {       
        return postRequest({
            url: API_BASE_URL + "/push/ussd/",
            method: 'POST',
            body: JSON.stringify(collectionJson)
        });
    }

    getSettlements(jsonParams) {
        return getRequest({ url: API_BASE_URL + "/balance/settlements" + queryParams(jsonParams), method: 'GET' });
    }

    getCashCollections(jsonParams){
        return getRequest({ url: API_BASE_URL + "/cash/" + queryParams(jsonParams), method: 'GET' }); 
    }
    getCashCollectionStatus(jsonParams){
        return getRequest({ url: API_BASE_URL + "/cash/collection/status" + queryParams(jsonParams), method: 'GET' }); 
    }

    getPushUssdCollections(jsonParams){
        return getRequest({ url: API_BASE_URL + "/push/ussd/" + queryParams(jsonParams), method: 'GET' }); 
    }

    // paymentPlan Configurations

    getPaymentPlanList(institutionId,page, limit, sort, order){
        return getRequest({
            url: API_BASE_URL + "/lga/payment/plan/institution/?institutionId="+institutionId+"&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    getPaymentPlans(page, limit, sort, order){
        return getRequest({
            url: API_BASE_URL + "/lga/payment/plan/?page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    getBeneficialRateList(institutionId,page, limit, sort, order){
        return getRequest({
            url: API_BASE_URL + "/rates/?institutionId="+institutionId+"&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    // Contributions
    group(groupJson, method) {
        return postRequest({ url: CONTRIBUTION_GROUP, method: method, body: JSON.stringify(groupJson) });
    }
    groupInfo(groupId, method) {
        return postRequest({ url: CONTRIBUTION_GROUP + "?groupId=" + groupId, method: method });
    }
    getGroups(jsonParams) {
        return getRequest({ url: CONTRIBUTION + "/groups" + queryParams(jsonParams), method: 'GET' });
    }

    deactivateGroup(groupId, method){
        return postRequest({ url: CONTRIBUTION_GROUP + "/deactivate?groupId=" + groupId, method: method });
    }

    member(memberJson, method) {
        return postRequest({
            url: CONTRIBUTION_MEMBER,
            method: method,
            body: JSON.stringify(memberJson)
        });
    }
    addMemberBatch(excelFile, groupId, sendSms) {
        const formData = new FormData();
        formData.append('file', excelFile);
        formData.append('groupId', groupId);
        formData.append('sendSms', sendSms);
        return postFiles({
            url: CONTRIBUTION_MEMBER + "/batch",
            method: 'POST',
            body: formData
        });
    }
    memberInfo(memberId, method) {
        return postRequest({
            url: CONTRIBUTION_MEMBER + "?memberId=" + memberId,
            method: method
        });
    }
    getMembers(jsonParams) {
        return getRequest({
            url: CONTRIBUTION + "/members" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getNonContributedMembers(jsonParams) {
        return getRequest({
            url: CONTRIBUTION + "/non-contributed/members" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getMemberContributionHistory(jsonParams){
        return getRequest({
            url: CONTRIBUTION + "/member/history" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getGroupContributionHistory(jsonParams){
        return getRequest({
            url: CONTRIBUTION + "/group/history" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    reallocateMembers(memberJson,method){
        return postRequest({
            url: CONTRIBUTION+"/reallocate",
            method: method,
            body: JSON.stringify(memberJson)
        }); 
    }

    deactivateMember(memberJson, method) {
        return postRequest({
            url: CONTRIBUTION+"/member/deactivate",
            method: method,
            body: JSON.stringify(memberJson)
        });
    } 
    
    updateMemberContrbutionStatus(jsonParams){
        return postRequest({
            url: CONTRIBUTION+"/update/member/contribution",
            method: "PUT",
            body: JSON.stringify(jsonParams)
        });

    }

    getContributions(groupId, memberId, memberCode, paymentId, page, limit, sort, order) {
        return getRequest({
            url: CONTRIBUTION + "/list?groupId=" + groupId + "&memberId=" + memberId + "&memberCode=" + memberCode + "&paymentId=" + paymentId + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    // Other Businesses
    business(buyerJson, method) {
        return postRequest({
            url: OTHER_BUSINESS,
            method: method,
            body: JSON.stringify(buyerJson)
        });
    }
    businessInfo(customerId, method) {
        return postRequest({
            url: OTHER_BUSINESS + "/" + customerId,
            method: method
        });
    }
    getBusinesses(business, description, page, limit, sort, order) {
        return getRequest({
            url: OTHER_BUSINESS + "?business=" + business + "&description=" + description + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }
    buyer(buyerJson, method) {
        return postRequest({
            url: OTHER_BUSINESS_BUYER,
            method: method,
            body: JSON.stringify(buyerJson)
        });
    }
    buyerInfo(customerId, method) {
        return postRequest({
            url: OTHER_BUSINESS_BUYER + "?customerId=" + customerId,
            method: method
        });
    }
    getCustomers(businessId, customerName, phoneNumber, paymentId, page, limit, sort, order) {
        return getRequest({
            url: OTHER_BUSINESS + "/customers?businessId=" + businessId + "&customerName=" + customerName + "&phoneNumber=" + phoneNumber + "&paymentId=" + paymentId + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }
    getArchivedCustomers(businessId, customerName, phoneNumber, paymentId, page, limit, sort, order) {
        return getRequest({
            url: OTHER_BUSINESS + "/customers?businessId=" + businessId + "&customerName=" + customerName + "&phoneNumber=" + phoneNumber + "&paymentId=" + paymentId + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order + "&archived=true",
            method: 'GET'
        });
    }
    getCustomerTransactions(businessId, accountId, phoneNumber, receiptNumber, client, customer, reqTimeFrom, reqTimeTo, page, limit, sort, order) {
        return getRequest({
            url: OTHER_BUSINESS + "/transactions?businessId=" + businessId + "&accountId=" + accountId + "&phoneNumber=" + phoneNumber + "&receiptNumber=" + receiptNumber + "&client=" + client + "&customer=" + customer + "&reqTimeFrom=" + reqTimeFrom + "&reqTimeTo=" + reqTimeTo + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }


    exportJasperPdf(jsonParams,fileName,reportCategory){
        if(reportCategory === "MONTHLY_INSTITUTION_REPORT"){          
            return downloadGetRequest({
                url: API_BASE_URL + "/lga/house/holder/monthly-transactions/report/"+ fileName + queryParams(jsonParams),
            method: 'GET'
            });
            
        }else if(reportCategory === "LGA_ANALYTICS_REPORT"){
            return downloadGetRequest({
                url: API_BASE_URL + "/lga/house/holder/analytics/report/"+ fileName + queryParams(jsonParams),
            method: 'GET'
            });

        }else if(reportCategory === "LGA_GENERAL_ANALYTICS_REPORT"){
            return downloadGetRequest({
                url: API_BASE_URL + "/lga/house/general/analytics/report/"+ fileName + queryParams(jsonParams),
            method: 'GET'
            });

        }else{
            return downloadGetRequest({
                url: API_BASE_URL + "/lga/house/holder/institution-transactions/report/"+ fileName + queryParams(jsonParams),
            method: 'GET'
            });                      
        }        
    }
}