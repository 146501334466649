import React,{ Component } from "react";
import { WalletService } from "../../service/WalletService";
import { ClientService } from "../../service/ClientService";
import { HomeService } from "../../service/HomeService";
import { Redirect } from 'react-router-dom';
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { LgaService } from "../../service/LgaService";
import { withTranslation } from "react-i18next";

class CollectCash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
            collectedAmount: 0,
            collectionPrompt: this.props.t("Collect Cash Payments"),
            collectionTypeSelected: false,
            tenantSelected: false,
            collectionSelected: '',
            collectionType: "choose",
            collectionTypes: [{label: this.props.t("House Holder Cash Payment"),value: "HOUSE HOLDER CASH PAYMENT"},{label: this.props.t("Tenants Cash Payment"),value: "TENANTS CASH PAYMENT"},{label: this.props.t("Hotel Cash Payment"),value: "HOTEL CASH PAYMENT"},{label: this.props.t("Others Cash Payment"),value: "OTHERS CASH PAYMENT"},{label: this.props.t("Bar Cash Payment"),value: "BAR CASH PAYMENT"},{label: this.props.t("Choose payment Types"),value: "choose"}],
            institutions: [],
            tenants: [],
            houseHolders: [],
            institutionId: null,
            tenantId: null,
            houseHolderId: null,
            phoneNumber: null,
            details: "",
            alert: '',
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            sortOrder: -1,
            sortField: 'id',
            regionId: null,
            categoryId: null,
            categories: [],
            partnerId: null,
            instituteName: '',
            instituteCode: '',
            mlipaAccount: '',  
        }
        this.walletService = new WalletService();
        this.clientService = new ClientService();
        this.homeService = new HomeService();
        this.lgaService = new LgaService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleHouseHolderChanges = this.handleHouseHolderChanges.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCollectionTypes = this.handleCollectionTypes.bind(this);
        this.handleTenantChanges = this.handleTenantChanges.bind(this);
    }

    componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        //this.fetchData();      
    }    

    async  fetchHouseHolderData() {
        try {            
          const response = await this.lgaService.getAllHouseHolders(this.props.instituteId,this.state.pageIndex, this.state.pageSize, this.state.sortField, 'asc');
          const option = response.content.map((d,i) => ({
            "value": d.reference,
            "label": d.reference + "-"+d.name
          }))
          this.setState({houseHolders: option});
          this.setState({collectionSelected: "HOLDERS"});
        } catch (error) {
          this.setState({alert: error.message});
          console.error('Error fetching data:', error);
        }
      }


      async  fetchTenatsData() {
        try {            
          const response = await this.lgaService.getAllTenants(this.props.instituteId,this.state.pageIndex, this.state.pageSize, this.state.sortField, 'asc');
          const option = response.content.map((d,i) => ({
            "value": d.reference,
            "label": d.reference + "-"+d.fullName
          }))
          this.setState({tenants: option});
          this.setState({collectionSelected: "TENANTS"});
        } catch (error) {
          this.setState({alert: error.message});
          console.error('Error fetching data:', error);
        }
      }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        const collectionJson = {
            collectedAmount: this.state.collectedAmount,
            details: this.state.details,
            tenantId: this.state.tenantId,
            houseHolderId:this.state.houseHolderId,
            phoneNumber: this.state.phoneNumber,
            collectionType: this.state.collectionType
        };

        if(this.state.collectionType !== "choose"){          
                this.walletService.createCashCollection(collectionJson)
                .then(response => {
                    this.setState({failed: false});
                    this.setState({alert: response.message});
                    this.props.history.push("/wallet/cashcollections/0");
                }).catch(error => {
                    this.setState({failed: true});
                    this.setState({alert: error.message});
                });         

        }else{

            this.setState({failed: true});
            this.setState({alert: "Cash Collection Failed, Invalid Collection Type selected"});
        }        
	}

    handleCollectionTypes(event){
        const value = event.target.value;
        this.setState({collectionType: value});
        if(value !== "choose"){
            this.setState({collectionTypeSelected:true});
            this.state.collectionTypes.map(data => {
                if(data.value === value){
                    this.setState({collectionPrompt: this.props.t("Collect") +" "+data.label});
                    if(data.value === "TENANTS CASH PAYMENT"){
                       this.fetchTenatsData();                       
                    }

                    if(data.value === "HOUSE HOLDER CASH PAYMENT"){
                        this.fetchHouseHolderData();
                    }

                }                
            });
            
        }else{
            this.setState({collectionTypeSelected:false}); 
        }        
    }

    handleTenantChanges(event){
        const value = event.target.value;
        this.setState({tenantId: value});
        this.setState({houseHolderId: "0"});
        if(value !== null){
          this.setState({tenantSelected: true});
        }else{
            this.setState({tenantSelected: false});
        }
    }

    handleHouseHolderChanges(event){
        const value = event.target.value;
        this.setState({tenantId: "0"});
        this.setState({houseHolderId: value});
        if(value !== null){
          this.setState({tenantSelected: true});
        }else{
            this.setState({tenantSelected: false});
        }
    }
    
    render() { 
        if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/cashcollections/0",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        { this.state.failed === true &&
                            <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                                    <div className="p-messages-wrapper rounded">
                                        <ul>
                                            <li>
                                                <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                                <span className="p-messages-detail">{this.state.alert}</span>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        }

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF", padding: "1%"}}>
                                <h2 style={{textAlign: "center"}}>{this.props.t(this.state.collectionPrompt)}</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>

                             <div className="p-grid">
                            <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Collection Type')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.collectionTypes} value={this.state.collectionType} onChange={this.handleCollectionTypes} required/>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Reference Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                             {this.state.collectionSelected === "TENANTS" ?<Dropdown options={this.state.tenants} value={this.state.tenantId} onChange={this.handleTenantChanges} filter={true} required/>:<Dropdown options={this.state.houseHolders} value={this.state.houseHolderId} onChange={this.handleHouseHolderChanges} filter={true} required/> }
                                        </div>
                                    </div>

                            </div>

                            

                           {this.state.collectionTypeSelected && this.state.tenantSelected && <div><div className="p-grid">
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Collection Amount')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputNumber type="text" name="collectedAmount" placeholder={this.props.t("Enter") + " " + this.props.t("Amount")} value={this.state.collectedAmount} mode="decimal" minFractionDigits={2} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Phone Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="phoneNumber" placeholder={this.props.t("Enter") + " " + this.props.t("Phone Number")} value={this.state.phoneNumber}  onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Collection Details')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="details" placeholder={this.props.t("Enter") + " " + this.props.t("Details")} value={this.state.details} mode="decimal" minFractionDigits={2} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={this.props.t("Confirm Collection")} icon="pi pi-plus" />
                                    </div>
                                </div></div>} 

                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default withTranslation()(CollectCash);
