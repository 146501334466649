import React, { Component } from 'react';
import { ClientService } from '../service/ClientService';
import { HomeService } from '../service/HomeService';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { Paginator } from 'primereact/paginator';

export class ListManagers extends Component{
    constructor(){
        super();
        this.state = {
            dataTableValue:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            sortOrder: -1,
            sortField: 'id',
            regionId: null,
            districtId: null,
            regions: [],
            districts: [],
            categoryId: null,
            categories: [],
            partnerId: null,
            partners: [],
            phoneNumber: '',
            username: '',
            fullName: '',
            failed: null,
            alert: ''
        }

        this.homeService = new HomeService();
        this.clientService = new ClientService();
        this.updateClientActivation = this.updateClientActivation.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onPartnerChange = this.onPartnerChange.bind(this);
        this.onDistrictChange = this.onDistrictChange.bind(this);
        this.onRegionChange = this.onRegionChange.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
    }
    getClientsSearch() {
        const order = this.state.sortOrder === 1 ? 'asc' : 'desc';
        let searchStr = "?";
        searchStr = searchStr.concat("phoneNumber=").concat(this.state.instituteName);
        searchStr = searchStr.concat("&instituteCode=").concat(this.state.instituteCode);
        searchStr = searchStr.concat("&mlipaAccount=").concat(this.state.mlipaAccount);
        searchStr = searchStr.concat("&pageIndex=").concat(this.state.pageIndex);
        searchStr = searchStr.concat("&pageSize=").concat(this.state.pageSize);
        searchStr = searchStr.concat("&sortField=").concat(this.state.sortField);
        searchStr = searchStr.concat("&order=").concat(order);
        return searchStr;
    }

    componentDidMount() {
        this.clientService.getManagers(this.state.phoneNumber, this.state.username, this.state.fullName, this.state.pageIndex, this.state.pageSize, this.state.sortField, 'asc')
        .then(data => {
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
        this.homeService.getRegions('', 0, 100, 'id', 'asc')
        .then(response => {
            const data = response.content;
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.regionName
            }))
            this.setState({regions: options});
        });
        this.homeService.getCategories('', 0, 100, 'id', 'asc')
        .then(response => {
            const data = response.content;
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.categoryName
            }))
            this.setState({categories: options});
        });
    }

    updateClientActivation(clientId, action) {
        const clientJson = {
            clientId: clientId,
            actionType: action
        }
        this.clientService.updateClientActivation(clientJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.clientService.getManagers(this.state.phoneNumber, this.state.username, this.state.fullName, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page}, () => {
            this.clientService.getManagers(this.state.phoneNumber, this.state.username, this.state.fullName, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
                this.setState({first: event.first, pageSize: event.rows});
            });
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.clientService.getManagers(this.state.phoneNumber, this.state.username, this.state.fullName, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.clientService.getManagers(this.state.phoneNumber, this.state.username, this.state.fullName, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });
        });     
    }

    onPartnerChange(event) {
        this.dt.filter(event.value, 'id', 'equals');
        this.setState({
            partnerId: event.value
        }, () => {
            this.clientService.getManagers(this.state.phoneNumber, this.state.username, this.state.fullName, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });
        });
    }

    onDistrictChange(event) {
        this.dt.filter(event.value, 'id', 'equals');
        this.setState({
            districtId: event.value
        }, () => {
            this.clientService.getManagers(this.state.phoneNumber, this.state.username, this.state.fullName, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });
        });
    }

    onRegionChange(event) {
        this.dt.filter(event.value, 'id', 'equals');
        this.setState({
            regionId: event.value
        }, () => {
            this.clientService.getManagers(this.state.phoneNumber, this.state.username, this.state.fullName, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });
        });
    }

    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
            {rowData['status'] === "SUSPENDED" && <Button type="button" onClick={() => this.updateClientActivation(rowData["id"], "activate")} icon="pi pi-check-circle" className="p-button-success" style={{marginRight: '.2em'}} title="Activate"/>}
            {rowData['status'] === "ACTIVATED" && <Button type="button" onClick={() => this.updateClientActivation(rowData["id"], "suspend")} icon="pi pi-times-circle" className="p-button-danger" style={{marginRight: '.2em'}} title="Suspend"/>}
            <Link to={"/client/update-client/" + rowData["id"]}><Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.2em'}} title="Edit Client"/></Link>
        </div>;
    }

    render() {

        let usernameFilter = <InputText type="text" name="username" style={{width: '100%'}} placeholder="Contains" value={this.state.username} onChange={this.handleInputChange}/>
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder="Contains" value={this.state.phoneNumber} onChange={this.handleInputChange}/>
        let fullNameFilter = <InputText type="text" name="fullName" style={{width: '100%'}} placeholder="Contains" value={this.state.fullName} onChange={this.handleInputChange}/>        
        let districtFilter = <Dropdown style={{width:'100%'}} placeholder="Select a District" value={this.state.districtId} options={this.state.districts} onChange={this.onDistrictChange}/>
        let regionFilter = <Dropdown style={{width:'100%'}} placeholder="Select a Region" value={this.state.regionId} options={this.state.regions} onChange={this.onRegionChange}/>

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-messages p-component p-messages-warning" style={{margin: '0 0 1em 0', display: 'block'}}>
                        { this.state.failed === true &&
                            <div className="p-messages-wrapper rounded">
                                <ul>
                                    <li>
                                        <span className="p-messages-icon pi pi-fw pi-2x pi-exclamation-triangle"></span>
                                        <span className="p-messages-detail">{this.state.alert}</span>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                    <div className="p-messages p-component p-messages-success" style={{margin: '0 0 1em 0', display: 'block'}}>
                        { this.state.failed === false &&
                            <div className="p-messages-wrapper rounded">
                                <ul>
                                    <li>
                                        <span className="p-messages-icon pi pi-fw pi-2x pi-check-circle"></span>
                                        <span className="p-messages-detail">{this.state.alert}</span>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                    <div className="card card-w-title datatable-demo">
                        <h1>List of District Managers</h1>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" paginator={false} 
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="phoneNumber" header="Phone Number" sortable={true} filter={true} filterElement={phoneNumberFilter} filterPlaceholder="Contains"/>
                            <Column field="username" header="Username" sortable={true} filter={true} filterElement={usernameFilter} filterPlaceholder="Contains"/>
                            <Column field="fullName" header="Full Name" sortable={false} filter={true} filterElement={fullNameFilter} filterPlaceholder="Contains"/>
                            <Column field="district.districtName" header="District" sortable={false} filter={true} filterElement={districtFilter}/>
                            <Column field="region.regionName" header="Region" sortable={false} filter={true} filterElement={regionFilter}/>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '10em'}}/>
                        </DataTable>
                        <Paginator currentPageReportTemplate={"Showing " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator>
                    </div>
                </div>
            </div>
        );
    }

}