import React, {Component} from 'react';
import { ClientService } from '../service/ClientService';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Dropdown} from 'primereact/dropdown';
import { Link } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { TabPanel, TabView } from 'primereact/tabview';
import { withTranslation } from "react-i18next";
import 'jspdf-autotable';
import { ProgressBar } from 'primereact/progressbar';
import { Paginator } from 'primereact/paginator';
import { AdalipaUtil } from '../Utilities/AdalipaUtil';
import BeatLoader from "react-spinners/BeatLoader";

class ListStudents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            excelData:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            firstName: '',
            middleName: '',
            lastName: '',
            phoneNumber: '',
            regNumber: '',
            accountId: '',
            studentId: '',
            recordId: '',
            message: '',
            Status: '',
            levelId: null,
            levels: [],
            courseId: null,
            courses: [],
            confirmCourse: false,
            confirmGrade: false,
            sortOrder: -1,
            sortField: 'id',
            failed: null,
            sending: false,
            alert: '',
            loading: false,
            activeIndex: 0,
            smsType: '',
            displayParentSmsModal: false,
            smsdata: {message:"This is a test message",recepient:12}
        };

        this.clientService = new ClientService();
        this.adalipaUtil = new AdalipaUtil();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onLevelChange = this.onLevelChange.bind(this);
        this.onCourseChange = this.onCourseChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.submitCustomMessage = this.submitCustomMessage.bind(this);
        this.upgradeLevel = this.upgradeLevel.bind(this);
        this.upgradeGrade = this.upgradeGrade.bind(this);
        this.feeStatusTemplate = this.feeStatusTemplate.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.export = this.export.bind(this);
        this.getParentSmsDetails = this.getParentSmsDetails.bind(this);
        this.sendMessageToParent = this.sendMessageToParent.bind(this);

        this.cols = [
            { field: 'firstName', header: 'First Name' },
            { field: 'middleName', header: 'Middle Name' },
            { field: 'lastName', header: 'Last Name' },
            { field: 'gradeName', header: 'Level' },
            { field: 'accountId', header: 'Reference' },
            { field: 'phoneNumber', header: 'Reference' },
            { field: 'status', header: 'Fee Status' }
        ];
        this.excelCols = {header: ['firstName', 'middleName', 'lastName', 'gradeName', 'accountId', 'phoneNumber', 'status']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    componentDidMount() {
        this.setState({loading: true});
        this.clientService.getStudents(this.state.firstName, this.state.middleName, this.state.lastName, this.state.phoneNumber, this.state.regNumber, this.state.accountId, this.state.Status, 0, this.state.pageSize, 'id', 'asc')
        .then(data => {
            this.setState({loading: false});
            const dataContent = data.content.map(d => ({
                ...d, gradeName: d.grade.gradeName + (this.props.institutionType === "Collages" ? " - " + d.grade.course.courseCode : "")
            }));
            this.setState({dataTableValue: dataContent});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                pageSize: data.pageable.pageSize,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
            const excelOptions = data.content.map(d => ({
                firstName: d.firstName, middleName: d.middleName, lastName: d.lastName, gradeName: d.grade.gradeName + (this.props.institutionType === "Collages" ? " - " + d.grade.course.courseCode : ""), accountId: d.accountId, phoneNumber: d.phoneNumber, status: d.status
            }));
            this.setState({excelData: excelOptions});
        });
        this.clientService.getLevels('', 0, 100, 'id', 'asc')
        .then(response => {
            const data = response.content;
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.gradeName + (d.course === null ? "" : " (" + d.course.courseCode + ")")
            }));
            options.push({
                "value" : null,
                "label" : "All Levels"
            });
            this.setState({levels: options});
        });
        this.clientService.getCourses('', 0, 100, 'id', 'asc')
        .then(response => {
            const data = response.content;
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.courseName
            }));
            options.push({
                "value" : null,
                "label" : "All Courses"
            });
            this.setState({courses: options});
        });
    }

    getParentSmsDetails = (data) => {
        this.setState({smsType: 'parent_control_number_msg'});
        this.setState({accountId: data.accountId, recordId:data.id})
        this.setState({displayParentSmsModal: true});
    }

    submitCustomMessage(event){
        event.preventDefault();
        this.setState({sending: true});
        const smsJson = {
            accountId: this.state.accountId,
            recordId: this.state.recordId,
            message: this.state.message,
            smsType: 'custom_sms'
        }
        this.clientService.sendCustomSms(smsJson).then(response => {
            this.setState({displayParentSmsModal: false});
            this.setState({sending: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
        }).catch(error => {
            this.setState({displayParentSmsModal: false});
            this.setState({sending: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });


    }

    sendMessageToParent(event){
        event.preventDefault();
        this.setState({sending: true});
        const smsJson = {
            accountId: this.state.accountId,
            recordId: this.state.recordId,
            smsType: this.state.smsType
        }
        this.clientService.sendQuickSms(smsJson)
        .then(response => {
            this.setState({displayParentSmsModal: false});
            this.setState({sending: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
        }).catch(error => {
            this.setState({displayParentSmsModal: false});
            this.setState({sending: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
        
    }

    onInputChange = event =>{
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;
        this.setState(
            {[inputName]: inputValue}
            );
    }

    onSortChange(event) {
        this.setState({loading: true});
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.clientService.getStudents(this.state.firstName, this.state.middleName, this.state.lastName, this.state.phoneNumber, this.state.regNumber, this.state.accountId, this.state.Status, 0, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({loading: false});
                const dataContent = data.content.map(d => ({
                    ...d, gradeName: d.grade.gradeName + (this.props.institutionType === "Collages" ? " - " + d.grade.course.courseCode : "")
                }));
                this.setState({dataTableValue: dataContent});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    pageSize: data.pageable.pageSize,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
                const excelOptions = data.content.map(d => ({
                    firstName: d.firstName, middleName: d.middleName, lastName: d.lastName, gradeName: d.grade.gradeName + (this.props.institutionType === "Collages" ? " - " + d.grade.course.courseCode : ""), accountId: d.accountId, phoneNumber: d.phoneNumber, status: d.status
                }));
                this.setState({excelData: excelOptions});
            });
        });
    }

    onPageChange(event) {
        this.setState({loading: true});
        this.clientService.getStudents(this.state.firstName, this.state.middleName, this.state.lastName, this.state.phoneNumber, this.state.regNumber, this.state.accountId, this.state.Status, event.page, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
        .then(data => {
            this.setState({loading: false});
            const dataContent = data.content.map(d => ({
                ...d, gradeName: d.grade.gradeName + (this.props.institutionType === "Collages" ? " - " + d.grade.course.courseCode : "")
            }));
            this.setState({dataTableValue: dataContent});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                pageSize: data.pageable.pageSize,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
            this.setState({first: event.first, pageSize: event.rows});
            const excelOptions = data.content.map(d => ({
                firstName: d.firstName, middleName: d.middleName, lastName: d.lastName, gradeName: d.grade.gradeName + (this.props.institutionType === "Collages" ? " - " + d.grade.course.courseCode : ""), accountId: d.accountId, phoneNumber: d.phoneNumber, status: d.status
            }));
            this.setState({excelData: excelOptions});
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.setState({loading: true});
            this.clientService.getStudents(this.state.firstName, this.state.middleName, this.state.lastName, this.state.phoneNumber, this.state.regNumber, this.state.accountId, this.state.Status, 0, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({loading: false});
                const dataContent = data.content.map(d => ({
                    ...d, gradeName: d.grade.gradeName + (this.props.institutionType === "Collages" ? " - " + d.grade.course.courseCode : "")
                }));
                this.setState({dataTableValue: dataContent});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    pageSize: data.pageable.pageSize,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
                const excelOptions = data.content.map(d => ({
                    firstName: d.firstName, middleName: d.middleName, lastName: d.lastName, gradeName: d.grade.gradeName + (this.props.institutionType === "Collages" ? " - " + d.grade.course.courseCode : ""), accountId: d.accountId, phoneNumber: d.phoneNumber, status: d.status
                }));
                this.setState({excelData: excelOptions});
            });
        });     
    }

    onLevelChange(event) {
        this.dt.filter(event.value, 'id', 'equals');
        this.setState({
            levelId: event.value
        }, () => {
            this.setState({loading: true});
            this.clientService.getStudents(this.state.firstName, this.state.middleName, this.state.lastName, this.state.phoneNumber, this.state.regNumber, this.state.accountId, this.state.Status, 0, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({loading: false});
                const dataContent = data.content.map(d => ({
                    ...d, gradeName: d.grade.gradeName + (this.props.institutionType === "Collages" ? " - " + d.grade.course.courseCode : "")
                }));
                this.setState({dataTableValue: dataContent});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    pageSize: data.pageable.pageSize,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
                const excelOptions = data.content.map(d => ({
                    firstName: d.firstName, middleName: d.middleName, lastName: d.lastName, gradeName: d.grade.gradeName + (this.props.institutionType === "Collages" ? " - " + d.grade.course.courseCode : ""), accountId: d.accountId, phoneNumber: d.phoneNumber, status: d.status
                }));
                this.setState({excelData: excelOptions});
            });
        });
    }

    onCourseChange(event) {
        this.dt.filter(event.value, 'id', 'equals');
        this.setState({
            courseId: event.value
        }, () => {
            this.setState({loading: true});
            this.clientService.getStudents(this.state.firstName, this.state.middleName, this.state.lastName, this.state.phoneNumber, this.state.regNumber, this.state.accountId, this.state.Status, 0, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({loading: false});
                const dataContent = data.content.map(d => ({
                    ...d, gradeName: d.grade.gradeName + (this.props.institutionType === "Collages" ? " - " + d.grade.course.courseCode : "")
                }));
                this.setState({dataTableValue: dataContent});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    pageSize: data.pageable.pageSize,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
                const excelOptions = data.content.map(d => ({
                    firstName: d.firstName, middleName: d.middleName, lastName: d.lastName, gradeName: d.grade.gradeName + (this.props.institutionType === "Collages" ? " - " + d.grade.course.courseCode : ""), accountId: d.accountId, phoneNumber: d.phoneNumber, status: d.status
                }));
                this.setState({excelData: excelOptions});
            });
        });
    }

    upgradeLevel() {
        this.setState({loading: true});
        const upgradeJson = {
            courseId: this.state.courseId
        }
        this.clientService.upgradeLevel(upgradeJson).then(response => {
            this.setState({confirmCourse:false});
            this.clientService.getStudents(this.state.firstName, this.state.middleName, this.state.lastName, this.state.phoneNumber, this.state.regNumber, this.state.accountId, this.state.Status, 0, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({loading: false});
                const dataContent = data.content.map(d => ({
                    ...d, gradeName: d.grade.gradeName + (this.props.institutionType === "Collages" ? " - " + d.grade.course.courseCode : "")
                }));
                this.setState({dataTableValue: dataContent});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    pageSize: data.pageable.pageSize,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
                const excelOptions = data.content.map(d => ({
                    firstName: d.firstName, middleName: d.middleName, lastName: d.lastName, gradeName: d.grade.gradeName + (this.props.institutionType === "Collages" ? " - " + d.grade.course.courseCode : ""), accountId: d.accountId, phoneNumber: d.phoneNumber, status: d.status
                }));
                this.setState({excelData: excelOptions});
            });
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    upgradeGrade() {
        this.setState({loading: true});
        const upgradeJson = {
            courseId: null
        }
        this.clientService.upgradeLevel(upgradeJson).then(response => {
            this.setState({confirmGrade:false});
            this.clientService.getStudents(this.state.firstName, this.state.middleName, this.state.lastName, this.state.phoneNumber, this.state.regNumber, this.state.accountId, this.state.Status, 0, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({loading: false});
                const dataContent = data.content.map(d => ({
                    ...d, gradeName: d.grade.gradeName + (this.props.institutionType === "Collages" ? " - " + d.grade.course.courseCode : "")
                }));
                this.setState({dataTableValue: dataContent});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    pageSize: data.pageable.pageSize,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
                const excelOptions = data.content.map(d => ({
                    firstName: d.firstName, middleName: d.middleName, lastName: d.lastName, gradeName: d.grade.gradeName + (this.props.institutionType === "Collages" ? " - " + d.grade.course.courseCode : ""), accountId: d.accountId, phoneNumber: d.phoneNumber, status: d.status
                }));
                this.setState({excelData: excelOptions});
            });
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    feeStatusTemplate(rowData, column) {
        return <ProgressBar value={rowData['status'].replace('%', '')} showValue={true}></ProgressBar>;
    }

    actionTemplate(rowData, column) {
        return <div>
            <Link to={"/student/info/" + rowData['accountId']}><Button type="button" icon="pi pi-eye" title="View Student" className="p-button-adalipa" style={{marginRight: '.5em'}}/></Link>
            <Link to="#"><Button type="button" onClick={()=>this.getParentSmsDetails(rowData)} icon="pi pi-comment" title="View Student" className="p-button p-button-sm p-button-warning" style={{marginRight: '.5em'}}/></Link>
        </div>;
    } 
    
    parentSmsDialogFooter = <div className="p-grid">
        <div className="p-col-6" style={{textAlign: "right"}}>
           <Button type="button" label={this.props.t("Cancel")} onClick={() => this.setState({displayParentSmsModal: false})} icon="pi pi-times-circle" className="p-button-sm" style={{backgroundColor: "#EEEFFF", color: "#3B007B"}} />
       </div>
       <div className="p-col-6" style={{textAlign: "left"}}>
         <Button type="submit" label={this.props.t("Send Sms")} onClick={(e) =>  (this.state.activeIndex === 0 ? this.sendMessageToParent(e) : this.submitCustomMessage(e))} icon="pi pi-check-circle" className="p-button-sm" />
       </div>
       </div>;


    export() {
        this.dt.exportCSV();
    }

    render() {
        let header = <div style={{textAlign:'left'}}>
            <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
            <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel("Students", this.excelCols, this.state.excelData)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
            <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable("Students", this.exportColumns, this.state.dataTableValue)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
        </div>
        let firstNameFilter = <InputText type="text" name="firstName" style={{width: '100%'}} placeholder="Contains" value={this.state.firstName} onChange={this.handleInputChange}/>
        let middleNameFilter = <InputText type="text" name="middleName" style={{width: '100%'}} placeholder="Contains" value={this.state.middleName} onChange={this.handleInputChange}/>
        let lastNameFilter = <InputText type="text" name="lastName" style={{width: '100%'}} placeholder="Contains" value={this.state.lastName} onChange={this.handleInputChange}/>
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder="Contains" value={this.state.phoneNumber} onChange={this.handleInputChange}/>
        let accountIdFilter = <InputText type="text" name="accountId" style={{width: '100%'}} placeholder="Contains" value={this.state.accountId} onChange={this.handleInputChange}/>
        let levelFilter = <Dropdown style={{width: '100%'}} placeholder="Levels" value={this.state.levelId} options={this.state.levels} onChange={this.onLevelChange}/>
        let courseFilter = <Dropdown style={{width: '100%'}} placeholder="Courses" value={this.state.courseId} options={this.state.courses} onChange={this.onCourseChange}/>

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const levelDialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={this.upgradeLevel} label="Yes" />
                <Button icon="pi pi-times" onClick={() => this.setState({confirmCourse:false})} label="No" className="p-button-secondary" />
            </div>
        );
        const gradeDialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={this.upgradeGrade} label="Yes" />
                <Button icon="pi pi-times" onClick={() => this.setState({confirmGrade:false})} label="No" className="p-button-secondary" />
            </div>
        );

        

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>List of registered students</h2></div>
                            <div className="p-col-8 p-md-8">
                                <Link to="/student/add-student" style={{float: "right"}}><Button label="Add Student" icon="pi pi-plus"/></Link>
                                <Link to="/student/add-student-batch" style={{float: "right"}}><Button label="Add Using Excel" icon="pi pi-file"/></Link>
                                <Dialog header="Upgrade Students' Levels" visible={this.state.confirmCourse} modal={true} style={{width:'50vw', textAlign: 'center'}} footer={levelDialogFooter} onHide={() => this.setState({confirmCourse:false})}>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="dropdown">Please Choose Course:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12" style={{padding: "1%", marginRight: "1%", marginLeft: "1%", width:'50vw'}}>
                                            <Dropdown options={this.state.courses} value={this.state.courseId} onChange={event => this.setState({courseId: event.value})} autoWidth={false} style={{width:'80%'}} />
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <p>Are yu sure you want to upgrade students levels?<br/><span style={{color: "#E00000"}}><strong>Note: </strong> Highest level students will be moved to graduates.&nbsp;<strong>This process is irreversible</strong></span></p>
                                        </div>
                                    </div>
                                </Dialog>
                                { this.props.institutionType === "Collages" && 
                                    <Button onClick={() => this.setState({confirmCourse: true})} style={{float: "right"}} label="Upgrade Students' levels" icon="pi pi-external-link"/>
                                }
                                <Dialog header="Upgrade Students' Levels" visible={this.state.confirmGrade} modal={true} style={{width:'50vw', textAlign: 'center'}} footer={gradeDialogFooter} onHide={() => this.setState({confirmGrade:false})}>
                                    <p>Are yu sure you want to upgrade students levels?&nbsp;<span style={{color: "#3B007B"}}><strong>Note: </strong> Highest level students will be moved to graduates.&nbsp;<strong>This process is irreversible</strong></span></p>
                                </Dialog>
                                { this.props.institutionType === "Schools" && 
                                    <Button onClick={() => this.setState({confirmGrade: true})} style={{float: "right"}} label="Upgrade Students' levels" icon="pi pi-external-link"/>
                                }
                                <Link to="/student/add-student" style={{float: "right"}}><Button label="Students Report" icon="pi pi-file"/></Link>
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={false} 
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="firstName" header="First Name" sortable={true} filter={true} filterElement={firstNameFilter} filterPlaceholder="Contains"/>
                            <Column field="middleName" header="Middle Name" sortable={true} filter={true} filterElement={middleNameFilter} filterPlaceholder="Contains"/>
                            <Column field="lastName" header="Last Name" sortable={true} filter={true} filterElement={lastNameFilter} filterPlaceholder="Contains"/>
                            <Column field="grade.gradeName" header="Level" sortable={true} filter={true} filterElement={levelFilter}/>
                            { this.props.institutionType === "Collages" && 
                                <Column field="course.courseName" header="Course" sortable={true} filter={true} filterElement={courseFilter}/>
                            }
                            <Column field="accountId" header="Reference" sortable={true} filter={true} filterElement={accountIdFilter} filterPlaceholder="Contains"/>
                            <Column field="phoneNumber" header="Number" sortable={true} filter={true} filterElement={phoneNumberFilter} filterPlaceholder="Contains"/>
                            <Column field="status" body={this.feeStatusTemplate} header="Fee Status" sortable={true} filter={false}></Column>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        <Paginator currentPageReportTemplate={"Showing " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>



                <Dialog header={this.props.t("Send Sms")} visible={this.state.displayParentSmsModal} className="p-col-6 p-offset-2" modal footer={this.parentSmsDialogFooter} onHide={() => this.setState({displayParentSmsModal: false})}>  
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                                    <TabPanel header={this.props.t("Account Info Sms")} leftIcon="pi pi-user">
                                        <div className="card card-w-title">
                                            <div className="p-col-12 p-md-12">
                                                <p>{this.props.t('In this panel you are able to send automated sms which contains important details of students, these details includes controll number and help contact number')}</p>
                                            </div>
                                        </div>
                                    </TabPanel> 
                                     <TabPanel header={this.props.t("Write Message")} leftIcon="pi pi-users">
                                        <div className="card card-w-title">
                                            <div className="p-col-12 p-md-12">
                                                <p>{this.props.t('In this panel you are able to write and send sms to a parent, here you can write what best suits your motive')}</p>
                                            </div>
                                            <div className="p-col-12 p-md-12" style={{paddingTop: "0px", marginTop: "0px"}}>
                                                <div className="p-col-12 p-md-offset-1 p-md-10" style={{paddingTop: "0px", marginTop: "0px"}}>
                                                    <h4>{this.props.t('Message')}</h4>
                                                    <InputTextarea name="message" value={this.state.message} onChange={this.onInputChange} required rows={3} cols={30} placeholder={this.props.t("Your Message")} autoResize={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>                                
                                </TabView>
                            </div>
                            {this.state.sending && 
                                <div className="p-grid p-fluid p-col-12" style={{textAlign:'center', padding: "2em"}}>
                                    <div className="p-col-12" style={{textAlign:'center'}}>
                                        <BeatLoader color={"#3B007B"} sending={this.state.sending} css={""} size={10} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </Dialog>



            </div>
        );
    }
}

export default withTranslation()(ListStudents)
