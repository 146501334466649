import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { UserService } from '../service/UserService';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { withTranslation } from "react-i18next";

class AddStaff extends Component {

	constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirmPassword: '',
            fullName: '',
            email: '',
            phoneNumber: '',
            errors: {},
			alert: ''
        };
        this.userService = new UserService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
    }

    handleValidation(){
        let errors = {};
        let formIsValid = true;

        //Name
        if(!this.state.fullName){
            formIsValid = false;
            errors["fullName"] = "Full name cannot be empty";
        }

        if(typeof this.state.fullName !== "undefined"){
            if(!this.state.fullName.match(/^[a-zA-Z -]+$/)){
                formIsValid = false;
                errors["fullName"] = "Full name can only be letters";
            }        
        }

        //Name
        if(!this.state.phoneNumber){
            formIsValid = false;
            errors["phoneNumber"] = "Phone number cannot be empty";
        }

        if(typeof this.state.phoneNumber !== "undefined"){
            if(!this.state.phoneNumber.match(/^[0-9-]+$/)){
                formIsValid = false;
                errors["phoneNumber"] = "Phone number can only be numbers";
            }        
        }
   
        //Email
        if(!this.state.email){
            formIsValid = false;
            errors["email"] = "Email cannot be empty";
        }

        if(typeof this.state.email !== "undefined"){
            let lastAtPos = this.state.email.lastIndexOf('@');
            let lastDotPos = this.state.email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }

        //Password
        if(!this.state.password){
            formIsValid = false;
            errors["password"] = "Password cannot be empty";
        }
        if(!this.state.confirmPassword){
            formIsValid = false;
            errors["confirmPassword"] = "Confirm password cannot be empty";
        }

        if(typeof this.state.confirmPassword !== "undefined"){
            if(this.state.confirmPassword !== this.state.password){
                formIsValid = false;
                errors["confirmPassword"] = "Password does not match";
            }        
        }

       this.setState({errors: errors});
       return formIsValid;
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        if(this.handleValidation()) {
            const courseJson = {
                username: this.state.email,
                password: this.state.password,
                fullName: this.state.fullName,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber
            };
            this.userService.addStaff(courseJson)
            .then(response => {
                this.setState({failed: false});
                this.setState({alert: response.message});
                this.props.history.push("/user/list/staffs");
            }).catch(error => {
                this.setState({failed: true});
                this.setState({alert: error.message});
            });
        } else {
            this.setState({failed: true});
            this.setState({alert: "Form has errors."});
        }
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/user/add/staff",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                    { this.state.failed === true &&
                            <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                                    <div className="p-messages-wrapper rounded">
                                        <ul>
                                            <li>
                                                <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                                <span className="p-messages-detail">{this.state.alert}</span>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        }
                        { this.state.failed === false &&
                            <div className="p-messages p-component p-messages-success" style={{margin: '0 0 1em 0', display: 'block'}}>
                                    <div className="p-messages-wrapper rounded">
                                        <ul>
                                            <li>
                                                <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                                <span className="p-messages-detail">{this.state.alert}</span>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        }

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>{this.props.t('New Staff')}</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Full Name')}:&nbsp;<span style={{color: "red"}}>{this.state.errors["fullName"]}</span></label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="fullName" placeholder="eg: John Doe" value={this.state.fullName} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Phone Number')}:&nbsp;<span style={{color: "red"}}>{this.state.errors["phoneNumber"]}</span></label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="phoneNumber" placeholder="eg: 0XXXXXXXXX" value={this.state.phoneNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Email')} / {this.props.t('Username')}:&nbsp;<span style={{color: "red"}}>{this.state.errors["email"]}</span></label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="email" placeholder="eg: johndoe@adalipa.co.tz" value={this.state.email} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Password')}:&nbsp;<span style={{color: "red"}}>{this.state.errors["password"]}</span></label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Password name="password" placeholder={this.props.t("Enter") + " " + this.props.t("Password")} value={this.state.password} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Confirm')} {this.props.t('Password')}:&nbsp;<span style={{color: "red"}}>{this.state.errors["confirmPassword"]}</span></label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Password name="confirmPassword" placeholder={this.props.t("Confirm") + " " + this.props.t("Password")} value={this.state.confirmPassword} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={this.props.t("Add Staff")} icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}
export default withTranslation()(AddStaff)