import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { LgaService } from '../../service/LgaService';
import { WalletService } from '../../service/WalletService';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import ResponseAlert from '../../Utilities/ResponseAlert';
import { AutoComplete } from 'primereact/autocomplete';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";

class PayTransfer extends Component {

	constructor(props) {
        super(props);
        this.state = {
            dataJson: {wrongHouse: {id: 0, name: ''}, correctHouse: {id: 0, name: ''}, paidAmount: 0, receiptNumber: '', details: this.props.t('Ililipwa kimakosa')},
            dataId: 0,
            paymentId: 0,
            wrongHouseHolders: [],
            correctHouseHolders: [],
            search: '',
            failed: null,
            alert: '',
            loading: false
        };
        this.lgaService = new LgaService();
        this.walletService = new WalletService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handelEdit = this.handelEdit.bind(this);
        this.selectedItemTemplate = this.selectedItemTemplate.bind(this);
        this.itemOptionTemplate = this.itemOptionTemplate.bind(this);
	}

    getWrongHouseHolders(search) {
        const jsonParams = {search: search, page: 0, limit: 100, sort: "id", order: 'asc' }
        this.lgaService.getHouseHolders(jsonParams)
        .then(data => {
            const dataContent = data.content.map(d => ({
                ...d,
                name: d.reference + " - " + d.name
            }))
            this.setState({wrongHouseHolders: dataContent});
        });
    }
    toggleWrongHouseHolders(e) {
        e.originalEvent.stopPropagation();
        this.getWrongHouseHolders(this.state.dataJson.wrongHouse.name);
    }
    wrongHouseChange(e) {
        this.setState(prevJson => ({dataJson: {...prevJson.dataJson, wrongHouse: {name: e.query || ""}}}), () => this.getWrongHouseHolders(this.state.dataJson.wrongHouse.name));
    }

    getCorrectHouseHolders(search) {
        const jsonParams = {search: search, page: 0, limit: 100, sort: "id", order: 'asc' }
        this.lgaService.getHouseHolders(jsonParams)
        .then(data => {
            const dataContent = data.content.map(d => ({
                ...d,
                name: d.reference + " - " + d.name
            }))
            this.setState({correctHouseHolders: dataContent});
        });
    }
    toggleCorrectHouseHolders(e) {
        e.originalEvent.stopPropagation();
        this.getCorrectHouseHolders(this.state.dataJson.correctHouse.name);
    }
    correctHouseChange(e) {
        this.setState(prevJson => ({dataJson: {...prevJson.dataJson, correctHouse: {name: e.query || ""}}}), () => this.getCorrectHouseHolders(this.state.dataJson.correctHouse.name));
    }
	
	componentDidMount() {
        if (this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        if (+this.props.match.params.dataId > 0) {
            this.setState({dataId: +this.props.match.params.dataId});
            this.lgaService.getCell(+this.props.match.params.dataId)
            .then(data => {
                this.setState({dataJson: {id: data.id, zone: {id: data.zone.id}, name: data.name, cellNumber: data.cellNumber, details: data.details}});
            });
        }
        if (+this.props.match.params.paymentId > 0) {
            this.setState({paymentId: +this.props.match.params.paymentId});
            this.walletService.getTransaction(+this.props.match.params.paymentId)
            .then(data => {
                this.setState(prevJson => ({dataJson: {...prevJson.dataJson, paidAmount: data.amount, receiptNumber: data.receiptNumber}}));
                this.lgaService.getHouseHolderByReference(data.accountId)
                .then(data => {
                    this.setState(prevJson => ({dataJson: {...prevJson.dataJson, wrongHouse: {id: data.id, name: data.reference + " - " + data.name}}}));
                });
            });
        }
        this.getWrongHouseHolders(this.state.dataJson.wrongHouse.name);
        this.getCorrectHouseHolders(this.state.dataJson.correctHouse.name);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({
            dataJson: {
                ...prevJson.dataJson, [inputName] : inputValue
            }
        }));        
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        this.lgaService.transferBillsPayments(this.state.dataJson)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/lga/pay/transfers");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

    handelEdit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        this.lgaService.transferBillsPayments(this.state.dataJson)
        .then(response => {
            this.setState({loading: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/lga/pay/transfers");
        }).catch(error => {
            this.setState({loading: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    itemOptionTemplate(option) {
        return (
            <div className="country-item">
                <div>{option.name}</div>
            </div>
        );
    }
    selectedItemTemplate(option, props) {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }
        return (
            <span>{props.placeholder}</span>
        );
    }

	render() {
        let {dataJson, wrongHouseHolders, correctHouseHolders} = this.state;
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/lga/pay/transfer/0/0",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <ResponseAlert failed={this.state.failed} alert={this.state.alert} />
                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>{this.props.t(this.state.dataId === 0 ? "Transfer Payment" : "Edit Transfer Payment")}</h2>
                            </div>
                            {this.state.loading && 
                                <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                    <div className="p-col-12" style={{textAlign:'center'}}>
                                        <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                    </div>
                                </div>
                            }
                            {!this.state.loading && 
                            <form onSubmit={this.state.dataId === 0 ? this.handleSubmit : this.handelEdit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Wrong Reference')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <AutoComplete id="wrongHouse" name="wrongHouse" value={dataJson.wrongHouse.name || ""} suggestions={wrongHouseHolders} onSelect={e => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, wrongHouse: {id: e.value.id, name: e.value.name}}}))} delay={2000} 
                                            completeMethod={(e) => e.query.length > 0 ? this.wrongHouseChange(e) : this.setState({wrongHouseHolders: wrongHouseHolders})} onClear={(e) => this.wrongHouseChange(e)}
                                            dropdown={true} onDropdownClick={(e) => this.toggleWrongHouseHolders(e)} itemTemplate={this.itemOptionTemplate} placeholder={this.props.t("Choose") + " " + this.props.t("House Holder")} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Correct Reference')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <AutoComplete id="correctHouse" name="correctHouse" value={dataJson.correctHouse.name || ""} suggestions={correctHouseHolders} onSelect={e => this.setState(prevJson => ({dataJson: {...prevJson.dataJson, correctHouse: {id: e.value.id, name: e.value.name}}}))} delay={2000} 
                                            completeMethod={(e) => e.query.length > 0 ? this.correctHouseChange(e) : this.setState({correctHouseHolders: correctHouseHolders})} onClear={(e) => this.correctHouseChange(e)}
                                            dropdown={true} onDropdownClick={(e) => this.toggleCorrectHouseHolders(e)} itemTemplate={this.itemOptionTemplate} placeholder={this.props.t("Choose") + " " + this.props.t("House Holder")} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-1 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Receipt Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="receiptNumber" placeholder={this.props.t("Enter") + " " + this.props.t("Receipt Number")} value={dataJson.receiptNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Paid Amount')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            {/* <InputText type="text" name="paidAmount" placeholder={this.props.t("Enter") + " " + this.props.t("Paid Amount")} value={dataJson.paidAmount} onChange={this.handleInputChange} required/> */}
                                            <InputNumber id="paidAmount" name="paidAmount" value={dataJson.paidAmount} onChange={this.handleInputChange} mode="decimal" minFractionDigits={2} placeholder={this.props.t("Enter") + " " + this.props.t("Paid Amount")} required />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-1 p-md-10">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Details')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputTextarea name="details" placeholder={this.props.t("Enter") + " " + this.props.t("Details")} value={dataJson.details} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={this.props.t(this.state.dataId === 0 ? "Transfer Payment" : "Edit Transfer Payment")} icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}
export default withTranslation()(PayTransfer)