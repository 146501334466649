import {LGA_ANALYTICS_REPORT, ROAD,ZONE, CELL, CELL_LEADER, HOUSE_HOLDER, HOUSE_BILL, MONTH_BILL, LGA_TERNANT, LGA, LGA_EMPLOYEE, LGA_SALARY } from '../api';
import { API_BASE_URL } from '../constants';
import { postRequest, postFiles, getRequest,getRequestTwo, queryParams } from './CoreService';

export class LgaService {

    addZone(dataJson) {
        return postRequest({
            url: ZONE,
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    editZone(dataJson) {
        return postRequest({
            url: ZONE,
            method: "PUT",
            body: JSON.stringify(dataJson)
        });
    }

    getZone(dataId) {
        return postRequest({
            url: ZONE + "/" + dataId,
            method: "GET"
        });
    }

    removeZone(dataId) {
        return postRequest({
            url: ZONE + "/" + dataId,
            method: "DELETE"
        });
    }

    getZones(jsonParams) {
        return getRequest({
            url: ZONE + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getZonesOfInstitution(jsonParams,institutionId) {
        return getRequest({
            url: ZONE+"/institution/"+institutionId + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getCells(jsonParams){
        return getRequest({
            url: CELL + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getCellsOfInstitution(jsonParams,institutionId){
        return getRequest({
            url: CELL+"/institution/"+institutionId + queryParams(jsonParams),
            method: 'GET'
        });
    }

    addCell(dataJson) {
        return postRequest({
            url: CELL,
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    editCell(dataJson) {
        return postRequest({
            url: CELL,
            method: "PUT",
            body: JSON.stringify(dataJson)
        });
    }

    getCell(dataId) {
        return postRequest({
            url: CELL + "/" + dataId,
            method: "GET"
        });
    }

    removeCell(dataId) {
        return postRequest({
            url: CELL + "/" + dataId,
            method: "DELETE"
        });
    }

    // getCells(jsonParams) {
    //     console.log("Json Params: "+jsonParams);
    //     return getRequest({
    //         url: CELL + queryParams(jsonParams),
    //         method: 'GET'
    //     });
    // }

    addCellLeader(dataJson) {
        return postRequest({
            url: CELL_LEADER,
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    editCellLeader(dataJson) {
        return postRequest({
            url: CELL_LEADER,
            method: "PUT",
            body: JSON.stringify(dataJson)
        });
    }

    getCellLeader(dataId) {
        return postRequest({
            url: CELL_LEADER + "/" + dataId,
            method: "GET"
        });
    }

    removeCellLeader(dataId) {
        return postRequest({
            url: CELL_LEADER + "/" + dataId,
            method: "DELETE"
        });
    }

    getCellLeaders(jsonParams) {
        return getRequest({
            url: CELL_LEADER + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getRoads(jsonParams) {
        return getRequest({
            url: ROAD + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getRoadsOfInstitution(jsonParams,institutionId) {
        return getRequest({
            url: ROAD+"/institution/"+institutionId + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getRoadsList(jsonParams) {
        return getRequest({
            url: ROAD+"/list" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getRoadList(jsonParams) {
        return getRequest({
            url: ROAD+"/list/" + queryParams(jsonParams),
            method: 'GET'
        });
    }
    

    getRoad(dataId,cellId) {
        return postRequest({
            url: ROAD + "/" + dataId+"/"+cellId,
            method: "GET"
        });
    }

    getAuditableList(jsonParams){
        return postRequest({
            url: API_BASE_URL+"/lga/audits/"+queryParams(jsonParams),
            method: "GET",
        });
    }

    addRoad(dataJson) {
        return postRequest({
            url: ROAD,
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    editRoad(dataJson,roadId,cellId) {
        return postRequest({
            url: ROAD+"/"+roadId+"/"+cellId,
            method: "PUT",
            body: JSON.stringify(dataJson)
        });
    }

    removeRoad(dataId,cellId){
        return postRequest({
            url: ROAD + "/" + dataId+"/"+cellId,
            method: "DELETE"
        });
    }

    addHouseHolder(dataJson) {
        return postRequest({
            url: HOUSE_HOLDER,
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }
    addHouseHolderBatch(excelFile, cellId) {
        const formData = new FormData();
        formData.append('file', excelFile);
        formData.append('cellId', cellId);
        return postFiles({
            url: HOUSE_HOLDER + "/batch",
            method: 'POST',
            body: formData
        });
    }

    addTenantBatch(excelFile, cellId) {
        const formData = new FormData();
        formData.append('file', excelFile);
        formData.append('cellId', cellId);
        return postFiles({
            url: API_BASE_URL + "/lga-tenants/batch",
            method: 'POST',
            body: formData
        });
    }

    addHouseRoad(dataJson){
        return postRequest({
            url: HOUSE_HOLDER+"/road",
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    editHouseHolder(dataJson) {
        return postRequest({
            url: HOUSE_HOLDER,
            method: "PUT",
            body: JSON.stringify(dataJson)
        });
    }

    getHouseHolder(dataId) {
        return postRequest({
            url: HOUSE_HOLDER + "/" + dataId,
            method: "GET"
        });
    }

    getLgaTenant(dataId) {
        return postRequest({
            url: LGA_TERNANT + "/by/id/" + dataId,
            method: "GET"
        });
    }

    getHouseHolderByReference(reference) {
        return postRequest({
            url: HOUSE_HOLDER + "/by/reference/" + reference,
            method: "GET"
        });
    }

    removeHouseHolder(dataId) {
        return postRequest({
            url: HOUSE_HOLDER + "/" + dataId,
            method: "DELETE"
        });
    }

    getHouseHolders(jsonParams) {
        return getRequest({
            url: HOUSE_HOLDER + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getHouseHoldersOfInstitution(jsonParams,institutionId) {
        return getRequest({
            url: HOUSE_HOLDER+"/institution/"+institutionId + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getHouseBill(feeId) {
        return getRequest({
            url: HOUSE_BILL + "/" + feeId,
            method: 'GET'
        });
    }

    getHouseBills(jsonParams) {
        return getRequest({
            url: HOUSE_BILL + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getHouseHoldersMonthBills(month) {
        return postRequest({
            url: HOUSE_HOLDER + "/bills/" + month,
            method: "GET"
        });
    }

    getLgaTenantMonthBills(month) {
        return postRequest({
            url: LGA_TERNANT + "/bills/" + month,
            method: "GET"
        });
    }

    getHouseHolderMonthBills(month, houseHolderId) {
        return postRequest({
            url: HOUSE_HOLDER + "/bills/" + month + "/" + houseHolderId,
            method: "GET"
        });
    }

    getLgaTenantsMonthBills(month, lgaTenantId) {
        return postRequest({
            url: LGA_TERNANT + "/bills/" + month + "/" + lgaTenantId,
            method: "GET"
        });
    }

    splitHouseBill(dataJson) {
        return postRequest({
            url: MONTH_BILL + "/split",
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    splitTenantBill(dataJson) {
        return postRequest({
            url: LGA_TERNANT + "/month/bills/split",
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    

    revokeMonthPayment(dataJson) {
        return postRequest({
            url: MONTH_BILL + "/revoke",
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    revokeTenantMonthPayment(dataJson) {
        return postRequest({
            url: MONTH_BILL + "/month/bills/revoke",
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    

    getMonthBills(jsonParams) {
        return getRequest({
            url: MONTH_BILL + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getTenantsMonthBills(jsonParams) {
        return getRequest({
            url: LGA_TERNANT +"/month/bills"+ queryParams(jsonParams),
            method: 'GET'
        });
    }

    transferBillsPayments(dataJson) {
        return postRequest({
            url: LGA + "/transfer/bills/payments",
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    getTransferredBillsPayments(jsonParams) {
        return getRequest({
            url: LGA + "/transferred/bills/payments" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    addTenant(dataJson) {
        return postRequest({
            url: LGA_TERNANT,
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    editTenant(dataJson) {
        return postRequest({
            url: LGA_TERNANT,
            method: "PUT",
            body: JSON.stringify(dataJson)
        });
    }

    getTenant(dataId) {
        return postRequest({
            url: LGA_TERNANT + "/" + dataId,
            method: "GET"
        });
    }

    removeTenant(dataId) {
        return postRequest({
            url: LGA_TERNANT + "/" + dataId,
            method: "DELETE"
        });
    }

    getTenants(jsonParams) {
        return getRequest({
            url: LGA_TERNANT + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getTenantTypes(jsonParams) {
        return getRequest({
            url: LGA_TERNANT + "/types" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    addTenantTransfer(dataJson) {
        return postRequest({
            url: LGA_TERNANT + "/transfers",
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    getTenantTransfers(jsonParams) {
        return getRequest({
            url: LGA_TERNANT + "/transfers" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    addLgaEmployee(dataJson) {
        return postRequest({
            url: LGA_EMPLOYEE,
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    editLgaEmployee(dataJson) {
        return postRequest({
            url: LGA_EMPLOYEE,
            method: "PUT",
            body: JSON.stringify(dataJson)
        });
    }

    getLgaEmployee(dataId) {
        return postRequest({
            url: LGA_EMPLOYEE + "/" + dataId,
            method: "GET"
        });
    }

    removeLgaEmployee(dataId) {
        return postRequest({
            url: LGA_EMPLOYEE + "/" + dataId,
            method: "DELETE"
        });
    }

    getLgaEmployees(jsonParams) {
        return getRequest({
            url: LGA_EMPLOYEE + queryParams(jsonParams),
            method: 'GET'
        });
    }

    addLgaSalary(dataJson) {
        return postRequest({
            url: LGA_SALARY,
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    resendLgaSalary(dataId) {
        return postRequest({
            url: LGA_SALARY + "/resend/" + dataId,
            method: "GET"
        });
    }

    getLgaSalary(dataId) {
        return postRequest({
            url: LGA_SALARY + "/" + dataId,
            method: "GET"
        });
    }

    getLgaSalaries(jsonParams) {
        return getRequest({
            url: LGA_SALARY + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getLgaAnalyticsReport(jsonParams){
        return getRequest({
            url: LGA_ANALYTICS_REPORT + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getTotalAnalyticsReport(jsonParams){
        return getRequest({
            url: "/lga/house/total/analytics-report" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getLgaGeneralAnalyticsReport(jsonParams){
        return getRequest({
            url: API_BASE_URL+"/lga/house/holder/institutional/analytics/report" + queryParams(jsonParams),
            method: 'GET'
        });

    }

    getTotalSummaryAnalyticsReport(jsonParams){
        return getRequest({
            url: API_BASE_URL+"/lga/house/total/institutional/analytics/report" + queryParams(jsonParams),
            method: 'GET'
        });

    }

    getInstitutions(jsonParams){
        return getRequest({
            url: API_BASE_URL+"/lga/house/institutions" + queryParams(jsonParams),
            method: 'GET'
        });
    }


    getAllHouseHolders(institutionId,page, limit, sort, order){
        return getRequestTwo({
            url: API_BASE_URL + "/lga/house/holders/by/institution?institutionId="+institutionId+"&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method:  'GET'
        })
    }

    getAllTenants(institutionId,page, limit, sort, order){
        return getRequestTwo({
            url: API_BASE_URL + "/lga-tenants/by/institution?institutionId="+institutionId+"&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method:  'GET'
        })

    }
}