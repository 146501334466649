import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import {Dropdown} from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { WalletService } from '../../service/WalletService';
import { withTranslation } from 'react-i18next';
import { Paginator } from 'primereact/paginator';
import { TabPanel, TabView } from 'primereact/tabview';
import {Dialog} from 'primereact/dialog';
import BeatLoader from "react-spinners/BeatLoader";
 
class ListGroups extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            groups: [],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            groupName: '',
            grouStatus: "Active",
            groupId: 0,
            nGroupId: 0,
            sortOrder: -1,
            sortField: 'id',
            displayDiactivationAlert: false,
            alert: "",
            failed: false,
            alertMessage: '',
            isManager: false,
            sending: false,
            isForreallocation: false

        };

        this.walletService = new WalletService();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.removeGroup = this.removeGroup.bind(this);
        this.reallocate =this.reallocate.bind(this);
        this.onGroupChanged = this.onGroupChanged.bind(this);
        this.checkGroupBalance = this.checkGroupBalance.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
    }

    getMemberRole = () =>{
        const roles = this.props.roles;
        if(roles.includes("MANAGER")) {
            this.setState({isManager: true})
        }    
        
    }

    getDataList = () => {
        this.getMemberRole();
        const jsonParams = {groupName: this.state.groupName,groupStatus: this.state.grouStatus, page: this.state.pageIndex, limit: this.state.pageSize, sort: this.state.sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc'};
        return this.walletService.getGroups(jsonParams)
        .then(data => {
            const options = data.content.map(d => ({
                "value" : d.id,
                "label" : d.groupName
            }));
            this.setState({groups: options});
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    componentDidMount() {
        this.getDataList();
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            });
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });     
    }

    checkGroupBalance = (groupId) =>{    
        this.setState({groupId:groupId})    
        this.walletService.getGroupContributionHistory({groupId:groupId})
        .then(data => {
            if(!(data.status === "unauthorized")){ 
                this.setState({groupId: groupId});
                this.setState({displayDiactivationAlert: true});            
                this.setState({alertMessage: data.message});
                this.getDataList();      
                
            }else{
                this.setState({alert: data.message});
                this.setState({failed: true});
            }           
           
        }).catch(error => {
            this.setState({alert: error.message});
            this.setState({failed: true});
        });

    }

    removeGroup(event) {
        event.preventDefault();
        this.setState({sending: true});       
        this.walletService.deactivateGroup(this.state.groupId, "DELETE")
        .then(response => {
            if(!(response.status === "unauthorized")){
                this.setState({sending: false});
                this.setState({failed: false});
                this.setState({alert: response.message});
                this.getDataList();
                if(response.activeMembers !== 0){
                    this.setState({isForreallocation: true});
                    this.setState({alertMessage: response.reallocationMessage});
                }else{
                    this.setState({displayDiactivationAlert: false});
                }
            }else{                
                this.setState({sending: false});
                this.setState({alert: response.message});
                this.setState({failed: true});  
            }
            
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    onGroupChanged(event){
        const target = event.target;
        const nGroupId = target.value;
        this.setState({nGroupId: nGroupId})
    }

    reallocate(event){
        event.preventDefault();
        this.setState({sending: true}); 
        const options = {newGroupId: this.state.nGroupId, oldGroupId: this.state.groupId}
        this.walletService.reallocateMembers(options,"POST")
        .then(data =>{
            if(!(data.status === "unauthorized")){
                this.setState({displayDiactivationAlert: false});
                this.setState({sending: false});
                this.setState({failed: false});
                this.setState({alert: data.message});
            }else{
                this.setState({sending: false});
                this.setState({alert: data.message});
                this.setState({failed: true});  
            }
        }).catch(error => {
            this.setState({failed: true});
			this.setState({alert: error.message});
        })

    }

    booleanTemplate(rowData, column) {
        return rowData['enabled'] === true ? 'Active' : 'Inactive';
    }

    actionTemplate(rowData, column) {
        return <div>
            <Link to={"/wallet/edit/group/" + rowData["id"]}><Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.2em'}} title="Edit Group"/></Link>
            {/* <Button type="button"onClick={() => this.removeGroup(rowData["id"])} icon="pi pi-trash" className="p-button-danger"/> */}
            {this.state.isManager && <Button type="button"onClick={() => this.checkGroupBalance(rowData["id"])} icon="pi pi-trash" className="p-button-danger"/>}
        </div>;
    }

    disableDialogFooter = <div className="p-grid">
    <div className="p-col-6" style={{textAlign: "right"}}>
       <Button type="button" label={this.props.t("Cancel")} onClick={() => this.setState({displayDiactivationAlert: false})} icon="pi pi-times-circle" className="p-button-sm" style={{backgroundColor: "#EEEFFF", color: "#3B007B"}} />
   </div>
   <div className="p-col-6" style={{textAlign: "left"}}>
      <Button type="submit" label={this.props.t("Remove Group")} onClick={(e) =>  this.removeGroup(e)} icon="pi pi-check-circle" className="p-button-sm" />

   </div>
   </div>;

   reallocateDialogFooter = <div className="p-grid">
   <div className="p-col-6" style={{textAlign: "right"}}>
      <Button type="button" label={this.props.t("Cancel")} onClick={() => this.setState({displayDiactivationAlert: false})} icon="pi pi-times-circle" className="p-button-sm" style={{backgroundColor: "#EEEFFF", color: "#3B007B"}} />
  </div>
  <div className="p-col-6" style={{textAlign: "left"}}>
     <Button type="submit" label={this.props.t("Reallocate Members")} onClick={(e) =>  this.reallocate(e)} icon="pi pi-check-circle" className="p-button-sm" />

  </div>
  </div>;

    render() {

        let groupNameFilter = <InputText type="text" name="groupName" style={{width: '100%'}} placeholder="Contains" value={this.state.groupName} onChange={this.handleInputChange}/>

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>Contribution Groups</h2></div>
                            <div className="p-col-8 p-md-8">
                                <Link to="/wallet/add/group" style={{float: "right"}}><Button label="Add Group" icon="pi pi-plus"/></Link>
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" paginator={false} 
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="groupName" header="Group" sortable={true} filter={true} filterElement={groupNameFilter}/>
                            <Column field="conAmt" header="Contribution Amount" sortable={true} filter={false}/>
                            <Column field="enabled" body={this.booleanTemplate} header="Status" sortable={true} filter={false}/>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        <Paginator currentPageReportTemplate={"Showing " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator>


                    <Dialog header={this.props.t("Deactivation Box")} visible={this.state.displayDiactivationAlert} className="p-col-6 p-offset-2" modal footer={(this.state.isForreallocation === false ? this.disableDialogFooter : this.reallocateDialogFooter)} onHide={() => this.setState({displayDiactivationAlert: false})}>  
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                                    <TabPanel header={this.props.t("Pre Deactivation Alert")} leftIcon="pi pi-user">
                                        <div className="card card-w-title">
                                            <div className="p-col-12 p-md-12">
                                                <p>{this.state.alertMessage}</p>
                                                {this.state.isForreallocation && <Dropdown options={this.state.groups} value={this.state.nGroupId} onChange={event => this.onGroupChanged(event)} autoWidth={false} />}
                                            </div>                  


                                        </div>
                                    </TabPanel>                                 
                                </TabView>
                            </div>
                            {this.state.sending && 
                                <div className="p-grid p-fluid p-col-12" style={{textAlign:'center', padding: "2em"}}>
                                    <div className="p-col-12" style={{textAlign:'center'}}>
                                        <BeatLoader color={"#3B007B"} sending={this.state.sending} css={""} size={10} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </Dialog>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default withTranslation() (ListGroups)
