import React,{ Component } from "react";

import { Redirect } from 'react-router-dom';
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { withTranslation } from "react-i18next";
import { ManagerialService } from "../service/ManagerialService";

class AddCrises extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            dataJson:[],
            zones: [],
            zoneId: 0,
            cells: [],
            cellId: 0,
            roads: [],
            roadId: 0,
            dataId: 0,
            institutionId: 0,
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
           
            crisesCategory: "choose",
            crisesCategories: [{label: this.props.t("Sexual Harassment"),value: "SEXUAL_HARASSMENT"},{label: this.props.t("Fire"),value: "FIRE"},{label: this.props.t("Theft"),value: "THEFT"},{label: this.props.t("Accident"),value: "ACCIDENT"},{label: this.props.t("Health"),value: "HEALTH"},{label: this.props.t("Geographical"),value: "GEOGRAPHICAL"}],
            crisesStatus: "choose",
            crisesStatusList: [{label: this.props.t("Approved"),value: "APPROVED"},{label: this.props.t("Reported"),value: "REPORTED"}],
            reporterName: '',
            numberOfOccurrence: 0,

            phoneNumber: '',
            crisesPrompt: 'Add New Crises',
            
            sortOrder: -1,
            sortField: 'id',
            loading: false,
            displayReceipt: false,
            isAdmin: false,
            CashAllowed: true,
            userEmail: '',
            error: ""
         }

         this.managerialService = new ManagerialService();
         this.handleInputChange = this.handleInputChange.bind(this);
         this.handleSubmit = this.handleSubmit.bind(this);
         this.handleCrisesCategories = this.handleCrisesCategories.bind(this);
         this.handleDropdownChange = this.handleDropdownChange.bind(this);
         this.handleCrisesStatus = this.handleCrisesStatus.bind(this);

    }
    componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        // if (+this.props.match.params.dataId > 0) {
        //     this.setState({dataId: +this.props.match.params.dataId});
        //     this.managerialService.getSpecificCrises(+this.props.match.params.dataId)
        //     .then(data => {
        //         if(data.road === null){
        //             data.road = {id: 0, name: 'None', details: 'None'};                
        //           }
        //           if(data.cell === null){
        //             data.cell = {id: 0, name: 'None', details: 'None'};                
        //           }
        //           if(data.zone === null){
        //             data.zone = {id: 0, name: 'None', details: 'None'};                
        //           }              
        //         this.setState({dataJson: {id: data.id, road: {id: data.road.id+"-"+ data.cell.id}, cell: {id: data.cell.id},dateOfBirth: {dob: data.dob}, name: data.name, gender: data.gender, maritalStatus: data.maritalStatus, age: data.age, houseNumber: data.houseNumber, phoneNumber: data.phoneNumber, tenants: data.tenants, frames: data.frames, tenantType: data.tenantType, sendSms: data.sendSms,roadName: {name: data.road.name}, nameOfRoad: data.road.name}});
        //         console.log("Premises Data", this.state.dataJson)
        //     });
        // }
        this.getDataList();      
    }    

    getDataList = () =>{
        this.setState({institutionId: this.props.instituteId});
        this.fetchZones(); 
    }

    async  fetchZones() {
        try {       
            // &page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
          const jsonParams = { institutionId: this.props.instituteId, page: 0, limit: 200, sort: "id", order: 'asc' }   
          const response = await this.managerialService.getInstiutionalZones(jsonParams);
          const defaultOptions = [{"id": 0, "name": this.props.t("All Zones")},];
          const option = response.content.map((d,i) => ({
            "id": d.id,
            "name": d.name + "-"+d.id
          }))
          this.setState({zones: defaultOptions.concat(option)});
        } catch (error) {
          this.setState({alert: error.message});
        }
      }


      async  fetchCells() {
        try {       
            // &page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
          const jsonParams = { zoneId: this.state.zoneId, page: 0, limit: 200, sort: "id", order: 'asc' }   
          const response = await this.managerialService.getZonalCells(jsonParams);
          const defaultOptions = [{"id": 0, "name": this.props.t("All Cells")},];
          const option = response.content.map((d,i) => ({
            "id": d.id,
            "name": d.name + "-"+d.id
          }))
          this.setState({cells: defaultOptions.concat(option)});
        } catch (error) {
          this.setState({alert: error.message});
        }
      }

      async  fetchRoads() {
        try {       
            // &page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
          const jsonParams = { cellId: this.state.cellId, page: 0, limit: 200, sort: "id", order: 'asc' }   
          const response = await this.managerialService.getCellRoads(jsonParams);
          const defaultOptions = [{"id": 0, "name": this.props.t("All Roads")},];
          const option = response.content.map((d,i) => ({
            "id": d.id,
            "name": d.name + "-"+d.id
          }))
          this.setState({roads: defaultOptions.concat(option)});
        } catch (error) {
          this.setState({alert: error.message});
        }
      }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        const crisesJson = {
            crisesStatus: this.state.crisesStatus,
            crisesDetails: this.state.details,
            numberOfOccurrence: this.state.numberOfOccurrence,
            reporterName: this.state.reporterName,
            reporterPhoneNumber: this.state.phoneNumber,
            crisesCategory: this.state.crisesCategory,
            institutionId: this.state.institutionId,
            cellId: this.state.cellId,
            zoneId: this.state.zoneId,
            roadId: this.state.roadId
        };

        if(this.state.crisesCategory !== "choose"){          
                this.managerialService.addCrisesReport(crisesJson)
                .then(response => {
                    this.setState({failed: false});
                    this.setState({alert: response.message});
                    this.props.history.push("/wallet/lga/crises");
                }).catch(error => {
                    this.setState({failed: true});
                    this.setState({alert: error.message});
                });         

        }else{

            this.setState({failed: true});
            this.setState({alert: "Crises Report Failed, Invalid Crises Category selected"});
        }        
	}

    handleDropdownChange(event) {
        const target = event;
        const inputName = target.target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            if(inputName === "zoneId") {
                this.fetchCells();
                this.setState({roadId: 0}, () => {this.getDataList();});                
            }else if(inputName === "cellId"){
                this.fetchRoads();
                // this.setState({}, () => {this.getDataList();});  
            } else {
                this.getDataList();
            }
        });
    }

    handleCrisesCategories(event){
        const value = event.target.value;
        this.setState({crisesCategory:value});
        if(value !== "choose"){
            this.setState({crisesCategorySelected:true});
            // eslint-disable-next-line array-callback-return
            this.state.crisesCategories.map(data => {
                if(data.value === value){
                    this.setState({crisesPrompt: this.props.t("Report")+" "+data.label});
                }                
            });
            
        }else{
            this.setState({crisesCategorySelected:false}); 
        }        
    }

    handleCrisesStatus(event){
        const value = event.target.value;
        this.setState({crisesStatus:value});               
    }

   

    render() { 
        if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/cashcollections/0",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        { this.state.failed === true &&
                            <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                                    <div className="p-messages-wrapper rounded">
                                        <ul>
                                            <li>
                                                <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                                <span className="p-messages-detail">{this.state.alert}</span>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        }

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF", padding: "1%"}}>
                                <h2 style={{textAlign: "center"}}>{this.props.t(this.state.crisesPrompt)}</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>

                             <div className="p-grid">
                            <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Crises Category')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.crisesCategories} value={this.state.crisesCategory} onChange={this.handleCrisesCategories} required/>
                                        </div>
                                    </div>

                                    {/* <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Reference Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                        <Dropdown options={this.state.tenants} value={this.state.tenantId} onChange={this.handleTenantChanges} filter={true} required/>
                                        </div>
                                    </div> */}

                            </div>

                            

                           {this.state.crisesCategorySelected &&  <div><div className="p-grid">
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Reporter Name')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="reporterName" placeholder={this.props.t("Enter") + " " + this.props.t("Reporter Name")} value={this.state.reporterName}  onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Phone Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="phoneNumber" placeholder={this.props.t("Enter") + " " + this.props.t("Phone Number")} value={this.state.phoneNumber}  onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Number Of Occurrence')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="numberOfOccurrence" placeholder={this.props.t("Enter") + " " + this.props.t("Details")} value={this.state.numberOfOccurrence} mode="decimal" minFractionDigits={1} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    {/* <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Phone Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="phoneNumber" placeholder={this.props.t("Enter") + " " + this.props.t("Phone Number")} value={this.state.phoneNumber}  onChange={this.handleInputChange} required/>
                                        </div>
                                    </div> */}
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Crises Details')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="details" placeholder={this.props.t("Enter") + " " + this.props.t("Details")} value={this.state.details} mode="decimal" minFractionDigits={2} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Crises Status')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.crisesStatusList} value={this.state.crisesStatus} onChange={this.handleCrisesStatus} required/>
                                        </div>
                                    </div>


                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Zone')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                        <Dropdown name="zoneId" style={{width: '100%'}} placeholder={this.props.t("Choose Zone")} filterPlaceholder={this.props.t("Search") + " " + this.props.t("Zone")} options={this.state.zones} value={this.state.zoneId} onChange={this.handleDropdownChange} optionLabel="name" optionValue="id" filter filterBy="name" autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Cell')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                        <Dropdown name="cellId" style={{width: '100%'}} placeholder={this.props.t("Choose Cell")} filterPlaceholder={this.props.t("Search") + " " + this.props.t("Cell")} options={this.state.cells} value={this.state.cellId} onChange={this.handleDropdownChange} optionLabel="name" optionValue="id" filter filterBy="name" autoWidth={false} />                                       
                                         </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Road')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                        <Dropdown name="roadId" style={{width: '100%'}} placeholder={this.props.t("Choose Road")} filterPlaceholder={this.props.t("Search") + " " + this.props.t("Road")} options={this.state.roads} value={this.state.roadId} onChange={this.handleDropdownChange} optionLabel="name" optionValue="id" filter filterBy="name" autoWidth={false} />
                                        </div>
                                    </div>

                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={this.props.t("Confirm Collection")} icon="pi pi-plus" />
                                    </div>
                                </div></div>} 

                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default withTranslation()(AddCrises);